export interface Distribusjon {
  id: number;
  navn: string;
  endringsdager?: number;
  epostUrl: string
  metode: string
  format: string;
  komplett: boolean;
  ikkeHKodenr: boolean;
  aktiv: boolean;
  abonnementId: number;
  abonnementNavn: string
  frekvensText: string;
}

export interface DistribusjonsHistorikk {
  distribusjonsHistorikkId: number;
  mottakerId: number;
  mottakerNavn: string;
  tidspunkt: Date;
  distribusjonsId: number;
  distribusjonsNavn: string;
  antallEnheter: number;
}

export class DistribusjonsHistorikkHelper{
  static parseFromJson(json: any): DistribusjonsHistorikk {
    return {
      distribusjonsHistorikkId: json.distribusjonsHistorikkId,
      mottakerId: json.mottakerId,
      mottakerNavn: json.mottakerNavn,
      tidspunkt: json.tidspunkt,
      distribusjonsId: json.distribusjonsId,
      distribusjonsNavn: json.distribusjonsNavn,
      antallEnheter: json.antallEnheter,
    };
  }
}

