import React, { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup } from "react-bootstrap";

import cx from "classnames";

import CompareIcon from "../../../CompareIcon";

import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { qaCompareSimpleValue } from "pages/QA/components/QACardDetails/utils";

import Style from "../../index.module.css";

interface Props {
  telefon: string;
  telefonFromCr: string;
  onChange: (updated: string) => void;
  error?: string;
}

const TelefonRow: React.FC<Props> = props => {
  const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
  const [compareStatus, setCompareStatus] = useState<string>(
    qaCompareSimpleValue(props.telefonFromCr, props.telefon),
  );

  useEffect(() => {
    if (compareStatus === 'ulik' || compareStatus === 'ny') {
      setIsExpandRow(true);
    }
  }, [compareStatus]);

  useEffect(() => {
    setCompareStatus(qaCompareSimpleValue(props.telefonFromCr, props.telefon));
  }, [props.telefon, props.telefonFromCr]);

  return (
    <>
      <div className={cx(Style.changeRequestRowHeaderContainer, { [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={() => setIsExpandRow(!isExpandRow)}>
        <h6 className={Style.changeRequestRowHeader}>
          {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
          <span className={Style.changeRequestRowHeaderText}>Telefonnummer</span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.formGroupRow}>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm" className={Style.inputGroup}>
                  <Form.Control readOnly value={props.telefonFromCr} />
                  <InputGroup.Text>
                    <CopyToClipboardIcon val={props.telefonFromCr} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              <CompareIcon compareStatus={compareStatus} onClick={() => props.onChange(props.telefonFromCr)} />
            </div>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm" className={Style.inputGroup}>
                  <Form.Control
                    value={props.telefon || ''}
                    onChange={e => props.onChange(e.target.value)}
                    isInvalid={!!props.error}
                  />
                  <Form.Control.Feedback type="invalid">{props.error}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </div>
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default TelefonRow;