import React from 'react';
import { Alert } from 'react-bootstrap';

interface Props {
  alertHeader: string;
  variant?: string;
  setShow: (show: boolean) => void;
  children: React.ReactNode;
}

const AlertDismissible = ({ alertHeader, variant, setShow, children }: Props) => {
  return (
    <Alert variant={variant} onClose={() => setShow(false)} dismissible>
      <Alert.Heading>{alertHeader}</Alert.Heading>
      {children}
    </Alert>
  );
}

export default AlertDismissible;
