import { useState, useEffect } from 'react';
import { orderBy } from 'lodash';
import { Table, OverlayTrigger, Tooltip, Button, Dropdown } from 'react-bootstrap';
import SortingIcon from 'components/SortingIcon';
import { Grossist } from 'services/KjederegisteretAdminApi/GrossisterApi/types';
import DateHelper from 'utils/DateHelper';
import Style from './index.module.css';
import GrossistEdit from './components/EditGrossist';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import useToaster from 'utils/hooks/useToaster';

interface Props {
  data: Grossist[];
  loadGrossister: (filter: string) => void;
}

const GrossistTable = (props: Props) => {
  const [grossister, setGrossister] = useState<Grossist[]>(props.data);
  const [sortCol, setSortCol] = useState<string>('grossistnr');
  const [reverseSort, setReverseSort] = useState<boolean>(true);
  const [selectedGrossist, setSelectedGrossist] = useState<Grossist>(null);

  const toaster = useToaster();

  useEffect(() => {
    setGrossister(props.data);
    handleColSortClicked('grossistnr');
  }, [props.data]);

  useEffect(() => {
    sortTableColumn(sortCol, reverseSort);
  }, [sortCol, reverseSort]);

  const sortTableColumn = (col: string, reverse: boolean) => {
    const copy = [...grossister];
    const sorted = orderBy(copy, [col], reverse ? 'desc' : 'asc');
    setGrossister(sorted);
  };

  const handleColSortClicked = (col: string) => {
    if (col === sortCol) {
      setReverseSort(!reverseSort);
    } else {
      setSortCol(col);
      setReverseSort(false);
    }
  };

  const goToEnhetClicked = (grossist: Grossist) => {
    const url = `/enheter/${grossist.grossistEnhetId}`;
    window.open(url, '_blank');
  };

  const handleShowLeggNedModal = (grossist: Grossist) => {
    const copy = grossister.map(o =>
      o.grossistnr === grossist.grossistnr ? { ...o, leggNed: !o.leggNed } : o,
    );
    setGrossister(copy);
  };

  const handleEditGrossist = (grossist: Grossist) => {
    setSelectedGrossist(grossist);
  };

  const handleEditModalClosed = (): void => {
    setSelectedGrossist(undefined);
  }

  const handleLeggNedGrossist = async (enhetId: number) => {
    const result = await GrossisterApi.leggNedGrossist(enhetId, { grossistEnhetId: enhetId, nedlagtDato: new Date() });
    if (result.success) {
      toaster.success("Suksess", "Grossist lagt ned!");
    } else {
      toaster.error("En feil oppsto", result.error);
    }
  }

  return (
    <>
      <Table hover>
        <thead>
          <tr>
            <th scope="col" className="d-none d-lg-table-cell" onClick={() => handleColSortClicked('grossistnr')}>
              <div className={Style.grossistnrColumn}>
                <span className={Style.sortableColumnHeader}>Grossistnummer</span>
                <SortingIcon sortDirection={sortCol !== 'grossistnr' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th scope="col" onClick={() => handleColSortClicked('kortnavn')}>
              <div className={Style.kortnavnColumn}>
                <span className={Style.sortableColumnHeader}>Kortnavn (EnhetId)</span>
                <SortingIcon sortDirection={sortCol !== 'kortnavn' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th scope="col" onClick={() => handleColSortClicked('kortnavn')}>
              <div className={Style.kortnavnColumn}>
                <span className={Style.sortableColumnHeader}>Navn</span>
                <SortingIcon sortDirection={sortCol !== 'kortnavn' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th scope="col" onClick={() => handleColSortClicked('nedlagtdato')}>
              <div className={Style.nedlagtColumn}>
                <span className={Style.sortableColumnHeader}>Nedlagt dato</span>
                <SortingIcon sortDirection={sortCol !== 'nedlagtdato' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th scope='col' onClick={() => handleColSortClicked('grossistgruppe')}>
              <div className={Style.grossistColumn}>
                <span className={Style.sortableColumnHeader}>Grossistgruppe</span>
                <SortingIcon sortDirection={sortCol !== 'grossistgruppe' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th className='text-center' scope='col' onClick={() => handleColSortClicked('activeEnhetCount')}>
              <div className={Style.grossistColumn}>
                <span className={Style.sortableColumnHeader}>Aktive enheter</span>
                <SortingIcon sortDirection={sortCol !== 'activeEnhetCount' ? '' : (reverseSort ? 'desc' : 'asc')} />
              </div>
            </th>
            <th className='fitContent'>
            </th>
            <th className='fitContent'>
              <div />
            </th>
          </tr>
        </thead>

        <tbody>
          {
            grossister.map(grossist => (
              <tr key={`${grossist.grossistnr}-${Math.random()}`}>
                <td className="d-none d-lg-table-cell">
                  {grossist.grossistnr}
                </td>
                <td>
                  <div>
                    <OverlayTrigger placement="top" delay={{ show: 300, hide: 200 }}
                      overlay={<Tooltip id="naviger-to-enhet-tooltip">Gå til underliggende enhet</Tooltip>}>
                      <Button variant="link" size="sm" onClick={() => goToEnhetClicked(grossist)}>
                        {`${grossist.kortnavn} (${grossist.grossistEnhetId})`}
                      </Button>
                    </OverlayTrigger>
                  </div>
                </td>
                <td>
                  {grossist.navn}
                </td>
                <td>
                  {DateHelper.formatDate(grossist.nedlagtdato)}
                </td>
                <td>
                  {grossist.grossistgruppe}
                </td>
                <td className='text-center'>
                  <a href={`/enheter?aktivFilter=Active&grossistFilter[]=${grossist.grossistnr}`}>
                    {grossist.activeEnhetCount}
                  </a>
                </td>
                <td className='fitContent'>
                  <IconButton icon="edit" variant='outline-primary' onClick={() => handleEditGrossist(grossist)}>Endre</IconButton>
                </td>
                <td className='fitContent'>
                  <Dropdown className="noCaret">
                    <Dropdown.Toggle style={{ padding: "0.3rem" }} variant="none" id="dropdown-basic">
                      <IcebergIcon icon="ellipsis" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        disabled={grossist.activeEnhetCount > 0}
                        title={grossist.activeEnhetCount > 0 ? "Aktive enheter må flyttes til en annen grossist før grossist kan legges ned" : ""}
                        onClick={() => handleLeggNedGrossist(grossist.grossistEnhetId)}>
                        Legg ned
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {
        selectedGrossist && (
          <GrossistEdit
            grossist={selectedGrossist}
            onClose={handleEditModalClosed}
          />
        )}
    </>
  )
}

export default GrossistTable;
