import React, { useEffect, useState } from 'react';
import DropdownTreeSelect, { Mode, TreeNode } from 'react-dropdown-tree-select';

import cx from 'classnames';

import { mapBransjeTre } from './utils';

import BransjeApi from 'services/KjederegisteretAdminApi/BransjeApi';
import { BransjeTree } from 'services/KjederegisteretAdminApi/BransjeApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';


export interface PickedBransje {
  id: number;
  name: string;
}

interface Props {
  onSelectionChanged: (bransjer: PickedBransje[]) => void;
  selected?: number[];
  disabled?: boolean;
  isSmall?: boolean;
  mode?: Mode;
  canSelectNedlagt?: boolean;
}

const BransjePicker = (props: Props) => {

  const [bransjeTre, setBransjeTre] = useState<BransjeTree[]>([]);
  const [isFetchingBransjer, setIsFetchingBransjer] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  const loadBransjeTre = async () => {
    setIsFetchingBransjer(true);
    const tre = await BransjeApi.getTree();
    setBransjeTre(tre);
    setIsFetchingBransjer(false);
  };

  const handleOnChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      const selectedBransjer = selectedNodes.map(o => ({ id: o.id, name: o.label }));
      props.onSelectionChanged(selectedBransjer);
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {
      loadBransjeTre();
    }
  }, []);


  const data = mapBransjeTre(bransjeTre, props.selected, props.canSelectNedlagt);

  return (
    <>
      <DropdownTreeSelect
        className={cx('reactDropdownMultiPicker', Style.multiPicker, {
          [Style.small]: props.isSmall,
        })}
        mode={props.mode || "multiSelect"}
        texts={{
          placeholder: 'Velg bransje',
          noMatches: 'Ingen treff',
        }}
        data={data || []}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        onChange={handleOnChange}
        disabled={props.disabled}
      />
      {isFetchingBransjer && (
        <div>
          <CommonLoader />
          <span> Henter data... </span>
        </div>
      )}
    </>
  );
};

export default React.memo(BransjePicker);
