import CommonLoader from "components/CommonLoader";
import EnhetTypeahead from "components/EnhetTypeahead";
import { PickedEnhet } from "components/EnhetTypeahead/types";
import {useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import EnhetApi from "services/KjederegisteretAdminApi/EnhetApi";
import { RekobleLeverandorKundenummerCommand } from "services/KjederegisteretAdminApi/EnhetApi/Commands";
import { LeverandorKundenummerDto } from "services/KjederegisteretAdminApi/EnhetApi/types";

interface Props {
    item?: LeverandorKundenummerDto;
    show: boolean;
    onClose: () => void;
    onUpdated: () => void;
}

const RekobleModal = (props: Props) => {
    const [selectedEnhet, setSelectedEnhet] = useState<PickedEnhet>();
    const [isLoading, setIsLoading] = useState<boolean>(false); 
    
    const onChange = (enhet: PickedEnhet) => {
        if (enhet && enhet.id) {
          setSelectedEnhet(enhet);
        } else {
          setSelectedEnhet(null);
        }
      };

    const handleRekobleClicked = async () => {        
        setIsLoading(true);
        const command: RekobleLeverandorKundenummerCommand = {
            id: props.item.id,
            moveToEnhetId: selectedEnhet.id,
          };
        await EnhetApi.rekobleLeverandorKundenummer(command);
        setIsLoading(false);
        props.onUpdated();
        props.onClose();
      };

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Rekoble {props.item?.kundenummer} fra {props.item?.mottakerNavn}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Velg enhet for rekobling</Form.Label>
                        <EnhetTypeahead onChange={enhet => onChange(enhet)} />                        
                    </Form.Group>                    
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" className="col" onClick={props.onClose}>
                    Lukk
                </Button>
                <Button variant="primary" className="col" onClick={handleRekobleClicked} disabled={!selectedEnhet || isLoading}>
                    Rekoble {isLoading && (
                        <CommonLoader />
                    )}
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default RekobleModal;