import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface Props {
  status: string;
}

// listens on signalr events when a changeRequest has got its status updated
const SignalRConnectionStatusIcon: React.FC<Props> = props => {

  const parseIconColor = (status: string) => {
    switch (status) {
      case 'Tilkoblet':
        return 'green';
      case 'Kobler til':
        return 'blue';
      default:
        return 'grey';
    }
  };

  const iconColor = parseIconColor(props.status);

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id={`tooltip-${props.status}`}>
          <strong>{props.status}</strong>
        </Tooltip>
      }
    >
      <span><div style={{backgroundColor: iconColor, height: 14, width: 14, borderRadius: 10}} /></span>
    </OverlayTrigger>
  );
};

export default SignalRConnectionStatusIcon;
