import { useState } from 'react';
import { ListGroupItem, Button } from 'react-bootstrap';
import { OverliggendeKjede } from 'services/KjederegisteretAdminApi/KjedeknytningerApi/types';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import AvsluttMedlemskapModal from '../../AvsluttMedlemskapModal';
import Style from './index.module.css';
import { format } from 'date-fns';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

interface Props {
  data: OverliggendeKjede;
  kjedeEnhet: EnhetViewModel;
  isLoadingKjedeEnhet: boolean;
  getKjedeEnhet: (id: number) => void;
  onKjedeLagtNed: () => void;
}

const HistoryItem = (props: Props) => {
  const [isShowAvsluttMedlemskapModal, setIsShowAvsluttMedlemskapModal] = useState(false);

  const isMountedRef = useIsMountedRef();

  const handleCloseAvsluttMedlemskapModal = () => {
    if (isMountedRef.current) {
      setIsShowAvsluttMedlemskapModal(false);
    }
  };
  const handleShowAvsluttMedlemskapModal = () => {
    if (isMountedRef.current) {
      setIsShowAvsluttMedlemskapModal(true);
    }
  };

  const goToKjedeEnhet = (id: number) => {
    const win = window.open(`/enheter/${id}`, '_blank', 'noopener,noreferrer');
    if (win != null) {
      win.focus();
    }
  };

  const isFremtidig: boolean = new Date(props.data.fraOgMedDato) > new Date();

  return (
    <ListGroupItem className={Style.listItem} variant={props.data.isActive && 'success'}>
      <div className={Style.membershipContainer}>
        <div className={Style.membership}>
          <Button
            title="Overliggende kjede sitt hovedkontor"
            onClick={() => goToKjedeEnhet(props.data.parentKjede.enhetID)}
            className={Style.goToKjedeLinkBtn}
            variant="link"
          >
            {props.data.parentKjede.kjedenavn}
          </Button>
          <span className={props.data.isActive || isFremtidig ? 'text-success' : 'text-danger'}>
            <strong> / </strong>
          </span>
          <span
            style={!props.data.isActive && !isFremtidig ? { textDecorationLine: 'line-through' } : {}}
          >
            {props.data.kjede.kjedenavn}
          </span>
        </div>
        <div className={Style.dates}>
          <span title="Fra og med dato" style={isFremtidig ? { color: 'rgba(var(--bs-warning-rgb))' } : {}}>
            <strong>{isFremtidig ? 'Fremtidig innmelding:' : 'Innmeldt:'}</strong>{' '}
            {format(new Date(props.data.fraOgMedDato), 'dd.MM.yyyy')}
          </span>
          {props.data.tilOgMedDato && (
            <span title="Til og med dato" style={{ paddingLeft: '1em' }}>
              <strong>Utmeldt:</strong> {format(new Date(props.data.tilOgMedDato), 'dd.MM.yyyy')}
            </span>
          )}
        </div>
      </div>

      <div className={Style.actionBtnContainer}>
        {props.data.isActive && (
          <Button
            onClick={() => handleShowAvsluttMedlemskapModal()}
            className={Style.closeMembershipBtn}
            size="sm"
            variant="outline-danger"
          >
            Meld ut
          </Button>
        )}
      </div>
      <AvsluttMedlemskapModal
        kjedeID={props.data.kjede.kjedeID}
        overliggendeKjede={props.data}
        kjedeEnhet={props.kjedeEnhet}
        isLoadingKjedeEnhet={props.isLoadingKjedeEnhet}
        isToppkjede={false}
        isShowModal={isShowAvsluttMedlemskapModal}
        handleCloseModal={handleCloseAvsluttMedlemskapModal}
        onKjedeLagtNed={props.onKjedeLagtNed}
        getKjedeEnhet={props.getKjedeEnhet}
      />
    </ListGroupItem>
  );
};

export default HistoryItem;
