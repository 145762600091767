import React from "react";
import { Form } from "react-bootstrap";

import NedlagtDatoRow from "./components/NedlagtDatoRow";
import KundenummerRow from "./components/KundenummerRow";
import EnhetnavnRow from "./components/EnhetnavnRow";
import GlnRow from "./components/GlnRow";
import OrgNrRow from "./components/OrgNrRow";
import { OrgNrInfo } from "./components/OrgNrRow/types";
import TelefonRow from "./components/TelefonRow";
import GrossistKundenummerRow from "./components/GrossistKundenummerRow";

import { EndringsMelding } from 'services/QAApi/types';
import { EnhetViewModel, FirmaViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";
import { ChangeRequestCommand } from "services/KjederegisteretAdminApi/ChangeRequestApi/types";

import { IFormData } from 'utils/hooks/useForm';

import Style from "./index.module.css";

interface Props {
  formData: IFormData<ChangeRequestCommand, EnhetViewModel>;
  changeRequest: EndringsMelding;
  firma: FirmaViewModel;
  enhet: EnhetViewModel;
  duplicateWarning?: { orgnrDupes: string, nameDupes: string };
}

const QABasisForm: React.FC<Props> = props => {

  const handleUpdatedOrgNrInfo = (updatedInfo: OrgNrInfo) => {
    const copy = { ...props.formData.values };
    copy.organisasjonsnr = updatedInfo.organisasjonsnr;
    copy.isOrgnrValidated = updatedInfo.isOrgnrValidated;
    copy.juridisknavn = updatedInfo.juridisknavn;
    props.formData.updateValues(copy);
  };

  return (
    <div className={Style.basisFormContainer}>
      <Form>
        <NedlagtDatoRow
          crNedlagtDato={props.changeRequest.changes.nedlagtDato}
          enhetNedlagtDato={props.formData.values.nedlagtDato}
          enhetOppstartDato={props.firma?.oppstartsdato || undefined}
          onChange={date =>
            props.formData.handleChange('nedlagtDato', date ? date : null)
          }
          changeRequest={props.changeRequest.changes}
          enhetId={props.formData.values.enhetId}
          lopenummer={props.formData.values.lopenummer}
          leverandorKundenummer={props.enhet?.leverandorKundenummer}
        />

        <KundenummerRow
          enhetId={props.formData.values.enhetId}
          leverandorKundenummer={props.enhet?.leverandorKundenummer}
          values={props.formData.values.leverandorKundenummer}
          onChange={values => props.formData.handleChange('leverandorKundenummer', values)}
          changeRequest={props.changeRequest.changes}
        />

        <EnhetnavnRow
          enhetnavn={props.formData.values.enhetnavn}
          onChange={updatedEnhetnavn => props.formData.handleChange('enhetnavn', updatedEnhetnavn)}
          enhetnavnFromCr={props.changeRequest.changes.enhetnavn}
          duplicateWarning={props.duplicateWarning?.nameDupes}
          error={
            props.formData.touched.enhetnavn && props.formData.errors.enhetnavn
              ? props.formData.errors.enhetnavn
              : undefined
          }
        />

        <GlnRow
          enhet={props.enhet}
          gln={props.formData.values.gln}
          glnFromCr={props.changeRequest.changes.gln}
          eierGLNNavn={props.firma?.eierGLNNavn}
          eierGLNNavnFromCr={props.changeRequest.changes.eierGLNNavn}
          sentBy={props.changeRequest.sentBy}
          sentBySystem={props.changeRequest.sentBySystem}
          onChange={updatedGln => props.formData.handleChange('gln', updatedGln)}
        />

        <OrgNrRow
          changeRequestId={props.changeRequest.id}
          incomingChangeRequest={{
            organisasjonsnr: props.changeRequest.changes.organisasjonsnr,
            juridisknavn: props.changeRequest.changes.juridisknavn,
          }}
          outgoingChangeRequest={{
            organisasjonsnr: props.formData.values.organisasjonsnr,
            isOrgnrValidated: props.formData.values.isOrgnrValidated,
            juridisknavn: props.formData.values.juridisknavn,
          }}
          enhetJurNavn={props.firma?.juridisknavn}
          duplicateWarning={props.duplicateWarning?.orgnrDupes}
          onChange={handleUpdatedOrgNrInfo}
        />

        <TelefonRow
          onChange={updated => props.formData.handleChange('telefon', updated)}
          telefon={props.formData.values.telefon}
          telefonFromCr={props.changeRequest.changes.telefon}
          error={
            props.formData.touched.telefon && props.formData.errors.telefon
              ? props.formData.errors.telefon
              : undefined
          }
        />

        <GrossistKundenummerRow
          onChange={(updated, updateHovedGrossist) => {
            if (updateHovedGrossist) {
              const valueCopy = { ...props.formData.values };
              if (props.formData.values.hovedGrossist) {
                const copy = [...props.formData.values.andreGrossister, props.formData.values.hovedGrossist].filter(x => x.grossistGln !== updated.grossistGln);
                valueCopy.andreGrossister = copy;
              }
              valueCopy.hovedGrossist = updated;
              props.formData.updateValues(valueCopy)
            } else {
              const copy = [...props.formData.values.andreGrossister, updated];
              props.formData.handleChange('andreGrossister', copy);
            }
          }}
          enhetId={props.formData.values.enhetId}
          changeRequest={props.changeRequest.changes}
          hovedGrossist={props.formData.values.hovedGrossist}
          andreGrossister={props.formData.values.andreGrossister}
          error={
            props.formData.touched.grossist && props.formData.errors.grossist
              ? props.formData.errors.grossist
              : undefined
          }
        />
      </Form>
    </div>
  )
}

export default QABasisForm;