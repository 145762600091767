import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

interface Props {
  btntxt?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  loading?: boolean;
  tooltip?: JSX.Element;
  onClick?: () => void;
}

const SpinnerButton: React.FC<Props> = props => {

  const clickHandler = async () => {
    if (props.onClick) {
      await props.onClick();
    }
  };

  const renderTooltip = attr => (
    <Tooltip id="button-tooltip" {...attr}>
      {props.tooltip || ''}
    </Tooltip>
  );

  return (
    <>
      {props.disabled && props.tooltip ? (
        <OverlayTrigger
          rootClose
          placement="top"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <div
            className={Style.btnContainer}
            style={{ display: 'inline-block', cursor: 'not-allowed' }}
          >
            <Button
              className={Style.button}
              type={props.type ? props.type : "button"}
              disabled={true}
              onClick={clickHandler}
              style={{ pointerEvents: 'none' }}
            >
              {props.btntxt && <div className={Style.btntxt}>{props.btntxt}</div>}
              {props.loading && (
                <CommonLoader/>
              )}
            </Button>
          </div>
        </OverlayTrigger>
      ) : (
        <div className={Style.btnContainer}>
          <Button
            className={Style.button}
            type={props.type ? props.type : "button"}
            onClick={clickHandler}>
            {props.btntxt && <div className={Style.btntxt}>{props.btntxt}</div>}
            {props.loading && (
                <CommonLoader />
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default SpinnerButton;
