import Sidebar, { NavLink } from ".";


const SidebarWrapper = () => {

    const navLinks: NavLink[] = [
        { label: 'QA', link: '/qa', icon: 'document-detail' },
        { label: 'Enheter', link: '/enheter', icon: 'store' },
        { label: 'Kjeder', link: '/kjeder', icon: 'kradmin' },
        { label: 'Enhetsgrupperinger', link: '/enhetsgruppering', icon: 'cooperation' },
        { label: 'Bransjer', link: '/bransjer', icon: 'category' },
        { label: 'Grossister', link: '/grossister', icon: 'wholesaler' },
        { label: 'Mottakere', link: '/mottakere', icon: 'users' },
        { label: 'Abonnement', link: '/abonnement', icon: 'credit-card' },
        { label: 'Distribusjoner', link: '/distribusjoner', icon: 'takecargo' },
        { label: 'Importer', link: '/importer', icon: 'import' },
        { label: 'Bulkjobber', link: '/bulkjobber', icon: 'combine' },
    ];
    return <Sidebar navLinks={navLinks} />;
}

export default SidebarWrapper;