import { useState, useEffect } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import SearchBox from './SearchBox';
import LocationMarker from './LocationMarker';
import SetLocationMarker from './SetLocationMarker';
import useGoogleMaps from 'utils/hooks/useGoogleMaps';
import useLongPress from 'utils/hooks/useLongPress';
import { Coordinates } from 'models/Coordinates';
import CommonLoader from 'components/CommonLoader';

interface Props {
  center: Coordinates;
  useSearchBox?: boolean;
  existingLocation?: Coordinates;
  existingLocationTitle?: string;
  canUpdateLocation?: boolean;
  onLatLngChanged?: (coordinates: { lat: number; lng: number }) => void;
  onLatLngRemoved?: (id: number) => void;
}

const GoogleMaps = (props: Props) => {
  const [center, setCenter] = useState(props.center);
  const [custLocationMarker, setCustLocationMarker] = useState<Coordinates>(undefined);
  const isLoaded = useGoogleMaps();

  const longPressCallback = e => {
    if (e && e.latLng) {
      if (props.canUpdateLocation) {

      }
      setCustLocationMarker(new Coordinates(
        e.latLng.lat(),
        e.latLng.lng()
      ));
    }
  };

  const longPressProps = useLongPress({
    onLongPress: ev => longPressCallback(ev),
  });

  useEffect(() => {
    if (props.existingLocation) {
      setCustLocationMarker(undefined);
      setCenter(props.existingLocation);
    }
  }, [props.existingLocation]);

  useEffect(() => {
    if (props.center) {
      setCenter(props.center);
    }
  }, [props.center]);

  if (isLoaded) {
    return (
      <GoogleMap
        id="address-map"
        mapContainerStyle={{
          height: '100%',
          width: '100%',
        }}
        zoom={12}
        center={center}
        {...longPressProps}
      >
        {props.useSearchBox && <SearchBox onPlaceFound={setCenter} />}
        {props.existingLocation &&
          <LocationMarker
            markedLocation={props.existingLocation}
            title={props.existingLocationTitle}
            editable={props.canUpdateLocation}
            onLatLngChanged={props.onLatLngChanged}
            onLatLngRemoved={props.onLatLngRemoved} />}
        {props.canUpdateLocation &&
          <SetLocationMarker position={custLocationMarker} setPosition={setCustLocationMarker} onLatLngChanged={props.onLatLngChanged} />}
      </GoogleMap>
    );
  }
  return <CommonLoader />;
}

export default GoogleMaps;
