import React, { useEffect, useState } from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { format } from 'date-fns';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { EnhetViewModel, PropertyChange } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

interface Props {
  enhet: EnhetViewModel;
}

const ChangesView: React.FC<Props> = props => {
  const [changes, setChanges] = useState<PropertyChange[]>([]);

  const isMountedRef = useIsMountedRef();

  const loadChanges = async (id: number) => {
    const result = await EnhetApi.getChanges(id);
    if (isMountedRef.current) {
      setChanges(result);
    }
  };

  useEffect(() => {
    loadChanges(props.enhet.id);
  }, [props.enhet]);

  return (
    <Row>
      <Col>
        <Table striped>
          <thead>
            <tr>
              <th>Navn</th>
              <th>Gammel verdi</th>
              <th>Ny verdi</th>
              <th>Tidspunkt</th>
              <th>Endret av</th>
            </tr>
          </thead>
          <tbody>
            {changes.map(x => (
              <tr key={x.id}>
                <td>{x.column}</td>
                <td>{x.oldValue}</td>
                <td>{x.newValue}</td>
                <td>{format(x.timestamp, 'dd.MM.yyyy')}</td>
                <td data-private>{x.changedBy}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default ChangesView;
