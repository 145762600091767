import axios from "axios";
import { AddEnhetKjedeMedlemskapCommand, UpdateEnhetKjedeMedlemskapCommand, EnhetKjede } from "./types";

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/enhetkjede';

const EnhetKjedeApi = {
  update: async (id: number, command: UpdateEnhetKjedeMedlemskapCommand): Promise<EnhetKjede> => {
    const response = await axios.put(`${baseUrl}/${id}`, command);
    return response.data;
  },
  add: async (command: AddEnhetKjedeMedlemskapCommand): Promise<EnhetKjede> => {
    const response = await axios.post(`${baseUrl}`, command);
    return response.data;
  },
  delete: async (id: number): Promise<EnhetKjede> => {
    const response = await axios.delete(`${baseUrl}/${id}`);
    return response.data;
  },
  reaktiver: async (id: number): Promise<EnhetKjede> => {
    const response = await axios.post(`${baseUrl}/${id}/reaktiver`);
    return response.data;
  }
}

export default EnhetKjedeApi;