import { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup } from "react-bootstrap";

import cx from "classnames";
import querystring from 'query-string';

import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon, useLocalStorage } from '@tradesolution/iceberg-ui-react';

import CompareIcon from "../../../CompareIcon";

import { EnhetSearchFilter } from "services/KjederegisteretAdminApi/EnhetSearchApi/types";

import { qaCompareSimpleValue } from "../../../../utils";
import openInNewTab from "utils/functions/openInNewTab";

import Style from "../../index.module.css";

interface Props {
  enhetnavn: string;
  onChange: (updatedEnhetnavn: string) => void;
  enhetnavnFromCr: string;
  duplicateWarning?: string;
  error?: string;
}

const EnhetnavnRow= (props: Props )=> {
  const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
  const [compareStatus, setCompareStatus] = useState<string>(
    qaCompareSimpleValue(props.enhetnavnFromCr, props.enhetnavn)
  );
  const [isUserPreferneceExpandRow, setIsUserPreferneceExpandRow] = useLocalStorage<boolean>('userPreferenceEnhetnavnRowExpanded', false);

  const handleGoToEnhetSearch = (criteria: string) => {
    let filter = new EnhetSearchFilter();
    filter.criteria = criteria;
    let searchstring = querystring.stringify(filter, {
      arrayFormat: 'bracket',
      skipNull: true,
    });
    openInNewTab(`/enheter?${searchstring}`);
  }

  useEffect(() => {
    if (compareStatus === 'ulik' || compareStatus === 'ny') {
      setIsExpandRow(true);
    }
    if(isUserPreferneceExpandRow) {
      setIsExpandRow(true);
    }
  }, [compareStatus]);

  useEffect(() => {
    setCompareStatus(qaCompareSimpleValue(props.enhetnavnFromCr, props.enhetnavn));
  }, [props.enhetnavn, props.enhetnavnFromCr]);

  const handleExpandRowClick = () => {
    setIsExpandRow(!isExpandRow);
    setIsUserPreferneceExpandRow(!isExpandRow);
  }

  const [isEnhetnavnFormatCorrect, setIsEnhetnavnFormatCorrect] = useState(true);

  const checkEnhetnavnFormat = (enhetnavn) => {
    // This regex checks if 'AVD' or 'avd' is present but not followed by a period and numbers
    const incorrectFormatRegex = /\bavd\b(?!\.\d+)/i;

    // This regex checks if 'AVD' or 'avd' is followed by a period and numbers
    const correctFormatRegex = /\bavd\.\d+/i;

    // If 'AVD' or 'avd' is present and not followed by the correct format, return false
    if (incorrectFormatRegex.test(enhetnavn) && !correctFormatRegex.test(enhetnavn)) {
      return false;
    }
    // If 'AVD' or 'avd' is not present or followed by the correct format, return true
    return true;
  };

  useEffect(() => {
    // If enhetnavn is not empty, check its format
    if (props.enhetnavn) {
      setIsEnhetnavnFormatCorrect(checkEnhetnavnFormat(props.enhetnavn));
    } else {
      // If enhetnavn is empty, we consider the format correct by default (no message shown)
      setIsEnhetnavnFormatCorrect(true);
    }
  }, [props.enhetnavn]);
  return (
    <>
      <div
        className={cx(Style.changeRequestRowHeaderContainer, { 
          [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={handleExpandRowClick}>
        <h6 className={Style.changeRequestRowHeader}>
          {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
          <span className={Style.changeRequestRowHeaderText}>Enhetnavn</span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.formGroupRow}>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm" className={Style.inputGroup}>
                  <Form.Control value={props.enhetnavnFromCr || ''} readOnly />
                  <InputGroup.Text className='text-muted'>
                    <CopyToClipboardIcon val={props.enhetnavnFromCr} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              <CompareIcon
                compareStatus={compareStatus}
                onClick={() => props.onChange(props.enhetnavnFromCr)} />
            </div>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm">
                  <Form.Control
                    value={props.enhetnavn || ''}
                    onChange={e => props.onChange(e.target.value)}
                    isInvalid={!!props.error}
                  />
                  <Form.Control.Feedback type="invalid">{props.error}</Form.Control.Feedback>
                </InputGroup>
                {!props.error && !props.enhetnavn && <Form.Text className="text-muted">Dette feltet er påkrevd.</Form.Text>}
                {!props.error && props.enhetnavn && props.duplicateWarning && (
                  <Form.Text className="text-muted">
                    <span>{props.duplicateWarning} </span>
                    <a style={{ fontSize: '12px' }} href="#" onClick={() => handleGoToEnhetSearch(props.enhetnavn)}>Se enhet(er)</a>
                  </Form.Text>
                )}
                {!isEnhetnavnFormatCorrect && (
                  <div className='text-primary'>Avdeling skal forkortes AVD.xxxx</div>
                )}
              </Form.Group>
            </div>
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default EnhetnavnRow;