import axios from 'axios';

import { Grossist, TilknyttedeEnheter, GrossistEnhet, LeggNedGrossistCommand, AddOrUpdateGrossistCommand, RekboleGrossistCommand } from './types';

import { getKjederegAdminApiUrl } from "../../";
import { ApiHelper, ApiResult } from 'services/ApiHelper';

const baseUrl = getKjederegAdminApiUrl() + '/grossister';

async function addGrossist(grossist: AddOrUpdateGrossistCommand): Promise<Grossist> {
  const response = await axios.post(`${baseUrl}`, grossist);
  return response.data;
}

async function leggNedGrossist(id: number, command: LeggNedGrossistCommand): Promise<ApiResult<any>> {
  return await ApiHelper.post<any>(`${baseUrl}/${id}/leggned`, command);
}

async function updateGrossist(id: number, grossist: AddOrUpdateGrossistCommand): Promise<Grossist> {
  const response = await axios.put(`${baseUrl}/${id}/update`, grossist)
  return response.data;
}

async function getGrossister(q?: string, activeOnly: boolean = false): Promise<Grossist[]> {
  const response = await axios.get(`${baseUrl}?query=${q || ''}&activeOnly=${activeOnly}`);
  return response.data;
}

async function searchEnheterWithGrossistType(filter: { query: string }): Promise<GrossistEnhet[]> {
  const response = await axios.get(`${baseUrl}/grossistenheter?query=${filter.query || ''}`);
  return response.data;
}

async function getEnheterByGrossistId(id: number, page: number, pageSize: number, searchQuery: string, includeNedlagt: boolean, includeHistoric: boolean): Promise<TilknyttedeEnheter> {
  const response = await axios.get(`${baseUrl}/${id}/enheter?page=${page || ''}&pageSize=${pageSize || ''}&q=${searchQuery || ''}&includeNedlagt=${includeNedlagt || false}&includeHistoric=${includeHistoric || false}`);
  return response.data;
}

async function rekobleGrossist(command: RekboleGrossistCommand) {
  const response = await axios.post(`${baseUrl}/rekoble`, command);
  return response.data;
}

export default {
  getGrossister,
  addGrossist,
  leggNedGrossist,
  getEnheterByGrossistId,
  updateGrossist,
  searchEnheterWithGrossistType,
  rekobleGrossist
};
