import axios from 'axios';
import { KjedeType } from '../KjedeApi/types';
import {
  LeggNedCommand,
  UpdateEnhetCommand,
  CreateEnhetCommand,
  EierskifteCommand,
  MarkerSomDublettCommand,
  UpdateGlnCommand,
  RemoveGlnCommand,
  RemoveEnhetgruppeFromEnhetCommand,
  ReaktiverEnhetgruppeMedlemskapCommand,
  AddToFlereGrupperingerCommand,
  UpdateGrossisterCommand,
  UpdateGrupperingCommand,
  UpdateAddressesCommand,
  KobleTilLeverandorKundenummerCommand,
  FjernKoblingTilLeverandorKundenummerCommand,
  RekobleLeverandorKundenummerCommand,
  UpdateAddresseCommand,
  DeleteAddressCommand
} from './Commands';
import { EnheterInGrupperingQuery, EnheterInKjedeQuery } from './Queries';
import {
  EnhetViewModel, GenericViewModel, GrupperingMedlem, KjedeHistorikk, KjedeMedlem,
  KontonrOgFakturaAdresseSjekkResponse, PagedCollection,
  PropertyChange, EierskifteEnhetViewModel, Enhetsgruppering,
  ReAktiviserCommand,
  LeverandorKundenummerDto
} from './types';

import { getKjederegAdminApiUrl } from "../../";
import { EnhetSearchFilter } from '../EnhetSearchApi/types';

const baseUrl = getKjederegAdminApiUrl() + '/enhet';

const EnhetApi = {

  getEnhetById: async (id: number): Promise<EnhetViewModel> => {
    let response;
    try {
      response = await axios.get(`${baseUrl}/${id}`);
    } catch (e) {
      return null;
    }

    if (response.data.regionalkjedeHistorikk) {
      response.data.regionalkjedeHistorikk = response.data.regionalkjedeHistorikk.map((o: KjedeHistorikk) => {
        o.fraOgMedDato = new Date(o.fraOgMedDato);
        o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;
        o.indirekteHistorikk = o.indirekteHistorikk.map(i => {
          i.fraOgMedDato = new Date(i.fraOgMedDato);
          i.tilOgMedDato = i.tilOgMedDato ? new Date(i.tilOgMedDato) : undefined;
          return i;
        });
        return o;
      });
    }

    if (response.data.markedskjedeHistorikk) {
      response.data.markedskjedeHistorikk = response.data.markedskjedeHistorikk.map((o: KjedeHistorikk) => {
        o.fraOgMedDato = new Date(o.fraOgMedDato);
        o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;

        o.indirekteHistorikk = o.indirekteHistorikk.map(i => {
          i.fraOgMedDato = new Date(i.fraOgMedDato);
          i.tilOgMedDato = i.tilOgMedDato ? new Date(i.tilOgMedDato) : undefined;
          return i;
        });
        return o;
      });
    }

    if (response.data.sisteRegionalkjedeMedlemskap) {
      response.data.sisteRegionalkjedeMedlemskap.fraOgMedDato = new Date(response.data.sisteRegionalkjedeMedlemskap.fraOgMedDato);
    }
    if (response.data.sisteMarkedskjedeMedlemskap && response.data.sisteMarkedskjedeMedlemskap.tilOgMedDato) {
      response.data.sisteMarkedskjedeMedlemskap.tilOgMedDato = new Date(response.data.sisteMarkedskjedeMedlemskap.tilOgMedDato);
    }

    if (response.data.firma.glnNedlagtDato) {
      response.data.firma.glnNedlagtDato = new Date(response.data.firma.glnNedlagtDato);
    }

    return response.data;
  },

  exportToExcel: async (filter: EnhetSearchFilter) =>
    axios.post(`${baseUrl}/exporttoexcel`, filter, { responseType: 'arraybuffer' }).then(response => response.data), // Må bruke responseType her for å kunne åpne filen?

  updateEnhet: async (data: UpdateEnhetCommand) =>
    axios.put(`${baseUrl}/${data.id}/`, data).then(response => response.data),

  removeEnhetsgruppeFromEnhet: async (data: RemoveEnhetgruppeFromEnhetCommand) =>
    axios.put(`${baseUrl}/${data.enhetId}/fjernSisteKnytningTilEnhetsgruppering`, data).then(response => response.data),

  reaktiverEnhetsgruppeMedlemskap: async (data: ReaktiverEnhetgruppeMedlemskapCommand) =>
    axios.put(`${baseUrl}/${data.enhetId}/reaktiverSisteKnytningTilEnhetsgruppering`, data).then(response => response.data),

  updateGrossister: async (command: UpdateGrossisterCommand) =>
    axios.put(`${baseUrl}/${command.enhetId}/grossister`, command).then(response => response.data),

  updateGln: async (command: UpdateGlnCommand) =>
    axios.put(`${baseUrl}/${command.id}/gln`, command).then(response => response.data),

  removeGln: async (command: RemoveGlnCommand) =>
    axios.put(`${baseUrl}/${command.id}/removeGln`, command).then(response => response.data),

  leggNedGln: async (id: number) => {
    const response = await axios.post(`${baseUrl}/${id}/leggnedGln`);
    return response.data;
  },

  reaktiverGln: async (id: number) => {
    const response = await axios.post(`${baseUrl}/${id}/reaktiverGln`);
    return response.data;
  },

  updateAddresses: (enhetID: number, data: UpdateAddressesCommand) =>
    axios.put(`${baseUrl}/${enhetID}/addresses`, data).then(response => response.data),

  updateAddress: async(id: number, data: UpdateAddresseCommand) => {
    const response = await axios.put(`${baseUrl}/${id}/updateAddress`, data);
    return response.data;
  },

  deleteAddress: async(id: number, data: DeleteAddressCommand) => {
    const response = await axios.delete(`${baseUrl}/${id}/deleteAddress`, { data: data });
    return response.data;
  },

  getEnhettyper: async (): Promise<GenericViewModel[]> =>
    axios.get(`${baseUrl}/enhettyper`).then(response => response.data),

  // getByGln: gln => axios.get(`${baseUrl}/gln/${gln}`).then(response => response.data),

  getByLopenummer: (lopenummer: number): Promise<EnhetViewModel> =>
    axios.get(`${baseUrl}/lopenr/${lopenummer}`).then(response => response.data),

  getByOrgnr: (orgnr: string): Promise<EnhetViewModel[]> =>
    axios.get(`${baseUrl}/orgnr/${orgnr}`).then(response => response.data),

  getIdByQuickSearch: (searchValue: string): Promise<number> =>
    axios.get(`${baseUrl}/idByQuickSearch?searchValue=${searchValue}`).then(response => response.data),

  getByKjede: async (
    kjedeId: number,
    query: EnheterInKjedeQuery,
  ): Promise<PagedCollection<KjedeMedlem>> => {
    const response = await axios.get(`${baseUrl}/kjede/${kjedeId}${query.getQueryString()}`);
    response.data.results = response.data.results.map((o: KjedeMedlem) => {
      o.fraOgMedDato = new Date(o.fraOgMedDato);
      o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;
      o.nedlagtDato = o.nedlagtDato ? new Date(o.nedlagtDato) : undefined;
      return o;
    });
    return response.data;
  },

  getTidligsteKjedeFraOgMedDato: async (id: number, kjedeType: KjedeType) => {
    let type = '';

    if (kjedeType === KjedeType.Region) {
      type = 'regional'
    }
    if (kjedeType === KjedeType.Marked) {
      type = 'marked'
    }

    return axios.get(`${baseUrl}/${id}/${encodeURIComponent(type)}/tidligsteKjedeFraOgMedDato`).then(response => new Date(response.data))
  },

  getByGruppering: async (
    enhetgrupperingId: number,
    query: EnheterInGrupperingQuery,
  ): Promise<PagedCollection<GrupperingMedlem>> => {
    const response = await axios.get(`${baseUrl}/gruppering/${enhetgrupperingId}${query.getQueryString()}`);
    response.data.results = response.data.results.map((o: GrupperingMedlem) => {
      o.fraOgMedDato = new Date(o.fraOgMedDato);
      o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;
      o.nedlagtDato = o.nedlagtDato ? new Date(o.nedlagtDato) : undefined;
      return o;
    });
    return response.data;
  },

  getChanges: async (id: number): Promise<PropertyChange[]> =>
    axios.get(`${baseUrl}/changes/${id}`).then(response => {
      return response.data.map((o: PropertyChange) => {
        return {
          id: o.id,
          column: o.column,
          oldValue: o.oldValue,
          newValue: o.newValue,
          changedBy: o.changedBy,
          timestamp: new Date(o.timestamp),
        };
      });
    }),

  createEnhet: (enhet: CreateEnhetCommand): Promise<number> =>
    axios.post(`${baseUrl}`, enhet).then(response => response.data),

  eierSkifte: async (command: EierskifteCommand): Promise<EnhetViewModel> =>
    axios.post(`${baseUrl}/${command.id}/eierskifte`, command).then(response => response.data),

  leggNedEnhet: async (command: LeggNedCommand): Promise<EnhetViewModel> =>
    axios.post(`${baseUrl}/${command.id}/leggned`, command).then(response => response.data),

  reaktiverEnhet: async (command: ReAktiviserCommand): Promise<EnhetViewModel> =>
    axios.post(`${baseUrl}/${command.id}/reaktiver`, command).then(response => response.data),

  fjernVidereforing: async (id: number): Promise<string> =>
    axios.post(`${baseUrl}/${id}/fjernVidereforing`).then(response => response.data),

  markerSomKomplett: async (id: number): Promise<EnhetViewModel> =>
    axios.post(`${baseUrl}/${id}/markersomkomplett`).then(response => response.data),

  undoMarkerSomKomplett: async (id: number): Promise<EnhetViewModel> =>
    axios.post(`${baseUrl}/${id}/undomarkersomkomplett`).then(response => response.data),

  publiser: async (id: number): Promise<number> =>
    axios.post(`${baseUrl}/${id}/publiser`).then(response => response.data),

  getEnhettypeHistorikk: id =>
    axios.get(`${baseUrl}/${id}/enhettyper/historikk`).then(response => response.data),

  getLeverandorKundenummer: async (leverandørTsKundeId: string, kundenummer: string): Promise<LeverandorKundenummerDto[]> => {
    const result = await axios.get(`${getKjederegAdminApiUrl()}/LeverandorKundenummer/${leverandørTsKundeId}?kundenummer=${kundenummer}`);
    return result.data;
  },

  updateGrupperingMedlemskap: async (enhetId: number, command: UpdateGrupperingCommand) =>
    axios
      .put(`${baseUrl}/${enhetId}/updateGrupperingMedlemskap`, command)
      .then(response => response.data),

  getGrupperingerForEnhet: async (enhetId: number): Promise<Enhetsgruppering[]> =>
    axios.get(`${baseUrl}/${enhetId}/grupperingerForEnhet`).then(response => response.data),

  knyttTilFlereEnhetsgrupperinger: async (enhetId: number, payload: AddToFlereGrupperingerCommand) =>
    axios
      .put(`${baseUrl}/${enhetId}/knyttTilFlereEnhetsgrupperinger`, payload)
      .then(response => response.data),

  searchEnheterTilEierskifte: (query: string): Promise<EierskifteEnhetViewModel[]> =>
    axios
      .get(`${baseUrl}/enheterTilEierskifte?query=${query || ''}`)
      .then(response => response.data),

  markerSomDublett: async (command: MarkerSomDublettCommand): Promise<EnhetViewModel> =>
    axios
      .post(`${baseUrl}/${command.id}/markersomdublett`, command)
      .then(response => response.data),

  deleteEnhet: async (enhetId: string) =>
    axios
      .delete(`${baseUrl}/${enhetId}`, { data: { id: enhetId } })
      .then(response => response.data),

  sjekkKontonrOgFakturaAdresse: async (enhetId: number): Promise<KontonrOgFakturaAdresseSjekkResponse> =>
    axios
      .get(`${baseUrl}/${enhetId}/sjekkKontonrOgFakturaAdresse`)
      .then(response => response.data),

  førsteMuligeOppstartDato: async (enhetId: number): Promise<Date | undefined> =>
    axios
      .get(`${baseUrl}/${enhetId}/firstpossibleOppstartDate`)
      .then(response => response.data ? new Date(response.data) : null),

  kobleTilLeverandorKundenummer: async (command: KobleTilLeverandorKundenummerCommand) => {
    await axios.post(`${getKjederegAdminApiUrl()}/LeverandorKundenummer/KobleTil`, command);
    return;
  },

  fjernKoblingTilLeverandorKundenummer: async (command: FjernKoblingTilLeverandorKundenummerCommand) => {
    await axios.post(`${getKjederegAdminApiUrl()}/LeverandorKundenummer/FjernKobling`, command);
    return;
  },

  rekobleLeverandorKundenummer: async (command: RekobleLeverandorKundenummerCommand) => {
    await axios.post(`${getKjederegAdminApiUrl()}/LeverandorKundenummer/Rekoble`, command);
    return;
  },

  getByGrossistkundenr: async (grossistGln: string, grossistKundenr: string): Promise<EnhetViewModel[]> => {
    const result = await axios.get(`${baseUrl}/grossist/${grossistGln}/${grossistKundenr}`);
    return result.data;
  }
};

export default EnhetApi;
