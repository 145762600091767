import axios from 'axios';

import { BrregEnhetResponse } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/brreg';

async function getByOrgNr(orgNr: string): Promise<BrregEnhetResponse[]> {
  const response = await axios.get(`${baseUrl}/search?orgNr=${orgNr}`);
  return response.data;
}

async function getByName(name: string): Promise<BrregEnhetResponse[]> {
  const response = await axios.get(`${baseUrl}/search?name=${name}`);
  return response.data;
}

export default {
  getByOrgNr,
  getByName
};
