/*IMPORTS */

/*React & React module dependencies */
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useMatch, useNavigate } from 'react-router-dom';

/*Shared components */
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';

/*Components */
import EnhetDetail from './EnhetDetail';
import SlettModal from './components/SlettModal';
import QuickSearch from './components/QuickSearch';
import DuplikatModal from './components/DuplikatModal';
import EierskifteModal from './components/EierskifteModal';
import PubliserModal from './components/PubliserModal';

/*Services */
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { EnhetViewModel, KontonrOgFakturaAdresseSjekkResponse, ReAktiviserCommand as ReAktiviserEnhetCommand } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { findEnhetDuplicates } from 'services/KjederegisteretAdminApi/EnhetApi/EnhetDuplicateHelper';
import SalgsdataInfoApi from 'services/SalgsdatainfoApi';
import { EnhetStatestikk, GrossistKunde, Fakturalinje } from 'services/SalgsdatainfoApi/types';
import { EierskifteCommand, MarkerSomDublettCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import { EnhetFromSearch } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';

/*Utils */
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useToaster from 'utils/hooks/useToaster';

/*Styling */
import Style from './index.module.css';
import ReaktiverModal from './components/ReaktiverModal';
import FjernvidereforingModal from './components/FjernvidereforingModal';

/*IMPORTS END */

const Enhet = () => {
    const isMountedRef = useIsMountedRef();

    const [showFjernVidereforingModal, setShowFjernVidereforingModal] = useState(false);
    const [isFjernVidereforingLoading, setIsFjernVidereforingLoading] = useState(false);
    const [showReaktiverModal, setShowReaktiverModal] = useState(false);
    const [showEierskifteModal, setShowEierskifteModal] = useState(false);
    const [showPubliserModal, setShowPubliserModal] = useState(false);
    const [showDuplikatModal, setShowDuplikatModal] = useState(false);
    const [isPubliserLoading, setIsPubliserLoading] = useState(false);
    const [isReaktiverLoading, setIsReaktiverLoading] = useState(false);
    const [isEierskifteLoading, setIsEierskifteLoading] = useState(false);
    const [isDuplikatLoading, setIsDuplikatLoading] = useState(false);
    const [enhet, setEnhet] = useState<EnhetViewModel | undefined>();
    const [enhetStatestikk, setEnhetStatestikk] = useState<EnhetStatestikk | undefined>();
    const [grossistKunder, setGrosssitKunder] = useState<GrossistKunde[] | undefined>();
    const [fakturalinjer, setFakturalinjer] = useState<Fakturalinje[] | undefined>();
    const [publiserBtnDisabled, setPubliserBtnDisabled] = useState<boolean>(false);
    const [isShowSlettModal, setIsShowSlettModal] = useState<boolean>(false);
    const [showSlettBtn, setShowSlettBtn] = useState<boolean>(false);
    const [isFutureGruppering, setIsFutureGruppering] = useState<boolean>(false);
    const [isFutureKjedemedlemskap, setIsFutureKjedeMedlemskap] = useState<boolean>(false);
    const [isEnhetNedlagt, setIsEnhetNedlagt] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const toaster = useToaster();
    const navigate = useNavigate();
    const match = useMatch("/enheter/:id");
    const enhetId = parseInt(match.params.id);

    const loadEnhet = async () => {
        setIsLoading(true);
        const e = await EnhetApi.getEnhetById(enhetId);
        if (!e) {
            navigate("/enhetnotfound");
        }
        setEnhet(e);
        setIsLoading(false);
    };

    const loadEnhetStatestikk = async (lopenummer: string) => {
        const result = await SalgsdataInfoApi.getSalgsdata(lopenummer);
        setEnhetStatestikk(result);
    };

    const loadGrossistKunde = async (lopenummer: string) => {
        const result = await SalgsdataInfoApi.getGrossistKunde(lopenummer);
        setGrosssitKunder(result);
    };

    const loadFakturalinjer = async (lopenummer: string) => {
        const result = await SalgsdataInfoApi.getFakturalinjer(lopenummer);
        setFakturalinjer(result);
    };

    const [kontonrOgFakturaAdresseSjekkResponse, setKontonrOgFakturaAdresseSjekkResponse] = useState<KontonrOgFakturaAdresseSjekkResponse | undefined>();
    const sjekkKontorOgFakturaAdresse = async (enhetId: number) => {
        const response = await EnhetApi.sjekkKontonrOgFakturaAdresse(enhetId);
        setKontonrOgFakturaAdresseSjekkResponse(response);
    };

    useEffect(() => {
        loadEnhet();
    }, [enhetId]);

    useEffect(() => {
        if (enhet) {
            setFakturalinjer(undefined);
            loadFakturalinjer(enhet.lopenr);
            setEnhetStatestikk(undefined);
            loadEnhetStatestikk(enhet.lopenr);
            loadGrossistKunde(enhet.lopenr);
            if (!enhet.firma.nedlagtDato) {
                sjekkKontorOgFakturaAdresse(enhet.id);
            }

        }
    }, [enhet]);

    const toggleEierskifteModal = () => {
        loadEierskifteState();
        setShowEierskifteModal(!showEierskifteModal);
    };

    const closeEierskifteModal = () => {
        setShowEierskifteModal(false);
    };


    const toggleReaktiverModal = () => {
        setShowReaktiverModal(!showReaktiverModal);
    };

    const closeReaktiverModal = () => {
        setShowReaktiverModal(false);
    };

    const toggleFjernVidereforingModal = () => {
        setShowFjernVidereforingModal(!showFjernVidereforingModal);
    };

    const closeFjernVidereforingModal = () => {
        setShowFjernVidereforingModal(false);

    };

    const togglePubliserModal = () => {
        if (enhet.komplettTidspunkt && enhet.besoksadresse && !duplicateByOrgnrAndBesok && !duplicateByEnhetsnavnAndBesok) {
            onPublisert();
        } else {
            setShowPubliserModal(!showPubliserModal);
        }

    };

    const closePubliserModal = () => {
        setShowPubliserModal(false);
    };

    const toggleDuplikatModal = () => {
        setShowDuplikatModal(!showDuplikatModal);
    };

    const closeDuplikatModal = () => {
        setShowDuplikatModal(false);
    };

    const toggleSlettModal = () => {
        setIsShowSlettModal(!isShowSlettModal);
    };

    const closeSlettModal = () => {
        setIsShowSlettModal(false);
    };

    const redirectToNyEnhet = (id: number) => {
        navigate(`/enheter/${id}`);
    };

    const onEierSkifte = async (
        dato: Date,
        navn: string,
        oppstartDato: Date,
        videreforTilEnhetId: number,
        fjernVidereforing: boolean,
        viderforKjederTilNyEnhet: boolean,
        videreforKontaktinfoTilNyEnhet: boolean,
    ) => {
        setIsEierskifteLoading(true);
        if (fjernVidereforing) {
            await fjernVidereforingForEnhet();
            setIsEierskifteLoading(false);
            closeEierskifteModal();
            await loadEnhet();
            return;
        }

        if (videreforTilEnhetId || navn) {
            let oppstart = navn ? oppstartDato : null;
            const command: EierskifteCommand = {
                id: enhetId,
                eierskifteDato: dato,
                nyttEnhetnavn: navn,
                oppstartNyEnhet: oppstart,
                videreforTilEnhetId: videreforTilEnhetId,
                videreforAktiveKjeder: viderforKjederTilNyEnhet,
                videreforKontaktinfo: videreforKontaktinfoTilNyEnhet,
            };
            try {
                const e = await EnhetApi.eierSkifte(command);
                if (e) {
                    toaster.success('Suksess', 'Eierskifte/Nedleggelse utført');
                    //setEnhet(e);
                    redirectToNyEnhet(e.id);
                }

            } catch (err) {
                toaster.error('Feil', HttpErrorHelper.formatError(err));
            }
        } else {
            try {
                const command = { id: enhetId, nedlagtDato: dato };
                const e = await EnhetApi.leggNedEnhet(command);
                toaster.success('Suksess', 'Eierskifte/Nedleggelse utført');
                //etEnhet(e);  
                await loadEnhet();

            } catch (err) {
                toaster.error('Feil', HttpErrorHelper.formatError(err));
            }
        }
        setIsEierskifteLoading(false);
        closeEierskifteModal();
    };

    const markerSomKomplett = async () => {
        return EnhetApi.markerSomKomplett(enhetId)
            .then(enhet => {
                setEnhet(enhet);
            })
            .catch(error => {
                console.log(
                    `Kunne ikke markere enhet med id '${enhetId}' som 'komplett'` + error.message,
                );
            });
    };

    const undoMarkerSomKomplett = async () => {
        return EnhetApi.undoMarkerSomKomplett(enhetId)
            .then(enhet => {
                setEnhet(enhet);
            })
            .catch(error => {
                console.log(
                    `Kunne ikke markere enhet med id '${enhetId}' som 'ikke komplett'` + error.message,
                );
            });
    };

    const onPublisert = () => {
        setIsPubliserLoading(true);
        EnhetApi.publiser(enhetId)
            .then(res => {
                if (res && isMountedRef.current) {
                    setIsPubliserLoading(false);
                    setShowPubliserModal(false);
                    setPubliserBtnDisabled(true);
                    handleEnhetUpdated();
                    toaster.success('Suksess', 'Enhet er publisert');
                }
            })
            .catch(err => {
                setIsPubliserLoading(false);
                setShowPubliserModal(false);
                setPubliserBtnDisabled(false);
                toaster.error('Feil! Kunne ikke publisere.', HttpErrorHelper.formatError(err));
            });
    };

    const onDuplikat = async (dublettAvId: number) => {
        setIsDuplikatLoading(true);
        const command: MarkerSomDublettCommand = {
            id: enhetId,
            dublettAvId: dublettAvId,
        };
        try {
            const e = await EnhetApi.markerSomDublett(command);
            setEnhet(e);
            toaster.success(
                'Suksess',
                'Enhet er nå markert som duplikat. Måtte framtiden bringe mer nøyaktighet :)',
            );
            setShowDuplikatModal(false);
        } catch (err) {
            toaster.error('Feil', HttpErrorHelper.formatError(err));
        }
        setIsDuplikatLoading(false);
    };


    const reaktiver = async (reaktiverOgsåKjedeOgEnhetsgruppemedlemsskap: boolean) => {
        setIsReaktiverLoading(true);

        const command: ReAktiviserEnhetCommand = {
            id: enhetId,
            reaktiverSisteKjedeOgEnhetsgrupperingsMedlemsskap: reaktiverOgsåKjedeOgEnhetsgruppemedlemsskap
        };

        try {
            const response = await EnhetApi.reaktiverEnhet(command);
            setEnhet(response);
            toaster.success('Suksess!', 'Enhet aktivert');
            await loadEnhet();
        } catch (err) {
            toaster.error('Feil', HttpErrorHelper.formatError(err));
        }
        setIsReaktiverLoading(false);
        closeReaktiverModal();
    };

    const fjernVidereforingForEnhet = async () => {
        setIsFjernVidereforingLoading(true);
        try {
            const response = await EnhetApi.fjernVidereforing(enhetId);
            toaster.success('Suksess! ', response);
            await loadEnhet();
        } catch (err) {
            toaster.error('Feil', HttpErrorHelper.formatError(err));
        }
        setIsFjernVidereforingLoading(false);
        closeFjernVidereforingModal();
    };

    const handleEnhetUpdated = async () => {
        loadEierskifteState();
        await loadEnhet();
    };

    const handleNavigateToLopenummer = async (lopenummer: number) => {
        const e = await EnhetApi.getByLopenummer(lopenummer);
        redirectToNyEnhet(e.id);
        setEnhet(e);
    };

    const handleNavigateToEnhetId = async (enhetId: number) => {
        redirectToNyEnhet(enhetId);
    };

    const navigateToForrgieLopenummer = () => {
        handleNavigateToLopenummer(enhet.firma.forrigeLopenr);
    };

    const navigateToNesteLopenummer = () => {
        handleNavigateToLopenummer(enhet.firma.nesteLopenr);
    };

    const checkIsFutureGruppering = async () => {
        if (enhet) {
            const eg = await EnhetApi.getGrupperingerForEnhet(enhet.id);
            if (eg && eg.some(e => new Date(e.fraOgMedDato) > new Date())) {
                setIsFutureGruppering(true);
            } else {
                setIsFutureGruppering(false);
            }
        }
    };

    const checkIsFutureKjedemedlemskap = () => {
        if (
            enhet &&
            (enhet.sisteRegionalkjedeMedlemskap?.isFuture || enhet.sisteMarkedskjedeMedlemskap?.isFuture)
        ) {
            setIsFutureKjedeMedlemskap(true);
        } else {
            setIsFutureKjedeMedlemskap(false);
        }
    };

    const checkIsEnhetNedlagt = () => {
        if (enhet && enhet.firma.nedlagtDato) {
            setIsEnhetNedlagt(true);
        } else {
            setIsEnhetNedlagt(false);
        }
    };

    const loadEierskifteState = () => {
        checkIsFutureGruppering();
        checkIsFutureKjedemedlemskap();
        checkIsEnhetNedlagt();
    };


    const [duplicateByOrgnrAndBesok, setDuplicateByOrgnrAndBesok] = useState<EnhetFromSearch[]>();
    const [duplicateByEnhetsnavnAndBesok, setDuplicateByEnhetsnavnAndBesok] = useState<EnhetFromSearch[]>();
    const [criteria, setCriteria] = useState<{
        enhetId: number;
        enhetnavn: string;
        juridisknavn: string;
        organisasjonsnummer: string;
        statistikkNr: number;
        besoksadresse: string;
    }>();

    const checkForDuplicates = () => {
        const criteria = {
            enhetId: enhet.id,
            enhetnavn: enhet.enhetnavn,
            juridisknavn: enhet.firma.juridisknavn,
            organisasjonsnummer: enhet.firma?.organisasjonsnr,
            statistikkNr: enhet.firma?.statistikknr,
            besoksadresse: enhet.besoksadresse?.formattedForOneLine,
        }

        setCriteria(criteria);

        findEnhetDuplicates(criteria).then(e => {
            if (e?.byOrgnrBesokCombo?.length > -1) {
                setDuplicateByOrgnrAndBesok(e.byOrgnrBesokCombo);
            } else {
                setDuplicateByOrgnrAndBesok([]);
            }

            // if (e?.byEnhetnavn?.length > -1) {
            //     setDuplicateByName(e.byEnhetnavn);
            // } else {
            //     setDuplicateByName([]);
            // }
            if (e?.byEnhetsnavnBesokCombo?.length > -1) {
                setDuplicateByEnhetsnavnAndBesok(e.byEnhetsnavnBesokCombo);
            } else {
                setDuplicateByEnhetsnavnAndBesok([]);
            }
        }).catch(err => {
            toaster.error(`Feil!`, HttpErrorHelper.formatError(err));
        });
    }

    useEffect(() => {
        if (enhet) {
            loadEierskifteState();
            checkForDuplicates();
        }

        if (enhet?.erPublisert) {
            setPubliserBtnDisabled(true);
            setShowSlettBtn(false);
        } else {
            if (!enhet?.besoksadresse || !enhet?.postadresse || !enhet?.leveringsadresse || !enhet?.fakturaAdresse) {
                setPubliserBtnDisabled(true);
            } else {
                setPubliserBtnDisabled(false);
            }
            setShowSlettBtn(true);
        }
    }, [enhet]);

    const getPublishButtonText = () => {
        if (isPubliserLoading) {
            return 'Publiserer';
        }
        if (enhet?.erPublisert) {
            return 'Publisert';
        }
        return 'Publiser';
    }

    return (
        <Container fluid className={Style.container}>
            <Row className={Style.headerRow}>
                <Col className={Style.headerContentCol}>
                    <h3>Enhetsvedlikehold</h3>
                </Col>
            </Row>
            <Row className={Style.buttonToolbarRow}>
                <Col>
                    <InputGroup className={Style.lopenrInput}>
                        <InputGroup.Text>
                            <div>
                                {!!enhet?.firma?.forrigeLopenr && (
                                    <IcebergIcon
                                        className={Style.navigateLopenrIcon}
                                        icon="arrow-left"
                                        title="Gå til forrige løpenr"
                                        onClick={navigateToForrgieLopenummer}
                                    />
                                )}
                            </div>
                            {isLoading ? (
                                <div className={Style.placeHolderContainer}>
                                    <div className={Style.placeholderItem}></div>
                                </div>
                            ) : (
                                <div>
                                    <span style={{ marginRight: '.2em' }}><strong>{enhet?.firma?.lopenr}</strong></span>
                                </div>
                            )}
                            <div>
                                {!!enhet?.firma?.nesteLopenr && (
                                    <IcebergIcon
                                        className={Style.navigateLopenrIcon}
                                        icon="arrow-right"
                                        title="Gå til neste løpenr"
                                        onClick={navigateToNesteLopenummer}
                                    />
                                )}
                            </div>
                        </InputGroup.Text>
                        <QuickSearch handleSelected={e => handleNavigateToEnhetId(e)} />
                    </InputGroup>
                </Col>
                <Col style={{ textAlign: "right" }}>
                    {enhet && showSlettBtn && (
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip-delete-available">
                                    Enhet er ikke publisert og kan slettes.
                                </Tooltip>
                            }
                        >
                            <Button className='me-2'
                                variant="outline-danger"
                                onClick={() => toggleSlettModal()}
                            >
                                Slett
                            </Button>
                        </OverlayTrigger>
                    )}

                    {!!enhet?.firma.nesteLopenr &&
                        <Button className='me-2' variant="outline-primary" onClick={() => toggleFjernVidereforingModal()}>
                            Fjern videreføring
                        </Button>
                    }

                    {enhet?.erDublett &&
                        <span data-toggle="tooltip" title="Dubletter kan ikke reaktiveres">
                            <Button className='me-2' variant="outline-primary" disabled>
                                Reaktiver
                            </Button>
                        </span>
                    }

                    {enhet?.firma.nedlagtDato && !(enhet?.erDublett) &&
                        <Button className='me-2' variant="outline-primary" onClick={() => toggleReaktiverModal()}>
                            Reaktiver
                        </Button>
                    }

                    {!enhet?.firma.nesteLopenr &&
                        <Button className='me-2' variant='outline-primary' onClick={() => toggleEierskifteModal()}>
                            {!enhet?.firma.nedlagtDato ? 'Eierskifte/Legg ned' : 'Eierskifte'}
                        </Button>
                    }

                    {enhet?.erDublett &&
                        <span data-toggle="tooltip" title="Allerede markert som dublett">
                            <Button className='me-2' variant="outline-primary" disabled={enhet?.erDublett}>
                                Dublett
                            </Button>
                        </span>
                    }

                    {!enhet?.erDublett &&
                        <Button className='me-2' variant="outline-primary" onClick={() => toggleDuplikatModal()}>
                            Dublett
                        </Button>
                    }
                    <span>
                        <IconButton
                            variant={enhet?.erPublisert ? "success" : "outline-primary"}
                            disabled={publiserBtnDisabled}
                            onClick={togglePubliserModal}
                            icon={enhet?.erPublisert ? "check" : "export"}
                            className={enhet?.erPublisert ? Style.publishBtnDisabled : Style.publishBtn}
                        >
                            {getPublishButtonText()}
                        </IconButton>
                    </span>
                </Col>
            </Row>

            <Row className={Style.contentRow}>
                <Col>
                    {enhet && (
                        <EnhetDetail
                            enhet={enhet}
                            enhetStatestikk={enhetStatestikk}
                            grossistKunder={grossistKunder}
                            fakuralinjer={fakturalinjer}
                            isPossibleDuplicateByEnhetsnavnAndBesok={duplicateByEnhetsnavnAndBesok?.length > 0}
                            isPossibleDuplicateByByOrgnrAndBesok={duplicateByOrgnrAndBesok?.length > 0}
                            handleEnhetUpdated={handleEnhetUpdated}
                            onNavigateToLopenummer={handleNavigateToLopenummer}
                            onMarkerSomKomplett={markerSomKomplett}
                            onUndoMarkerSomKomplett={undoMarkerSomKomplett}
                            kontonrOgFakturaAdresseSjekkResponse={kontonrOgFakturaAdresseSjekkResponse}
                        />
                    )}
                </Col>
            </Row>

            <EierskifteModal
                nedlagtDato={enhet?.firma.nedlagtDato}
                show={showEierskifteModal}
                handleClose={closeEierskifteModal}
                handleSave={onEierSkifte}
                isNedlagt={isEnhetNedlagt}
                isFutureGruppering={isFutureGruppering}
                isFutureKjedemedlemskap={isFutureKjedemedlemskap}
                viderefortTil={enhet?.firma.nesteLopenr}
                utforIsLoading={isEierskifteLoading}
                loadEierskifteState={loadEierskifteState}
            />


            <ReaktiverModal
                show={showReaktiverModal}
                handleClose={closeReaktiverModal}
                reaktiverIsLoading={isReaktiverLoading}
                handleReaktiver={reaktiver}
            />

            <FjernvidereforingModal
                onFjernVidereforing={fjernVidereforingForEnhet}
                show={showFjernVidereforingModal}
                enhet={enhet}
                fjernVidereforingIsLoading={isFjernVidereforingLoading}
                handleClose={closeFjernVidereforingModal}
            />

            <PubliserModal
                show={showPubliserModal}
                onPubliser={onPublisert}
                onCloseModal={closePubliserModal}
                komplettTidspunkt={enhet?.komplettTidspunkt}
                duplicateByOrgnrAndBesok={duplicateByOrgnrAndBesok}
                duplicateByEnhetsnavnAndBesok={duplicateByEnhetsnavnAndBesok}
                isMissingBesoksadresse={!enhet?.besoksadresse}
                isMissingBransje={!enhet?.bransje}
                isMissingEnhetstype={!enhet?.enhetType}
                isMissingOppstartdato={!enhet?.oppstartsdato}
                criteria={criteria}
            />

            <DuplikatModal
                show={showDuplikatModal}
                handleClose={closeDuplikatModal}
                handleSave={onDuplikat}
                utforIsLoading={isDuplikatLoading}
                enhetnavn={enhet?.enhetnavn}
                duplikatAvLopenummer={
                    enhet?.firma.nedlagtDato && enhet?.firma.nedlagtDato === enhet?.firma.oppstartsdato
                        ? enhet?.firma.nesteLopenr?.toString()
                        : null
                }
            />
            <SlettModal
                enhetId={enhet?.id.toString()}
                name={enhet?.enhetnavn}
                show={isShowSlettModal}
                handleClose={closeSlettModal}
            />
        </Container>
    );
};

export default Enhet;
