export const lookUpBransjeNavnfraTine = (bransjenr: string) => {
  switch (bransjenr) {
    case "10":
    case "010":
      return "Ordinær dagligvare";
    case "19":
    case "019":
      return "Andre utsalgssteder";
    case "20":
    case "020":
      return "Bensinstasjoner";
    case "21":
    case "021":
      return "Kiosk /Convenience";
    case "22":
    case "022":
      return "Treningssenter";
    case "23":
    case "023":
      return "Ikke i bruk";
    case "24":
    case "024":
      return "Ikke i bruk";
    case "25":
    case "025":
      return "Frittstående utsalgssteder";
    case "26":
    case "026":
      return "Ikke i bruk";
    case "27":
    case "027":
      return "Ikke i bruk";
    case "28":
    case "028":
      return "Kino";
    case "29":
    case "029":
      return "Netthandel B2C";
    case "102":
      return "Hotell";
    case "103":
      return "Annen overnatting";
    case "105":
      return "Restaurant";
    case "106":
      return "Restaurant konsept";
    case "107":
      return "Kafe";
    case "108":
      return "Bakeriutsalg";
    case "109":
      return "Kaffebarer";
    case "120":
      return "Fastfood";
    case "127":
      return "Catering";
    case "131":
      return "Fergeselskap/reise";
    case "135":
      return "Kantine";
    case "136":
      return "Universitet/høyskole";
    case "138":
      return "OPEX FUS";
    case "139":
      return "OPEX barnehager privat";
    case "140":
      return "OPEX barnehager offentlig";
    case "141":
      return "Barnehager privat";
    case "142":
      return "Barnehager offentlig";
    case "143":
      return "OPEX Skolefritidsordning";
    case "144":
      return "Skolefritidsordning";
    case "145":
      return "OPEX Barne- og ungdomsskoler";
    case "146":
      return "OPEX Ungdomsskoler";
    case "147":
      return "Barne- og ungdomsskoler";
    case "148":
      return "Ungdomsskoler";
    case "149":
      return "Skolelyst Barne- og ungdomsskoler";
    case "150":
      return "Skolelyst Ungdomsskoler";
    case "152":
      return "Sykehus offentlig";
    case "153":
      return "Sykehus privat";
    case "155":
      return "Aldershjem offentlig";
    case "156":
      return "Aldershjem privat";
    case "157":
      return "Forsvaret/fengsel/instit.";
    case "158":
      return "VGS offentlig";
    case "159":
      return "VGS privat";
    case "160":
      return "M & D";
    case "161":
      return "Fullvending";
    case "162":
      return "Netthandel B2B";
    case "163":
      return "Cash & Carry";
    case "164":
      return "Engros/skipshandel";
    case "165":
      return "Spesialutsalg";
    case "166":
      return "Utradisjonelle salgssted";
    case "167":
      return "Apotek";
    case "168":
      return "Annen varehandel";
    case "169":
      return "TINE Partner dgv / kbs";
    case "170":
      return "Frittstående idrettslag";
    case "171":
      return "Campingplasser";
    case "172":
      return "Festivaler og idrettsarrangementer";
    case "173":
      return "Arena salg";
    case "174":
      return "NFF fotball";
    case "175":
      return "NHF håndball";
    case "180":
      return "Arbeidslyst";
    case "210":
      return "Ernæring";
    case "215":
      return "Kjøtt";
    case "220":
      return "Fisk";
    case "225":
      return "Bakeri";
    case "245":
      return "Iskrem";
    case "251":
      return "Småskala";
    case "255":
      return "Ferdigmat";
    case "265":
      return "Fôr";
    case "269":
      return "Miljøfor og andre forkunder";
    case "270":
      return "Leieproduksjon";
    case "505":
      return "Spesialgrossist";
    case "510":
      return "STH Grossist";
    case "515":
      return "Dagligvaregrossister";
    case "590":
      return "KBS-grossistsalg tilleggsfordeling NG";
    case "990":
      return "Transportoppdrag";
    default:
      return bransjenr;
  }
}