import ConfirmButton from "components/ConfirmButton";
import DatePicker from "components/DatePicker";
import GrupperingPicker from "components/GrupperingPicker";
import { IMappedGrupperingNode } from "components/GrupperingPicker/types";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import EnhetsgruppeMedlemsskapsApi from "services/KjederegisteretAdminApi/EnhetsgruppeMedlemsskapsApi";
import { EnhetgruppeKjedeMedlemskapDto } from "services/KjederegisteretAdminApi/EnhetsgruppeMedlemsskapsApi/types";
import KjedeApi from "services/KjederegisteretAdminApi/KjedeApi";
import { FlyttKjedeTilNyttInnkjopCommand, GetKjedeByIdResponse } from "services/KjederegisteretAdminApi/KjedeApi/types";
import useToaster from "utils/hooks/useToaster";

interface Props {
    kjede: GetKjedeByIdResponse;
}

const InnkjopsMedlemsskap = (props: Props) => {

    const toaster = useToaster();
    const [medlemsskap, setMedlemsskap] = useState<EnhetgruppeKjedeMedlemskapDto[]>([]);
    const [showNew, setShowNew] = useState<boolean>(false);
    const [showExtraInfo, setShowExtraInfo] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const loadMedlemsskap = async () => {
        const medlemmer = await EnhetsgruppeMedlemsskapsApi.getByKjedeId(props.kjede.kjedeID);
        setMedlemsskap(medlemmer);
        if (medlemmer.length > 0) {
            setCommand({ ...command, fraEnhetgruppeId: medlemmer[0].enhetgruppeingId });
        }
    };

    useEffect(() => {
        loadMedlemsskap();
    }, [props.kjede]);

    const formatDate = (date: Date | undefined) => {
        if (date) {
            return format(date, "dd.MM.yyyy");
        }
        return "";
    };

    const [command, setCommand] = useState<FlyttKjedeTilNyttInnkjopCommand>({
        fraEnhetgruppeId: -1,
        tilEnhetgruppeId: -1,
        fraOgMedDato: new Date()
    });

    const handleGrupperingSelected = (grupperinger: IMappedGrupperingNode[]) => {
        setCommand({ ...command, tilEnhetgruppeId: grupperinger[0].id });
    };

    const handleDateSelected = (date: Date) => {
        setCommand({ ...command, fraOgMedDato: date });
    };

    const submit = async () => {
        setLoading(true);
        try {
            await KjedeApi.flyttKjedeTilNyttInnkjop(props.kjede.kjedeID, command);
            toaster.success("'Kjede flyttet til nytt innkjøpssamarbeid", `Kjede '${props.kjede.kjedenavn}' ble flyttet til nytt innkjøpssamarbeid.`);
        } catch (err) {
            toaster.error("Feil ved flytting av kjede til nytt innkjøpssamarbeid", err.message);
        }
        setLoading(false);
    };

    return (
        <>
            <h6>Medlemsskap i innkjøpssamarbeid for {props.kjede.kjedenavn}</h6>
            <Table>
                <thead></thead>
                <tbody>
                    {medlemsskap.map(m => (
                        <tr key={m.enhetgruppeingId}>
                            <td>{formatDate(m.fraOgMedDato)} - {formatDate(m.tilOgMedDato)}</td>
                            <td>{m.enhetgrupperingNavn} ({m.enhetgrupperingNr})</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button onClick={() => setShowNew(true)}>Flytt kjede inn i nytt innkjøpssamarbeid</Button>
            <Modal show={showNew} onHide={() => setShowNew(false)}>
                <Modal.Header>Flytt kjede fra eksisterende og inn i nytt innkjøpssamarbeid</Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Eksisterende innkjøpssamarbeid</Form.Label>
                            <GrupperingPicker disabled={true} treeSelectMode={"radioSelect"} findPreselectedNodesByID={() => [command.fraEnhetgruppeId]} onSelectionChanged={handleGrupperingSelected} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nytt innkjøpssamarbeid</Form.Label>
                            <GrupperingPicker closeOnSelect={true} enhetgrupperingType="IS" onlyTopLevel={true} treeSelectMode={"radioSelect"} onSelectionChanged={handleGrupperingSelected} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>
                                Flyttedato
                            </Form.Label>
                            <DatePicker onChange={handleDateSelected} selected={command.fraOgMedDato} adjustTimeForTimezoneOffset={true} />
                        </Form.Group>
                    </Form>
                    <p style={{ margin: "1rem 0" }}>
                        <strong>
                            <IcebergIcon icon="info" /> <a href="#" onClick={() => setShowExtraInfo(!showExtraInfo)}>Mer info om denne jobben</a>
                        </strong>
                    </p>
                    {showExtraInfo && <Alert variant="info">
                        <h6> Ved flytting inn i nytt innkjøpssamarbeid skjer følgende:</h6>
                        <ul>
                            <li>En ny undergruppe med kjedens navn og nr vil opprettes under nytt innkjøpssamarbeid</li>
                            <li>Aktive enhetsmedlemmer vil meldes ut fra undergruppe i nåværende innkjøpssamarbeid og meldes inn som medlemmer i undergruppen opprettet under nytt innkjøpssamarbeid </li>
                            <li>Fremtidige enhetsmedlemmer vil fjernes fra gammel undergruppe og kopieres inn i ny</li>
                            <li>Gammel undergruppe vil bli markert som nedlagt.</li>
                        </ul>
                    </Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowNew(false)}>
                        Lukk
                    </Button>
                    <ConfirmButton onClick={submit} disabled={loading} customtext="Utfør" />
                </Modal.Footer>
            </Modal>
        </>
    )
};

export default InnkjopsMedlemsskap;