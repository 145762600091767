import axios from 'axios';
import { EnhetStatestikk, Fakturalinje, GrossistKunde } from './types';
import { getKjederegAdminApiUrl } from 'services';

const baseUrl = getKjederegAdminApiUrl() + '/SalgsdataInfo';

async function getSalgsdata(lopenummer: string): Promise<EnhetStatestikk> {
    const response = await axios.get(`${baseUrl}/KundeStatistikk/${lopenummer}`);
    return response.data;
}

async function getFakturalinjer(lopenummer: string): Promise<Fakturalinje[]> {
    const response = await axios.get(`${baseUrl}/Fakturalinje/${lopenummer}`);
    return response.data;
}

async function getFakturalinjerByGrossist(grossistGln: string, grossistKundenummer: string): Promise<Fakturalinje[]> {
    const response = await axios.get(`${baseUrl}/Fakturalinje/grossistgln/${grossistGln}/kundenr/${grossistKundenummer}`);
    return response.data;
}

async function getGrossistKunde(lopenummer: string): Promise<GrossistKunde[]> {
    const response = await axios.get(`${baseUrl}/GrossistStatistikk/${lopenummer}`);
    return response.data;
}

const SalgsdataInfoApi = {
    getSalgsdata,
    getFakturalinjer,
    getFakturalinjerByGrossist,
    getGrossistKunde
};
export default SalgsdataInfoApi; 