import React, { useState } from "react";
import { Button } from "react-bootstrap";

import { EndringsMelding } from "services/QAApi/types";

import QAApi from "services/QAApi";

import HttpErrorHelper from "utils/HttpErrorHelper";
import useToaster from "utils/hooks/useToaster";

import Style from "./index.module.css";
import { useMsal } from "@azure/msal-react";
import CommonLoader from "components/CommonLoader";

interface Props {
  changeRequest: EndringsMelding;
}

const ToolButtons = (props: Props) => {
  const toaster = useToaster();

  const { accounts } = useMsal();

  const username = accounts[0] && accounts[0].name;

  const currentUser = username || 'ukjent';

  const [isVaskLoading, setIsVaskLoading] = useState(false);
  const [isIgnorerLoading, SetIsIgnorerLoading] = useState(false);
  const [isAvvisLoading, setIsAvvistLoading] = useState(false);
  const [isReaktiverLoading, setIsReaktiverLoading] = useState(false);

  const onAvvisClicked = async (id: string) => {
    setIsAvvistLoading(true);
    try {
      await QAApi.avvis(id, currentUser, 'Manuelt avvist');
      setIsAvvistLoading(false);
      toaster.success('Suksess!', ' Endringsmelding avvist.');
    } catch (err) {
      setIsAvvistLoading(false);
      toaster.error(
        'Feil! Kunne ikke avvise endringsmelding.',
        HttpErrorHelper.formatError(err),
      );
    }
  }

  const onReaktiverClicked = async (id: string) => {
    setIsReaktiverLoading(true);
    try {
      await QAApi.reaktiver(id, currentUser, 'Reaktivert');
      setIsReaktiverLoading(false);
      toaster.success('Suksess!', ' Endringsmelding reaktivert.');
    } catch (err) {
      setIsReaktiverLoading(false);
      toaster.error(
        'Feil! Kunne ikke reaktivere endringsmelding.',
        HttpErrorHelper.formatError(err),
      );
    }
  }

  const onIgnorerClicked = async (id: string, lopenummer?: string) => {
    SetIsIgnorerLoading(true);
    try {
      await QAApi.settTilBehandlet({
        id: id,
        lopenummer: lopenummer,
        changedBy: currentUser,
      });
      SetIsIgnorerLoading(false);
      toaster.info('Behandlet', 'Endringsmelding har fått status "behandlet"');
    } catch (err) {
      SetIsIgnorerLoading(false);
      toaster.error(
        'Feil! Kunne ikke endre status på endringsmelding.',
        HttpErrorHelper.formatError(err),
      );
    }
  }

  const onStartVaskClicked = async (id: string) => {
    setIsVaskLoading(true);
    try {
      const res = await QAApi.startVaskEndringsmelding(id);
      if (res) {
        toaster.success('Suksess', `${res}`);
      }
    } catch (err) {
      setIsVaskLoading(false);
      toaster.error('Feil! Noe gikk galt.', HttpErrorHelper.formatError(err));
    }

    setIsVaskLoading(false);
  };

  return (
    <div className={Style.moveButton}>
      <Button className={Style.buttonStyle} size="sm" variant="outline-danger"
        onClick={() => onAvvisClicked(props.changeRequest.id)}>
        {
          isAvvisLoading ?
            <span>
              <CommonLoader  />
              Avviser
            </span> :
            <span>Avvis</span>
        }
      </Button>

     { props.changeRequest.status !=='Vasket' && (<Button className={Style.buttonStyle} size="sm" variant="outline-primary"
        onClick={() => onReaktiverClicked(props.changeRequest.id)}>
        {
          isReaktiverLoading ?
            <span>
              <CommonLoader />
              Reaktiverer
            </span> :
            <span>Reaktiver</span>
        }
      </Button>)}

      <Button className={Style.buttonStyle} size="sm" variant="outline-primary"
        onClick={() => onIgnorerClicked(props.changeRequest.id, props.changeRequest.lopenummer)}>
        {
          isIgnorerLoading ?
            <span>
              <CommonLoader  />
              Ignorerer
            </span> :
            <span>Ignorer</span>
        }
      </Button>

      <Button className={Style.buttonStyle} size="sm" variant="outline-primary"
        onClick={() => onStartVaskClicked(props.changeRequest.id)}>
        {
          isVaskLoading ?
            <span>
              <CommonLoader  />
              Vasker
            </span> :
            <span>Vask på nytt</span>
        }
      </Button>
    </div>
  )
}

export default ToolButtons;