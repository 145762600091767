import axios from 'axios';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/elma';

async function isRegistered(orgNr: string): Promise<boolean> {
  const response = await axios.get(`${baseUrl}/${orgNr}/isRegistered`);
  return response.data;
}

export default {
  isRegistered
};
