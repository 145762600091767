/*IMPORTS */

/*React & React module dependencies */
import React from "react"
import { Container } from "react-bootstrap";

/*Shared components */
import PageHeader from "components/PageHeader";

/*Components */
import QAStatistics from "components/QAStatistics";

/*Styling */
import Style from './index.module.css';

/*IMPORTS END */


const HomePage = () => {

  /*Content */
  return (
    <Container fluid className={Style.Container}>
      <PageHeader>QA Statistikk</PageHeader>
      <br />
      <QAStatistics includeMonthAndYear/>
    </Container>
  )
}

export default HomePage;