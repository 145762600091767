import React, { useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { debounce } from 'lodash';
import RemoveSpaceAndDot from 'utils/RemoveSpaceAndDot';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';

interface Props {
  handleSelected: (id: number) => void;
}

const QuickSearch: React.FC<Props> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState('Bytt til løpenummer eller GLN...');

  const search = debounce(async (q: string) => {
    setIsLoading(true);
    const result = await EnhetApi.getIdByQuickSearch(q);
    setIsLoading(false);
    if (result) {
      props.handleSelected(result);
      typeaheadRef.current?.clear();
      setPlaceholder('Byttet til enhet. Bytte igjen?');
    }
  }, 300);

  const typeaheadRef = useRef(null);

  return (
    <AsyncTypeahead
      id="quickSearch"
      filterBy={() => true}
      minLength={3}
      delay={200}
      options={[]}
      isLoading={isLoading}
      searchText="Søker"
      placeholder={placeholder}
      promptText="Skriv for å søke"
      emptyLabel="Fant ikke enhet"
      useCache={false}
      maxResults={1}
      onSearch={q => search(RemoveSpaceAndDot(q))}
      ref={typeaheadRef}
    />
  );
};

export default QuickSearch;
