import MottakerTypeahead from "components/MottakerTypeahead";
import { Button, Form } from "react-bootstrap";
import EnhetApi from "services/KjederegisteretAdminApi/EnhetApi";
import { KobleTilLeverandorKundenummerCommand } from "services/KjederegisteretAdminApi/EnhetApi/Commands";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useForm from "utils/hooks/useForm";
import useToaster from "utils/hooks/useToaster";
import Style from './index.module.css'


interface Props {
    enhetId: number;
    onUpdated: () => void;
}

const KobleModal = (props: Props) => {
    const toaster = useToaster();
    const initialVals: KobleTilLeverandorKundenummerCommand = {
        enhetId: props.enhetId,
        mottakerId: 0,
        kundenummer: '',
    };

    const validate = (values: KobleTilLeverandorKundenummerCommand) => {
        let err: any = {};
        if (!values.kundenummer) err.kundenummer = 'Kundenummer må fylles ut';
        if (!values.mottakerId) err.mottakerId = 'Mottaker må velges';
        return err;
    };

    const onSubmit = async (command: KobleTilLeverandorKundenummerCommand) => {
        try {
            await EnhetApi.kobleTilLeverandorKundenummer(command);
            toaster.success('Suksess', 'leverandør kundenummer er koblet til');
            props.onUpdated();
        } catch (err) {
            toaster.error('Noe gikk galt.', HttpErrorHelper.formatError(err));
        }
    };

    const { touched, errors, values, handleSubmit, handleChange, submitDisabled } = useForm(
        initialVals,
        validate,
        onSubmit,
    );


    return (
        <Form onSubmit={handleSubmit}>
            {/* mottaker delen */}
            <Form.Group className='mb-3'>
                <Form.Label>Mottaker</Form.Label>
                <MottakerTypeahead
                    onHandleChange={e => handleChange('mottakerId', e ? e.id.toString() : '')}
                />
                {errors?.mottakerId && touched?.mottakerId && <div className={Style.invalidFeedback}>{errors?.mottakerId}</div>}
            </Form.Group>


            {/* kundenummer delen */}
            <Form.Group className="mb-3">
                <Form.Label>Kundenummer</Form.Label>
                <Form.Control
                    name="kundenummer"
                    type="text"
                    value={values.kundenummer}
                    onChange={e => handleChange('kundenummer', e.target.value)}
                    isInvalid={touched.kundenummer && errors.kundenummer}
                    isValid={touched.kundenummer && !errors.kundenummer}
                />
                <Form.Control.Feedback type="invalid">{errors.kundenummer}</Form.Control.Feedback>
            </Form.Group>

            {/* lagre knappen delen */}
            <Form.Group className="mb-3">
                <Button type="submit" disabled={submitDisabled}>
                    Lagre
                </Button>
            </Form.Group>
        </Form>
    );
};

export default KobleModal;
