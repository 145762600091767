import { IcebergIcon, IconButton } from "@tradesolution/iceberg-ui-react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import QAApi from "services/QAApi";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useToaster from "utils/hooks/useToaster";

const PopoverQACardList = () => {
    const toaster = useToaster();
    const handleVaskAlle = async () => {

        try {
            const res = await QAApi.vaskAlleEndringsmeldinger();
            toaster.success('Suksess', `${res}`);
        } catch (err) {
            toaster.error('Feil! Noe gikk galt.', HttpErrorHelper.formatError(err));
        }
    };
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                <IconButton icon='redo' onClick={handleVaskAlle} variant="outline-primary" style={{ width: '100%', border: '0px' }} >Vask alle på nytt</IconButton>
            </Popover.Body>
        </Popover>
    );
    return (
        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
            <button style={{ border: 'none', background: 'transparent', padding: 0 }}>
                <IcebergIcon icon="ellipsis" color='#68778D' />
            </button>
        </OverlayTrigger>
    );
};

export default PopoverQACardList;