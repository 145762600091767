import React from 'react';
import { EnhetStatestikk } from 'services/SalgsdatainfoApi/types';
import { format } from 'date-fns';
import { Col, Row } from 'react-bootstrap';

interface Props {
  enhetStatestikk: EnhetStatestikk;
}

/* eslint-disable react/prefer-stateless-function */
const SisteGrossistFaktura: React.FC<Props> = props => {
  const formatDate = (value: Date) => {
    return format(value, 'dd.MM.yyyy');
  };

  return (
    <div style={{ width: '100%' }} className="fakturaHistorikk">
      <h5>Sist fakturert dato</h5>
      {!props.enhetStatestikk && (
        <p>Henter grossist fakturadata ...</p>
      )}
      {props.enhetStatestikk?.kundePrGrossistgruppeSisteFakturadato.length === 0 && (
        <p>Finner ingen grossist fakturadata</p>
      )}
      <div className="margin-bottom-1rem">
        {props.enhetStatestikk?.kundePrGrossistgruppeSisteFakturadato.map(
          linje =>
            <Row key={linje.grossistGruppeTypeKode}>
              <Col>{linje.grossistGruppeTypeKode}</Col>
              <Col>{formatDate(linje.sisteFakturadato)}</Col>
            </Row>
        )}
      </div>
    </div>
  );
};

export default SisteGrossistFaktura;
