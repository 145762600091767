import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import useToaster from 'utils/hooks/useToaster';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { useNavigate } from 'react-router-dom';
import CommonLoader from 'components/CommonLoader';

interface Props {
  enhetId: string;
  name: string;
  show: boolean;
  handleClose: () => void;
}

const SlettModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toaster = useToaster();
  const navigate = useNavigate();

  const deleteEnhet = () => {
    setIsLoading(true);
    EnhetApi.deleteEnhet(props.enhetId)
      .then(() => {
        toaster.success('Suksess!', 'Enheten ble slettet.');
      })
      .catch(err => {
        toaster.error('Feil! Kunne ikke slette.', HttpErrorHelper.formatError(err));
      })
      .finally(() => {
        setIsLoading(false);
        props.handleClose();
        redirectToEnhetsearch();
      });
  };

  const redirectToEnhetsearch = () => {
    navigate(`/enheter`);
  };

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Slett enhet</Modal.Title>
      </Modal.Header>
      <Modal.Body>Enhten ved navn {props.name} blir slettet og blir borte fra søk.</Modal.Body>
      <Modal.Footer>
        <Button disabled={isLoading} variant="secondary" onClick={() => props.handleClose()}>
          Avbryt
        </Button>
        <Button disabled={isLoading} variant="danger" onClick={() => deleteEnhet()}>
          {isLoading && (
            <span className='mr-2'>
              <CommonLoader  />
            </span>
          )}
          <span>{isLoading ? 'Sletter' : 'Slett'}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SlettModal;
