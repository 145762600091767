/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";

/*Shared components */
import { IAdresse } from 'components/AddressTypeahead/types';

/*Components */
import DetailStickyHeader from "./components/DetailStickyHeader";
import QABasisForm from "./components/QABasisForm";
import QAAddressForm from "./components/QAAddressForm";
import TilleggsInfoForm from "./components/TilleggsInfoForm";
import DetailStickyFooter from "./components/DetailStickyFooter";

/*Services */
import QAApi from "services/QAApi";
import { EndringsMelding } from "services/QAApi/types";
import EnhetApi from "services/KjederegisteretAdminApi/EnhetApi";
import { EnhetViewModel, LeverandorKundenummerDto } from "services/KjederegisteretAdminApi/EnhetApi/types";
import { IEnhetInfo, findEnhetDuplicates } from "services/KjederegisteretAdminApi/EnhetApi/EnhetDuplicateHelper";
import ChangeRequestApi from "services/KjederegisteretAdminApi/ChangeRequestApi";
import { ChangeRequestCommand } from "services/KjederegisteretAdminApi/ChangeRequestApi/types";

/*Utils */
import openInNewTab from "utils/functions/openInNewTab";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import useToaster from "utils/hooks/useToaster";
import useForm from "utils/hooks/useForm";
import usePrevious from "utils/hooks/usePrevious";
import { getChangeRequestCommandInitialValue, getChangeRequestCommandUpdateValue, toAdresseFromViewModel } from "./utils";

/*Styling */
import Style from "./index.module.css";
import { useMsal } from "@azure/msal-react";
import CommonLoader from "components/CommonLoader";
import QaFakturaAddressForm from "./components/QaFakturaAddressForm";

/*IMPORTS END */


/*Interface */
interface Props {
  changeRequest: EndringsMelding;
  onGotoNext: () => void;
  onCloseWindowClicked: () => void;
}


const QACardDetails = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const toaster = useToaster();

  const { accounts } = useMsal();

  const username = accounts[0] && accounts[0].name;

  const currentUser = username || 'ukjent';

  const [enhetRelatedToChange, setEnhetRelatedToChange] = useState<EnhetViewModel>();
  const [possibleEnhetDuplicate, setPossibleEnhetDuplicate] = useState<{ orgnrDupes: string, nameDupes: string }>();
  const [enhetID, setEnhetID] = useState<number>();
  const [isLoadingCr, setIsLoadingCr] = useState<boolean>(false);
  const [copyPostadresse, setCopyPostAdresse] = useState<boolean>(false);
  const [statusMsg, setStatusMsg] = useState<string>('');

  const [selectedBesoksadresse, setSelectedBesoksadresse] = useState<IAdresse>(toAdresseFromViewModel(enhetRelatedToChange?.besoksadresse));
  const [selectedLeveringsadresse, setSelectedLeveringsadresse] = useState<IAdresse>(toAdresseFromViewModel(enhetRelatedToChange?.leveringsadresse));
  const [selectedPostadresse, setSelectedPostadresse] = useState<IAdresse>(toAdresseFromViewModel(enhetRelatedToChange?.postadresse));
  const [selectedFakturaadresse, setSelectedFakturaadresse] = useState<IAdresse>(toAdresseFromViewModel(enhetRelatedToChange?.fakturaAdresse));

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [folgesOppComment, setFolgesOppComment] = useState('');
  const [folgesOppLoading, setFolgesOppLoading] = useState<boolean>(false);
  const [showFolgOppModal, setShowFolgOppModal] = useState<boolean>(false);
  const [avvisLoading, setAvvisLoading] = useState<boolean>(false);
  const [ignorerLoading, setIgnorerLoading] = useState<boolean>(false);

  const prevEnhetID: number = usePrevious(enhetID);
  const prevCrId: string = usePrevious(props.changeRequest?.id);

  const initialValue = getChangeRequestCommandInitialValue(props.changeRequest.changes.leverandorKundenummerHosTsKundeId);

  const validate = (values: ChangeRequestCommand) => {
    const errors: any = {};
    if (!values.enhetnavn) {
      errors.enhetnavn = 'Enhetnavn er påkrevd og kan ikke være tomt.';
    }

    if (values?.telefon?.length > 8) {
      errors.telefon = 'Telefonnummer må være 8 tegn eller mindre'
    }
    if ((values?.telefon) && (values?.telefon).match(/^[0-9]+$/) == null) errors.telefon = 'Telefonnummer kan kun bestå av tall.'

    if (values.organisasjonsnr && !values.isOrgnrValidated && (values.organisasjonsnr !== enhetRelatedToChange.firma.organisasjonsnr)) { errors.organisasjonsnr = 'Orgnr eksisterer ikke i brønnøysundregisteret' };

    return errors;
  };

  const handleSubmitAndGoToNext = async () => {
    setSubmitLoading(true);
    try {
      const res = await ChangeRequestApi.approveChangeRequest(formData.values);
      if (res && res.id) {
        await QAApi.settTilBehandlet({
          id: props.changeRequest.id,
          enhetId: res.id,
          lopenummer: res.lopenr,
          changedBy: currentUser,
        });
        if (!formData.values.enhetId) {
          openInNewTab(`/enheter/${res.id}`);
        }
        setSubmitLoading(false);
        toaster.success('Suksess!', 'Enhet oppdatert.');

        if (props.onGotoNext) {
          props.onGotoNext();
        }
      }
    } catch (err) {
      setSubmitLoading(false);
      toaster.error('Feil! Kunne ikke lagre.', HttpErrorHelper.formatError(err));
    }
    return null;
  };

  const formData = useForm<ChangeRequestCommand, EnhetViewModel>(initialValue, validate, handleSubmitAndGoToNext);

  const getEnhetById = async (id: number) => {
    if (isMountedRef.current) {
      resetForm();

      if (id) {
        setIsLoadingCr(true);
        const enhet = await EnhetApi.getEnhetById(id);

        if (!enhet) {
          toaster.error('Feil!', 'Enhet koblet til endringsmeldingen finnes ikke lenger!');
          setIsLoadingCr(false);
          return;
        }

        let kundenummer: LeverandorKundenummerDto[] = [];
        if (props.changeRequest.changes.leverandorKundenummerHosTsKundeId && enhet) {
          kundenummer = enhet.leverandorKundenummer
            .filter(x => x.tsKundeId === props.changeRequest.changes.leverandorKundenummerHosTsKundeId);
        }
        if (enhet) {
          setEnhetRelatedToChange(enhet);
          updateFormdata(enhet, kundenummer);
          setIsLoadingCr(false);
        }
      } else {
        setEnhetRelatedToChange(null);
      }

      setIsLoadingCr(false);
    }
  };

  const updateFormdata = (enhet: EnhetViewModel, kundenummer?: LeverandorKundenummerDto[]) => {
    const changeRequest = getChangeRequestCommandUpdateValue(enhet, props.changeRequest.changes.leverandorKundenummerHosTsKundeId, copyPostadresse, kundenummer)
    formData.updateValues(changeRequest);
  };

  const resetForm = () => {
    setSelectedBesoksadresse(null);
    setSelectedFakturaadresse(null);
    setSelectedPostadresse(null);
    setSelectedLeveringsadresse(null);
    formData.resetForm(initialValue);
  };

  const getChangeRequestEnhetIdToUse = (): number => {
    if (props.changeRequest?.changesUsedInEnhetWithEnhetId && (parseInt(props.changeRequest?.changesUsedInEnhetWithEnhetId) !== enhetID)) {
      return parseInt(props.changeRequest.changesUsedInEnhetWithEnhetId);
    }
    return props.changeRequest.changesMightBeRelatedToEnhetId;
  };

  const checkForDuplicates = (vals: IEnhetInfo) => {
    const copy = { ...possibleEnhetDuplicate };
    findEnhetDuplicates(vals).then(e => {
      if (e?.byOrgnr.length >= 50) {
        copy.orgnrDupes = 'Det er 50 eller flere enheter med samme orgnr i KR.';
      } else if (e?.byOrgnr.length > 0 && e?.byOrgnr.length < 50) {
        copy.orgnrDupes = `Det er ${e.byOrgnr.length} enhet(er) med samme orgnr i KR.`;
      } else {
        copy.orgnrDupes = '';
      }

      if (e?.byEnhetnavn.length >= 50) {
        copy.nameDupes = 'Det er 50 eller flere enheter med samme navn i KR.';
      } else if (e?.byEnhetnavn.length > 0 && e?.byEnhetnavn.length < 50) {
        copy.nameDupes = `Det er ${e.byEnhetnavn.length} enheter med samme navn i KR.`
      } else {
        copy.nameDupes = '';
      }
      setPossibleEnhetDuplicate(copy);
    }).catch(err => {
      toaster.error(`Feil!`, HttpErrorHelper.formatError(err));
    });
  };

  const handleSubmitClicked = () => {
    handleSubmitAndGoToNext();
    return null;
  }

  const handleFolgOppClicked = async () => {
    if (isMountedRef.current) {
      if (props.changeRequest.status === 'FolgesOpp') {
        handleRemoveFromOppfolging();
      } else {
        setShowFolgOppModal(true);
      }
    }
  }

  const handleRemoveFromOppfolging = async () => {
    setFolgesOppLoading(true);
    try {
      await QAApi.stansOppfolging(props.changeRequest.id, currentUser);
      setFolgesOppLoading(false);
      toaster.success('Suksess!', ' Status oppdatert');
    } catch (err) {
      setFolgesOppLoading(false);
      toaster.error('Feil! Kunne ikke endre status.', HttpErrorHelper.formatError(err));
    }
  }

  const handleAvvisClicked = async () => {
    if (isMountedRef.current) {
      setAvvisLoading(true);
      try {
        await QAApi.avvis(props.changeRequest.id, currentUser, 'Manuelt avvist');
        toaster.success('Suksess!', ' Endringsmelding avvist.');
        setAvvisLoading(false);
      } catch (err) {
        setAvvisLoading(false);
        toaster.error('Feil! Kunne ikke avvise endringsmelding.', HttpErrorHelper.formatError(err));
      }
    }
  }

  const handleIgnorerClicked = async () => {
    setIgnorerLoading(true);
    try {
      QAApi.settTilBehandlet({
        id: props.changeRequest.id,
        lopenummer: enhetRelatedToChange?.lopenr,
        changedBy: currentUser,
      });
      setIgnorerLoading(false);
      toaster.success('Behandlet', 'Endringsmelding har fått status "behandlet"');
    } catch (err) {
      setIgnorerLoading(false);
      toaster.error('Feil! Kunne ikke endre status på endringsmelding.', HttpErrorHelper.formatError(err));
    }
  }


  const handleEnhetSelectedChangeHandler = (enhet: { id: number, name: string }) => {
    if (enhet.id) {
      setEnhetID(enhet.id);
    } else {
      setEnhetID(null);
    }
  };

  const handleImprovedBesoksAdresseChanged = (updatedAddress: IAdresse) => {
    const copy = { ...formData.values };
    if (updatedAddress) {
      copy.besoksadresse.gateadresse = updatedAddress.gateadresse;
      copy.besoksadresse.postnr = updatedAddress.postnr;
      copy.besoksadresse.poststed = updatedAddress.poststed;
      copy.besoksadresse.formattedForOneLine = updatedAddress.formattedForOneLine;
      copy.besoksadresse.latitude = updatedAddress.latitude || null;
      copy.besoksadresse.longitude = updatedAddress.longitude || null;

      setSelectedBesoksadresse(prev => {
        return {
          gateadresse: updatedAddress.gateadresse,
          postnr: updatedAddress.postnr,
          poststed: updatedAddress.poststed,
          formattedForOneLine: updatedAddress.formattedForOneLine,
          longitude: updatedAddress.longitude,
          latitude: updatedAddress.latitude,
          landkode: 'NO',
          //adressetype: 'Besøksadresse',
          isCustom: updatedAddress.customOption,
        }
      });
    } else {
      copy.besoksadresse.gateadresse = '';
      copy.besoksadresse.postnr = '';
      copy.besoksadresse.poststed = '';
      copy.besoksadresse.formattedForOneLine = '';
      copy.besoksadresse.latitude = null;
      copy.besoksadresse.longitude = null;
      setSelectedBesoksadresse(null);
    }

    formData.updateValues(copy);

  }

  const handleImprovedFakturaAdresseChanged = (updatedAddress?: IAdresse) => {
    const copy = { ...formData.values };
    if (updatedAddress) {
      copy.fakturaAdresse = {
        gateadresse: updatedAddress.gateadresse,
        postnr: updatedAddress.postnr,
        poststed: updatedAddress.poststed,
        formattedForOneLine: updatedAddress.formattedForOneLine,
        adressetype: 'FakturaAdresse',
        isCustom: updatedAddress.customOption,
      };

      setSelectedFakturaadresse(prev => {
        return {
          gateadresse: updatedAddress.gateadresse,
          postnr: updatedAddress.postnr,
          poststed: updatedAddress.poststed,
          formattedForOneLine: updatedAddress.formattedForOneLine,
          longitude: updatedAddress.longitude,
          latitude: updatedAddress.latitude,
          landkode: 'NO',
          isCustom: updatedAddress.customOption,
        }
      });
    } else {
      copy.fakturaAdresse = undefined;
      setSelectedFakturaadresse(undefined);
    }

    formData.updateValues(copy);
  }

  const handleCopyPostAdresse = (checked: boolean) => {
    const copy = { ...formData.values };
    copy.copyPostAdresse = checked;
    setCopyPostAdresse(checked);
    formData.updateValues(copy);
  }

  const handleNotatChanged = (val: string) => {
    formData.handleChange('notat', val);
  };

  const handleBransjeChanged = (id: number) => {
    formData.handleChange('bransjeId', id);
  }

  const handleAddToOppfolging = async () => {
    setFolgesOppLoading(true);
    try {
      await QAApi.folgOpp(props.changeRequest.id, currentUser, folgesOppComment);;
      toaster.success('Suksess!', ' Endringsmelding følges opp.');
      setFolgesOppLoading(false);
      setShowFolgOppModal(false);
      setFolgesOppComment('');
    } catch (err) {
      setFolgesOppLoading(false);
      toaster.error('Feil! Kunne ikke følge opp endringsmelding.', HttpErrorHelper.formatError(err));
    }
  }

  useEffect(() => {
    if (enhetRelatedToChange?.besoksadresse) {
      setSelectedBesoksadresse(toAdresseFromViewModel(enhetRelatedToChange.besoksadresse));
    } else {
      setSelectedBesoksadresse(null);
    }
    if (enhetRelatedToChange?.leveringsadresse) {
      setSelectedLeveringsadresse(toAdresseFromViewModel(enhetRelatedToChange.leveringsadresse));
    } else {
      setSelectedLeveringsadresse(null);
    }
    if (enhetRelatedToChange?.postadresse) {
      setSelectedPostadresse(toAdresseFromViewModel(enhetRelatedToChange.postadresse));
    } else {
      setSelectedPostadresse(null);
    }
    if (enhetRelatedToChange?.fakturaAdresse) {
      setSelectedFakturaadresse(toAdresseFromViewModel(enhetRelatedToChange.fakturaAdresse));
    } else {
      setSelectedFakturaadresse(null);
    }
  }, [enhetRelatedToChange])

  useEffect(() => {
    if (!enhetID) {
      checkForDuplicates({
        enhetnavn: formData.values.enhetnavn,
        juridisknavn: formData.values.juridisknavn,
        organisasjonsnummer: formData.values.organisasjonsnr,
      });
    }
  }, [formData.values.enhetnavn, formData.values.organisasjonsnr]);


  useEffect(() => {
    if (isMountedRef.current && !isLoadingCr) {
      getEnhetById(enhetID);
    }
  }, [enhetID]);

  useEffect(() => {
    if (props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet') {
      setStatusMsg(props.changeRequest.statusLog[0].comment)
    } else {
      setStatusMsg('');
    }
  }, [props.changeRequest]);

  useEffect(() => {
    if (isMountedRef.current && !isLoadingCr) {
      if ((props.changeRequest?.id !== prevCrId) && (props.changeRequest && props.changeRequest.changesMightBeRelatedToEnhetId && props.changeRequest.changesMightBeRelatedToEnhetId === prevEnhetID)) { // reset if same enhet but different changerequest
        setEnhetID(getChangeRequestEnhetIdToUse());
      }
    }
  }, [props.changeRequest?.id]);

  useEffect(() => {
    if (isMountedRef.current && !isLoadingCr) {
      if (!props.changeRequest.changesMightBeRelatedToEnhetId) {
        resetForm(); // if no changesMightBeRelatedToEnhetId we reset.
      }
      if (prevEnhetID && props.changeRequest.changesMightBeRelatedToEnhetId !== prevEnhetID) {
        // if previously selected enhetID is not null (cleared or new), and new selected changerequest enhet id-
        // is not the same, we will reset. Else we will only set the enhetId so that it is shown in the picker and fetched from API again.
        // This is so that you dont remove the enhet when changing between changerequest with the same related enhetId, but
        // removes it when clearing from typeahead.
        resetForm();
        setEnhetID(getChangeRequestEnhetIdToUse());
      } else {
        setEnhetID(getChangeRequestEnhetIdToUse());
      }
    }
  }, [props.changeRequest]);

  useEffect(() => {
    if (isMountedRef.current) {
      if (avvisLoading || folgesOppLoading || submitLoading || ignorerLoading) {
        setSubmitDisabled(true);
      } else {
        setSubmitDisabled(false);
      }
    }
  }, [avvisLoading, folgesOppLoading, submitLoading, ignorerLoading]);

  return (
    <div className={Style.container}>
      <DetailStickyHeader
        enhet={enhetRelatedToChange}
        erPublisert={enhetRelatedToChange?.erPublisert}
        changeRequest={props.changeRequest}
        submitDisabled={formData.submitDisabled || submitDisabled}
        submitLoading={submitLoading}
        folgesOppLoading={folgesOppLoading}
        onFolgesOppClicked={handleFolgOppClicked}
        onAvvisClicked={handleAvvisClicked}
        onIgnoreClicked={handleIgnorerClicked}
        onSubmitClicked={handleSubmitClicked}
        onEnhetChange={handleEnhetSelectedChangeHandler}
        onCloseWindowClicked={props.onCloseWindowClicked} />

      {
        !isLoadingCr && !formData.loading ?
          <div className={Style.qaDetailContainer}>

            <Row className={Style.formFieldRow}>
              <h4 className={Style.changeRequestStatusMsg}>
                {statusMsg}
              </h4>
            </Row>

            <Row className={Style.formFieldRow}>
              <QABasisForm
                formData={formData}
                enhet={enhetRelatedToChange}
                firma={enhetRelatedToChange?.firma}
                changeRequest={props.changeRequest}
                duplicateWarning={possibleEnhetDuplicate} />
            </Row>

            <hr />

            <Row className={Style.formFieldRow}>
              <QAAddressForm
                isCustomAddress={selectedBesoksadresse?.customOption}
                changeRequestAddress={props.changeRequest.changes.besoksadresse && {
                  gateadresse: props.changeRequest.changes.besoksadresse.gateadresse,
                  postnr: props.changeRequest.changes.besoksadresse.postnr,
                  poststed: props.changeRequest.changes.besoksadresse.poststed,
                  formattedForOneLine: props.changeRequest.changes.besoksadresse.formattedForOneLine,
                  landkode: 'NO'
                }}
                changeRequestId={props.changeRequest.id}
                improvedAddress={selectedBesoksadresse}
                onImprovedAddressChanged={handleImprovedBesoksAdresseChanged}
                postadresse={selectedPostadresse}
                fakturaadresse={selectedFakturaadresse}
                leveringsadresse={selectedLeveringsadresse}
                copyPostAdresse={copyPostadresse}
                setCopyPostAdresse={handleCopyPostAdresse}
              />
            </Row>

            <hr />

            {props.changeRequest.changes.fakturaAdresse &&
              <>
                <Row className={Style.formFieldRow}>
                  <QaFakturaAddressForm
                    isCustomAddress={selectedFakturaadresse?.customOption}
                    changeRequestAddress={props.changeRequest.changes.fakturaAdresse && {
                      gateadresse: props.changeRequest.changes.fakturaAdresse.gateadresse,
                      postnr: props.changeRequest.changes.fakturaAdresse.postnr,
                      poststed: props.changeRequest.changes.fakturaAdresse.poststed,
                      formattedForOneLine: props.changeRequest.changes.fakturaAdresse.formattedForOneLine,
                      landkode: 'NO'
                    }}
                    changeRequestId={props.changeRequest.id}
                    improvedAddress={selectedFakturaadresse}
                    onImprovedAddressChanged={handleImprovedFakturaAdresseChanged}
                  />
                </Row>
                <hr />
              </>
            }

            <div>
              <Row>
                <Col>
                  <TilleggsInfoForm
                    changerequest={props.changeRequest}
                    onBransjeChanged={handleBransjeChanged}
                    selectedBransjeId={formData.values.bransjeId}
                    notat={formData.values.notat}
                    onNotatChanged={handleNotatChanged}
                    grossist={formData.values?.hovedGrossist} />
                </Col>
              </Row>
            </div>
            <Modal show={showFolgOppModal} onHide={() => setShowFolgOppModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Følg opp endringsmelding</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  Endringsmelding får status "Følges opp" slik at andre kan se at du følger opp saken men at det kan ta litt tid av ulike årsaker.
                </p>
                <Form.Group controlId="folgesOppComment">
                  <h6>Hva er årsaken til at denne må følges opp? Skriv en kommentar</h6>
                  <Form.Control as="textarea" rows={3} value={folgesOppComment || ''}
                    onChange={e => setFolgesOppComment(e.target.value)} />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowFolgOppModal(false)}>
                  Avbryt
                </Button>
                <Button variant="primary" onClick={handleAddToOppfolging}>
                  <span>Følg opp</span>
                  {folgesOppLoading && <CommonLoader />}
                </Button>
              </Modal.Footer>
            </Modal>
          </div> :
          <div className={Style.spinnerContainer}>
            <CommonLoader />
          </div>
      }
      <DetailStickyFooter
        changeRequest={props.changeRequest}
        submitDisabled={formData.submitDisabled || submitDisabled}
        folgesOppLoading={folgesOppLoading}
        submitLoading={submitLoading}
        onFolgesOppClicked={handleFolgOppClicked}
        onSubmitClicked={handleSubmitClicked}
        onAvvisClicked={handleAvvisClicked}
        onIgnoreClicked={handleIgnorerClicked}
      />
    </div>
  )
}

export default QACardDetails;