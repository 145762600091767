import { useState, useEffect } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import useForm from 'utils/hooks/useForm';
import EnhetTypeahead from 'components/EnhetTypeahead';
import EnhetsgrupperingtypePicker from 'pages/Enhetsgruppering/EnhetgrupperingTypePicker';
import useToaster from 'utils/hooks/useToaster';
import GrupperingPicker from 'components/GrupperingPicker';
import { IMappedGrupperingNode } from 'components/GrupperingPicker/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import Style from './index.module.css';
import { AddEnhetgrupperingCommand } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import { PickedEnhet } from 'components/EnhetTypeahead/types';
import CommonLoader from 'components/CommonLoader';

interface Props {
  reload: () => void;
}

const EnhetsgrupperingCreate = (props: Props) => {
  const toaster = useToaster();
  const isMountedRef = useIsMountedRef();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [selectedGrupperinger, setSelectedGrupperinger] = useState<IMappedGrupperingNode[]>();

  const validate = (values: AddEnhetgrupperingCommand) => {
    const errors: any = {};
    if (!values.enhetgrupperingtypekode)
      errors.enhetgrupperingtypekode = 'Enhetsgrupperingtype mangler!';
    if (!values.enhetId) errors.enhetId = 'Hovedkontor er ikke satt!';
    if (!values.enhetsgrupperingnavn) errors.enhetsgrupperingnavn = 'Enhetsgrupperingnavn mangler!';
    return errors;
  };

  const onSubmit = async values => {
    setIsCreating(true);
    try {
      const payload: AddEnhetgrupperingCommand = {
        enhetgrupperingtypekode: values.enhetgrupperingtypekode,
        enhetgrupperingIdOver: values.enhetgrupperingIdOver,
        enhetId: values.enhetId,
        enhetsgrupperingnavn: values.enhetsgrupperingnavn,
        kjedenr: values.kjedenr,
      };
      await EnhetsgrupperingApi.createEnhetsgruppering(payload);
      toaster.success('Suksess!', 'Enhetsgrupperingen er opprettet!');
      props.reload();
    } catch (err) {
      toaster.error('Feil.', HttpErrorHelper.formatError(err));
    }
    setIsCreating(false);
  };

  const initialData: AddEnhetgrupperingCommand = {
    enhetId: null,
    enhetNavn: '',
    enhetgrupperingIdOver: null,
    enhetgrupperingtypekode: '',
    kjedenr: null,
    enhetsgrupperingnavn: '',
  };

  const {
    touched,
    errors,
    values,
    handleChange,
    handleSubmit,
    submitDisabled,
    updateValues,
  } = useForm(initialData, validate, onSubmit);

  const onChangeEnhet = (enhet: PickedEnhet) => {
    const copy = { ...values };
    copy.enhetId = enhet.id;
    copy.enhetNavn = enhet.name;
    updateValues(copy);
  };

  const handleChangeType = (val: string) => {
    if (val) {
      handleChange('enhetgrupperingtypekode', val);
    } else {
      handleChange('enhetgrupperingtypekode', '');
    }
  };

  useEffect(() => {
    if (isMountedRef.current && selectedGrupperinger?.length > 0) {
      handleChange('enhetgrupperingIdOver', selectedGrupperinger[0].id);
    }
  }, [selectedGrupperinger])

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup controlId="formGrupperingHovedkontor">
        <Form.Label>
          Hovedkontor: <span style={{ color: 'red' }}>*</span> (påkrevd)
        </Form.Label>
        <EnhetTypeahead
          isInvalid={!!(touched.enhetId && errors.enhetId)}
          onlyActive
          enhettype="HOVEDKONTOR"
          onChange={e => onChangeEnhet(e)}
        />
        <Form.Control.Feedback type="invalid">{errors.enhetId}</Form.Control.Feedback>
        <Form.Text>
          {values.enhetId && values.enhetNavn && (
            <div>
              <span>Enhet: </span>
              <Button className={Style.linkToEnhetBtn} size="sm" variant="link"
                onClick={() => window.open(`enheter/${values.enhetId}`, '_blank')}>
                {values.enhetNavn}
              </Button>
            </div>
          )}
        </Form.Text>
      </FormGroup>

      <FormGroup controlId="formGrupperingnavn">
        <Form.Label>
          Grupperingsnavn: <span style={{ color: 'red' }}>*</span> (påkrevd)
        </Form.Label>
        <Form.Control
          type="text"
          name="enhetsgrupperingsnavn"
          value={values.enhetsgrupperingnavn || ''}
          onChange={e => handleChange('enhetsgrupperingnavn', e.target.value)}
          isInvalid={(touched.enhetsgrupperingnavn && errors.enhetsgrupperingnavn)}
          isValid={touched.enhetsgrupperingnavn && !errors.enhetsgrupperingnavn}
        />
        <Form.Control.Feedback type="invalid">{errors.enhetsgrupperingnavn}</Form.Control.Feedback>
      </FormGroup>

      <FormGroup controlId="formEnhetgrupperingForeldre">
        <Form.Label>Overliggende:</Form.Label>
        <GrupperingPicker treeSelectMode='radioSelect'
          onSelectionChanged={(grupperinger) => setSelectedGrupperinger(grupperinger)}
        />
      </FormGroup>

      <FormGroup controlId="formGrupperingtype">
        <Form.Label>
          Grupperingtype: <span style={{ color: 'red' }}>*</span> (påkrevd)
        </Form.Label>

        <EnhetsgrupperingtypePicker
          selected={values.enhetgrupperingtypekode}
          isInvalid={(touched.enhetgrupperingtypekode && errors.enhetgrupperingtypekode)}
          isValid={touched.enhetgrupperingtypekode && !errors.enhetgrupperingtypekode}
          onChange={(value: string) => handleChangeType(value)}
        />
        <Form.Control.Feedback type="invalid">
          {errors.enhetgrupperingtypekode}
        </Form.Control.Feedback>
      </FormGroup>

      <FormGroup controlId="formGrupperingKjedenr">
        <Form.Label>Kjedenr (valgfritt):</Form.Label>
        <Form.Control
          type="text"
          name="enhetsgrupperingkjedenr"
          value={values.kjedenr || ''}
          onChange={e => handleChange('kjedenr', e.target.value)}
        />
        <Form.Text className="text-muted">
          Kjedenummer gjør grupperingen synlig som en kjede i gammel fileksport. Hvis kjedenummer ikke blir spesifisert, blir gruppen tildelt et automatisk.
        </Form.Text>
      </FormGroup>

      {!isCreating && (
        <Button type="submit" disabled={submitDisabled}>
          Lagre
        </Button>
      )}

      {isCreating && (
        <Button variant="primary" disabled>
          <CommonLoader  />
        </Button>
      )}
    </Form>
  );
};

export default EnhetsgrupperingCreate;
