import axios from 'axios';
import { MenuItem } from './types';

import { getKundeportalenApiUrl } from 'services';

const baseUrl = getKundeportalenApiUrl();

  async function fetchMenu (isTileDisplay: boolean): Promise<MenuItem[]>{
    return axios
      .get(`${baseUrl}/menu/me?isTileDisplay=${isTileDisplay}`)
      .then((response) => response.data.tjenester);
  }

  const KundeportalenApi = {
    fetchMenu
  };

 export default KundeportalenApi;