import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";

import formatDistance from "date-fns/formatDistance";

import ChangeRequestCard from "./components/ChangeRequestCard";

import { EndringsMelding } from "services/QAApi/types";



import Style from "./index.module.css";

interface Props {
    changeRequests: EndringsMelding[];
    selectedChangeRequest?: EndringsMelding;
    onCardClicked: (changeRequest: EndringsMelding, nextChangeRequest?: EndringsMelding) => void;
}

const ChangeRequestsList = (props: Props) => {
    const formatRecievedDate = (date: Date) => formatDistance(new Date(date), new Date()).replace('omtrent', 'ca.');

    return (
        <div className={Style.changeRequestListContainer}>
            {
                props.changeRequests && props.changeRequests.length > 0 ? (
                    <ListGroup>
                        {
                            props.changeRequests.map((msg, i) => (
                                <ListGroupItem className={Style.changeRequestListGroupItem}
                                    onClick={() => props.onCardClicked(msg, props.changeRequests[i + 1])}
                                    key={msg.id}>
                                    <ChangeRequestCard changeRequest={msg}
                                        recievedAt={formatRecievedDate(msg.recievedAt)}
                                        selected={
                                            props.selectedChangeRequest && props.selectedChangeRequest.id === msg.id
                                        } />
                                </ListGroupItem>
                            ))
                        }
                    </ListGroup>
                ) : (
                    <div className={Style.emptyListPlaceholder}>
                        <h4>Listen er tom.</h4>
                    </div>
                )
            }
        </div>
    )
}

export default ChangeRequestsList;