import React, { useEffect, useState } from 'react';
import { OverliggendeKjede } from 'services/KjederegisteretAdminApi/KjedeknytningerApi/types';
import KjedeknytningerApi from 'services/KjederegisteretAdminApi/KjedeknytningerApi';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import TransformToToppkjedeModal from './TransformToToppkjedeModal';
import AvsluttMedlemskapModal from './AvsluttMedlemskapModal';
import { Button, Alert, Row, Col } from 'react-bootstrap';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import KjedeHistory from './KjedeHistory';
import Style from './index.module.css';
import { KjedeType, GetKjedeByIdResponse } from 'services/KjederegisteretAdminApi/KjedeApi/types';

interface Props {
  kjede: GetKjedeByIdResponse;
  onMedlemskapUpdated: (kjedeId: number) => void;
  onKjedeLagtNed: () => void;
  isMedlemmer: boolean;
}

const EndreKjedeMedlemsskapsForm: React.FC<Props> = props => {
  const [historikk, setHistorikk] = useState([]);
  const [isShowAvsluttMedlemskapModal, setIsShowAvsluttMedlemskapModal] = useState(false);
  const [isShowTransformModal, setIsShowTransformModal] = useState(false);
  const [toppkjede, setToppkjede] = useState<OverliggendeKjede>();
  const [kjedeEnhet, setKjedeEnhet] = useState<EnhetViewModel>();
  const [isLoadingKjedeEnhet, setIsLoadingKjedeEnhet] = useState<boolean>(false);
  const [isToppKjedeMedlemskap, setIsToppKjedeMedlemskap] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  const handleCloseAvsluttMedlemskapModal = () => setIsShowAvsluttMedlemskapModal(false);
  const handleShowAvsluttMedlemskapModal = () => setIsShowAvsluttMedlemskapModal(true);
  const handleCloseTransformModal = () => setIsShowTransformModal(false);
  const handleShowTransformModal = () => setIsShowTransformModal(true);

  const loadHistorikk = async (kjedeId: number) => {
    const result = await KjedeknytningerApi.getOverliggende(kjedeId);
    const toppMedlemskap = result.filter(
      (x: OverliggendeKjede) => x.kjedetypeKode === KjedeType.Topp,
    );
    checkIfToppkjede();
    setToppkjede(toppMedlemskap[0]);
    setHistorikk(result);
  };

  const getKjedeEnhet = async (enhetID: number) => {
    setIsLoadingKjedeEnhet(true);
    const res = await EnhetApi.getEnhetById(enhetID);
    if (res) {
      setKjedeEnhet(res);
      setIsLoadingKjedeEnhet(false);
    }
  };

  const checkIfToppkjede = () => {
    if (!props.kjede.overliggendRegionKjedeID && !props.kjede.overliggendSalgsOgMarkedsKjedeID) {
      setIsToppKjedeMedlemskap(true);
    } else {
      setIsToppKjedeMedlemskap(false);
    }
  };

  useEffect(() => {
    if (props.kjede && isMountedRef.current) {
      loadHistorikk(props.kjede.kjedeID);
      setKjedeEnhet(null);
      getKjedeEnhet(props.kjede.enhetID);
    }
  }, [props.kjede.kjedeID]);

  useEffect(() => {
    if (props.kjede && isMountedRef.current) {
      checkIfToppkjede();
    }
  }, [props.kjede]);

  useEffect(() => {
    loadHistorikk(props.kjede.kjedeID);
  }, []);

  return (
    <div>
      {toppkjede ? (
        <div className={Style.optionsContainer}>
          <h5>Toppnivå kjede</h5>
          {props.isMedlemmer && toppkjede.isActive && (
            <Row>
              <Col>
                <Alert variant="info">
                  Kjeden har flere medlemmer. Kjedemedlemskapet kan ikke avsluttes.
                </Alert>
              </Col>
            </Row>
          )}
          {!toppkjede.isActive && (
            <Row>
              <Col>
                <Alert variant="info">Toppnivå kjedemedlemskap er avsluttet.</Alert>
              </Col>
            </Row>
          )}
          {toppkjede.isActive && (
            <Row>
              <Col>
                <Button
                  disabled={props.isMedlemmer}
                  variant="outline-danger"
                  onClick={handleShowAvsluttMedlemskapModal}
                >
                  Avslutt medlemskap
                </Button>
              </Col>
            </Row>
          )}
          <AvsluttMedlemskapModal
            kjedeID={props.kjede.kjedeID}
            overliggendeKjede={toppkjede}
            kjedeEnhet={kjedeEnhet}
            isLoadingKjedeEnhet={isLoadingKjedeEnhet}
            isToppkjede={isToppKjedeMedlemskap}
            isMedlemmer={props.isMedlemmer}
            isShowModal={isShowAvsluttMedlemskapModal}
            handleCloseModal={handleCloseAvsluttMedlemskapModal}
            onKjedeLagtNed={props.onKjedeLagtNed}
            getKjedeEnhet={getKjedeEnhet}
          />
        </div>
      ) : (
        <div className={Style.optionsContainer}>
          <Button
            className={Style.moveToToppkjedeBtn}
            variant="outline-primary"
            onClick={handleShowTransformModal}
          >
            Flytt til toppnivå
          </Button>
          <TransformToToppkjedeModal
            kjede={props.kjede}
            isShowModal={isShowTransformModal}
            handleCloseModal={handleCloseTransformModal}
            onMedlemskapUpdated={props.onMedlemskapUpdated}
            loadHistorikk={loadHistorikk}
          />
        </div>
      )}

      <KjedeHistory
        kjede={props.kjede}
        kjedeType={KjedeType.Region}
        overliggende={historikk.filter(o => o.kjedetypeKode === KjedeType.Region)}
        isLoadingKjedeEnhet={isLoadingKjedeEnhet}
        onMedlemskapUpdated={props.onMedlemskapUpdated}
        onKjedeLagtNed={props.onKjedeLagtNed}
        loadHistorikk={loadHistorikk}
        getKjedeEnhet={getKjedeEnhet}
        kjedeEnhet={kjedeEnhet}
      />

      <KjedeHistory
        kjede={props.kjede}
        kjedeType={KjedeType.Marked}
        overliggende={historikk.filter(o => o.kjedetypeKode === KjedeType.Marked)}
        isLoadingKjedeEnhet={isLoadingKjedeEnhet}
        onMedlemskapUpdated={props.onMedlemskapUpdated}
        onKjedeLagtNed={props.onKjedeLagtNed}
        loadHistorikk={loadHistorikk}
        getKjedeEnhet={getKjedeEnhet}
        kjedeEnhet={kjedeEnhet}
      />
    </div>
  );
};

export default EndreKjedeMedlemsskapsForm;
