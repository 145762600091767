import { IconButton } from "@tradesolution/iceberg-ui-react";
import BransjePicker, { PickedBransje } from "components/BransjePicker";
import ApiFeedback from "components/Feedback/ApiFeedback";
import { PickedKjede, PickerMode } from "components/KjedePicker";
import MarkedsKjedePicker from "components/MarkedsKjedePicker";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ApiResult } from "services/ApiHelper";
import AbonnementApi from "services/KjederegisteretAdminApi/AbonnementApi";
import { Abonnement, CreateAbonnementCommand2 } from "services/KjederegisteretAdminApi/AbonnementApi/types";

interface Props {
    onClose: () => void;
}

const CreateModal = (props: Props) => {

    const initialValue: CreateAbonnementCommand2 = {
        name: '',
        bransjeIds: [],
        kjedeIds: [],
        isKjederEkskluderende: false
    }

    const [command, setCommand] = useState<CreateAbonnementCommand2>(initialValue);

    const handleClose = () => {
        props.onClose();
    };

    const handleBransjeSelectionChanged = (bransjer: PickedBransje[]): void => {
        setCommand(prev => ({ ...prev, bransjeIds: bransjer.map(o => o.id) }))
    }

    const handleKjedeSelectionChanged = (kjeder: PickedKjede[]): void => {
        setCommand(prev => ({ ...prev, kjedeIds: kjeder.map(o => parseInt(o.kjedenr)) }))
    }

    const handleNameUpdated = (value: string): void => {
        setCommand(prev => ({ ...prev, name: value }))
    }

    const handleExcluderendeToggled = () => {
        setCommand(prev => ({ ...prev, isKjederEkskluderende: !prev.isKjederEkskluderende }))
    }

    const [apiResult, setApiResult] = useState<ApiResult<Abonnement>>();
    const handleSave = async () => {
        const result = await AbonnementApi.createAbonnement2(command);
        setApiResult(result);
    };

    return (
        <Modal onHide={handleClose} show>
            <Modal.Header closeButton>
                <Modal.Title>Opprett abonnement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Navn</Form.Label>
                        <Form.Control value={command.name} onChange={e => handleNameUpdated(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mt-1">
                        <Form.Label>Bransjeutvalg</Form.Label>
                        <BransjePicker
                            onSelectionChanged={handleBransjeSelectionChanged}
                            selected={command.bransjeIds} />
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label>Markedskjeder</Form.Label>
                        <MarkedsKjedePicker
                            pickerMode={PickerMode.Multi}
                            onSelectionChanged={handleKjedeSelectionChanged}
                            selected={command.kjedeIds} />
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label>Ekskluder kjeder</Form.Label>
                        <Form.Check
                            label="Ved avhukning vil enheter med medlemskap i valgte kjeder filtreres bort for abonnenter"
                            checked={command.isKjederEkskluderende}
                            onChange={handleExcluderendeToggled} />
                    </Form.Group>
                </Form>
                <ApiFeedback apiResult={apiResult} successMessage="Abonnement opprettet" errorMessage="En feil oppsto" />
            </Modal.Body>
            <Modal.Footer>
                {!!apiResult && <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>}
                {!apiResult &&
                    <>
                        <IconButton className='col' icon='close' variant="outline-primary" onClick={handleClose}>
                            Avbryt
                        </IconButton>
                        <IconButton
                            variant="primary"
                            icon='disk'
                            className='col'
                            type="button"
                            onClick={handleSave}>
                            Lagre
                        </IconButton>
                    </>}
            </Modal.Footer>
        </Modal>
    );
}

export default CreateModal;