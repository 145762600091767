import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { CompareStatus } from "../../utils";

interface Props {
  compareStatus: string;
  size?: 'xs' | 'sm' | 'lg' | '2x';
  onClick?: () => void;
}

const CompareIcon: React.FC<Props> = props => {

  if (props.compareStatus === CompareStatus.ny) {
    return (
      <div>
        <IcebergIcon cursor='pointer' icon="arrow-right" backgroundColor="green" color="#fff" size={props.size || ''} onClick={props.onClick} rounded bold />
      </div>
    );
  }

  if (props.compareStatus === CompareStatus.ignorer) {
    return (
      <div style={{ lineHeight: '15px' }}>
        <IcebergIcon icon="check" color="#fff" backgroundColor="grey" size={props.size || ''} rounded bold />
      </div>
    );
  }

  if (props.compareStatus === CompareStatus.lik) {
    return (
      <OverlayTrigger
        key="tooltip-top"
        placement="top"
        overlay={<Tooltip id="tooltip">Like</Tooltip>}>
        <div style={{ lineHeight: '15px' }}>
          <IcebergIcon icon="check" size={props.size || ''} backgroundColor='green' color='#fff' rounded bold />
        </div>
      </OverlayTrigger>
    );
  }

  if (props.compareStatus === CompareStatus.ulik) {
    return (
      <OverlayTrigger
        key="tooltip-top"
        placement="top"
        overlay={<Tooltip id="tooltip">Ulike</Tooltip>}>
        <div>
          <IcebergIcon cursor='pointer' icon="arrow-right" backgroundColor="orange" color="#fff" size={props.size || ''} onClick={props.onClick} rounded bold />
        </div>
      </OverlayTrigger>
    );
  }

  return null;
};

export default CompareIcon;
