import axios from 'axios';

import { LandDto } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/land';

export default {
  get: (): Promise<LandDto[]> => axios.get(`${baseUrl}`).then(response => response.data),
};
