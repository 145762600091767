import React from 'react';
import { Card, Form } from 'react-bootstrap';

interface Props {
    title: string;
    onOptionChanged: (value: string, checked: boolean) => void;
    options: {
        value: string,
        label: string,
        checked: boolean
    }[];
}

const OtherFilters = (props: Props) => {
    return (
        <Card className="mb-3">
            <Card.Header>{props.title}</Card.Header>
            <Card.Body>
                <Form>
                    {props.options.map(fv => (
                        <div key={fv.value}>
                            <Form.Check
                                type="checkbox"
                                name={props.title}
                                id={fv.value}
                                value={fv.value}
                                checked={fv.checked}
                                onChange={e => props.onOptionChanged(e.target.value, e.target.checked)}
                                label={fv.label}
                            />
                        </div>
                    ))}
                </Form>
            </Card.Body>
        </Card>
    );
};

export default OtherFilters;
