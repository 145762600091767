import { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Table, Alert, OverlayTrigger, Popover, Card } from 'react-bootstrap';
import useToaster from 'utils/hooks/useToaster';
import { EnhetStatestikk, Fakturalinje, GrossistKunde } from 'services/SalgsdatainfoApi/types';
import SisteGrossistFaktura from '../../../components/SisteGrossistFaktura';
import AndelBestillbarChart from './components/AndelBestillbarChart';
import GrossistPicker, { PickedGrossist } from 'components/GrossistPicker';
import Style from './index.module.css';
import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import { Grossist as GrossistModel, SelectedGrossist } from 'services/KjederegisteretAdminApi/GrossisterApi/types';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import ConfirmButton from 'components/ConfirmButton';
import RekobleGrossist from './RekobleGrossist';
import EnhetGrossistApi from 'services/KjederegisteretAdminApi/EnhetGrossistApi';
import { AddEnhetGrossistKoblingCommand } from 'services/KjederegisteretAdminApi/EnhetGrossistApi/types';
import { GrossistViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';

interface Props {
  enhetId: number;
  grossists: GrossistViewModel[];
  onUpdated: () => void;
  enhetStatestikk: EnhetStatestikk;
  grossistKunder: GrossistKunde[];
  fakturalinjer: Fakturalinje[];
}

interface KundenrSuggestionsFromSalgsdata {
  glnNotFound: boolean;
  currentDoesNotExistInSuggestions: boolean;
  otherSuggestions: string[];
  suggestion: string;
}

const Grossist = (props: Props) => {

  const toaster = useToaster();
  const [kobledeGrossists, setKobledeGrossists] = useState<GrossistViewModel[]>(
    props.grossists
  );

  const [statesatikkTableVisible, setStatestikkTableVisible] = useState(false);
  const [grossistKundenummerTableVisible, setGrossistKundenummerTableVisible] = useState(true);
  const [fakturalinjerVisible, setFakturalinjerVisible] = useState(false);

  const [krGrossists, setKrGrossists] = useState<GrossistModel[]>([]);

  const loadGrossists = async () => {
    const g = await GrossisterApi.getGrossister("", true);
    setKrGrossists(g);
  };

  useEffect(() => {
    loadGrossists();
  }, []);

  const findKrGrossistByGln = (gln: string | number) => {
    return krGrossists.find(g => g.gln === gln);
  };

  const isAlreadyAdded = (gln: number, kundenummer: number) => {
    return !!kobledeGrossists.find(g => g.grossistGln === gln?.toString() && g.kundenummerHosGrossist === kundenummer?.toString());
  };

  const addGrossistKobling = async (enhetId: number, grossistGln: number, kundenummer: string) => {
    const command: AddEnhetGrossistKoblingCommand = {
      enhetId: enhetId,
      grossistGln: grossistGln,
      kundenummer: kundenummer,
    };
    try {
      await EnhetGrossistApi.addKobling(command);
      toaster.success("Suksess", "Grossist kobling lagt til");
    } catch (error) {
      toaster.error("En feil oppsto", error);
    }
    props.onUpdated();
  }

  const handleAddGrossistClicked = async (item: GrossistKunde) => {
    await addGrossistKobling(props.enhetId, item.grossistGLN, item.grossistkundenr?.toString());
  }

  useEffect(() => {
    setKobledeGrossists(props.grossists);
  }, [props.grossists]);

  const getKundenummerSuggestionsFromSalgsdata = (gln: string, currentKundeNr: string): KundenrSuggestionsFromSalgsdata => {
    if (!props.grossistKunder) {
      return {
        glnNotFound: true,
        currentDoesNotExistInSuggestions: false,
        otherSuggestions: [],
        suggestion: ''
      };
    }

    return {
      glnNotFound: props.grossistKunder.filter(o => o.grossistGLN?.toString() === gln).length === 0,
      currentDoesNotExistInSuggestions: currentKundeNr && props.grossistKunder.filter(o => o.grossistGLN?.toString() === gln).length > 0 && !props.grossistKunder.find(o => o.grossistGLN?.toString() === gln && o.grossistkundenr?.toString() === currentKundeNr),
      otherSuggestions: props.grossistKunder.filter(x => x.grossistGLN?.toString() === gln && x.grossistkundenr?.toString() !== currentKundeNr).map(o => o.grossistkundenr?.toString()),
      suggestion: props.grossistKunder.filter(x => x.grossistGLN?.toString() === gln).length > 0 ? props.grossistKunder.filter(x => x.grossistGLN?.toString() === gln)[0].grossistkundenr?.toString() : ''
    };
  };

  const findPreSelectedNodes = () => {
    return pickedGrossist ? [pickedGrossist.id] : [];
  };

  const handleHovedGrossistChanged = async (kobling: GrossistViewModel) => {
    try {
      await EnhetGrossistApi.updateHovedgrossist(kobling.enhetGrossistId);
      toaster.success("Suksess", "Hovedgrossist oppdatert");
    } catch (error) {
      toaster.error("En feil oppsto", error);
    }
    props.onUpdated();
  };

  const formatKundenrSuggestions = (suggestions: KundenrSuggestionsFromSalgsdata) => {
    if (!suggestions) {
      return null;
    }

    if (suggestions.glnNotFound) {
      return <Form.Text className='text-danger'>Grossistkobling finnes ikke i salgsdata</Form.Text>;
    }

    if (suggestions.currentDoesNotExistInSuggestions) {
      return <>
        <Form.Text className='text-danger'>Kundenummeret finnes ikke i salgsdata. </Form.Text>
        <Form.Text> Forslag til kundenr: {suggestions.otherSuggestions.join(', ')}</Form.Text>
      </>;
    }
  };

  const [showNew, setShowNew] = useState(false);
  const [kundenummerHosGrossist, setKundenummerHosGrossist] = useState('');

  const toggleNewKobling = () => {
    setShowNew(!showNew);
  }

  const [pickedGrossist, setPickedGrossist] = useState<PickedGrossist | undefined>(undefined);

  const [suggestions, setSuggestions] = useState<KundenrSuggestionsFromSalgsdata | undefined>();

  useEffect(() => {
    if (pickedGrossist) {
      const s = getKundenummerSuggestionsFromSalgsdata(pickedGrossist.grossistGln, kundenummerHosGrossist);
      setSuggestions(s);
    }
  }, [kundenummerHosGrossist, pickedGrossist?.grossistGln]);

  const handleKobleGrossist = async () => {
    if (!pickedGrossist) return;

    await addGrossistKobling(props.enhetId, parseInt(pickedGrossist.grossistGln), kundenummerHosGrossist);
  };

  const fjernKobling = async (enhetGrossistId: number) => {
    try {
      await EnhetGrossistApi.removeKobling(enhetGrossistId);
      toaster.success("Suksess", "Grossist kobling fjernet");
    } catch (error) {
      toaster.error("En feil oppsto", error);
    }
    setShowContextMenu(-1);
    props.onUpdated();
  }

  const [showContextMenu, setShowContextMenu] = useState<number>(-1);
  const [showRekobleGrossistModal, setShowRekobleGrossistModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<SelectedGrossist | undefined>();

  const handleRekobleGrossistClicked = (item: SelectedGrossist) => {
    setShowContextMenu(undefined);
    setShowRekobleGrossistModal(true);
    setSelectedItem(item);
  }

  const handleCloseRekobleGrossistModal = () => {
    setSelectedItem(undefined);
    setShowRekobleGrossistModal(false);
  };

  const toogleOverlayMenu = (enhetGrossistId: number) => {
    if (showContextMenu === enhetGrossistId) {
      setShowContextMenu(-1);
    } else {
      setShowContextMenu(enhetGrossistId);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <IconButton style={{ float: "right" }} onClick={toggleNewKobling} size="sm" variant='outline-primary' icon={showNew ? "close" : "plus"}>{showNew ? "Lukk" : "Grossistkobling"}</IconButton>
            </Col>
          </Row>
          {showNew &&
            <Card style={{ margin: "1rem 0" }}>
              <Card.Body>
                <Card.Title>Koble til ny grossist</Card.Title>
                <Row>
                  <Col>
                    <GrossistPicker mode='radioSelect' onSelectionChanged={e => setPickedGrossist(e[0])} findPreselectedNodesByID={findPreSelectedNodes} />
                  </Col>
                  <Col>
                    <Form.Control type='number' placeholder='Kundenummer hos grossist' value={kundenummerHosGrossist} onChange={e => setKundenummerHosGrossist(e.target.value)} />
                    {formatKundenrSuggestions(suggestions)}
                  </Col>
                  <Col className='col-sm-auto'>
                    <IconButton variant='outline-primary' icon="plus" onClick={handleKobleGrossist}>Legg til</IconButton>
                  </Col>
                </Row>
              </Card.Body>
            </Card>}
          <Row style={{ margin: "1rem 0" }}>
            <Col>
              <Form>
                <Table>
                  <tbody>
                    {kobledeGrossists.map(g => {
                      return (
                        <tr key={g.id}>
                          <td vertical-align='middle'>
                            {g.name} ({g.grossistGln})
                          </td>
                          <td>
                            <Form.Control size='sm' type='number' readOnly placeholder='Kundenummer hos grossist' value={g.kundenummerHosGrossist} />
                          </td>
                          <td vertical-align='middle'>
                            <Form.Check type="radio" name="hovedgrossist" label="Hovedgrossist" checked={g.erHovedgrossist} onChange={() => handleHovedGrossistChanged(g)} />
                          </td>
                          <td className='fitContent'>
                            <OverlayTrigger trigger="click" key={`left_${g.enhetGrossistId}`} placement={'left'} rootClose show={showContextMenu === g.enhetGrossistId} onToggle={(nextShow) => toogleOverlayMenu(g.enhetGrossistId)}
                              overlay={<Popover id={`popover-positioned-${'left'}`}>
                                <Popover.Body>
                                  <Button variant='outline-primary' style={{ width: '100%', border: '0px' }} onClick={() => handleRekobleGrossistClicked(g)}>
                                    Rekoble
                                  </Button>
                                  <ConfirmButton onClick={() => fjernKobling(g.enhetGrossistId)} variant='outline-primary' style={{ width: '100%', border: '0px' }} />
                                </Popover.Body>
                              </Popover>}>
                              <Button style={{ color: '#68778D', border: 'none', background: 'transparent', padding: '0.5rem' }}>
                                <IcebergIcon icon='ellipsis' />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
            </Col>
          </Row>
          {!props.enhetStatestikk && (
            <p>Henter grossist faktureringsdata ...</p>
          )}
          {props.enhetStatestikk?.kundePrGrossistgruppeSisteFakturadato.length === 0 && (
            <Alert variant="warning">Finner ingen grossist fakturadata for denne enheten</Alert>
          )}
          {props.enhetStatestikk?.kundePrGrossistgruppeSisteFakturadato.length > 0 && (
            <Row className={Style.grossistTabRow}>
              <Col className={Style.andelBestillbarChartCol}>
                <AndelBestillbarChart enhetStatestikk={props.enhetStatestikk} />
              </Col>
              <Col className={Style.sisteGrossistCol}>
                <SisteGrossistFaktura enhetStatestikk={props.enhetStatestikk} />
              </Col>
            </Row>
          )}
          {!props.fakturalinjer && (
            <p>Henter fakturalinjer ...</p>
          )}
          {props.fakturalinjer?.length === 0 && (
            <Alert variant="warning">Finner ingen fakturalinjer for denne enheten</Alert>
          )}
          {props.grossistKunder && props.grossistKunder.length > 0 && (
            <Row className={Style.grossistTabRow}>
              <Col>
                <h5 style={{ marginTop: '1em' }}>Grossistkundenummer fra Salgsdata</h5>
                <Row>
                  <Col>
                    <Button
                      className={Style.showTableBtn}
                      variant="link"
                      size="sm"
                      onClick={() => setGrossistKundenummerTableVisible(!grossistKundenummerTableVisible)}
                    >
                      {grossistKundenummerTableVisible ? 'Lukk detaljer' : 'Vis detaljer fra ' + props.grossistKunder.length + ' grossist(er)'}
                    </Button>
                  </Col>
                </Row>
                {grossistKundenummerTableVisible && (
                  <div className="margin-bottom-1rem">
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Grossist</th>
                          <th>GLN</th>
                          <th>Kundenummer</th>
                          <th>Grossistens kundenavn</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.grossistKunder.map(
                          (item, index) => <tr key={index}>
                            <td>{item.grossistnavn}</td>
                            <td>{item.grossistGLN}</td>
                            <td>{item.grossistkundenr}</td>
                            <td>{item.grossistensKundenavn}</td>
                            <td>
                              {findKrGrossistByGln(item.grossistGLN)
                                ?
                                (!isAlreadyAdded(item.grossistGLN, item.grossistkundenr)
                                  ? <Button size="sm" className='float-end' onClick={() => handleAddGrossistClicked(item)}>Legg til</Button>
                                  : <Button size="sm" className='float-end' variant='success' disabled>Allerede lagt inn</Button>)
                                : null}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Col>
            </Row>
          )}
          {props.fakturalinjer && props.fakturalinjer.length > 0 && (
            <Row className={Style.grossistTabRow}>
              <Col>
                <h5 style={{ marginTop: '1em' }}>Fakturalinjer fra Salgsdata</h5>
                <Row>
                  <Col>
                    <Button
                      className={Style.showTableBtn}
                      variant="link"
                      size="sm"
                      onClick={() => setFakturalinjerVisible(!fakturalinjerVisible)}
                    >
                      {fakturalinjerVisible ? 'Lukk detaljer' : 'Vis siste ' + props.fakturalinjer.length + ' fakturalinjer'}
                    </Button>
                  </Col>
                </Row>
                {fakturalinjerVisible && (
                  <div className="margin-bottom-1rem">
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Varenavn</th>
                          <th>Antall</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.fakturalinjer.map(
                          (item, index) => <tr key={index}>
                            <td>{item.varenavn}</td>
                            <td>{item.antall}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Col>
            </Row>
          )}
          {props.enhetStatestikk?.kundePrGrossistgruppeSisteFakturadato.length > 0 && (
            <Row className={Style.grossistTabRow}>
              <Col>
                <h5 style={{ marginTop: '1em' }}>Fakturastatistikk for siste 12 mnd</h5>
                <Row>
                  <Col>
                    <Button
                      className={Style.showTableBtn}
                      variant="link"
                      size="sm"
                      onClick={() => setStatestikkTableVisible(!statesatikkTableVisible)}
                    >
                      {statesatikkTableVisible ? 'Lukk detaljer' : 'Vis detaljer om ' + props.enhetStatestikk?.kundePrGrossistgruppePrMaaned?.length + ' linje(r)'}
                    </Button>
                  </Col>
                </Row>
                {statesatikkTableVisible && (
                  <div className="margin-bottom-1rem">
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Grossist og kundenummer</th>
                          <th>Andel pakninger</th>
                          <th># fakturalinjer</th>
                          <th>År-Mnd</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.enhetStatestikk?.kundePrGrossistgruppePrMaaned.map(
                          (linje, index) => <tr key={index}>
                            <td>{linje.grossistnavn} - {linje.grossistkundenr}</td>
                            <td>{Math.round((linje.antallBestilbarPercent + Number.EPSILON) * 100) / 100}%</td>
                            <td>{linje.antallFakturalinjer}</td>
                            <td>{linje.fakturadatoAar}-{linje.fakturadatoMaaned}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <RekobleGrossist show={showRekobleGrossistModal} item={selectedItem} onClose={handleCloseRekobleGrossistModal} onUpdated={props.onUpdated} />
    </>
  );
};

export default Grossist;


