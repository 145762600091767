import { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import KjedeApi from 'services/KjederegisteretAdminApi/KjedeApi';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import CommonLoader from 'components/CommonLoader';

interface Props {
  kjedeId: number;
  show: boolean;
  setShow: (show: boolean) => void;
  onKjedeDeleted: () => void;
}

const SlettKjedeModal = (props: Props) => {
  const toaster = useToaster();

  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

  const handleClose = () => {
    props.setShow(false);
  };

  const deleteKjede = async () => {
    setIsLoadingDelete(true);
    try {
      await KjedeApi.deleteKjede(props.kjedeId);
      toaster.success('Suksess!', 'Kjede er slettet.');
      setIsLoadingDelete(false);
      if (props.onKjedeDeleted) {
        props.onKjedeDeleted();
      }
    } catch (err) {
      setIsLoadingDelete(false);
      toaster.error('Feil!', HttpErrorHelper.formatError(err));
    }
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Slett kjede</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Hvis denne kjeden ble opprettet ved en feil og den ikke er tilknyttet noen enheter, kan du
        slette den.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Lukk
        </Button>
        <Button variant="primary" onClick={() => deleteKjede()}>
          {isLoadingDelete && (
            <span className='mr-1'>
              <CommonLoader/>
            </span>
          )}
          <span>Slett</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SlettKjedeModal;
