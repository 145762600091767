/* eslint-disable no-param-reassign */
export const breadthFirstSearch = (element: any, searchQuery: string, callback) => {
  if (element.keywords && element.keywords.toUpperCase().includes(searchQuery.toUpperCase())) {
    element.expanded = true;
    if (element.parent) {
      expand(element.parent);
    }
  } else if (element.expanded && element.children && !element.children.some(x => x.expanded)) {
    element.expanded = false;
  }
  if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i += 1) {
      result = breadthFirstSearch(element.children[i], searchQuery, callback);
    }
    return result;
  }
  return element;
};

const expand = node => {
  if (node.parent) {
    node.parent.expanded = true;
    expand(node.parent);
  } else {
    node.expanded = true;
  }
};
