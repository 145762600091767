import axios from "axios";
import { EnhetSearchFilter, SearchResult } from "./types";

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/enhet';

const EnhetSearchApi = {
    searchEnheter: async (filter: EnhetSearchFilter): Promise<SearchResult> =>
        axios.post(`${baseUrl}/search`, filter).then(response => {
            response.data.items = response.data.items.map((o: any) => {
                o.nedlagtDato = o.nedlagtDato ? new Date(o.nedlagtDato) : null;
                o.oppstartsdato = o.oppstartsdato ? new Date(o.oppstartsdato) : null;
                o.postFraOgMedDato = o.postFraOgMedDato ? new Date(o.postFraOgMedDato) : null;
                o.komplettTidspunkt = o.komplettTidspunkt ? new Date(o.komplettTidspunkt) : null;
                o.opprettet = new Date(o.opprettet);
                o.besoksadresse = o.adresseList.find((a: { adressetypeKode: string; }) => a.adressetypeKode === 'Besøksadresse')?.adresse || null;
                o.gateadresse = o.besoksadresser.find((a: { erHovedAdresse: boolean; }) => a.erHovedAdresse)?.gateadresse || null;
                o.poststed = o.besoksadresser.find((a: { erHovedAdresse: boolean; }) => a.erHovedAdresse)?.poststed || null;
                o.postnummer = o.besoksadresser.find((a: { erHovedAdresse: boolean; }) => a.erHovedAdresse)?.postnummer || null;
                return o;
            });
            return response.data;
        }),
}

export default EnhetSearchApi;