import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { format } from "date-fns";

import DistribusjonsApi from "services/KjederegisteretAdminApi/DistribusjonsApi";
import { DistribusjonsHistorikk } from "services/KjederegisteretAdminApi/DistribusjonsApi/types";

interface Props {
  id: number;
}

const DistribusjonsHistorikkTabell: React.FC<Props> = props => {

  const [data, setData] = useState<DistribusjonsHistorikk[]>();

  useEffect(() => {
    if (props.id) {
      DistribusjonsApi.getDistribusjonsHistorikkById(props.id).then(res => {
        setData(res);
      });
    }
  }, [props.id]);

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>
            <span>Tidspunkt</span>
          </th>
          <th>
            <span>Distribusjonsnavn</span>
          </th>
          <th>
            <span>Antall enheter</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          data &&
          data.map(i => (
            <tr key={Math.random()}>
              <td>{format(i.tidspunkt, 'dd.MM.yyyy HH:mm:ss')}</td>
              <td>{i.distribusjonsNavn}</td>
              <td>{i.antallEnheter}</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}

export default DistribusjonsHistorikkTabell;