import axios from "axios";

import { ChangeRequestCommand } from "./types";
import { EnhetViewModel } from "../EnhetApi/types";

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/changerequests';

async function approveChangeRequest(command: ChangeRequestCommand): Promise<EnhetViewModel> {
  const response = await axios.post(baseUrl, command);
  return response.data;
}

const ChangeRequestApi = {
  approveChangeRequest
}

export default ChangeRequestApi;