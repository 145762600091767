/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, InputGroup, FormControl } from 'react-bootstrap';

/*Shared components */
import PageWrapper from 'components/PageWrapper';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

/*Components */
import DistribusjonsTable from './components/DistribusjonsTable';

/*Services */
import DistribusjonsApi from 'services/KjederegisteretAdminApi/DistribusjonsApi';
import { DistribusjonsHistorikk } from 'services/KjederegisteretAdminApi/DistribusjonsApi/types';

/*Utils */
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import useDebounce from 'utils/hooks/useDebounce';

/*Styling */
import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

/*IMPORTS END */

const Distribusjoner: React.FC = () => {
  /*useState properties */
  const [data, setData] = useState<DistribusjonsHistorikk[]>([]);
  const [filterQuery, setFilterQuery] = useState('');
  const [isLoadingData, setIsLoadingData] = useState(false);


  const toaster = useToaster();
  const debouncedFilterQuery = useDebounce(filterQuery, 300);

  /*functions */
  const filterOnChange = (e: string) => {
    setFilterQuery(e);
  };

  const fetchDistribusjoner = useCallback(async (query: string) => {
    setIsLoadingData(true);
    try {
      const res = await DistribusjonsApi.getDistribusjonsHistorikk(query, undefined);
      setData(res);
    } catch (err) {
      toaster.error('Feil!', HttpErrorHelper.formatError(err));
    }
    setIsLoadingData(false);
  }, []);

  /*useEffect */
  useEffect(() => {
    fetchDistribusjoner(debouncedFilterQuery);
  }, [debouncedFilterQuery, fetchDistribusjoner]);

  /*Content */
  return (
    <PageWrapper isFluid header="Distribusjoner">
      <Row>
        <Col md="5">
          <InputGroup className='mb-3'>
            <FormControl
              value={filterQuery}
              placeholder="Filtrer distribusjoner på navn og mottakernavn..."
              style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
              onChange={(e: { target: { value: string; }; }) => filterOnChange(e.target.value)} />
            <InputGroup.Text style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
              {
                filterQuery.length > 0 ?
                  <IcebergIcon icon='close' cursor='pointer'
                    onClick={() => setFilterQuery('')} />
                  : <IcebergIcon icon='search' />
              }
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          {isLoadingData ? (
            <div className={Style.spinnerContainer}>
              <CommonLoader />
              <h5>Henter distribusjoner...</h5>
            </div>
          ) : (
            <DistribusjonsTable data={data} />
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default Distribusjoner;
