import axios from 'axios';
import { Mottaker, AddOrUpdateMottakerCommand, CreateMottakerCommand } from './types';

import { getKjederegAdminApiUrl } from "../../";
import { ApiHelper, ApiResult } from 'services/ApiHelper';

const baseUrl = getKjederegAdminApiUrl() + '/mottaker';

async function getMottakere(): Promise<Mottaker[]> {
  const response = await axios.get(baseUrl);
  return response.data;
}

async function getMottaker(id: number): Promise<Mottaker> {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
}

async function getMottakerByTsKundeId(tsKundeId: string): Promise<Mottaker> {
  const response = await axios.get(`${baseUrl}/byTsKundeId/${tsKundeId}`);
  return response.data;
}

async function addMottaker(command: AddOrUpdateMottakerCommand) {
  const response = await axios.post(`${baseUrl}`, command).then(response => response.data);
  return response.data;
}

async function createMottaker(command: CreateMottakerCommand): Promise<ApiResult<Mottaker>> {
  return await ApiHelper.post(`${baseUrl}`, command);
}

async function updateMottaker(id: number, command: AddOrUpdateMottakerCommand) {
  const response = await axios.put(`${baseUrl}/${id}`, command);
  return response.data;
}

async function deleteMottaker(id: number) {
  return axios.delete(`${baseUrl}/${id}`);
}

async function get(query: string, onlyWithTsKundeId = false): Promise<Mottaker[]> {
  const response = await axios.get(`${baseUrl}?q=${query}&onlyWithTsKundeId=${onlyWithTsKundeId}`);
  return response.data;
}

const MottakerApi = {
  getMottakere,
  getMottaker,
  getMottakerByTsKundeId,
  addMottaker,
  createMottaker,
  updateMottaker,
  deleteMottaker,
  get
}

export default MottakerApi;
