import React from 'react';
import { format } from 'date-fns';
import { EnhetFromSearch } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
    enhet: EnhetFromSearch;
}

const EnhetStatusIcon: React.FC<Props> = props => {
    if (props.enhet.nedlagtDato) {
        return (
            <IcebergIcon
                title={`${props.enhet.nedlagtDato < new Date() ? 'Nedlagt' : 'Legges ned'}: ${format(
                    props.enhet.nedlagtDato,
                    'dd.MM.yyyy',
                )}`}
                icon="alert"
            />
        );
    }
    if (props.enhet.oppstartsdato && props.enhet.oppstartsdato > new Date()) {
        return (
            <IcebergIcon
                title={`Oppstart: ${format(props.enhet.oppstartsdato, 'dd.MM.yyyy')}`}
                icon="clock"
            />
        );
    }
    return null;
};

export default EnhetStatusIcon;
