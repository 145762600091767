import { Card, Form } from 'react-bootstrap';

interface Props {
    title: string;
    value: string;
    options: { label: string; value: string; }[];
    onOptionChanged: (value: string) => void;
}

const AktivFilterRadio = (props: Props) => {
    return (
        <Card className="mb-3">
            <Card.Header>{props.title}</Card.Header>
            <Card.Body>
                {props.options.map(fv => (
                    <Form.Group className="form-check" key={fv.value}>
                        <Form.Check
                            // className="form-check-input"
                            type="radio"
                            // custom
                            name={props.title}
                            id={fv.value}
                            value={fv.value}
                            checked={fv.value === props.value}
                            onChange={e => props.onOptionChanged(e.target.value)}
                            label={fv.label}
                        />
                    </Form.Group>
                ))}
            </Card.Body>
        </Card>
    );
}

export default AktivFilterRadio;
