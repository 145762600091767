import { IAdresse } from "components/AddressTypeahead/types";

export function formatAddressAsOneLiner(address: IAdresse): string {
  if (!address) return '';
  if (!address.gateadresse && !address.postnr) return '';
  if (!address.gateadresse) return `${address.postnr} ${address.poststed}`;
  return `${address.gateadresse}, ${address.postnr ? address.postnr : ''} ${address.poststed ? address.poststed : ''}`;
}

export function parseAsAddress(addressText: string): IAdresse | null {
  if (!addressText) {
    throw new Error('addressText is missing!');
  }

  if (addressText.indexOf(',') > -1) {
    const firstSplit = addressText.split(',');
    const gateadresse = firstSplit[0].trim();

    const addressPart2 = firstSplit[1].trim();
    const indexOfFirstSpace = addressPart2.indexOf(' ');

    if (indexOfFirstSpace > -1) {
      const postnr = addressPart2.substring(0, indexOfFirstSpace);
      const poststed = addressPart2.substring(indexOfFirstSpace + 1);
      return {
        gateadresse: gateadresse,
        postnr: postnr,
        poststed: poststed,
        formattedForOneLine: addressText,
        landkode: 'NO',
      };
    }
  }

  return null;
}
