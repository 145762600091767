import React from 'react';
import Button from 'react-bootstrap/Button';
import Style from "./index.module.css";

const toRegExp = text => new RegExp(text, 'i');

const createHighlighting = (match: RegExpMatchArray, text: string, subtitle: string) => {
  const { length } = match[0];
  const { index } = match;
  if (index === 0) {
    const other = text.substring(length, text.length + 1);
    return (
      <span>
        <span className={Style.Highlight}>{match[0]}</span>
        {other} {subtitle ? ` (${subtitle})` : ''}
      </span>
    );
  }
  const other = text.substring(0, index);
  const last =
    length < text.substring(index, text.length).length ? text.substring(index, text.length) : '';
  return (
    <span>
      {other} <span className={Style.Highlight}>{match[0]}</span> {last.replace(match[0], '')}
      {subtitle ? ` (${subtitle})` : ''}
    </span>
  );
};

const formatContent = (title: string, subtitle: string, text?: string) => {
  const standardContent = (
    <span>
      {title} {subtitle ? ` (${subtitle})` : ''}
    </span>
  );
  if (!text) return standardContent;
  const regex = toRegExp(text);
  const titleMatch = title.match(regex);
  if (titleMatch) {
    return createHighlighting(titleMatch, title, subtitle);
  }
  return standardContent;
};

export const transformTitle = (data, onClick: (picked: any) => void, text?: string, parent?: any) =>
  data.map(e => {
    if (parent) {
      e.parent = parent;
    }
    if (!e.title || text) {
      const content = formatContent(
        e.name ? e.name.toUpperCase() : '',
        e.subtitle ? e.subtitle.toUpperCase() : '',
        text ? text.toUpperCase() : text,
      );
      e.title = (
        <div>
          {onClick && (
            <Button className={Style.NodeButton} variant={getVariant(e)} onClick={() => onClick(e)}>
              {content}
            </Button>
          )}
          {!onClick && (
            <Button className={Style.NodeButton} variant={getVariant(e)} disabled>
              {content}
            </Button>
          )}
        </div>
      );
      if (e.children) {
        transformTitle(e.children, onClick, text, e);
      }
    }
    return e;
  });

export const setParent = (data: any, parent?: any) =>
  data.map(e => {
    if (parent) {
      e.parent = parent;
    }
    if (e.children) {
      setParent(e.children, e);
    }
    return e;
  });

function getVariant(e) {
  if (e.isInactive) return 'outline-danger';
  if (e.isEmphasized) return 'outline-success';
  return 'outline-primary';
}
