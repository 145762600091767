import React from 'react';
import KjedePicker, { PickedKjede, PickerMode } from 'components/KjedePicker';
import { KjedeType } from 'services/KjederegisteretAdminApi/KjedeApi/types';

interface Props {
  onSelectionChanged: (kjeder: PickedKjede[]) => void;
  selected?: number[];
  pickerMode: PickerMode;
}

const MarkedsKjedePicker = (props: Props) => {
  return (
    <KjedePicker
      pickerMode={props.pickerMode}
      onSelectionChanged={props.onSelectionChanged}
      selected={props.selected}
      kjedeType={KjedeType.Marked}
    />
  );
};

export default MarkedsKjedePicker;
