import React, { useState, useEffect, useCallback } from 'react';
import { Form, Col, Button, Card, Row } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import RegionalkjedePicker from 'components/RegionalkjedePicker';
import MarkedsKjedePicker from 'components/MarkedsKjedePicker';
import KjedeBreadcrumb from '../KjedeBreadcrumb';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import Style from './index.module.css';
import { PickedKjede, PickerMode } from 'components/KjedePicker';
import { HierarchyKjede } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { format } from 'date-fns';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { GrupperingForMedlemsKjede } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import KjedeGrupperingSuggestions, { Suggestion } from './KjedeGrupperingSuggestions';

import IconPopOver from 'components/IconPopOver';
import { AddEnhetKjedeMedlemskapCommand } from 'services/KjederegisteretAdminApi/EnhetKjedeApi/types';
import KjedeApi from 'services/KjederegisteretAdminApi/KjedeApi';
import { KjedeType, GetKjedeByIdResponse } from 'services/KjederegisteretAdminApi/KjedeApi/types';
import CommonLoader from 'components/CommonLoader';

interface Props {
  enhetId?: number;
  kjedeType: string;
  isLoadingSubmit?: boolean;
  onUpdateKjede?: (kjede: AddEnhetKjedeMedlemskapCommand) => void;
  defaultFraOgMedDato: Date | undefined;
  defaultAvdelingsnummer?: string | undefined;
}

interface SelectedGruppe { gruppeNavnOver: string, gruppenavn: string, id: number, kjedenr: string, gruppenr };

const KjedemedlemskapForm = (props: Props) => {
  const [pickedKjede, setPickedKjede] = useState<PickedKjede | undefined>();
  const [avdelingsnummer, setAvdelingsnummer] = useState(props.defaultAvdelingsnummer ?? '');
  const [selectedFraOgMedDato, setSelectedFraOgMedDato] = useState<Date | undefined>(props.defaultFraOgMedDato);
  const [earliestFraOgMedDato, setEarliestFraOgMedDato] = useState<Date>();
  const [grupperingerForMedlemskjede, setGrupperingerForMedlemskjede] = useState<GrupperingForMedlemsKjede[]>();
  const [selectedGrupper, setSelectedGrupper] = useState<SelectedGruppe[]>([]);
  const [betraktesSomKjedemedlemskapSelectedId, setBetraktesSomKjedemedlemskapSelectedId] = useState<number | undefined>();
  const isMountedRef = useIsMountedRef();

  const onSubmit = () => {
    onSubmitKjede(betraktesSomKjedemedlemskapSelectedId);
  }

  const onSubmitKjede = (gruppeSkalBetraktesSomKjedemedlemskapId?: number) => {
    props.onUpdateKjede(
      {
        enhetId: props.enhetId,
        kjedeId: pickedKjede.id,
        fraOgMedDato: selectedFraOgMedDato,
        kundenummer: avdelingsnummer,
        enhetgrupperinger: selectedGrupper.map(x => x.id),
        gruppeSkalBetraktesSomKjedemedlemskapId: gruppeSkalBetraktesSomKjedemedlemskapId || null,
      }
    );
  };

  const [kjedeResponse, setKjedeResponse] = useState<GetKjedeByIdResponse | undefined>();
  const loadHierarkiForKjede = async (kjedeId: number) => {
    const kjede = await KjedeApi.getKjedeById({
      id: kjedeId,
      kjedeType: props.kjedeType
    });
    setKjedeResponse(kjede);
  }

  const handlePickedKjedeChanged = useCallback((kjeder: PickedKjede[]) => {
    if (kjeder && kjeder.length > 0) {
      setPickedKjede(kjeder[0]);
      loadHierarkiForKjede(kjeder[0].id);
    } else {
      setPickedKjede(undefined);
      setKjedeResponse(undefined);
    }
  }, []);

  useEffect(() => {
    async function fetchDate() {
      const response = await EnhetApi.getTidligsteKjedeFraOgMedDato(props.enhetId, props.kjedeType);
      setEarliestFraOgMedDato(response);

      if ((response.getTime() !== new Date('0001-01-01T00:00:00').getTime()) && (new Date().getTime() < response.getTime())) {
        setSelectedFraOgMedDato(response);
      }
    }

    if (isMountedRef.current) {
      fetchDate()
    }
  }, []);

  useEffect(() => {
    async function fetchEnhetgrupperHierarki() {
      const response = await EnhetsgrupperingApi.getGrupperingWithMedlemskjedeById(pickedKjede.id);
      setGrupperingerForMedlemskjede(response);
      const match = response[0]?.matchingUndergruppering;
      if (match) {
        setSelectedGrupper([{
          id: match.enhetgrupperingID,
          kjedenr: match.kjedenr,
          gruppenr: match.enhetgrupperingnr,
          gruppeNavnOver: match.enhetsgrupperingOverNavn,
          gruppenavn: match.enhetsgrupperingnavn
        }]);
        setBetraktesSomKjedemedlemskapSelectedId(match.enhetgrupperingID);
      }
    }
    if (isMountedRef.current && pickedKjede) {
      fetchEnhetgrupperHierarki();
    } else {
      setGrupperingerForMedlemskjede(undefined);
    }
  }, [pickedKjede?.id]);

  const handleSuggestionsUpdated = (suggestions: Suggestion[]) => {
    if (selectedGrupper.length > 0) {
      setBetraktesSomKjedemedlemskapSelectedId(selectedGrupper[0].id);
    } else {
      setBetraktesSomKjedemedlemskapSelectedId(undefined);
    }
    setSelectedGrupper(suggestions.map(o => {
      return {
        id: o.id,
        kjedenr: o.kjedenr,
        gruppenr: o.gruppenr,
        gruppeNavnOver: o.gruppeNavnOver,
        gruppenavn: o.gruppenavn
      }
    }));
  };

  return (
    <Card>
      <Card.Body>
        <div>
          <h6>Forhåndsvisning:</h6>
          <div className={Style.suggestion}>
            {kjedeResponse && kjedeResponse.hierarchy ? (
              <div className={Style.pickedKjedeContainer}>
                <KjedeBreadcrumb
                  kjedeHierarchy={kjedeResponse.hierarchy.map(
                    o => new HierarchyKjede(o.id, o.nr.toString(), o.name, o.enhetId),
                  )}
                />
              </div>
            ) : (
              <div className={Style.kjedePlaceholder}>
                <h6>Velg en kjede fra listen under</h6>
              </div>
            )}
          </div>
        </div>
        <Form>
          <Row>
            <Form.Group as={Col}>
              <Form.Label>Søk etter kjede</Form.Label>
              {props.kjedeType === KjedeType.Region && (
                <RegionalkjedePicker
                  pickerMode={PickerMode.Single}
                  onSelectionChanged={handlePickedKjedeChanged}
                //selected={pickedKjede ? [pickedKjede.id] : []}
                />
              )}

              {props.kjedeType === KjedeType.Marked && (
                <MarkedsKjedePicker
                  pickerMode={PickerMode.Single}
                  onSelectionChanged={handlePickedKjedeChanged}
                  selected={pickedKjede ? [pickedKjede.id] : []}
                />
              )}
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col}>
              <Form.Label>Avdelingsnummer</Form.Label>
              <Form.Control
                name="avdelingsnummer"
                type="text"
                value={avdelingsnummer}
                onChange={e => setAvdelingsnummer(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Fra og med <span style={{ color: 'red' }}>*</span></Form.Label>
              <div
                style={{
                  width: '100%',
                }}
              >
                <DatePicker
                  selected={selectedFraOgMedDato}
                  onChange={date => setSelectedFraOgMedDato(date)}
                  minDate={earliestFraOgMedDato}
                  required={true}
                  isInvalid={!selectedFraOgMedDato}
                  adjustTimeForTimezoneOffset={true}
                />
                {earliestFraOgMedDato &&
                  earliestFraOgMedDato.getTime() !== new Date('0001-01-01T00:00:00').getTime()
                  && (
                    <Form.Text>Første mulige dato: {format(earliestFraOgMedDato, 'dd.MM.yyyy')}</Form.Text>
                  )}
                {!selectedFraOgMedDato && (
                  <Form.Text style={{ color: 'red' }}>Kjede må ha fra og med dato</Form.Text>
                )}
              </div>
            </Form.Group>
          </Row>

          {pickedKjede && grupperingerForMedlemskjede?.length > 0 && (
            <Row>
              <Form.Group as={Col}>
                <Form.Label>
                  <span><strong>Forslag til enhetsgruppering for enhet</strong></span>
                  <IconPopOver menuTitle="Forslag til enhetsgruppering" toggleIcon="info">
                    For å gjøre det enklere å huske på å melde en enhet inn i enhetgruppering,
                    får du forslag til enhetsgruppering basert på valgt kjede.
                    Forslag genereres basert på hvilken enhetsgruppering valgt kjede er medlemskjede i.
                    Dersom det finnes en undergruppering med samme navn som valgt kjede, vises denne først i listen.
                  </IconPopOver>
                </Form.Label>
                {grupperingerForMedlemskjede.length > 0 && grupperingerForMedlemskjede.map((x, i: number) => (
                  <div key={i + Math.random()} className={Style.suggestionContainer}>
                    <KjedeGrupperingSuggestions
                      kjedeName={pickedKjede.name}
                      enhetsgruppering={x}
                      onSuggestionsUpdated={handleSuggestionsUpdated}
                      selectedSuggestions={selectedGrupper}
                    />
                  </div>
                ))}
              </Form.Group>
            </Row>
          )}

          <Row>
            <Form.Group as={Col}>
              <Button
                style={{ margin: '.5em' }}
                variant={selectedGrupper && selectedGrupper.length > 0 ? 'outline-primary' : 'primary'}
                disabled={!pickedKjede || !selectedFraOgMedDato || props.isLoadingSubmit}
                className="btn-block" onClick={onSubmit}
              >
                {props.isLoadingSubmit && (
                  <span className='mr-1'>
                    <CommonLoader />
                  </span>
                )}
                <span>Lagre</span>
              </Button>
            </Form.Group>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default KjedemedlemskapForm;
