import React, { useState, useRef } from 'react';
import Style from './index.module.css';
import { Overlay, Popover } from 'react-bootstrap';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  toggleIcon: 'ellipsis-horizontal' | 'info';
  menuTitle?: string;
  children: React.ReactNode;
}

const IconPopOver = (props: Props) => {

  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setShow(!show);
    setTarget(event.target);
  };

  return (
    <div className={Style.container} ref={ref} onClick={(e) => handleClick(e)}>
      <IcebergIcon
        className={Style.menuIcon}
        size='18px'
        icon={props.toggleIcon}
        color='black'
      />
      <Overlay
        rootClose
        show={show}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
      >
        <Popover className={Style.popover} id="popover-menu">
          <Popover.Header className={Style.popoverTitle}>{props.menuTitle}</Popover.Header>
          <Popover.Body className={Style.popoverContent}>
            <div className={Style.childrenContainer}>{props.children}</div>
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default IconPopOver;
