import axios from 'axios';
import {
  Abonnement, AbonnementQuery, CreateAbonnementCommand, CreateAbonnementResponse,
  UpdateAbonnementCommand, UpdateAbonnementBransjerCommand, UpdateAbonnementKjederCommand,
  UpdateAbonnementResponse,
  UpdateAbonnementCommand2,
  CreateAbonnementCommand2
} from './types';

import { getKjederegAdminApiUrl } from "../../";
import { ApiHelper, ApiResult } from 'services/ApiHelper';

const baseUrl = getKjederegAdminApiUrl() + '/abonnement';

async function getAbonnement(parameters: AbonnementQuery): Promise<Abonnement[]> {
  let url = `${baseUrl}?skip=${parameters.skip}&take=${parameters.take}`;
  if (parameters.q) {
    url += `&query=${parameters.q}`;
  }
  const response = await axios.get(url);
  return response.data;
};

async function createAbonnement(command: CreateAbonnementCommand): Promise<CreateAbonnementResponse> {
  const response = await axios.post(`${baseUrl}`, command);
  return response.data;
}

async function updateAbonnement(id: number, command: UpdateAbonnementCommand): Promise<UpdateAbonnementResponse> {
  const response = await axios.put(`${baseUrl}/${id}`, command);
  return response.data;
}

async function updateAbonnementBransjer(id: number, command: UpdateAbonnementBransjerCommand): Promise<UpdateAbonnementResponse> {
  const response = await axios.put(`${baseUrl}/${id}/Bransjer`, command);
  return response.data;
}

async function updateAbonnementKjeder(id: number, command: UpdateAbonnementKjederCommand): Promise<UpdateAbonnementResponse> {
  const response = await axios.put(`${baseUrl}/${id}/Kjeder`, command);
  return response.data;
}

async function deleteAbonnement(id: number): Promise<ApiResult<Abonnement>> {
  return await ApiHelper.del<Abonnement>(`${baseUrl}/${id}`);
}

async function updateAbonnement2(id: number, command: UpdateAbonnementCommand2): Promise<ApiResult<Abonnement>> {
  const url = `${baseUrl}/${id}/new`;
  return await ApiHelper.put<Abonnement>(url, command);
}

async function createAbonnement2(command: CreateAbonnementCommand2): Promise<ApiResult<Abonnement>> {
  const url = `${baseUrl}/new`;
  return await ApiHelper.post<Abonnement>(url, command);
}

const AbonnementApi = {
  getAbonnement,
  createAbonnement,
  updateAbonnement,
  updateAbonnementBransjer,
  updateAbonnementKjeder,
  deleteAbonnement,
  updateAbonnement2,
  createAbonnement2
}

export default AbonnementApi;