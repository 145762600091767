import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import LandApi from 'services/KjederegisteretAdminApi/LandApi';
import { LandDto } from 'services/KjederegisteretAdminApi/LandApi/types';

interface Props {
  selected: string;
  disabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  onChange: (selected: string) => void;
}

const CountryPicker: React.FC<Props> = props => {
  const [countries, setCountries] = useState<LandDto[]>([]);

  useEffect(() => {
    let isSubscribed = true;
    LandApi.get().then(data => {
      if (isSubscribed) {
        setCountries(data);
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <Form.Control as="select" id="landkode-picker"
                  disabled={props.disabled} isValid={props.isValid}
                  isInvalid={props.isInvalid} value={props.selected}
                  onChange={e => props.onChange(e.target.value.toUpperCase())}>
      <option>Velg land...</option>
      {countries &&
        countries.map(c => (
          <option key={c.kode} value={c.kode}>
            {c.navn}
          </option>
        ))}
    </Form.Control>
  );
};

export default CountryPicker;
