import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import { useState } from 'react';
import { Col, Row, Button, Table, OverlayTrigger, Popover } from 'react-bootstrap';
import { EnhetViewModel, LeverandorKundenummerDto } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import RekobleModal from './RekobleModal';
import ConfirmButton from 'components/ConfirmButton';
import { FjernKoblingTilLeverandorKundenummerCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import KobleModal from './KobleModal';


interface Props {
  enhet: EnhetViewModel;
  onUpdated: () => void;
}

const LeverandorKundenummer = (props: Props) => {
  const [show, setShow] = useState(undefined);
  const [showRekobleModal, setShowRekobleModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<LeverandorKundenummerDto | undefined>();
  const [showKobleModal, setShowKobleModal] = useState(false);
  const toogleOverlayMenu = (item?: LeverandorKundenummerDto, nextShow?: boolean) => {
    if (nextShow === true)
      setShow(item.id);
    if (nextShow === false && show === item.id)
      setShow(undefined);
  };

  const handleRekobleClicked = (item: LeverandorKundenummerDto) => {
    setShow(undefined);
    setShowRekobleModal(true);
    setSelectedItem(item);
  };

  const handleCloseRekobleModal = () => {
    setSelectedItem(undefined);
    setShowRekobleModal(false);
  };

  const fjernKobling = async (item: LeverandorKundenummerDto) => {
    const command: FjernKoblingTilLeverandorKundenummerCommand = {
      enhetId: props.enhet.id,
      mottakerId: item.mottakerId,
      kundenummer: item.kundenummer,
    };
    await EnhetApi.fjernKoblingTilLeverandorKundenummer(command);
    props.onUpdated();
  };

  const onCreatedNewClicked = () => {
    setShowKobleModal(!showKobleModal);
  };

  return (
    <div className='container-fluid'>
      <Row>
        <Col>
          <Button className='float-end' variant="primary" onClick={() => onCreatedNewClicked()}>
            {!showKobleModal ? (
              <span>
                <IcebergIcon icon="plus" /> Koble til
              </span>
            ) : (
              <span>
                <IcebergIcon icon="close" /> Lukk
              </span>
            )}
          </Button>
        </Col>

      </Row>

      <Row>
        <Col>
          {showKobleModal && (
            <>
              <h4>Koble til leverandørkundenummer</h4>
              <KobleModal enhetId={props.enhet.id} onUpdated={props.onUpdated}  />
            </>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          <Table style={{ borderCollapse: 'collapse', borderTop: 'none' }} hover>
            <thead>
              <tr>
                <th>Navn</th>
                <th>Kundenummer</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{ borderTop: 'none', border: '1px solid #DCDCDC' }}>
              {
                props.enhet.leverandorKundenummer.map(x => {
                  return <tr key={x.id}>
                    <td>{x.mottakerNavn}</td>
                    <td>{x.kundenummer}</td>
                    <td className='fitContent'>
                      <OverlayTrigger trigger="click" key={'left'} placement={'left'} rootClose show={show === x.id} onToggle={(nextShow) => toogleOverlayMenu(x, nextShow)}
                        overlay={<Popover id={`popover-positioned-${'left'}`}>
                          <Popover.Body>
                            <Button variant='outline-primary' style={{ width: '100%', border: '0px' }} onClick={() => handleRekobleClicked(x)}>
                              Rekoble
                            </Button>
                            <ConfirmButton onClick={() => fjernKobling(x)} variant='outline-primary' style={{ width: '100%', border: '0px' }} />
                          </Popover.Body>
                        </Popover>}>
                        <Button style={{ color: '#68778D', border: 'none', background: 'transparent', padding: '0.5rem' }}>
                          <IcebergIcon icon='ellipsis'></IcebergIcon>
                        </Button>
                      </OverlayTrigger>
                    </td>
                  </tr>;
                }
                )
              }
            </tbody>
          </Table>
        </Col>
      </Row>
      <RekobleModal show={showRekobleModal} item={selectedItem} onClose={handleCloseRekobleModal} onUpdated={props.onUpdated} />
    </div>
  );
};

export default LeverandorKundenummer;
