import React, { useState } from 'react';
import KjedeknytningerApi from 'services/KjederegisteretAdminApi/KjedeknytningerApi';
import { AddOverliggendeKjedeCommand } from 'services/KjederegisteretAdminApi/KjedeknytningerApi/types';
import { Button, Modal, InputGroup } from 'react-bootstrap';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';
import DatePicker from 'components/DatePicker';
import addMinutes from 'date-fns/addMinutes';
import Style from './index.module.css';
import { KjedeType, GetKjedeByIdResponse } from 'services/KjederegisteretAdminApi/KjedeApi/types';
import CommonLoader from 'components/CommonLoader';

interface Props {
  kjede: GetKjedeByIdResponse;
  isShowModal: boolean;
  handleCloseModal: () => void;
  onMedlemskapUpdated: (kjedeId: number) => void;
  loadHistorikk: (id: number) => void;
}

const TransformToToppkjedeModal: React.FC<Props> = props => {
  const [selectedUtmeldingsDato, setSelectedUtmeldingsDato] = useState<Date>(
    addMinutes(new Date(), -new Date().getTimezoneOffset()),
  );
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const toaster = useToaster();

  const transformToToppkjede = async () => {
    setIsLoadingSubmit(true);
    const command: AddOverliggendeKjedeCommand = {
      kjedeID: props.kjede.kjedeID,
      OverliggendeKjedeId: null,
      kjedeTypeKode: KjedeType.Topp,
      fraOgMedDato: selectedUtmeldingsDato,
    };

    try {
      await KjedeknytningerApi.addOverliggende(props.kjede.kjedeID, command);
      toaster.success('Suksess!', 'Kjedeknytning er oppdatert!');
      setIsLoadingSubmit(false);
      props.loadHistorikk(props.kjede.kjedeID);
      if (props.onMedlemskapUpdated) {
        props.onMedlemskapUpdated(props.kjede.kjedeID);
      }
    } catch (err) {
      setIsLoadingSubmit(false);
      toaster.error('Feil! Kunne ikke oppdatere.', HttpErrorHelper.formatError(err));
    }
  };

  return (
    <Modal show={props.isShowModal} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Endre til toppnivå</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>
          Hvis <strong>{props.kjede.kjedenavn}</strong> blir endret til toppnivå kjede, vil kjeden meldes
          ut av aktive regional og marked kjedemedlemskap.{' '}
        </p>
        <InputGroup>
          <label htmlFor="date-picker">Innmeldingsdato</label>
          <DatePicker
            id="date-picker"
            selected={selectedUtmeldingsDato}
            onChange={d => setSelectedUtmeldingsDato(d)}
            adjustTimeForTimezoneOffset={true}
          />
        </InputGroup>
      </Modal.Body>
      <Modal.Footer className={Style.modalFooter}>
        <Button variant="secondary" onClick={props.handleCloseModal}>
          Avbryt
        </Button>
        <Button disabled={isLoadingSubmit} onClick={transformToToppkjede}>
          {isLoadingSubmit && (
            <span style={{ paddingRight: '.4em' }}>
              <CommonLoader  />
            </span>
          )}
          <span>{isLoadingSubmit ? '...utfører' : 'Utfør'}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TransformToToppkjedeModal;
