import { useEffect, useState, useCallback } from 'react';
import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';

export interface SignalRData {
  Id: string;
  Status: string;
  Title: string;
}

interface Props {
  negotiateUrl: string;
  listeners: {
    type: string;
    handler: (data: SignalRData) => void;
  }[]
}

const useSignalR = (props: Props) => {
  const [connectionStatus, setConnectionStatus] = useState<string>('');
  const [currentConnection, setCurrentConnection] = useState<HubConnection>();
  const [eventListeners] = useState(props.listeners);

  const setupConnection = useCallback(async () => {
    if (!props.negotiateUrl) {
      return;
    }
    const connection = new HubConnectionBuilder()
      .withUrl(props.negotiateUrl)
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect()
      .build();

    eventListeners.forEach(listener => {
      connection.on(listener.type, data => {
        if (listener.handler) {
          listener.handler(data);
        }
      });
    });

    connection.onclose(() => {
      setConnectionStatus('Frakoblet');
    });

    connection.onreconnecting(() => {
      setConnectionStatus('Kobler til');
    });

    connection.onreconnected(() => {
      setConnectionStatus('Tilkoblet');
    });

    await connection.start();
    setConnectionStatus('Tilkoblet');
    setCurrentConnection(connection);
  }, [eventListeners, props.negotiateUrl]);

  useEffect(() => {
    if (!currentConnection) {
      setupConnection();
    }
  }, [currentConnection, setupConnection]);

  return {
    signalRConnectionStatus: connectionStatus,
  };
};

export default useSignalR;
