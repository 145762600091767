import axios from 'axios';
import {
  Enhetgruppering, EnhetsgruppeTreeItem, UpdateEnhetgrupperingCommand, AddEnhetgrupperingCommand,
  Medlemsgruppe, Medlemskjede, AddMedlemKjedeCommand, MeldUtMedlemskjedeCommand, FjernMedlemskjedeCommand,
  AddMedlemGruppeCommand, MeldUtMedlemsgruppeCommand, FjernMedlemsgruppeCommand, ToggleNedleggelseOrDeleteCommand,
  EnhetgrupperingnavnHierarki, GrupperingForMedlemsKjede, GrupperingForMedlemsgruppe
} from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/enhetsgruppering';

const getEnhetsgrupperingerTree = async (includeNedlagte: boolean = true, enhetgrupperingTypekode: string = null, onlyTopLevel: boolean = false): Promise<EnhetsgruppeTreeItem[]> => {
  const response = await axios.get(`${baseUrl}/tree?includeNedlagte=${includeNedlagte}&enhetgrupperingTypekode=${enhetgrupperingTypekode || ''}&onlyTopLevel=${onlyTopLevel}`);
  return response.data;
}

const getEnhetgruppering = async (id: number): Promise<Enhetgruppering> => {
  const response = await axios.get(`${baseUrl}/${id}`);
  const result = response.data;
  result.medlemskjeder = response.data.medlemskjeder.map((o: Medlemskjede) => {
    o.fraOgMedDato = new Date(o.fraOgMedDato);
    o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;
    return o;
  });
  result.medlemsgrupper = response.data.medlemsgrupper.map((o: Medlemsgruppe) => {
    o.fraOgMedDato = new Date(o.fraOgMedDato);
    o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;
    return o;
  });
  return result;
}

const getEnhetsgrupperingtyper = async () =>
  axios.get(`${baseUrl}/typer`).then(response => response.data);

const updateEnhetsgrupperingDetaljer = async (id: number, payload: UpdateEnhetgrupperingCommand) =>
  axios.put(`${baseUrl}/${id}/update`, payload).then(response => response.data);

const createEnhetsgruppering = async (payload: AddEnhetgrupperingCommand) =>
  axios.post(`${baseUrl}/createEnhetsgruppering`, payload).then(response => response.data);

const getEnhetsgrupperingNavnHierarki = async (enhetId: number): Promise<EnhetgrupperingnavnHierarki[]> =>
  axios
    .get(`${baseUrl}/enhetenhetgruppering/${enhetId}/navnhierarki`)
    .then(response => response.data);

const getMedlemskjeder = async (grupperingId: number): Promise<Medlemskjede[]> => {
  const response = await axios
    .get(`${baseUrl}/${grupperingId}/medlemskjeder`);
  const result = response.data.map((o: Medlemskjede) => {
    o.fraOgMedDato = new Date(o.fraOgMedDato);
    o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;
    return o;
  });
  return result;
};

const addMedlemskjede = async (grupperingId, command: AddMedlemKjedeCommand) =>
  axios.post(`${baseUrl}/${grupperingId}/addMedlemskjede`, command).then(response => response.data);

const meldUtMedlemskjede = async (grupperingId, command: MeldUtMedlemskjedeCommand) =>
  axios.post(`${baseUrl}/${grupperingId}/meldUtMedlemskjede`, command).then(response => response.data);

const fjernMedlemskjede = async (grupperingId, command: FjernMedlemskjedeCommand) =>
  axios.post(`${baseUrl}/${grupperingId}/fjernMedlemskjede`, command).then(response => response.data);

const getMedlemsgrupper = async grupperingId => {
  const response = await axios
    .get(`${baseUrl}/${grupperingId}/medlemsgrupper`);
  const result = response.data.map((o: Medlemsgruppe) => {
    o.fraOgMedDato = new Date(o.fraOgMedDato);
    o.tilOgMedDato = o.tilOgMedDato ? new Date(o.tilOgMedDato) : undefined;
    return o;
  });
  return result;
};

const getGrupperingWithMedlemskjedeById = async (kjedeId: number): Promise<GrupperingForMedlemsKjede[]> =>{
  const response = await axios.get(`${baseUrl}/${kjedeId}/medlemskjedegrupperinger`);
  return response.data;
}
  

const getGrupperingWithMedlemsgruppeById = async (grupperingId: number): Promise<GrupperingForMedlemsgruppe[]> =>
  axios.get(`${baseUrl}/${grupperingId}/medlemsgruppegrupperinger`).then(response => response.data);

const addMedlemsgruppe = async (grupperingId, command: AddMedlemGruppeCommand) =>
  axios.post(`${baseUrl}/${grupperingId}/addMedlemsgruppe`, command).then(response => response.data);

const meldUtMedlemsgruppe = async (grupperingId, command: MeldUtMedlemsgruppeCommand) =>
  axios.post(`${baseUrl}/${grupperingId}/meldUtMedlemsgruppe`, command).then(response => response.data);

const fjernMedlemsgruppe = async (grupperingId: number, command: FjernMedlemsgruppeCommand) =>
  axios.post(`${baseUrl}/${grupperingId}/fjernMedlemsgruppe`, command).then(response => response.data);

const toogleNedleggelseOrDelete = async (grupperingId, command: ToggleNedleggelseOrDeleteCommand): Promise<string> =>
  axios.post(`${baseUrl}/${grupperingId}/ToggleNedleggelseOrDelete`, command).then(response => response.data)

const getAntallAktiveEnheter = async (grupperingId: number, includeInactive: boolean, date: Date): Promise<number> => {
  const response = await axios.get(`${baseUrl}/${grupperingId}/antallenhetsmedlemmer?date=${date.toJSON()}`);
  return response.data;
}

const meldUtAktiveEnhetsMedlemmer = async (enhetsgruppeId: number, utmeldingsDato: Date): Promise<{ antallNedlagteMedlemskap: number }> => {
  const response = await axios.post(`${baseUrl}/${enhetsgruppeId}/meldUtAktiveEnhetsMedlemmer`, { utmeldingsDato: utmeldingsDato });
  return response.data;
}

export default {
  getEnhetsgrupperingerTree,
  getEnhetgruppering,
  getEnhetsgrupperingtyper,
  updateEnhetsgrupperingDetaljer,
  createEnhetsgruppering,
  getEnhetsgrupperingNavnHierarki,
  getMedlemskjeder,
  addMedlemskjede,
  meldUtMedlemskjede,
  fjernMedlemskjede,
  getMedlemsgrupper,
  addMedlemsgruppe,
  meldUtMedlemsgruppe,
  fjernMedlemsgruppe,
  toogleNedleggelseOrDelete,
  getGrupperingWithMedlemskjedeById,
  getGrupperingWithMedlemsgruppeById,
  meldUtAktiveEnhetsMedlemmer,
  getAntallAktiveEnheter,
};
