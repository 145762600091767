import React from 'react';
import { Card } from 'react-bootstrap';
import { EnhetgrupperingnavnHierarki } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import Style from './index.module.css';

interface Props {
  navnHierarki: EnhetgrupperingnavnHierarki[];
}

const EnhetsgrupperingBreadcrumb: React.FC<Props> = props => {
  return (
    <Card.Subtitle className="mb-2 text-muted" style={{ paddingBottom: '0em' }}>
      <ul className={Style.breadcrumb}>
        {props.navnHierarki
          .sort(function (a, b) {
            return b.lvl - a.lvl;
          })
          .map(item => (
            <li key={`${Math.random()}`}>{`${item.enhetsgrupperingnavn}`}</li>
          ))}
      </ul>
    </Card.Subtitle>
  );
}

export default EnhetsgrupperingBreadcrumb;
