import React, { useState } from 'react';
import { ListGroup, ListGroupItem, Button } from 'react-bootstrap';

import HistoryItem from './HistoryItem';
import MedlemskapEdit from './MedlemskapEdit';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { OverliggendeKjede } from 'services/KjederegisteretAdminApi/KjedeknytningerApi/types';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { KjedeType, GetKjedeByIdResponse } from 'services/KjederegisteretAdminApi/KjedeApi/types';

import Style from './index.module.css';

interface Props {
  overliggende: OverliggendeKjede[];
  isLoadingKjedeEnhet: boolean;
  loadHistorikk: (kjedeId: number) => void;
  onMedlemskapUpdated: (kjedeId: number) => void;
  onKjedeLagtNed: () => void;
  getKjedeEnhet: (id: number) => void;
  kjedeEnhet: EnhetViewModel;
  kjedeType: string;
  kjede: GetKjedeByIdResponse;
}

const KjedeHistory: React.FC<Props> = props => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [futureParent, setFutureParent] = useState<string>('');

  const getHistorikkTitle = (kjedeType: string) => {
    if (kjedeType === KjedeType.Region) return 'Regional medlemskap';
    if (kjedeType === KjedeType.Marked) return 'Marked medlemskap';
  };

  const handleParentKjedeChanged = (kjedenavn: string) => {
    setFutureParent(kjedenavn);
  };

  return (
    <div className={Style.container}>
      <div className={Style.headerContainer}>
        <h5 style={{ marginBottom: '0' }}>{getHistorikkTitle(props.kjedeType)}</h5>
        <Button
          size="sm"
          className={Style.editMedlemskapBtn}
          variant={isEdit ? 'secondary' : 'outline-primary'}
          onClick={() => setIsEdit(!isEdit)}
        >
          {isEdit ? (
            <IcebergIcon icon="close" className={Style.knytningBtnIcon} />
          ) : (
            <IcebergIcon icon="plus" className={Style.knytningBtnIcon} />
          )}
          {isEdit ? <span>Avbryt</span> : <span>Ny knytning</span>}
        </Button>
      </div>

      <ListGroup>
        {isEdit && (
          <ListGroupItem>
            <div className={Style.editMembershipContainer}>
              <MedlemskapEdit
                kjede={props.kjede}
                kjedeType={props.kjedeType}
                onParentUpdated={handleParentKjedeChanged}
                onMedlemskapUpdated={props.onMedlemskapUpdated}
                loadHistorikk={props.loadHistorikk}
                setIsEdit={setIsEdit}
                overliggendeList={props.overliggende}
              />
              <hr />
              <small>
                <p className="form-text text-muted">Forhåndsvisning av ny kjedeknytning:</p>
              </small>
              <div className={Style.previewMembershipContainer}>
                <span className="text-primary">{futureParent}</span>
                <span>
                  <strong> / </strong>
                </span>
                <span>{props.kjede.kjedenavn}</span>
              </div>
            </div>
          </ListGroupItem>
        )}
        {props.overliggende && props.overliggende.length > 0 ? (
          props.overliggende.map(o => (
            <HistoryItem
              key={`${o.kjedetypeKode}-${o.kjede.kjedenr}-${Math.random()}`}
              onKjedeLagtNed={props.onKjedeLagtNed}
              getKjedeEnhet={props.getKjedeEnhet}
              kjedeEnhet={props.kjedeEnhet}
              isLoadingKjedeEnhet={props.isLoadingKjedeEnhet}
              data={o}
            />
          ))
        ) : (
          <ListGroupItem>Ingen historikk</ListGroupItem>
        )}
      </ListGroup>
    </div>
  );
};

export default KjedeHistory;
