import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Spinner, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { format } from 'date-fns';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import ChangeGln from './ChangeGln';

import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import GlnApi from 'services/KjederegisteretAdminApi/GlnApi';
import { GlnModel } from 'services/KjederegisteretAdminApi/GlnApi/types';

import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import Style from './index.module.css';
import { UpdateGlnCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';

interface Props {
    enhet: EnhetViewModel;
    gln: string;
    onUpdated: () => void;
}

const Gln: React.FC<Props> = props => {
    const [eierAvGln, setEierAvGln] = useState<GlnModel | undefined>();
    const [showRemoveGlnModal, setShowRemoveGlnModal] = useState<boolean>(false);
    const [isLoadingRemoveGln, setIsLoadingRemoveGln] = useState<boolean>(false);
    const [isLoadingEierAvGln, setIsLoadingEierAvGln] = useState<boolean>(false);
    const [isLoadingLeggNedGln, setIsLoadingleggNedGln] = useState<boolean>(false);
    const [isLoadingReaktiverGln, setIsLoadingReaktiverGln] = useState<boolean>(false);
    const [isLoadingUpdateGln, setIsLoadingUpdateGln] = useState(false);
    const [glnModel, setGlnModel] = useState<GlnModel | undefined>();
    const [isEierGlnInfoDifferent, setEierGlnInfoDifferent] = useState<boolean>(false);

    const toaster = useToaster();

    const finnEierAvGln = async (gln: string) => {
        setIsLoadingEierAvGln(true);
        const eier = await GlnApi.eierGln(gln);
        if (eier) {
            setEierAvGln(eier);
        }
        setIsLoadingEierAvGln(false);
    };

    const lookup = async (gln: string) => {
        const item = await GlnApi.lookupGln(gln);
        if (item) {
            setGlnModel(item);
        }
    };


    const handleUpdateGln = async (command: UpdateGlnCommand) => {
        setIsLoadingUpdateGln(true);
        try {
            await EnhetApi.updateGln(command);
            setIsLoadingUpdateGln(false);
            toaster.success("Suksess!", "Enhet ble oppdatert med nytt GLN.")
            props.onUpdated();
        } catch (err) {
            setIsLoadingUpdateGln(false);
            toaster.error("Feil!", HttpErrorHelper.formatError(err))
        }
    }

    const handleRemoveGlnClicked = async () => {
        setIsLoadingRemoveGln(true);
        try {
            await EnhetApi.removeGln({
                id: props.enhet.id,
                gln: props.gln,
            })
            setIsLoadingRemoveGln(false);
            setShowRemoveGlnModal(false);
            toaster.success("Suksess!", "Gln ble fjernet fra enhet.")
            props.onUpdated();
        } catch (err) {
            setIsLoadingRemoveGln(false);
            toaster.error('Feil! Kunne ikke fjerne.', HttpErrorHelper.formatError(err));
        }
    };

    const onLeggNedGlnClicked = async () => {
        setIsLoadingleggNedGln(true);
        try {
            await EnhetApi.leggNedGln(props.enhet.id);
            toaster.success("Suksess!", "Gln ble lagt ned.")
            props.onUpdated();
        } catch (err) {
            toaster.error('Feil! Kunne ikke legge ned.', HttpErrorHelper.formatError(err));
        }
        setIsLoadingleggNedGln(false);
    };

    const onReaktiverGlnClicked = async () => {
        setIsLoadingReaktiverGln(true);
        try {
            await EnhetApi.reaktiverGln(props.enhet.id);
            toaster.success("Suksess!", "Gln ble reaktivert.")
            props.onUpdated();
        }
        catch (err) {

            toaster.error('Feil! Kunne ikke reaktivere.', HttpErrorHelper.formatError(err));
        }
        setIsLoadingReaktiverGln(false);
    };

    useEffect(() => {
        if (props.gln) {
            finnEierAvGln(props.gln);
            lookup(props.gln);
        }
    }, [props.gln]);

    useEffect(() => {
        if (!props.enhet.firma.eierGLN || !eierAvGln || !glnModel) {
            return;
        }
        if (props.enhet.firma.eierGLN !== eierAvGln.gln) {
            setEierGlnInfoDifferent(true);
        }
        else if (props.enhet.firma.eierGLNNavn !== eierAvGln.lokasjonsNavn) {
            setEierGlnInfoDifferent(true);
        }
        else if ((!props.enhet?.firma?.glnNedlagtDato && glnModel.nedlagtDato) ||
            (props.enhet?.firma?.glnNedlagtDato && !glnModel?.nedlagtDato)) {
            setEierGlnInfoDifferent(true);
        } else {
            setEierGlnInfoDifferent(false);
        }
    }, [props.enhet, eierAvGln, glnModel]);

    return (
        <div className={Style.container}>
            {props.gln && (
                <>
                    <Row className={Style.eierAvGlnRow}>
                        <Col>
                            <h6>
                                <span>GLN <strong>{props.gln}</strong>
                                    {props.enhet.firma.glnNedlagtDato && (
                                        <span className="text-danger"> (nedlagt {format(new Date(props.enhet.firma.glnNedlagtDato), 'dd.MM.yyyy')}) </span>)}
                                    <span> - eies av {props.enhet.firma.eierGLNNavn} </span>
                                    {isLoadingEierAvGln && !eierAvGln && (
                                        <span>
                                            <Spinner size="sm" animation="border" role="status" />
                                            <span style={{ paddingLeft: '.2em' }}>Kontrollerer mot GS1...</span>
                                        </span>
                                    )}
                                    {!isLoadingEierAvGln && !glnModel && (
                                        <span>
                                            <OverlayTrigger overlay={<Tooltip id="nodata">
                                                Fant ingen GLN data og kan derfor ikke sammenligne
                                            </Tooltip>}>
                                                <div style={{ display: 'inline-flex', verticalAlign: 'top', padding: '1px 1px 0' }}>
                                                    <IcebergIcon icon="close" color="white" backgroundColor='red' size="16px" bold rounded />
                                                </div>
                                            </OverlayTrigger>
                                        </span>
                                    )}
                                    {!isLoadingEierAvGln && glnModel && !isEierGlnInfoDifferent && (
                                        <span>
                                            <OverlayTrigger overlay={<Tooltip id="identical">
                                                EierGln og nedlagt status er identisk med GS1 sin informasjon
                                            </Tooltip>}>
                                                <div style={{ display: 'inline-flex', verticalAlign: 'top', padding: '1px 1px 0' }}>
                                                    <IcebergIcon icon="check" color="white" backgroundColor="green" size="16px" bold rounded />
                                                </div>
                                            </OverlayTrigger>
                                        </span>
                                    )}
                                    {!isLoadingEierAvGln && isEierGlnInfoDifferent && (
                                        <span>
                                            <OverlayTrigger overlay={<Tooltip id="identical">
                                                GS1 mener at GLN eies av {eierAvGln.firmaNavn} ({eierAvGln.eierGln}) istedenfor {props.enhet.firma.eierGLNNavn} ({props.enhet.firma.eierGLN}).
                                                {glnModel && glnModel.nedlagtDato && (
                                                    <span> De mener også at GLNet er nedlagt {format(new Date(glnModel.nedlagtDato), 'dd.MM.yyyy')}</span>
                                                )}
                                                {glnModel && !glnModel.nedlagtDato && (
                                                    <span> De mener at GLNet er aktivt </span>
                                                )}
                                            </Tooltip>}>
                                                <div style={{ display: 'inline-flex', verticalAlign: 'top', padding: '1px 1px 0' }}>
                                                    <IcebergIcon icon="close" color="white" backgroundColor='red' size="16px" bold rounded />
                                                </div>
                                            </OverlayTrigger>
                                        </span>
                                    )}
                                </span>
                            </h6>

                        </Col>
                    </Row>
                    <Row className="mb-2 pb-2">
                        <Col>
                            {!props.enhet.firma.glnNedlagtDato &&
                                <Button size='sm' variant="outline-primary" className='me-2' onClick={onLeggNedGlnClicked} disabled={isLoadingLeggNedGln}>Legg ned GLN</Button>}
                            {(props.enhet.firma.glnNedlagtDato && (glnModel && !glnModel.nedlagtDato)) &&
                                <Button size='sm' variant='outline-primary' className='me-2' onClick={onReaktiverGlnClicked} disabled={isLoadingReaktiverGln}>Reaktiver GLN</Button>}

                            {isEierGlnInfoDifferent &&
                                <Button variant='outline-primary' className="me-2" size='sm' onClick={() => handleUpdateGln({ id: props.enhet.id, gln: props.enhet.firma.gln })} >
                                    {isLoadingUpdateGln && (
                                        <span style={{ paddingRight: '0.5rem', margin: '0' }}>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                                        </span>
                                    )}
                                    Hent GLN info fra GS1
                                </Button>
                            }
                            <Button size='sm' variant="outline-danger" onClick={() => setShowRemoveGlnModal(true)}>Fjern GLN</Button>
                        </Col>

                        <Modal show={showRemoveGlnModal} onHide={() => setShowRemoveGlnModal(false)}>
                            <Modal.Header>
                                <Modal.Title>Fjern GLN fra enhet</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                GLN (<strong>{props.gln}</strong>) vil ikke lengre være koblet til {<strong>{props.enhet?.enhetnavn}</strong> || 'denne enheten'}.
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowRemoveGlnModal(false)}>Avbryt</Button>
                                <Button variant="danger" onClick={handleRemoveGlnClicked}>
                                    {isLoadingRemoveGln && (
                                        <Spinner size="sm" animation="border" role="status" />
                                    )}
                                    <span>Fjern GLN fra enhet</span>
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </Row></>

            )}

            <Row className="mt-2">
                <Col>
                    <h5>Oppdater GLN</h5>
                    <ChangeGln onUpdated={props.onUpdated} enhetId={props.enhet.id} existingGln={props.gln} />
                </Col>
            </Row>
        </div>
    );
};

export default Gln;
