import { Bar } from 'react-chartjs-2';
import { Statistikk } from 'services/QAApi/types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
  data: Statistikk;
}

const AlleBarChart = (props: Props) => {
  const data = {
    labels: props.data.perKilde.map((o) => o.kilde),
    datasets: [
      {
        label: 'Aktive',
        data: props.data.perKilde.map((o) => o.antallAktive),
        backgroundColor: '#0075DB',
        borderWidth: 1,
      },
      {
        label: 'Følges opp',
        data: props.data.perKilde.map((o) => o.antallFolgesOpp),
        backgroundColor: '#BFE0FF',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: true,
      },
    },
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} height={400} width={600} options={options} />
    </div>
  );
};

export default AlleBarChart;
