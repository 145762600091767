export class EnheterInKjedeQuery {
  setInkluderUtmeldte(value: boolean): EnheterInKjedeQuery {
    this.includeHistoric = value;
    return this.copy();
  }

  setSortBy(value: string): EnheterInKjedeQuery {
    this.sortBy = value;
    this.page = 1;
    return this.copy();
  }

  setQueryText(value: string): EnheterInKjedeQuery {
    this.q = value;
    this.page = 1;
    return this.copy();
  }

  nextPage(): EnheterInKjedeQuery {
    this.page = this.page + 1;
    return this.copy();
  }

  copy(): EnheterInKjedeQuery {
    return EnheterInKjedeQuery.parse(this);
  }

  getQueryString() {
    let queryString = '?page=' + this.page + '&pageSize=' + this.pageSize;

    if (this.q) queryString += '&q=' + this.q.toUpperCase();
    if (this.sortBy) queryString += '&sortBy=' + this.sortBy;
    if (this.includeHistoric) queryString += '&includeHistoric=' + this.includeHistoric;

    return queryString;
  }

  q: string = null;
  sortBy: string = null;
  includeHistoric = false;
  page = 1;
  pageSize = 30;

  static parse(data: any): EnheterInKjedeQuery {
    const query = new EnheterInKjedeQuery();
    query.q = data.q;
    query.sortBy = data.sortBy;
    query.page = data.page;
    query.pageSize = data.pageSize;
    query.includeHistoric = data.includeHistoric;
    return query;
  }
}

export class EnheterInGrupperingQuery {
  setInkluderUtmeldte(value: boolean): EnheterInGrupperingQuery {
    this.includeHistoric = value;
    return this.copy();
  }

  setSortBy(value: string): EnheterInGrupperingQuery {
    this.sortBy = value;
    this.page = 1;
    return this.copy();
  }

  setQueryText(value: string): EnheterInGrupperingQuery {
    this.q = value;
    this.page = 1;
    return this.copy();
  }

  nextPage(): EnheterInGrupperingQuery {
    this.page = this.page + 1;
    return this.copy();
  }

  copy(): EnheterInGrupperingQuery {
    return EnheterInGrupperingQuery.parse(this);
  }

  getQueryString() {
    let queryString = '?page=' + this.page + '&pageSize=' + this.pageSize;

    if (this.q) queryString += '&q=' + this.q.toUpperCase();
    if (this.sortBy) queryString += '&sortBy=' + this.sortBy;
    if (this.includeHistoric) queryString += '&includeHistoric=' + this.includeHistoric;

    return queryString;
  }

  q: string = null;
  sortBy: string = null;
  includeHistoric = false;
  page = 1;
  pageSize = 30;

  static parse(data: any): EnheterInGrupperingQuery {
    const query = new EnheterInGrupperingQuery();
    query.q = data.q;
    query.sortBy = data.sortBy;
    query.page = data.page;
    query.pageSize = data.pageSize;
    query.includeHistoric = data.includeHistoric;
    return query;
  }
}
