import axios from 'axios';

import { AddressSearchResult, AddressViewModel, UpdatePositionCommand } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/adresse';

const AdresseApi = {
  updatePosition: async (data: UpdatePositionCommand) => {
    const response = await axios.put(`${baseUrl}/${data.id}/updatePosition`, data);
    return response.data;
  },

  deletePosition: async (id: number) => {
    const response = await axios.delete(`${baseUrl}/${id}/deletePosition`);
    return response.data;
  },


  createAddress: async data => axios.post(`${baseUrl}/`, data).then(response => response.data),

  findMatching: async data =>
    axios
      .get(
        `${baseUrl}/lookup?gateadresse=${data.gateadresse}&postnr=${data.postnr}&poststed=${data.poststed
        }&checkExternalSource=${data.checkExternalSource}`,
        data,
      )
      .then(response => response.data),

  search: async (query: string): Promise<AddressSearchResult[]> =>
    axios.get(`${baseUrl}/search?q=${query}`).then(response => response.data),

  getById: async (id: number): Promise<AddressViewModel | undefined> =>
    axios.get(`${baseUrl}/${id}`).then(response => response.data),
}

export default AdresseApi;
