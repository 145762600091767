import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { Coordinates } from 'models/Coordinates';

interface Props {
  onPlaceFound?: (coordinates: Coordinates) => void;
}

const SearchBox: React.FC<Props> = ({ onPlaceFound }) => {
  const [autoComplete, setAutoComplete] = useState(null);
  const [autoCompleteRes, setAutoCompleteRes] = useState(null);

  const onPlacesChanged = () => {
    if (autoComplete !== null) {
      const res = autoComplete.getPlaces();
      if (res) {
        setAutoCompleteRes(res);
      }
    }
  };

  useEffect(() => {
    if (autoCompleteRes && autoCompleteRes.length > 0) {
      onPlaceFound(
        new Coordinates(
          autoCompleteRes[0].geometry.location.lat(),
          autoCompleteRes[0].geometry.location.lng(),
        ),
      );
    }
  }, [autoCompleteRes]);

  return (
    <StandaloneSearchBox onLoad={ac => setAutoComplete(ac)} onPlacesChanged={onPlacesChanged}>
      <div style={{ height: '450px', display: 'flex', flexDirection: 'column' }}>
        <InputGroup
          style={{
            width: '50%',
            marginTop: '.9em',
            marginLeft: '15.5em',
          }}
        >
          <FormControl placeholder="Søk på sted i google maps.." />
        </InputGroup>
      </div>
    </StandaloneSearchBox>
  );
}

export default SearchBox;
