import { format } from 'date-fns';
import React, { useState } from 'react';
import { Badge, Button, Card, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DirekteKjedeMedlemskap, EnhetFromSearch } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
interface Props {
  enhet: EnhetFromSearch;
}

const EnhetSearchPreview: React.FC<Props> = props => {
  const [showDetails, setShowDetails] = useState(false);
  const navigate = useNavigate();

  const formatDate = (date?: Date) => {
    if (!date) return '';
    return format(date, 'dd.MM.yyyy');
  };

  const formatDirekteKjedeMedlemskap = (medlemskap: DirekteKjedeMedlemskap) => {
    return (<>
      <div>
        <i>{formatDate(new Date(medlemskap.fraOgMedDato))} - {medlemskap.tilOgMedDato ? formatDate(new Date(medlemskap.tilOgMedDato)) : ''}</i>
      </div>
      <div>
        {medlemskap.kjedenummer} {medlemskap.kjedenavn} ({medlemskap.kundenummerHosKjede})
      </div>
    </>);
  };

  return (
    <Card style={{ marginBottom: '1rem' }}>
      <Card.Header onClick={() => navigate(`/enheter/${props.enhet.enhetID}`)}>
        <h6 style={{ margin: '0' }}>
          {props.enhet.enhetTypeKoder[0]}{' '}
          {props.enhet.nedlagtDato && props.enhet.nedlagtDato < new Date() && (
            <Badge bg="danger">Nedlagt {format(props.enhet.nedlagtDato, 'dd.MM.yyyy')}</Badge>
          )}
          {props.enhet.oppstartsdato && props.enhet.oppstartsdato > new Date() && (
            <Badge bg="warning">
              Oppstart {format(props.enhet.oppstartsdato, 'dd.MM.yyyy')}
            </Badge>
          )}
        </h6>
      </Card.Header>
      <Card.Body>
        <Card.Title onClick={() => navigate(`/enheter/${props.enhet.enhetID}`)}
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >{`${props.enhet.lopenr} ${props.enhet.enhetnavn}`}</Card.Title>
        <Card.Subtitle className="text-muted">{`${props.enhet.organisasjonsnr} ${props.enhet.juridiskNavn
          }`}</Card.Subtitle>
        <Card.Text>
          {props.enhet.adresseList
            .filter(x => x.adressetypeKode === 'Besøksadresse')
            .map(a => a.adresse)}
        </Card.Text>
        <Button
          className="btn-sm"
          variant="secondary"
          onClick={() => setShowDetails(!showDetails)}
          style={{ marginBottom: "1rem" }}
        >
          {!showDetails ? "Mer info" : "Lukk"}
        </Button>
        {showDetails && (
          <>
            <div style={{ marginBottom: "1rem" }}>
              <Card>
                <Card.Header>
                  <strong>Identifikatorer</strong>
                </Card.Header>
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Id</td>
                        <td>{props.enhet.enhetID}</td>
                      </tr>
                      <tr>
                        <td>Løpenr</td>
                        <td>{props.enhet.lopenr}</td>
                      </tr>
                      <tr>
                        <td>Statistikknr</td>
                        <td>{props.enhet.statistikknr}</td>
                      </tr>
                      <tr>
                        <td>GLN</td>
                        <td>{props.enhet.gln}</td>
                      </tr>
                      <tr>
                        <td>OrgNr</td>
                        <td>{props.enhet.organisasjonsnr}</td>
                      </tr>
                      <tr>
                        <td>Forrige løpenr</td>
                        <td>{props.enhet.forrigeLopenr}</td>
                      </tr>
                      <tr>
                        <td>Neste løpenr</td>
                        <td>{props.enhet.nesteLopenr}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <strong>Medlemsskap</strong>
                </Card.Header>
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Enhetstype</td>
                        <td>
                          {props.enhet.enhetTypeKoder.join(', ')}
                        </td>
                      </tr>
                      <tr>
                        <td>Bransje</td>
                        <td>
                          {props.enhet.latestHovedBransjeDescription}
                        </td>
                      </tr>
                      <tr>
                        <td>Grossist</td>
                        <td>
                          {props.enhet.latestHovedGrossistDescription}
                        </td>
                      </tr>
                      <tr>
                        <td>Regionkjede</td>
                        <td>
                          {props.enhet.direkteRegionalKjedeMedlemskap.map(o => (<div key={o.kjedeId} style={{ marginBottom: "0.5rem" }}>{formatDirekteKjedeMedlemskap(o)}</div>))}
                        </td>
                      </tr>
                      <tr>
                        <td>Markedskjede</td>
                        <td>
                          {props.enhet.direkteMarkedsKjedeMedlemskap.map(o => (<div key={o.kjedeId} style={{ marginBottom: "0.5rem" }}>{formatDirekteKjedeMedlemskap(o)}</div>))}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <strong>Adresser</strong>
                </Card.Header>
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Besøk</td>
                        <td>
                          {props.enhet.adresseList
                            .filter(x => x.adressetypeKode === 'Besøksadresse')
                            .map(a => a.adresse)}
                        </td>
                      </tr>
                      <tr>
                        <td>Post</td>
                        <td>
                          {props.enhet.adresseList
                            .filter(x => x.adressetypeKode === 'Postadresse')
                            .map(a => a.adresse)}
                        </td>
                      </tr>
                      <tr>
                        <td>Faktura</td>
                        <td>
                          {props.enhet.adresseList
                            .filter(x => x.adressetypeKode === 'FakturaAdresse')
                            .map(a => a.adresse)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </div>
            <div style={{ marginBottom: "1rem" }}>
              <Card>
                <Card.Header>
                  <strong>Datoer</strong>
                </Card.Header>
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Opprettet</td>
                        <td>{formatDate(props.enhet.opprettet)}</td>
                      </tr>
                      <tr>
                        <td>Oppstart</td>
                        <td>{formatDate(props.enhet.oppstartsdato)}</td>
                      </tr>
                      <tr>
                        <td>Komplett</td>
                        <td>{formatDate(props.enhet.komplettTidspunkt)}</td>
                      </tr>
                      <tr>
                        <td>Post fra og med</td>
                        <td>{formatDate(props.enhet.postFraOgMedDato)}</td>
                      </tr>
                      <tr>
                        <td>Nedlagt</td>
                        <td>{formatDate(props.enhet.nedlagtDato)}</td>
                      </tr>
                      <tr>
                        <td>Publisert</td>
                        <td>{props.enhet.erPublisert ? 'JA' : 'NEI'}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Card>
                <Card.Header>
                  <strong>Annet</strong>
                </Card.Header>
                <Card.Body>
                  <Table>
                    <tbody>
                      <tr>
                        <td>Epost</td>
                        <td>
                          {props.enhet.epost}
                        </td>
                      </tr>
                      <tr>
                        <td>Telefon</td>
                        <td>
                          {props.enhet.telefon}
                        </td>
                      </tr>
                      <tr>
                        <td>Kontonr</td>
                        <td>
                          {props.enhet.kontonr}
                        </td>
                      </tr>
                      <tr>
                        <td>Salgsareal (m2)</td>
                        <td>
                          {props.enhet.salgsArealKvadratmeter}
                        </td>
                      </tr>
                      <tr>
                        <td>Produl</td>
                        <td>
                          {props.enhet.produl}
                        </td>
                      </tr>
                      <tr>
                        <td>Offentlig</td>
                        <td>
                          {props.enhet.erOffentlig ? 'JA' : 'NEI'}
                        </td>
                      </tr>
                      <tr>
                        <td>Sesongdrift</td>
                        <td>
                          {props.enhet.erSesongdrift ? 'JA' : 'NEI'}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>

              <Card>
                <Card.Header>
                  <strong>Notat</strong>
                </Card.Header>
                <Card.Body>
                  {props.enhet.notat}
                </Card.Body>
              </Card>
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default EnhetSearchPreview;
