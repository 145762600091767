import { useState, useEffect } from 'react';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import Form from 'react-bootstrap/Form';

interface Props {
  selected: string;
  onChange: (value: string) => void;
  isValid?: boolean;
  isInvalid?: boolean;
}

const EnhetsgrupperingtypePicker = (props: Props) => {
  const [enhetsgrupperingtyper, setEnhetsgrupperingtyper] = useState([]);

  useEffect(() => {
    getEnhetsgrupperingtyper();
  }, []);

  const getEnhetsgrupperingtyper = async () => {
    const typer = await EnhetsgrupperingApi.getEnhetsgrupperingtyper();
    setEnhetsgrupperingtyper(typer);
  };

  return (
    <Form.Control
      as="select"
      value={props.selected}
      onChange={e => props.onChange(e.target.value)}
      isValid={props.isValid}
      isInvalid={props.isInvalid}>
      <option value="">-- Velg type --</option>
      {enhetsgrupperingtyper &&
        enhetsgrupperingtyper.map(c => (
          <option key={c.kode} value={c.kode}>
            {c.navn}
          </option>
        ))}
    </Form.Control>
  );
};

export default EnhetsgrupperingtypePicker;
