import { useState, useEffect } from 'react';
import DatePicker from 'components/DatePicker';
import EierskifteEnhetTypeahead from './EierskifteEnhetTypeahead';
import { EierskifteEnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { Alert, FormGroup, Row, Col, Modal, Form } from 'react-bootstrap';
import CommonLoader from 'components/CommonLoader';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import { addDays, addHours } from 'date-fns';

interface Props {
    nedlagtDato?: Date
    isNedlagt: boolean;
    isFutureGruppering: boolean;
    isFutureKjedemedlemskap: boolean;
    viderefortTil?: number;
    show?: boolean;
    handleClose: () => void;
    handleSave: (dato: Date, navn: string, oppstartDato: Date, videreforTilEnhetId: number, fjernVidereforing: boolean, viderforKjederTilNyEnhet: boolean, videreforKontaktinfoTilNyEnhet: boolean) => void;
    utforIsLoading: boolean;
    loadEierskifteState: () => void;
}

const EierskifteModal = (props: Props) => {

    const [nedleggelsesDate, setNedleggelsesDate] = useState<Date>(props.nedlagtDato);
    const [navn, setNavn] = useState('');
    const [oppstartDate, setOppstartDate] = useState<Date | undefined>(
        nedleggelsesDate ? addDays(nedleggelsesDate, 1) : undefined
    );
    const [isDatesValid, setIsDatesValid] = useState<boolean>(true);
    const [viderefores, setViderefores] = useState<boolean>(false);
    const [viderforKjederTilNyEnhet, setViderforKjederTilNyEnhet] = useState<boolean>(false);
    const [videreforesTil, setVidereforesTil] = useState<EierskifteEnhetViewModel | null>(null);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState<boolean>(false);
    const [fjernVidereforing, setFjernVidereforing] = useState<boolean>(false);
    const [videreforKontaktinfoTilNyEnhet, setVidereforKontaktinfoTilNyEnhet] = useState<boolean>(false);
    const [showDateIsTodayWarning, setShowDateIsTodayWarning] = useState<boolean>(false);

    const toggleViderefor = () => {
        setViderefores(!viderefores);
        setNavn('');
    };

    const toggleFjernVidereforing = () => {
        setFjernVidereforing(!fjernVidereforing);
    };

    const toggleViderforKjederTilNyEnhet = () => {
        setViderforKjederTilNyEnhet(!viderforKjederTilNyEnhet);
    };

    const toggleViderforKontaktinfoTilNyEnhet = () => {
        setVidereforKontaktinfoTilNyEnhet(!videreforKontaktinfoTilNyEnhet);
    };

    const handleVidereforingTil = (e: EierskifteEnhetViewModel) => {
        if (!e) {
            setVidereforesTil(null);
            return;
        }

        setVidereforesTil(e);
    };

    const [dateError, setDateError] = useState<string>('');

    useEffect(() => {
        if (nedleggelsesDate) {
            const nyOppstart = addHours(nedleggelsesDate, 24); // use addHours instead of addDays in case date falls on daylight savings day
            setOppstartDate(nyOppstart);
        }
    }, [nedleggelsesDate])

    useEffect(() => {
        setNedleggelsesDate(props.nedlagtDato);
    }, [props.nedlagtDato]);


    const handleDateChanged = (date: Date | undefined) => {
        if (date) {
            console.log('Nedleggelsesdato: ' + date);
            setNedleggelsesDate(date);
            setDateError('');
        }
        else {
            setNedleggelsesDate(undefined);
            setDateError('Nedleggelsesdato må fylles ut');
        }
    };

    useEffect(() => {
        if (videreforesTil && navn) {
            setIsSubmitEnabled(false);
            return;
        }
        else {
            setIsSubmitEnabled(true);
        }

        if (!isDatesValid) {
            setIsSubmitEnabled(false);
            return;
        }
        else {
            setIsSubmitEnabled(true);
        }

        if (videreforesTil) {
            setIsSubmitEnabled(videreforesTil.kanVidereforeTil);
        }

        if (props.viderefortTil) {
            setIsSubmitEnabled(fjernVidereforing);
        }

        if (!props.isNedlagt && props.isFutureGruppering) {
            setIsSubmitEnabled(false);
        }
        if (!props.isNedlagt && props.isFutureKjedemedlemskap) {
            setIsSubmitEnabled(false);
        }
    }, [videreforesTil, navn, fjernVidereforing, isDatesValid, props.isFutureKjedemedlemskap, props.isFutureGruppering])

    useEffect(() => {
        if (!navn || !nedleggelsesDate || !oppstartDate) {
            setIsDatesValid(true);
            return;
        }

        if (nedleggelsesDate > oppstartDate)
            setIsDatesValid(false);
        else
            setIsDatesValid(true);
    }, [nedleggelsesDate, oppstartDate, navn])

    useEffect(() => {
        let today = new Date();
        if (viderefores && props.nedlagtDato && props.nedlagtDato.getDate() === today.getDate()) {
            setShowDateIsTodayWarning(true);
        }
        else {
            setShowDateIsTodayWarning(false);
        }
    }, [props.nedlagtDato, viderefores])

    useEffect(() => {
        props.loadEierskifteState();
    }, []);

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{!props.isNedlagt ? 'Eierskifte / Nedleggelse' : 'Eierskifte'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.isFutureGruppering && (
                    <Alert variant="info">
                        <p><strong>OBS: </strong>Enheten er fremtidig innmeldt i en gruppering.</p>
                        <hr />
                        <p className="mb-0">
                            Medlemskap må fjernes før nedleggelse er mulig.
                        </p>
                    </Alert>
                )}
                {props.isFutureKjedemedlemskap && (
                    <Alert variant="info">
                        <p><strong>OBS: </strong>Enheten er fremtidig innmeldt i et kjedemedlemskap</p>
                        <hr />
                        <p className="mb-0">
                            Medlemskap må fjernes før nedleggelse er mulig.
                        </p>
                    </Alert>
                )}
                <Form>
                    <Form.Group className='mb-3'>
                        <Col>
                            <Form.Label>Nedleggelsesdato</Form.Label>
                            <div>
                                <DatePicker disabled={props.isNedlagt || props.isFutureGruppering || props.isFutureKjedemedlemskap} selected={nedleggelsesDate} onChange={handleDateChanged} adjustTimeForTimezoneOffset={true} />
                            </div>
                            {props.isNedlagt && (
                                <Form.Text>Må reaktivere for å endre nedleggelsesdato</Form.Text>
                            )}
                            {showDateIsTodayWarning && (
                                <div>
                                    <Form.Text style={{ color: 'rgba(var(--bs-warning-rgb))' }}>Skal nedleggelsesdato være dagens dato?</Form.Text>
                                </div>
                            )}
                            {dateError && (
                                <Form.Text style={{ color: 'rgba(var(--bs-danger-rgb))' }}>{dateError}</Form.Text>
                            )}
                        </Col>
                    </Form.Group>
                    {!!props.viderefortTil && (
                        <div>
                            <FormGroup className='mb-3'>
                                <Col>
                                    <div className="card card-body bg-light">
                                        <Form.Label>Enhet er videreført til løpenummer {props.viderefortTil}</Form.Label>
                                        <Form.Check
                                            type="checkbox"
                                            defaultChecked={fjernVidereforing}
                                            onChange={toggleFjernVidereforing}
                                            label="Fjern videreføring?"
                                        />
                                    </div>
                                </Col>
                            </FormGroup>
                        </div>
                    )}
                    {!props.viderefortTil && (
                        <div>
                            <Form.Group className='mb-3'>
                                <Col>
                                    <div className="card card-body bg-light">
                                        <Form.Check
                                            type="checkbox"
                                            defaultChecked={viderefores}
                                            onChange={toggleViderefor}
                                            label="Viderefør virksomheten til ny eier"
                                        />
                                    </div>
                                </Col>
                            </Form.Group>
                            {viderefores && (
                                <div>
                                    <Form.Group className='mb-3'>
                                        <Col>
                                            <Form.Label>Navn på ny enhet</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="enhetNavn"
                                                value={navn}
                                                onChange={e => setNavn(e.target.value.toUpperCase())}
                                                disabled={videreforesTil !== null}
                                            />
                                        </Col>
                                    </Form.Group>
                                    {navn && (
                                        <Form.Group className='mb-3'>
                                            <Col>
                                                <div className="card card-body bg-light">
                                                    <Row className="margin-bottom-1rem">
                                                        <Col>
                                                            <Form.Label>Oppstart ny enhet</Form.Label>
                                                            <DatePicker selected={oppstartDate} onChange={setOppstartDate} adjustTimeForTimezoneOffset={true} />
                                                            {!isDatesValid && (
                                                                <Form.Text style={{ color: 'rgba(var(--bs-success-rgb))' }}>Oppstartsdato for ny enhet må være etter nedlagtdato</Form.Text>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check
                                                                type="checkbox"
                                                                defaultChecked={viderforKjederTilNyEnhet}
                                                                onChange={toggleViderforKjederTilNyEnhet}
                                                                label="Viderefør aktive kjeder og enhetsgrupperinger til ny enhet"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Check
                                                                type="checkbox"
                                                                defaultChecked={videreforKontaktinfoTilNyEnhet}
                                                                onChange={toggleViderforKontaktinfoTilNyEnhet}
                                                                label="Viderefør kontaktinfo og kjedeavdelingsnummer (hvis kjeder videreføres)"
                                                            />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Form.Group>
                                    )}
                                    <Form.Group className='mb-3'>
                                        <Col>
                                            <Form.Label>eller finn en eksisterende enhet å videreføre til</Form.Label>
                                            <EierskifteEnhetTypeahead
                                                onChange={handleVidereforingTil}
                                                isValid={videreforesTil?.kanVidereforeTil}
                                                disabled={navn.length > 0}
                                            />
                                            {videreforesTil && !videreforesTil.kanVidereforeTil && (
                                                <Form.Text style={{ color: 'rgba(var(--bs-danger-rgb))' }}>Kan ikke videreføre til enhet som allerede har registrert forrige løpenummer</Form.Text>
                                            )}
                                        </Col>
                                    </Form.Group>
                                </div>
                            )}
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <IconButton className='col' icon='close' variant="outline-primary" onClick={props.handleClose}>
                    Lukk
                </IconButton>
                <IconButton
                    icon='disk'
                    className='col'
                    variant="primary"
                    disabled={!isSubmitEnabled || dateError !== ''}
                    onClick={() => props.handleSave(
                        nedleggelsesDate,
                        navn,
                        oppstartDate,
                        videreforesTil?.enhetId,
                        fjernVidereforing,
                        viderforKjederTilNyEnhet,
                        videreforKontaktinfoTilNyEnhet
                    )}>
                    Utfør
                    {props.utforIsLoading && (
                        <span className='ms-2'>
                            <CommonLoader />
                        </span>
                    )}
                </IconButton>
            </Modal.Footer>
        </Modal>
    );
}

export default EierskifteModal;
