import { Table, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { GrupperingMedlem } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { format } from 'date-fns';
import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

interface Props {
  enheter: GrupperingMedlem[];
  isLoadingEnheter: boolean;
  hasMore: boolean;
  onLoadMoreEnheter: () => void;
}

const EnhetTable = (props: Props) => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Navn</th>
            <th>Periode</th>
          </tr>
        </thead>
        <tbody>
          {props.enheter.length > 0 ? (
            props.enheter.map(k => (
              <tr key={k.lopenr + '-' + k.fraOgMedDato}>
                <td>
                  {k.nedlagtDato && k.nedlagtDato.getTime() <= currentDate.getTime() &&
                    <div>
                      <Badge bg="danger">NEDLAGT</Badge>
                    </div>}
                  <Link to={`/enheter/${k.enhetID}`}>{k.lopenr + ', ' + k.enhetnavn}</Link>
                  <br />
                  {k.besoksadresse &&
                    `${k.besoksadresse.gateadresse} ${k.besoksadresse.postnr} ${k.besoksadresse.poststed
                    }`}
                </td>
                <td>
                  {format(k.fraOgMedDato, 'dd.MM.yyyy')} -{' '}
                  {!!k.tilOgMedDato ? format(k.tilOgMedDato, 'dd.MM.yyyy') : ''}
                </td>
              </tr>
            ))
          ) : (
            <tr key={Math.random()}>
              {props.isLoadingEnheter ? (
                <td colSpan={2}>
                  <div className={Style.spinnerContainer}>
                      <CommonLoader  />
                    <span>Henter enheter..</span>
                  </div>
                </td>
              ) : (
                <td colSpan={2}>
                  <h6 className={Style.noEnheterFeedbackHeader}>Ingen enheter i gruppering</h6>
                </td>
              )}
            </tr>
          )}
        </tbody>
      </Table>
      {props.hasMore && !props.isLoadingEnheter && (
        <div style={{ textAlign: 'center', padding: '1rem' }}>
          <Button variant="link" onClick={props.onLoadMoreEnheter}>
            Hent flere...
          </Button>
        </div>
      )}
    </>
  );
};

export default EnhetTable;
