import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Form, Table } from "react-bootstrap";
import { format } from 'date-fns';
import PageHeader from "components/PageHeader";
import ConfirmButton from "components/ConfirmButton";
import { IconButton } from '@tradesolution/iceberg-ui-react';
import QAApi from "services/QAApi";
import { Filter, UpsertFilterAction } from "services/QAApi/types";
import HttpErrorHelper from "utils/HttpErrorHelper";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import useToaster from "utils/hooks/useToaster";
import { useMsal } from "@azure/msal-react";
import CommonLoader from "components/CommonLoader";

const QAFilterList: React.FC = () => {
    const isMountedRef = useIsMountedRef();

    const toaster = useToaster();

    const { accounts } = useMsal();

    const username = accounts[0] && accounts[0].name;

    const currentUser = username || 'ukjent';

    const [filter, setFilter] = useState<Filter[]>([]);
    const [loadingFilter, setLoadingFilter] = useState<boolean>(false);
    const [addFilterDisabled, setAddFilterDisabled] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);

    const getFilter = useCallback(async () => {
        setLoadingFilter(true);
        var result = await QAApi.getFilter();
        if (isMountedRef.current) {
            setFilter(result);
        }
        setLoadingFilter(false);
    }, [isMountedRef]);

    const addFilter = () => {
        const newFilter: Filter = {
            id: null,
            comment: '',
            eierGln: '',
            organisasjonsnummer: '',
            changedBy: '',
            lastChanged: null,
        };
        const copy = [...filter];
        copy.unshift(newFilter);
        setFilter(copy);
    };

    const onCommentChanged = (id: string, value: string) => {
        const copy = [...filter];
        const index = copy.findIndex(o => o.id === id);
        copy[index].comment = value;
        setFilter(copy);
    };

    const onEierGlnChanged = (id: string, value: string) => {
        const copy = [...filter];
        const index = copy.findIndex(o => o.id === id);
        copy[index].eierGln = value;
        setFilter(copy);
    };

    const onOrgnrChanged = (id: string, value: string) => {
        const copy = [...filter];
        const index = copy.findIndex(o => o.id === id);
        copy[index].organisasjonsnummer = value;
        setFilter(copy);
    };

    const formatDate = (value: Date) => format(value, 'dd.MM.yyyy');

    const handleUpsertClicked = (filter: Filter) => {
        setIsSaving(true);
        const command: UpsertFilterAction = {
            id: filter.id,
            eierGln: filter.eierGln,
            organisasjonsnummer: filter.organisasjonsnummer,
            comment: filter.comment,
            changedBy: currentUser,
        };
        QAApi.upsertFilter(command)
            .then(() => {
                toaster.success('Suksess!', 'Filter ble lagret');
            })
            .catch(err => {
                toaster.error('Feil! Kunne ikke lagre filter.', HttpErrorHelper.formatError(err));
            })
            .finally(() => {
                setIsSaving(false);
                getFilter();
            });
    };

    const removeNewFilter = () => {
        const copy = [...filter];
        const index = copy.findIndex(o => o.id === null);
        copy.splice(index, 1);
        setFilter(copy);
    };

    const handleDeleteClicked = id => {
        if (!id) {
            removeNewFilter();
            return;
        }
        setIsDeleting(true);
        QAApi.deleteFilter(id)
            .then(() => {
                toaster.success('Suksess!', 'Filter ble fjernet');
            })
            .catch(err => {
                toaster.error('Feil! Kunne ikke fjerne filter.', HttpErrorHelper.formatError(err));
            })
            .finally(() => {
                setIsDeleting(false);
                getFilter();
            });
    };

    useEffect(() => {
        getFilter();
    }, [getFilter]);

    useEffect(() => {
        const index = filter.findIndex(o => o.id === null);
        if (index < 0) setAddFilterDisabled(false);
        else setAddFilterDisabled(true);
    }, [filter]);

    const handleVaskAlle = async () => {
        try {
            const res = await QAApi.vaskAlleEndringsmeldinger();
            if (res) {
                toaster.success('Suksess', `${res}`);
            }
        } catch (err) {
            toaster.error('Feil! Noe gikk galt.', HttpErrorHelper.formatError(err));
        }
    };

    return (
        <div className="container-fluid">
            <PageHeader>Filter for GlnApi meldinger</PageHeader>

            <div className="text-end">
                <IconButton onClick={handleVaskAlle} variant="outline-primary" icon="redo" style={{ marginRight: '10px' }}>
                    Vask alle på nytt
                </IconButton>
                <IconButton icon='plus' variant="primary" onClick={addFilter} disabled={addFilterDisabled}>
                    Nytt filter
                </IconButton>
            </div>


            <Row>
                <Col>
                    {
                        loadingFilter ?
                            <div>
                                <CommonLoader />
                                <h5>Henter filter...</h5>
                            </div> :
                            <Form>
                                <Table responsive hover>
                                    <thead>
                                        <th>Kommentar</th>
                                        <th>Eiergln</th>
                                        <th>Organisasjonsnummer</th>
                                        <th>Sist endret</th>
                                        <th>Sist endret av</th>
                                        <th />
                                    </thead>
                                    <tbody>
                                        {
                                            filter.length === 0 ?
                                                <tr>
                                                    <td colSpan={6}>
                                                        Det finnes ingen filter. Legg til hvis du ønsker automatisk avvisning
                                                        basert på egenskaper i meldingen
                                                    </td>
                                                </tr> :
                                                (
                                                    filter.map(f =>
                                                        <tr key={f.id}>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        value={f.comment || ''}
                                                                        onChange={e => onCommentChanged(f.id, e.target.value)} />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        value={f.eierGln || ''}
                                                                        onChange={e => onEierGlnChanged(f.id, e.target.value)} />
                                                                </Form.Group>
                                                            </td>
                                                            <td>
                                                                <Form.Group>
                                                                    <Form.Control
                                                                        value={f.organisasjonsnummer || ''}
                                                                        onChange={e => onOrgnrChanged(f.id, e.target.value)} />
                                                                </Form.Group>
                                                            </td>
                                                            <td>{f.lastChanged ? formatDate(f.lastChanged) : ''}</td>
                                                            <td>{f.changedBy}</td>
                                                            <td className="text-end">

                                                                <ConfirmButton style={{ marginRight: '10px' }} onClick={async () => handleDeleteClicked(f.id)} size="sm" />
                                                                {
                                                                    isDeleting &&
                                                                    <CommonLoader />
                                                                }
                                                                <IconButton icon="disk" variant="primary" size="sm" onClick={e => handleUpsertClicked(f)}>
                                                                    Lagre
                                                                    {
                                                                        isSaving &&
                                                                        <CommonLoader />
                                                                    }
                                                                </IconButton>
                                                            </td>
                                                        </tr>
                                                    )
                                                )
                                        }
                                    </tbody>
                                </Table>
                            </Form>
                    }
                </Col>
            </Row>
        </div>
    )
}

export default QAFilterList;