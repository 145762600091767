import React, { useEffect, useState } from 'react';
import { KjedeHistorikk } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import Style from './index.module.css';

interface Props {
  item: KjedeHistorikk;
  checkKjedeStatus: (item: {isActive: boolean, isFuture: boolean, isHistoric: boolean}) => React.ReactNode;
}

const KjedeListItem: React.FC<Props> = props => {
  const [kjedenavn, setKjedenavn] = useState<string>();
  const [avdelingsnr, setAvdelingsnr] = useState<string>();

  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (props.item && isMountedRef.current) {
      setKjedenavn(props.item.nameHierarchy[props.item.nameHierarchy.length - 1]);
      setAvdelingsnr(props.item.kundenrIKjede);
    }
  }, [props.item]);

  return (
    <div className={Style.container}>
      <span className={Style.kjedeName}>
        {kjedenavn} (avd.nr: {avdelingsnr})
      </span>
      <span className={Style.statusBadge}>{props.checkKjedeStatus(props.item)}</span>
    </div>
  );
};

export default KjedeListItem;
