import React from "react";
import { Popover, Row, Col, ListGroup } from "react-bootstrap";

import { parseISO, format } from 'date-fns';

import { BrregEnhetResponse, BrregEnhetResponseAddress } from "services/KjederegisteretAdminApi/BrregApi/types";

import Style from "./index.module.css";

const BrregPopover = (data: BrregEnhetResponse) => {
  const formatAddress = (adress: BrregEnhetResponseAddress) => {
    if (adress.co) {
      return <div>{adress.co}<br />{adress.address}<br />
        {adress.postalCode} {adress.postalPlace} <br /> {adress.countryCode}  </div>
    }
    else {
      return <div>{adress.address}<br />
        {adress.postalCode} {adress.postalPlace} <br /> {adress.countryCode}  </div>
    }
  }

  const formatDate = (val: string) => {
    const parsed = parseISO(val);
    return format(parsed, 'dd.MM.yyyy');
  };

  return (
    <Popover className={Style.popover} id="popover-menu">
      <Popover.Header>Juridisk informasjon</Popover.Header>
      <Popover.Body className={Style.popoverContent}>
        {
          data &&
          <Row>
            <Col className={Style.popoverContentCol}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Navn: </strong>
                  {data.name}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Konkurs: </strong>
                  {data.konkurs ? 'JA' : 'NEI'}
                </ListGroup.Item>

                <ListGroup.Item>
                  <strong>Registrert dato: </strong>
                  <span>{data.regDato ? formatDate(data.regDato.toString()) : ''}</span>
                </ListGroup.Item>
                {data.slettedato && (
                  <ListGroup.Item variant="warning">
                    <strong>Slettet dato: </strong>
                    <span>{formatDate(data.slettedato.toISOString()) || ''}</span>
                  </ListGroup.Item>
                )}
                <ListGroup.Item>
                  <div>
                    <span><strong>Næringskode: </strong></span>
                    <span>{data.naeringskode1?.kode}</span>
                  </div>

                  <div>
                    <span><strong>Beskrivelse: </strong></span>
                    <span>{data.naeringskode1?.beskrivelse}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div>
                    <span><strong>Næringskode 2: </strong></span>
                    <span>{data.naeringskode2?.kode}</span>
                  </div>

                  <div>
                    <span><strong>Beskrivelse 2: </strong></span>
                    <span>{data.naeringskode2?.beskrivelse}</span>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Under avvikling: </strong>
                  {data.underAvvikling ? 'JA' : 'NEI'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Under tvangsavvikling : </strong>
                  {data.underTvangsavviklingEllerTvangsopplosning ? 'JA' : 'NEI'}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Organisasjonsform: </strong>
                  {data.organisasjonsform}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col className={Style.popoverContentCol}>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <strong>Antall ansatte: </strong>
                  {data.antallAnsatte}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Forretningsadresse: </strong>
                  {data && data.forretningsAddress && (
                    formatAddress(data.forretningsAddress)
                  )}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Postadresse: </strong>
                  {data && data.postAddress && (
                    formatAddress(data.postAddress)
                  )}
                </ListGroup.Item>

              </ListGroup>
              {data.finnesIkke && (
                <ListGroup.Item variant="warning">
                  <strong>Finnes ikke: </strong>
                  {data.finnesIkke && 'JA'}
                </ListGroup.Item>
              )}
            </Col>
          </Row>
        }
      </Popover.Body>
    </Popover>
  )
}

export default BrregPopover;