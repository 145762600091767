import React, { useEffect, useState, useRef } from 'react';
import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import { Grossist } from 'services/KjederegisteretAdminApi/GrossisterApi/types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import DropdownTreeSelect, { TreeNode, Mode } from 'react-dropdown-tree-select';
import Style from './index.module.css';
import cx from 'classnames';
import CommonLoader from 'components/CommonLoader';

const mapNode = (node: Grossist, selectedIds?: number[], selectedNrs?: number[]) => {
  const item = {
    label: node.navn,
    id: node.grossistEnhetId,
    gln: node.gln,
    nr: node.grossistnr,
    children: [],
    checked: false,
  };
  if (selectedIds && selectedIds.indexOf(node.grossistEnhetId) > -1) {
    item.checked = true;
  }
  if (selectedNrs && selectedNrs.indexOf(node.grossistnr) > -1) {
    item.checked = true;
  }

  return item;
};

const mapGrossister = (grossister: Grossist[], selectedIds: number[], selectedNrs: number[]) => {
  if (!grossister) return [];
  return grossister.map(o => mapNode(o, selectedIds, selectedNrs));
};

export interface PickedGrossist {
  id: number;
  name: string;
  nr: number;
  grossistGln: string;
}

interface Props {
  onSelectionChanged: (grossister: PickedGrossist[]) => void;
  findPreselectedNodesByID?: (picker: string) => number[];
  findPreselectedNodesByNR?: (picker: string) => number[];
  disabled?: boolean;
  isSmall?: boolean;
  closeOnSelect?: boolean;
  mode?: Mode;
}

const GrossistPicker= (props: Props) => {
  const [grossisterList, setGrossisterList] = useState<Grossist[]>([]);
  const [isFetchingGrossister, setIsFetchingGrossister] = useState(false);

  const isMountedRef = useIsMountedRef();
  const preSelectedNR = props.findPreselectedNodesByNR ? props.findPreselectedNodesByNR('grossister') : [];
  const preSelectedID = props.findPreselectedNodesByID ? props.findPreselectedNodesByID('grossister') : [];

  const pickerRef = useRef<DropdownTreeSelect>(null);

  const loadGrossister = async () => {
    if (isMountedRef.current) {
      setIsFetchingGrossister(true);
      const grossister = await GrossisterApi.getGrossister(null, true);
      setGrossisterList(grossister);
      setIsFetchingGrossister(false);
    }
  };

  useEffect(() => {
    loadGrossister();
  }, []);

  const handleOnChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      const selectedGrossister = selectedNodes.map(o => ({
        id: o.id,
        name: o.label,
        grossistGln: o.gln,
        nr: o.nr,
      }));
      props.onSelectionChanged(selectedGrossister);
      if(isMountedRef.current && props.closeOnSelect && pickerRef.current.state.showDropdown){
        pickerRef.current.searchInput.click();
      }
    }
  };

  // map bransje tre til en struktur react-dropdown-tree-select skjønner
  const data = mapGrossister(grossisterList, preSelectedID, preSelectedNR);

  return (
    <>
      <DropdownTreeSelect
        className={cx('reactDropdownMultiPicker', Style.multiPicker, {
          [Style.small]: props.isSmall,
        })}
        mode={props.mode || 'multiSelect'}
        texts={{
          placeholder: 'Velg grossister',
          noMatches: 'Ingen treff',
        }}
        data={data || []}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        onChange={(currentNode: TreeNode, selectedNodes: TreeNode[]) => handleOnChange(currentNode, selectedNodes)}
        disabled={props.disabled || false}
        ref={pickerRef}
      />
      {isFetchingGrossister && (
        <div>
          <CommonLoader  />
          <span> Henter data... </span>
        </div>
      )}
    </>
  );
};

export default React.memo(GrossistPicker); 
