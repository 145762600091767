import axios from "axios";

export class ApiHelper {
    static async post<TResponse>(url: string, payload?: any): Promise<ApiResult<TResponse>> {
        try {
            const result = await axios.post(url, payload);
            return {
                status: result.status,
                success: result.status >= 200 && result.status < 400,
                error: undefined,
                data: result.data as TResponse
            }
        } catch (error) {
            return {
                status: 0,
                success: false,
                error: error,
                data: undefined
            }
        }
    }

    static async put<TResponse>(url: string, payload?: any): Promise<ApiResult<TResponse>> {
        try {
            const result = await axios.put(url, payload);
            return {
                status: result.status,
                success: result.status >= 200 && result.status < 400,
                error: undefined,
                data: result.data as TResponse
            }
        } catch (error) {
            return {
                status: 0,
                success: false,
                error: error,
                data: undefined
            }
        }
    }

    static async del<TResponse>(url: string): Promise<ApiResult<TResponse>> {
        try {
            const result = await axios.delete(url);
            return {
                status: result.status,
                success: result.status >= 200 && result.status < 400,
                error: undefined,
                data: result.data as TResponse
            }
        } catch (error) {
            return {
                status: 0,
                success: false,
                error: error,
                data: undefined
            }
        }
    }

    static toQuerystring = (keyValues: { key: string, value: string }[]) => {
        return keyValues.map(kv => `${kv.key}=${kv.value}`).join("&");
    }
}

export interface ApiResult<T> {
    data: T | undefined;
    status: number;
    error: string | undefined;
    success: boolean;
}