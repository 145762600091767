import React, { useState, useCallback, useEffect } from 'react';
import DropdownTreeSelect, { TreeNode } from 'react-dropdown-tree-select';
import cx from 'classnames';
import KommuneApi from 'services/KjederegisteretAdminApi/KommuneApi';
import { KommuneTree } from 'services/KjederegisteretAdminApi/KommuneApi/types';
import { PickedOmråde, PreSelectedOmråder } from './types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

interface Props {
  onSelectionChanged: (områder: PickedOmråde[]) => void;
  findPreselectedNodes?: () => PreSelectedOmråder;
  disabled?: boolean;
  isSmall?: boolean;
}

class TreHelper {
  static mapNode = (node: KommuneTree, selected: PreSelectedOmråder) => {
    const item = {
      label: node.name,
      value: node.nr,
      id: node.id,
      type: node.type,
      children: [],
      checked: false,
      expanded: false,
    };

    if (item.type === 'fylke') {
      item.checked = selected.fylker.indexOf(item.label) > -1;
    }
    if (item.type === 'kommune') {
      item.checked = selected.kommuner.indexOf(item.label) > -1;
    }
    if (item.type === 'postnr') {
      item.checked = selected.postnr.indexOf(item.value) > -1;
    }

    if (node.children) {
      node.children.forEach((element: KommuneTree) => {
        item.children.push(TreHelper.mapNode(element, selected));
      });
    }
    return item;
  };

  static mapToTre = (tre: KommuneTree[], selected: PreSelectedOmråder) => {
    if (!tre) return [];
    return tre.map(o => TreHelper.mapNode(o, selected));
  }
}

const OmrådePicker: React.FC<Props> = props => {
  const [områdeTre, setOmrådeTre] = useState<KommuneTree[]>([]);
  const [isFetchingOmråder, setIsFetchingOmråder] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  const loadOmrådeTre = async () => {
    setIsFetchingOmråder(true);
    const tre = await KommuneApi.getTree();
    setOmrådeTre(tre);
    setIsFetchingOmråder(false);
  };

  const handleOnChange = useCallback((currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      const selectedOmråder = selectedNodes.map(o => ({ id: o.id, name: o.label, nr: o.value, type: o.type }));
      props.onSelectionChanged(selectedOmråder);
    }
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      loadOmrådeTre();
    }
  }, []);

  const noPreselected: PreSelectedOmråder = {
    fylker: [],
    kommuner: [],
    postnr: [],
  };
  const preSelectedNodes = props.findPreselectedNodes ? props.findPreselectedNodes() || noPreselected : noPreselected;
  const data = TreHelper.mapToTre(områdeTre, preSelectedNodes);

  return (
    <>
      <DropdownTreeSelect
        className={cx('reactDropdownMultiPicker', Style.multiPicker, {
          [Style.small]: props.isSmall,
        })}
        mode="multiSelect"
        texts={{
          placeholder: 'Velg område',
          noMatches: 'Ingen treff',
        }}
        data={data || []}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        onChange={(currentNode: TreeNode, selectedNodes: TreeNode[]) => handleOnChange(currentNode, selectedNodes)}
        disabled={props.disabled}
      />

      {isFetchingOmråder && (
        <div>
          <CommonLoader  />
          <span> Henter data... </span>
        </div>
      )}
    </>
  );
};

export default React.memo(OmrådePicker);