import { useState, useEffect } from 'react';
import { IAdresse } from 'components/AddressTypeahead/types';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { KontonrOgFakturaAdresseSjekkResponse } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import styles from './index.module.css';
import AdresseInput from './AdresseInput';
import useToaster from 'utils/hooks/useToaster';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { DeleteAddressCommand, UpdateAddresseCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { Coordinates } from 'models/Coordinates';
import { UpdatePositionCommand } from 'services/KjederegisteretAdminApi/AdresseApi/types';
import AdresseApi from 'services/KjederegisteretAdminApi/AdresseApi';
import { AddresseTyper } from './types';

interface Props {
    enhet: EnhetViewModel;
    kontonrOgFakturaAdresseSjekkResponse: KontonrOgFakturaAdresseSjekkResponse;
    onEnhetUpdated: () => void;
}

const AdresserV2 = (props: Props) => {

    const toaster = useToaster();

    const [besoksAdresse, setBesokssadresse] = useState<IAdresse>(props.enhet?.besoksadresse);
    const [besokEkstraInfo, setBesokEkstraInfo] = useState<string>(props.enhet?.besoksadresse?.ekstraInfo);
    const [leveringsAdresse, setLeveringsadresse] = useState<IAdresse>(props.enhet?.leveringsadresse);
    const [leveringsEkstraInfo, setLeveringsEkstraInfo] = useState<string>(props.enhet?.leveringsadresse?.ekstraInfo);
    const [postAdresse, setPostadresse] = useState<IAdresse>(props.enhet?.postadresse);
    const [postEkstraInfo, setPostEkstraInfo] = useState<string>(props.enhet?.postadresse?.ekstraInfo);
    const [fakturaAdresse, setFakturaadresse] = useState<IAdresse>(props.enhet?.fakturaAdresse);
    const [fakturaEkstraInfo, setFakturaEkstraInfo] = useState<string>(props.enhet?.fakturaAdresse?.ekstraInfo);

    // states for coordinates 
    const [besokCoordinates, setBesokCoordinates] = useState<Coordinates | null>(
        besoksAdresse?.latitude && besoksAdresse?.longitude ?
            new Coordinates(besoksAdresse?.latitude, besoksAdresse?.longitude) :
            null
    );
    const [leveringCoordinates, setLeveringCoordinates] = useState<Coordinates | null>(
        leveringsAdresse?.latitude && leveringsAdresse?.longitude ?
            new Coordinates(leveringsAdresse?.latitude, leveringsAdresse?.longitude) :
            null
    );
    const [postCoordinates, setPostCoordinates] = useState<Coordinates | null>(
        postAdresse?.latitude && postAdresse?.longitude ?
            new Coordinates(postAdresse?.latitude, postAdresse?.longitude) :
            null
    );
    const [fakturaCoordinates, setFakturaCoordinates] = useState<Coordinates | null>(
        fakturaAdresse?.latitude && fakturaAdresse?.longitude ?
            new Coordinates(fakturaAdresse?.latitude, fakturaAdresse?.longitude) :
            null
    );

    const handleSaveAddress = async (addressType: string) => {
        const id = props.enhet.id;
        let updateAddressCommand: UpdateAddresseCommand;

        let address = null;
        switch (addressType) {
            case "besoksadresse":
                address = besoksAdresse ? {
                    gateadresse: besoksAdresse?.gateadresse,
                    postnr: besoksAdresse?.postnr,
                    poststed: besoksAdresse?.poststed,
                    landkode: besoksAdresse?.landkode,
                    koordinater: besoksAdresse?.latitude && besoksAdresse?.longitude ? new Coordinates(besoksAdresse?.latitude, besoksAdresse?.longitude) : null,
                    ekstraInfo: besokEkstraInfo,
                    erPostboksAdresse: false,
                } : null;
                break;
            case "leveringsadresse":
                address = leveringsAdresse ? {
                    gateadresse: leveringsAdresse?.gateadresse,
                    postnr: leveringsAdresse?.postnr,
                    poststed: leveringsAdresse?.poststed,
                    landkode: leveringsAdresse?.landkode,
                    koordinater: leveringsAdresse?.latitude && leveringsAdresse?.longitude ? new Coordinates(leveringsAdresse?.latitude, leveringsAdresse?.longitude) : null,
                    ekstraInfo: leveringsEkstraInfo,
                    erPostboksAdresse: false,
                } : null;
                break;
            case "postadresse":
                address = postAdresse ? {
                    gateadresse: postAdresse?.gateadresse,
                    postnr: postAdresse?.postnr,
                    poststed: postAdresse?.poststed,
                    landkode: postAdresse?.landkode,
                    koordinater: postAdresse?.latitude && postAdresse?.longitude ? new Coordinates(postAdresse?.latitude, postAdresse?.longitude) : null,
                    ekstraInfo: postEkstraInfo,
                    erPostboksAdresse: false,
                } : null;
                break;
            case "fakturaAdresse":
                address = fakturaAdresse ? {
                    gateadresse: fakturaAdresse?.gateadresse,
                    postnr: fakturaAdresse?.postnr,
                    poststed: fakturaAdresse?.poststed,
                    landkode: fakturaAdresse?.landkode,
                    koordinater: fakturaAdresse?.latitude && fakturaAdresse?.longitude ? new Coordinates(fakturaAdresse?.latitude, fakturaAdresse?.longitude) : null,
                    ekstraInfo: fakturaEkstraInfo,
                    erPostboksAdresse: false,
                } : null;
                break;
            default:
                toaster.error('Feil!', 'Ugyldig adressetype.');
                return;
        }

        updateAddressCommand = {
            id: id,
            addressType: addressType,
            address: address
        };

        try {
            await EnhetApi.updateAddress(id, updateAddressCommand);
            toaster.success('Suksess!', 'Adresse er oppdatert.');
            props.onEnhetUpdated();
        } catch (err) {
            toaster.error(
                'Feil! Kunne ikke oppdatere adresse.',
                HttpErrorHelper.formatError(err),
            );
        }
    };


    const handleSaveCoordinates = async (addressType: string, lat: number, lng: number) => {
        let updateCoordinatesCommand: UpdatePositionCommand;
        switch (addressType) {
            case "besoksadresse":
                updateCoordinatesCommand = {
                    id: besoksAdresse.id,
                    lat,
                    lng,
                };
                break;
            case "leveringsadresse":
                updateCoordinatesCommand = {
                    id: leveringsAdresse.id,
                    lat,
                    lng,
                };
                break;
            case "postadresse":

                updateCoordinatesCommand = {
                    id: postAdresse.id,
                    lat,
                    lng,
                };
                break;
            case "fakturaAdresse":
                updateCoordinatesCommand = {
                    id: fakturaAdresse.id,
                    lat,
                    lng,
                };
                break;
            default:
                toaster.error('Feil!', 'Ugyldig adressetype.');
                return;
        }
        try {
            await AdresseApi.updatePosition(updateCoordinatesCommand);
            toaster.success('Suksess!', 'Koordinater er oppdatert.');
            // props.onEnhetUpdated();
        } catch (err) {
            toaster.error(
                'Feil! Kunne ikke oppdatere koordinater.',
                HttpErrorHelper.formatError(err),
            );
        }
    }

    const handleDeleteCoordinates = async (addressType: string, id: number) => {
        let prevState;
        switch (addressType) {
            case "besoksadresse":
                id = besoksAdresse.id;
                prevState = besokCoordinates;
                setBesokCoordinates(null);
                break;
            case "leveringsadresse":
                id = leveringsAdresse.id;
                prevState = leveringCoordinates;
                setLeveringCoordinates(null);
                break;
            case "postadresse":
                id = postAdresse.id;
                prevState = postCoordinates;
                setPostCoordinates(null);
                break;
            case "fakturaAdresse":
                id = fakturaAdresse.id;
                prevState = fakturaCoordinates;
                setFakturaCoordinates(null);
                break;
            default:
                toaster.error('Feil!', 'Ugyldig adressetype.');
                return;
        }
        try {
            await AdresseApi.deletePosition(id);
            toaster.success('Suksess!', 'Koordinater er fjernet.');
            props.onEnhetUpdated();
        } catch (err) {
            switch (addressType) {
                case "besoksadresse":
                    setBesokCoordinates(prevState);
                    break;
                case "leveringsadresse":
                    setLeveringCoordinates(prevState);
                    break;
                case "postadresse":
                    setPostCoordinates(prevState);
                    break;
                case "fakturaAdresse":
                    setFakturaCoordinates(prevState);
                    break;
            }
            toaster.error(
                'Feil! Kunne ikke slette koordinater.',
                HttpErrorHelper.formatError(err),
            );
        }
    };


    const handleDeleteAddress = async (addressType: string) => {
        const id = props.enhet.id;
        let deleteAddressCommand: DeleteAddressCommand;

        switch (addressType) {
            case "besoksadresse":
                deleteAddressCommand = {
                    id: id,
                    addressType: addressType,
                };
                break;
            case "leveringsadresse":
                deleteAddressCommand = {
                    id: id,
                    addressType: addressType,
                };
                break;
            case "postadresse":
                deleteAddressCommand = {
                    id: id,
                    addressType: addressType,
                };
                break;
            case "fakturaAdresse":
                deleteAddressCommand = {
                    id: id,
                    addressType: addressType,
                };
                break;
            default:
                toaster.error('Feil!', 'Ugyldig adressetype.');
                return;
        }

        try {
            await EnhetApi.deleteAddress(id, deleteAddressCommand);
            toaster.success('Suksess!', 'Adresse er fjernet.');
            props.onEnhetUpdated();
        } catch (err) {
            toaster.error(
                'Feil! Kunne ikke slette adresse.',
                HttpErrorHelper.formatError(err),
            );
        }
    };


    /* useEffect(() => {
        if (props.enhet.erPublisert && !besoksAdresse) {
        } else if (besokError || postError || fakturaError || leveringError) {

        } else {
        }
    }, [besoksAdresse, leveringsAdresse, postAdresse, fakturaAdresse, besokError, postError, fakturaError, leveringError]);
 */
    useEffect(() => {
        if (props.enhet?.besoksadresse?.formattedForOneLine !== besoksAdresse?.formattedForOneLine) {
            setBesokssadresse(props.enhet?.besoksadresse);
        }
        if (props.enhet?.besoksadresse?.ekstraInfo !== besokEkstraInfo) {
            setBesokEkstraInfo(props.enhet?.besoksadresse?.ekstraInfo);
        }
        if (props.enhet?.leveringsadresse?.formattedForOneLine !== leveringsAdresse?.formattedForOneLine) {
            setLeveringsadresse(props.enhet?.leveringsadresse);
        }
        if (props.enhet?.leveringsadresse?.ekstraInfo !== leveringsEkstraInfo) {
            setLeveringsEkstraInfo(props.enhet?.leveringsadresse?.ekstraInfo);
        }
        if (props.enhet?.postadresse?.formattedForOneLine !== postAdresse?.formattedForOneLine) {
            setPostadresse(props.enhet?.postadresse);
        }

        if (props.enhet?.postadresse?.ekstraInfo !== postEkstraInfo) {
            setPostEkstraInfo(props.enhet?.postadresse?.ekstraInfo);
        }
        if (props.enhet?.fakturaAdresse?.formattedForOneLine !== fakturaAdresse?.formattedForOneLine) {
            setFakturaadresse(props.enhet?.fakturaAdresse);
        }

        if (props.enhet?.fakturaAdresse?.ekstraInfo !== fakturaEkstraInfo) {
            setFakturaEkstraInfo(props.enhet?.fakturaAdresse?.ekstraInfo);
        }

        // coordinates 
        if (props.enhet?.besoksadresse?.latitude !== besokCoordinates?.lat ||
            props.enhet?.besoksadresse?.longitude !== besokCoordinates?.lng) {

            if (props.enhet?.besoksadresse?.latitude != null && props.enhet?.besoksadresse?.longitude != null) {
                setBesokCoordinates(new Coordinates(props.enhet.besoksadresse.latitude, props.enhet.besoksadresse.longitude));
            }
            setBesokssadresse(props.enhet?.besoksadresse);
        }
        if (props.enhet?.leveringsadresse?.latitude !== leveringCoordinates?.lat ||
            props.enhet?.leveringsadresse?.longitude !== leveringCoordinates?.lng) {

            if (props.enhet?.leveringsadresse?.latitude != null && props.enhet?.leveringsadresse?.longitude != null) {
                setLeveringCoordinates(new Coordinates(props.enhet.leveringsadresse.latitude, props.enhet.leveringsadresse.longitude));
                setLeveringsadresse(props.enhet?.leveringsadresse);
            }

        }

        if (props.enhet?.postadresse?.latitude !== postCoordinates?.lat ||
            props.enhet?.postadresse?.longitude !== postCoordinates?.lng) {

            if (props.enhet?.postadresse?.latitude != null && props.enhet?.postadresse?.longitude != null) {
                setPostCoordinates(new Coordinates(props.enhet.postadresse.latitude, props.enhet.postadresse.longitude));
                setPostadresse(props.enhet?.postadresse);
            }
        }

        if (props.enhet?.fakturaAdresse?.latitude !== fakturaCoordinates?.lat ||
            props.enhet?.fakturaAdresse?.longitude !== fakturaCoordinates?.lng) {

            if (props.enhet?.fakturaAdresse?.latitude != null && props.enhet?.fakturaAdresse?.longitude != null) {
                setFakturaCoordinates(new Coordinates(props.enhet.fakturaAdresse.latitude, props.enhet.fakturaAdresse.longitude));
                setFakturaadresse(props.enhet?.fakturaAdresse);
            }

        }

    }, [props.enhet])

    const handleAddressCopied = (adresse: IAdresse, ekstraInfo: string, type: AddresseTyper) => {
        switch (type) {
            case 'Besøksadresse':
                setBesokssadresse(adresse);
                setBesokEkstraInfo(ekstraInfo);
                break;
            case 'Leveringsadresse':
                setLeveringsadresse(adresse);
                setLeveringsEkstraInfo(ekstraInfo);
                break;
            case 'Postadresse':
                setPostadresse(adresse);
                setPostEkstraInfo(ekstraInfo);
                break;
            case 'FakturaAdresse':
                setFakturaadresse(adresse);
                setFakturaEkstraInfo(ekstraInfo);
                break;
        }

    }


    return (
        <div className={styles.container}>

            <AdresseInput
                title='Besøksadresse'
                adresse={besoksAdresse}
                onAdresseChanged={setBesokssadresse}
                onAdresseCopied={(address, ekstraInfo) => handleAddressCopied(address, ekstraInfo, 'Besøksadresse')}
                ekstraInfo={besokEkstraInfo}
                onEkstraInfoChanged={setBesokEkstraInfo}
                copyAddresses={[{ adresse: leveringsAdresse, title: 'Bruk leveringsadresse', ekstraInfo: leveringsEkstraInfo }, { adresse: postAdresse, title: 'Bruk postadresse', ekstraInfo: postEkstraInfo }, { adresse: fakturaAdresse, title: 'Bruk fakturaadresse', ekstraInfo: fakturaEkstraInfo }]}
                requireCoordinates={true}
                onSave={() => handleSaveAddress('besoksadresse')}
                onDelete={() => handleDeleteAddress('besoksadresse')}
                onUpdateCoordinates={({ lat, lng }) => handleSaveCoordinates('besoksadresse', lat, lng)}
                onDeleteCoordinates={(id) => handleDeleteCoordinates('besoksadresse', id)}
                coordinates={besokCoordinates}
                setCoordinates={(newCoordinates) => setBesokCoordinates(newCoordinates)}
            />

            <AdresseInput
                title="Leveringsadresse"
                adresse={leveringsAdresse}
                onAdresseChanged={setLeveringsadresse}
                onAdresseCopied={(address, ekstraInfo) => handleAddressCopied(address, ekstraInfo, 'Leveringsadresse')}
                ekstraInfo={leveringsEkstraInfo}
                onEkstraInfoChanged={setLeveringsEkstraInfo}
                copyAddresses={[{ adresse: besoksAdresse, title: 'Bruk besøksadresse', ekstraInfo: besokEkstraInfo }, { adresse: postAdresse, title: 'Bruk postadresse', ekstraInfo: postEkstraInfo }, { adresse: fakturaAdresse, title: 'Bruk fakturaadresse', ekstraInfo: fakturaEkstraInfo }]}
                requireCoordinates={true}
                onSave={() => handleSaveAddress('leveringsadresse')}
                onDelete={() => handleDeleteAddress('leveringsadresse')}
                onUpdateCoordinates={({ lat, lng }) => { handleSaveCoordinates('leveringsadresse', lat, lng) }}
                onDeleteCoordinates={(id) => handleDeleteCoordinates('leveringsadresse', id)}
                coordinates={leveringCoordinates}
                setCoordinates={(newCoordinates) => setLeveringCoordinates(newCoordinates)}
            />

            <AdresseInput
                title="Postadresse"
                adresse={postAdresse}
                onAdresseChanged={setPostadresse}
                onAdresseCopied={(address, ekstraInfo) => handleAddressCopied(address, ekstraInfo, 'Postadresse')}
                ekstraInfo={postEkstraInfo}
                onEkstraInfoChanged={setPostEkstraInfo}
                copyAddresses={[{ adresse: besoksAdresse, title: 'Bruk besøksadresse', ekstraInfo: besokEkstraInfo }, { adresse: leveringsAdresse, title: 'Bruk leveringsadresse', ekstraInfo: leveringsEkstraInfo }, { adresse: fakturaAdresse, title: 'Bruk fakturaadresse', ekstraInfo: fakturaEkstraInfo }]}
                onSave={() => handleSaveAddress('postadresse')}
                onDelete={() => handleDeleteAddress('postadresse')}
                onUpdateCoordinates={({ lat, lng }) => { handleSaveCoordinates('postadresse', lat, lng) }}
                onDeleteCoordinates={(id) => handleDeleteCoordinates('postadresse', id)}
                coordinates={postCoordinates}
                setCoordinates={(newCoordinates) => setPostCoordinates(newCoordinates)}
            />

            <AdresseInput
                title="Fakturaadresse"
                adresse={fakturaAdresse}
                onAdresseChanged={setFakturaadresse}
                onAdresseCopied={(address, ekstraInfo) => handleAddressCopied(address, ekstraInfo, 'FakturaAdresse')}
                ekstraInfo={fakturaEkstraInfo}
                onEkstraInfoChanged={setFakturaEkstraInfo}
                copyAddresses={[{ adresse: besoksAdresse, title: 'Bruk besøksadresse', ekstraInfo: besokEkstraInfo }, { adresse: leveringsAdresse, title: 'Bruk leveringsadresse', ekstraInfo: leveringsEkstraInfo }, { adresse: postAdresse, title: 'Bruk postadresse', ekstraInfo: postEkstraInfo }]}
                kontonrOgFakturaAdresseSjekkResponse={props.kontonrOgFakturaAdresseSjekkResponse}
                onSave={() => handleSaveAddress('fakturaAdresse')}
                onDelete={() => handleDeleteAddress('fakturaAdresse')}
                onUpdateCoordinates={({ lat, lng }) => { handleSaveCoordinates('fakturaAdresse', lat, lng) }}
                onDeleteCoordinates={(id) => handleDeleteCoordinates('fakturaAdresse', id)}
                coordinates={fakturaCoordinates}
                setCoordinates={(newCoordinates) => setFakturaCoordinates(newCoordinates)}
            />

        </div>
    )
}
export default AdresserV2;
