import { IconButton, TsKunde } from "@tradesolution/iceberg-ui-react";
import ApiFeedback from "components/Feedback/ApiFeedback";
import TsKundeTypeahead from "components/TsKundeTypeahead";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { ApiResult } from "services/ApiHelper";
import MottakerApi from "services/KjederegisteretAdminApi/MottakerApi";
import { CreateMottakerCommand, Mottaker } from "services/KjederegisteretAdminApi/MottakerApi/types";
import AbonnementTypeahead, { Abo } from "../MottakerEdit/components/AbonnementTypeahead";

interface Props {
    onClose: () => void;
}

const CreateModal = (props: Props) => {

    const initialValue: CreateMottakerCommand = {
        name: '',
        tsKundeId: undefined,
        abonnementIds: [],
        notat: ''
    }

    const [command, setCommand] = useState<CreateMottakerCommand>(initialValue);

    const handleClose = () => {
        props.onClose();
    };

    const [selectedTsKunde, setSelectedTsKunde] = useState<TsKunde>();
    const handleTsKundeSelected = (kunde: TsKunde): void => {
        if (kunde) {
            setCommand(prev => ({ ...prev, tsKundeId: kunde.tsKundeId }))
            setSelectedTsKunde(kunde);

            // if no name is set, then set it to tsKundeName
            if (!command.name) {
                handleNameUpdated(kunde.tsKundeNavn);
            }
        }
    }

    const handleNameUpdated = (value: string): void => {
        setCommand(prev => ({ ...prev, name: value }))
    }

    const [selectedAbonnement, setSelectedAbonnement] = useState<Abo[]>([]);
    const handleAbonnementChanged = (selected: Abo[]) => {
        setSelectedAbonnement(selected);
        setCommand(prev => ({ ...prev, abonnementIds: selected.map(o => o.id) }));
    };

    const handleNotatUpdated = (value: string): void => {
        setCommand(prev => ({ ...prev, notat: value }))
    }

    const [apiResult, setApiResult] = useState<ApiResult<Mottaker>>();
    const handleSave = async () => {
        const result = await MottakerApi.createMottaker(command);
        setApiResult(result);
    };

    return (
        <Modal onHide={handleClose} show>
            <Modal.Header closeButton>
                <Modal.Title>Opprett mottaker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>TsKunde</Form.Label>
                        <TsKundeTypeahead
                            onChange={handleTsKundeSelected}
                            selected={selectedTsKunde} />
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label>Navn</Form.Label>
                        <Form.Control value={command.name} onChange={e => handleNameUpdated(e.target.value)} />
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label>Enhetsutvalg</Form.Label>
                        <AbonnementTypeahead onChange={handleAbonnementChanged} selected={selectedAbonnement} />
                    </Form.Group>
                    <Form.Group className='mt-1'>
                        <Form.Label>Notat</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="notat"
                            value={command.notat || ''}
                            onChange={e => handleNotatUpdated(e.target.value)} />
                    </Form.Group>
                </Form>
                <ApiFeedback apiResult={apiResult} successMessage="Mottaker opprettet" errorMessage="En feil oppsto" />
            </Modal.Body>
            <Modal.Footer>
                {!!apiResult && <IconButton className="col" icon="close" variant="outline-primary" onClick={handleClose}>Lukk</IconButton>}
                {!apiResult &&
                    <>
                        <IconButton className='col' icon='close' variant="outline-primary" onClick={handleClose}>
                            Avbryt
                        </IconButton>
                        <IconButton
                            variant="primary"
                            icon='disk'
                            className='col'
                            type="button"
                            onClick={handleSave}>
                            Lagre
                        </IconButton>
                    </>}
            </Modal.Footer>
        </Modal>
    );
}

export default CreateModal;