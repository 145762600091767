import GrossistgruppePicker from "components/GrossistgruppePicker";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";

const TestPage = () => {

    const [selectedGrossists, setSelectedGrossists] = useState<string[]>([]);

    const handleSelectionChanged = (grossistGln: string[]): void => {
        setSelectedGrossists(grossistGln);
    };

    console.log(selectedGrossists);

    const findSelectedGrossists = () => {
        return selectedGrossists;
    };

    return (
        <div>
            <h1>Test Page</h1>

            <Row>
                <Col>
                    <GrossistgruppePicker onSelectionChanged={handleSelectionChanged} findSelectedGrossists={findSelectedGrossists} />
                </Col>
            </Row>
        </div>
    )
}

export default TestPage;