import React, { useRef, useEffect, useState } from 'react';
import { Card, Button } from 'react-bootstrap';

import { format } from 'date-fns';

import KjedemedlemskapForm from '../KjedemedlemskapForm';
import KundenummerPeriodeForm, { KundeNummerPeriodeFormData } from '../KundenummerPeriode';
import KjedeBreadcrumb from '../KjedeBreadcrumb';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { KjedeHistorikk } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { AddEnhetKjedeMedlemskapCommand } from 'services/KjederegisteretAdminApi/EnhetKjedeApi/types';

import Style from './index.module.css';

export enum EditType {
  Future,
  Active,
  Historic,
}

interface Props {
  kjede: KjedeHistorikk;
  kjedeType: string;
  handleUpdateKjede: (command: AddEnhetKjedeMedlemskapCommand) => void;
  handleUpdateKjedeInfo: (data: KundeNummerPeriodeFormData) => void;
  editType: EditType;
  handleFjernEnhetFromKjede?: () => Promise<void>;
  canRemoveKjede: boolean;
}

const KjedeMedlemskap: React.FC<Props> = props => {
  const [displayKjedeForm] = useState(false);
  const [displayKjedeInfoForm, setDisplayKjedeInfoForm] = useState(false);

  const endreContainerRef = useRef(null);
  const topOfPage = useRef(null);
  const scrollToRef = ref => window.scrollTo({ behavior: 'smooth', top: ref.current.offsetTop });
  const [visEndringerIOverliggendeKjede, setVisEndringerIOverliggendeKjede] = useState(false);

  useEffect(() => {
    if ((displayKjedeForm || displayKjedeInfoForm) && endreContainerRef !== null) {
      scrollToRef(endreContainerRef);
    } else if (topOfPage !== null) {
      scrollToRef(topOfPage);
    }
  }, [displayKjedeForm, displayKjedeInfoForm]);

  const toggleShowKjedeInfoForm = () => {
    setDisplayKjedeInfoForm(!displayKjedeInfoForm);
  };

  const editTypeToText = (et: EditType) => {
    switch (et) {
      case EditType.Active:
        return 'Aktiv';
      case EditType.Future:
        return 'Fremtidig';
      default:
        return '';
    }
  };

  const formatDate = (date: Date) => {
    if (date) return format(date, 'dd.MM.yyyy');
    return '';
  };

  return (
    <Card style={{ marginBottom: '1rem' }}>
      <Card.Body>
        <div ref={topOfPage}>
          {(props.editType === EditType.Active || props.editType === EditType.Future) && (
            <div className={Style.titleContainer}>
              <Card.Title
                className={
                  props.editType === EditType.Future ? Style.cardFutureTitle : Style.cardCurrentTitle
                }
              >
                {editTypeToText(props.editType)}
              </Card.Title>
              <Button
                size="sm"
                variant="outline-primary"
                title={displayKjedeInfoForm ? 'Lukk' : 'Rediger'}
                onClick={toggleShowKjedeInfoForm}
              >
                <IcebergIcon size="sm" icon={displayKjedeInfoForm ? 'close' : 'edit'} bold={displayKjedeInfoForm} />
              </Button>
            </div>
          )}
        </div>

        <KjedeBreadcrumb kjedeHierarchy={props.kjede.kjedeHierarki} />

        {props.handleUpdateKjedeInfo && (
          <KundenummerPeriodeForm
            kundenummer={props.kjede.kundenrIKjede}
            isEditMode={displayKjedeInfoForm}
            tilOgMedDato={props.kjede.tilOgMedDato}
            fraOgMedDato={props.kjede.fraOgMedDato || new Date()}
            handleUpdateKjedeInfo={props.handleUpdateKjedeInfo}
            handleFjernEnhetFromKjede={props.handleFjernEnhetFromKjede}
            canRemoveKjede={props.canRemoveKjede}
          />
        )}
        <div ref={endreContainerRef}>
          {displayKjedeForm && (
            <KjedemedlemskapForm defaultFraOgMedDato={new Date()} kjedeType={props.kjedeType} onUpdateKjede={props.handleUpdateKjede} />
          )}
        </div>
        {props.kjede.indirekteHistorikk.length > 0 && (
          <>
            <hr />
            <div style={{ paddingLeft: '1em' }}>
              <Button
                variant="link"
                size="sm"
                onClick={() => setVisEndringerIOverliggendeKjede(!visEndringerIOverliggendeKjede)}
              >
                {visEndringerIOverliggendeKjede ? 'Skjul' : 'Vis'} {props.kjede.indirekteHistorikk.length}{' '}
                endring(er) i overliggende kjedestruktur
              </Button>
              {visEndringerIOverliggendeKjede &&
                props.kjede.indirekteHistorikk.map((h, i) => (
                  <div key={i}>
                    <div className={Style.kjedehistorikkDates}>{`${formatDate(
                      h.fraOgMedDato,
                    )} - ${formatDate(h.tilOgMedDato)}`}</div>
                    <KjedeBreadcrumb key={i} kjedeHierarchy={h.kjedeHierarki} substyle />
                  </div>
                ))}
            </div>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default KjedeMedlemskap;
