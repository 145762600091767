import { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup, OverlayTrigger, Col } from "react-bootstrap";

import querystring from 'query-string';
import cx from "classnames";

import CompareIcon from "../../../CompareIcon";
import BrregPopover from "components/BrregPopover";
import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { OrgNrInfo } from "./types";

import BrregApi from "services/KjederegisteretAdminApi/BrregApi";
import { BrregEnhetResponse } from "services/KjederegisteretAdminApi/BrregApi/types";
import { EnhetSearchFilter } from "services/KjederegisteretAdminApi/EnhetSearchApi/types";

import openInNewTab from "utils/functions/openInNewTab";
import useIsMountedRef from "utils/hooks/useIsMountedRef";
import { qaCompareSimpleValue, qaCompareObjectValues, CompareStatus } from "../../../../utils";

import Style from "../../index.module.css";
import useLocalStorage from "utils/hooks/useLocalStorage";
import { BrRegInfo } from "pages/Enhet/EnhetEdit/components/Basis/BrregInput";
import BrregTypeAhead from "components/BrregTypeAhead";

interface Props {
  changeRequestId: string;
  /** Brukes for dataene som kommer fra QAApi  */
  incomingChangeRequest: OrgNrInfo;
  /** Brukes for datene fra vår databasen */
  outgoingChangeRequest: OrgNrInfo;
  /** Kjøres når et nytt orgnummer er valgt */
  onChange: (updatedValue: OrgNrInfo) => void;
  enhetJurNavn: string;
  duplicateWarning: string;
}

const OrgNrRow = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const [lookupChangeRequestOrgnrResult, setLookupChangeRequestOrgnrResult] = useState<BrregEnhetResponse[]>([]);
  const [isLoadingLookupChangeRequest, setIsLoadingLookupChangeRequest] = useState<boolean>(false);
  const [isShowMoreChangeRequest, setIsShowMoreChangeRequest] = useState<boolean>(false);
  const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
  const [isUserPreferneceExpandRow, setIsUserPreferneceExpandRow] = useLocalStorage<boolean>('userPreferenceOrgNrRowExpanded', false);
  const [isLoadingBrreg, setIsLoadingBrreg] = useState<boolean>(false);
  const [selectedBrregEnhetInfo, setSelectedBrregEnhetInfo] = useState<BrregEnhetResponse>();
  const [compareStatus, setCompareStatus] = useState<string>(
    qaCompareSimpleValue(props.incomingChangeRequest.organisasjonsnr, props.outgoingChangeRequest.organisasjonsnr),
  );
  const [selectedJuridisk, setSelectedJuridisk] = useState<BrRegInfo | undefined>(
    props.outgoingChangeRequest.juridisknavn && props.outgoingChangeRequest.organisasjonsnr ?
      { name: props.outgoingChangeRequest.juridisknavn, orgNr: props.outgoingChangeRequest.organisasjonsnr }
      : undefined);

  const lookupByChangeRequestOrgNr = async (value: string) => {
    isMountedRef.current && setIsLoadingLookupChangeRequest(true);
    if (value) {
      const res = await BrregApi.getByOrgNr(value);
      if (res && !res[0].finnesIkke && !res[0].slettedato && isMountedRef.current) {
        setLookupChangeRequestOrgnrResult(res);
        setIsLoadingLookupChangeRequest(false);
      }
    }
    isMountedRef.current && setIsLoadingLookupChangeRequest(false);
  };

  const transferChangeRequestValue = () => {
    if (isMountedRef.current) {
      if (props.incomingChangeRequest?.organisasjonsnr && (props.incomingChangeRequest?.juridisknavn ||
        (lookupChangeRequestOrgnrResult[0] && lookupChangeRequestOrgnrResult[0].name && !lookupChangeRequestOrgnrResult[0].finnesIkke))
      ) {
        const simpleSelected: BrRegInfo = {
          name: (props.incomingChangeRequest.juridisknavn !== lookupChangeRequestOrgnrResult[0].name) ? lookupChangeRequestOrgnrResult[0].name : props.incomingChangeRequest?.juridisknavn,
          orgNr: props.incomingChangeRequest?.organisasjonsnr,
        };
        setSelectedJuridisk(simpleSelected);
      }
    }
  };

  const handleBrregSelectedChange = (selected?: BrregEnhetResponse) => {
    if (selected) {
      setSelectedBrregEnhetInfo(selected);
      const simpleSelected: BrRegInfo = {
        name: selected.name.toUpperCase(),
        orgNr: selected.orgNr,
      };
      if ((selected.name !== selectedJuridisk?.name) && (selected.orgNr !== selectedJuridisk?.orgNr)) {
        setSelectedJuridisk(simpleSelected);
      }
    }
    else {
      setSelectedJuridisk(undefined);
      setSelectedBrregEnhetInfo(null);
    }
  }

  const handleGoToEnhetSearch = (criteria: string) => {
    let filter = new EnhetSearchFilter();
    filter.criteria = criteria;
    let searchstring = querystring.stringify(filter, {
      arrayFormat: 'bracket',
      skipNull: true,
    });
    openInNewTab(`/enheter?${searchstring}`);
  }

  useEffect(() => {
    if (isMountedRef.current) {

      // if selectedJuridisk is undefined, only compare orgnr
      if (!props.incomingChangeRequest.juridisknavn) {
        setCompareStatus(qaCompareSimpleValue(
          props.incomingChangeRequest.organisasjonsnr,
          selectedJuridisk?.orgNr
        ));
      } else {
        setCompareStatus(qaCompareObjectValues(
          {
            orgnr: props.incomingChangeRequest.organisasjonsnr,
            juridisknavn: props.incomingChangeRequest.juridisknavn
          },
          {
            orgnr: selectedJuridisk?.orgNr,
            juridisknavn: selectedJuridisk?.name
          }
        ));
      }
    }
  }, [selectedJuridisk, props.incomingChangeRequest.organisasjonsnr, lookupChangeRequestOrgnrResult]);

  useEffect(() => {
    if (props.incomingChangeRequest.organisasjonsnr) {
      lookupByChangeRequestOrgNr(props.incomingChangeRequest.organisasjonsnr);
    } else {
      setLookupChangeRequestOrgnrResult([]);
    }
  }, [props.incomingChangeRequest.organisasjonsnr]);

  useEffect(() => {
    if (props.outgoingChangeRequest.organisasjonsnr !== selectedJuridisk?.orgNr || (!selectedJuridisk && isMountedRef.current)) {
      setIsShowMoreChangeRequest(false);
      handleBrregSelectedChange(null);
      setSelectedJuridisk(undefined);
      setSelectedBrregEnhetInfo(undefined);
    }
  }, [props.outgoingChangeRequest]);

  useEffect(() => {
    if (compareStatus === 'ulik' || compareStatus === 'ny') {
      setIsExpandRow(true);
    }

    if (isUserPreferneceExpandRow) {
      setIsExpandRow(true);
    }
  }, [compareStatus]);

  useEffect(() => {
    if (selectedBrregEnhetInfo && (selectedBrregEnhetInfo.slettedato || selectedBrregEnhetInfo.finnesIkke)) {
      setIsExpandRow(true);
    }

    if (isUserPreferneceExpandRow) {
      setIsExpandRow(true);
    }

  }, [selectedBrregEnhetInfo]);

  useEffect(() => {
    if (isMountedRef.current) {
      props.onChange({
        organisasjonsnr: selectedJuridisk?.orgNr,
        juridisknavn: selectedJuridisk?.name,
        isOrgnrValidated: true,
      })
    }
  }, [selectedJuridisk]);

  const handleExpandRowClick = () => {
    setIsExpandRow(!isExpandRow);
    setIsUserPreferneceExpandRow(!isExpandRow);
  };

  // hvis juridisk navn er tom, ikke vis varsel tegn
  // hvis det er juridisk navn og ulik med den som kommer fra brreg, vis varsel tegn 
  const renderWarningSign = () => {
    const juridiskNavn = props.incomingChangeRequest.juridisknavn;
    const brregJuridiskNavn = lookupChangeRequestOrgnrResult.length > 0 ? lookupChangeRequestOrgnrResult[0].name : '';
    const isLoading = isLoadingLookupChangeRequest;
    if (!isLoading && juridiskNavn) {
      if (juridiskNavn.toUpperCase() !== brregJuridiskNavn.toUpperCase()) {
        return (
          <div className={Style.jurNameMismatchIconContainer}>
            <IcebergIcon backgroundColor="rgba(var(--bs-danger-rgb))" color="white" size="sm" icon="exclamationMark" rounded bold />
          </div>
        )
      }
    }
    return null;
  };
  /** Vi viser en grønn ticker når orgnr finnes i brreg og det er en aktiv enhet */
  const notshowGreenTicker = selectedBrregEnhetInfo?.slettedato || selectedBrregEnhetInfo?.finnesIkke;
  return (
    <>
      <div className={cx(Style.changeRequestRowHeaderContainer, { [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={handleExpandRowClick}>
        <h6 className={Style.changeRequestRowHeader}>
          {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
          {renderWarningSign()}
          <span className={Style.changeRequestRowHeaderText}>Organisasjonsnummer</span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.formGroupRow}>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size='sm' className={Style.orgNrInputGroup}>
                  {!lookupChangeRequestOrgnrResult[0] ? (
                    <InputGroup.Text style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                      <IcebergIcon icon="search" />
                    </InputGroup.Text>
                  ) : (
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={BrregPopover(lookupChangeRequestOrgnrResult[0])}>
                      {lookupChangeRequestOrgnrResult[0].slettedato || lookupChangeRequestOrgnrResult[0].finnesIkke ? (
                        <Button variant="warning" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      ) : (
                        <Button variant="success" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      )}
                    </OverlayTrigger>
                  )}
                  <Form.Control
                    readOnly
                    value={props.incomingChangeRequest.organisasjonsnr || ''}
                    isValid={lookupChangeRequestOrgnrResult.length > 0}
                    isInvalid={lookupChangeRequestOrgnrResult && lookupChangeRequestOrgnrResult[0]?.finnesIkke === true} />
                  <InputGroup.Text>
                    <CopyToClipboardIcon val={props.incomingChangeRequest.organisasjonsnr} />
                  </InputGroup.Text>
                </InputGroup>
                {lookupChangeRequestOrgnrResult[0] && !lookupChangeRequestOrgnrResult[0].finnesIkke && (
                  <Row className={Style.orgNrFormRow}>
                    {!isShowMoreChangeRequest &&
                      <Col className={Style.formTxtCol}>
                        <div>
                          {lookupChangeRequestOrgnrResult[0] && !isLoadingLookupChangeRequest && (props.incomingChangeRequest.juridisknavn !== lookupChangeRequestOrgnrResult[0].name) &&
                            <>
                              <Form.Text className='text-muted'>
                                Juridisk navn i endringsmelding:{' '}
                                <span style={{ color: 'red' }}> {props.incomingChangeRequest.juridisknavn}</span>
                              </Form.Text>
                              <br />
                            </>}
                          {lookupChangeRequestOrgnrResult[0] && (
                            <Form.Text className='text-muted'>Juridisk navn i brreg: {lookupChangeRequestOrgnrResult[0].name}</Form.Text>
                          )}
                        </div>
                      </Col>
                    }
                  </Row>
                )}
              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              <CompareIcon compareStatus={compareStatus} onClick={transferChangeRequestValue} />
            </div>
            <div className={Style.formGroupContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup id="juridiskinfo" size="sm">
                  {!selectedBrregEnhetInfo ? (
                    <InputGroup.Text style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                      <IcebergIcon icon="search" />
                    </InputGroup.Text>
                  ) : (
                    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={BrregPopover(selectedBrregEnhetInfo)}>
                      {notshowGreenTicker ? (
                        <Button variant="warning" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      ) : (
                        <Button variant="success" id="button-addon1" style={{ paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                          <IcebergIcon icon="check" bold />
                        </Button>
                      )}
                    </OverlayTrigger>
                  )}
                  <BrregTypeAhead
                    selected={selectedJuridisk}
                    clearTypeaheadInitiator={props.changeRequestId}
                    onBrregEnhetSelected={e => handleBrregSelectedChange(e)}
                    setIsLoadingBrreg={setIsLoadingBrreg}
                    size="small"
                  />
                </InputGroup>
                {selectedJuridisk && !selectedBrregEnhetInfo && !isLoadingBrreg && (
                  <Form.Text className={Style.brregWarningFormText}>
                    <span>Ingen funn på eksisterende juridisk navn og organisasjonsnummer kombinasjon. </span>
                  </Form.Text>
                )}
                {!!selectedBrregEnhetInfo?.overordnetEnhet && !isLoadingBrreg && (
                  <Form.Text className="text-danger">
                    <span>Dette er et bedriftsnummer og skal ikke brukes som organisasjonsnummer</span>
                  </Form.Text>
                )}
                {!props.enhetJurNavn && selectedBrregEnhetInfo?.orgNr && props.duplicateWarning && (
                  <Form.Text className='text-muted'>
                    <span>{props.duplicateWarning} </span>
                    <a style={{ fontSize: '12px' }} href="#" onClick={() => handleGoToEnhetSearch(selectedJuridisk?.orgNr)}>Se enhet(er)</a>
                  </Form.Text>
                )}
              </Form.Group>
            </div>
          </Row>
        </div>
      </Collapse>
    </>
  )
}

export default OrgNrRow;