import React from "react";
import { Container } from "react-bootstrap";
import { HelmetProvider, Helmet } from "react-helmet-async";
import PageHeader from '../PageHeader';

interface Props {
  header: string;
  isFluid?: boolean | string;
  children?: React.ReactNode;
}

const PageWrapper= (props: Props) => {
  return (
    <Container fluid={props.isFluid}>
      <HelmetProvider>
        <PageHeader>{props.header}</PageHeader>
        <Helmet>
          <title>{props.header}</title>
          <meta name="title" content={props.header} />
        </Helmet>
        {props.children}
      </HelmetProvider>
    </Container>
  )
};

export default PageWrapper;
