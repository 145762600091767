import axios from 'axios';

import { getKjederegAdminApiUrl } from 'services';

export interface IBruker {
  id: number;
  brukerID: string;
  fornavn: string;
  etternavn?: string;
  epost?: string;
}

const baseUrl = getKjederegAdminApiUrl() + "/brukere";

async function getBrukere() : Promise<IBruker[]> {
  const response = await axios.get(baseUrl);
  return response.data;
}

export default {
  getBrukere,
};
