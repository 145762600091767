import React, { useState, useEffect } from 'react';
import { Form, InputGroup, FormControl, Row, Col, ListGroup, Button } from 'react-bootstrap';

import { debounce } from 'lodash';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import GlnApi from 'services/KjederegisteretAdminApi/GlnApi';
import { UpdateGlnCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import { AvailableGlnModel } from 'services/KjederegisteretAdminApi/GlnApi/types';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';

import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useForm from 'utils/hooks/useForm';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

interface Props {
  enhetId: number;
  onUpdated: () => void;
  //updateExistingGln: boolean;
  existingGln: string;
}

const ChangeGln: React.FC<Props> = props => {

  const [searchResult, setSearchResult] = useState<AvailableGlnModel>(null);
  const [isLoadingSearchGln, setIsLoadingSearchGln] = useState(false);
  const [isLoadingUpdateGln, setIsLoadingUpdateGln] = useState(false);
  const [isValueValid, setIsValueValid] = useState(false);
  const [isValueInvalid, setIsValueInvalid] = useState(false);

  const toaster = useToaster();

  const openEnhetInNewTab = (url: string) => {
    const win = window.open(url, '_blank', 'noopener,noreferrer');
    if (win != null) {
      win.focus();
    }
  };

  const initialValues: UpdateGlnCommand = {
    id: props.enhetId,
    gln: ''
  }

  const validate = (values: UpdateGlnCommand) => {
    const errors: any = {};

    if (values.gln.length < 13) errors.gln = 'For få siffer (13)';
    if (values.gln.length > 13) errors.gln = 'For mange siffer. (13)';
    if (values.gln !== '' && !isNumeric(values.gln)) errors.gln = 'Kun siffer i GLN (0..9).';
    return errors;
  };

  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  }

  const handleUpdateGln = async (command: UpdateGlnCommand) => {
    setIsLoadingUpdateGln(true);
    try {
      await EnhetApi.updateGln(command);
      setIsLoadingUpdateGln(false);
      toaster.success("Suksess!", "Enhet ble oppdatert med nytt GLN.")
      props.onUpdated();
    } catch (err) {
      setIsLoadingUpdateGln(false);
      toaster.error("Feil!", HttpErrorHelper.formatError(err))
    }
  }

  const searchGln = debounce(async (gln: string) => {
    setIsLoadingSearchGln(true);
    try {
      const result = await GlnApi.findAvailableGln(gln);
      setSearchResult(result);

      setIsLoadingSearchGln(false);
    } catch (error) {
      setIsLoadingSearchGln(false);
      setSearchResult(null);
    }
  }, 300);

  const formData = useForm(initialValues, validate, handleUpdateGln);

  const onInputChange = (gln: string) => {
    formData.handleChange('gln', gln);
  }


  useEffect(() => {
    setSearchResult(null);
    const copy = { ...formData.values, gln: '', id: props.enhetId };
    formData.updateValues(copy);
  }, [props.enhetId])

  useEffect(() => {
    if (isValueValid) {
      searchGln(formData.values.gln);
    }
  }, [isValueValid]);

  useEffect(() => {
    if (formData.touched.gln && formData.errors.gln && formData.values.gln !== '') {
      setIsValueInvalid(true);
    } else {
      setIsValueInvalid(false);
    }
    if (formData.touched.gln && !formData.errors.gln && formData.values.gln !== '') {
      setIsValueValid(true);
    } else {
      setIsValueValid(false);
    }
  }, [formData.errors.gln])

  return (
    <Form onSubmit={formData.handleSubmit}>
      <Row>
        <Col>
          <InputGroup>
            <InputGroup.Text id="search-icon-addon">
              {isLoadingSearchGln ? (
                <CommonLoader color="#003b6e" />
              ) : (
                <IcebergIcon icon="search" />
              )}
            </InputGroup.Text>
            <FormControl
              placeholder="Søk på GLN.."
              aria-label="gln"
              aria-describedby="search-icon-addon"
              value={formData.values.gln}
              onChange={e => onInputChange(e.target.value)}
              isInvalid={isValueInvalid}
              isValid={isValueValid}
            />
            <Form.Control.Feedback type="invalid">{formData.errors.gln}</Form.Control.Feedback>
          </InputGroup>
        </Col>

      </Row>

      {formData.touched.gln && !searchResult && !isLoadingSearchGln &&
        <Row>
          <Col>
            <h6>Ingen treff.</h6>
          </Col>
        </Row>
      }

      {searchResult && !isLoadingSearchGln &&
        <Row className={Style.krResultRow}>
          <Col>
            <h6>Funn på søk: {searchResult.gln}</h6>
            <ListGroup>
              {searchResult.enhetId && (
                <ListGroup.Item>
                  <span style={{ color: 'red', fontSize: '1.3em' }}>GLN er i bruk:</span>
                  <Button variant="link" onClick={() => openEnhetInNewTab(`/enheter/${searchResult.enhetId}`)}>{searchResult.enhetNavn}</Button>
                </ListGroup.Item>
              )}
              {searchResult.gln && !searchResult.enhetId && (
                <ListGroup.Item>
                  <span style={{ color: 'green', fontSize: '1.3em' }}>GLN tilgjengelig: {searchResult.gln}</span>
                </ListGroup.Item>
              )}
            </ListGroup>
          </Col>
        </Row>
      }

      {
        searchResult && !isLoadingSearchGln && (
          <Row className={Style.glnApiResultListRow}>
            <Col>
              <ListGroup>
                <ListGroup.Item><strong>Eier GLN: </strong>{searchResult.eierGln}</ListGroup.Item>
                <ListGroup.Item><strong>Lokasjonsnavn: </strong> {searchResult.lokasjonsNavn}</ListGroup.Item>
                <ListGroup.Item><strong>Adresse: </strong> {searchResult.besokAdresse} </ListGroup.Item>
                <ListGroup.Item><strong>Postnummer: </strong> {searchResult.besokPostnummer} </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        )
      }

      <Row className={Style.changeGlnBtnRow}>
        <Col>
          <Button size='sm' className="mt-3 mb-2" type="submit" disabled={(!searchResult) || (searchResult?.gln && searchResult?.enhetId) ? true : false} >

            {isLoadingUpdateGln && (
              <span style={{ paddingRight: '0.5rem', margin: '0' }}>
                <CommonLoader color="#003b6e" />
              </span>
            )}
            Sett GLN
          </Button>

        </Col>
      </Row>
    </Form >
  );
};

export default ChangeGln;
