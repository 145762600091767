import { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import CountryPicker from 'components/CountryPicker';
import { IAdresse } from 'components/AddressTypeahead/types';
import GoogleMaps from 'components/GoogleMaps';
import { Coordinates } from 'models/Coordinates';
import IconPopoverMenu from 'components/IconPopOver';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import useGoogleMaps from 'utils/hooks/useGoogleMaps';
import useGeocoder from 'utils/hooks/useGeocoder';
import Style from './index.module.css'

interface Props {
  adresse: IAdresse;
  ekstraInfo: string;
  onEkstraInfoChanged: (info: string) => void;
  coordinates: Coordinates;
  setCoordinates: (coordinates: Coordinates) => void;
  errors: any;
  isFormDisabled: boolean;
  onValueChanged: (targetName: string, value: string) => void;
  onCoordinatesChanged: (coord: { lat: number, lng: number }) => void;
  onCoordinatesRemoved: (id: number) => void;
  onPostnrChanged: (postnr: string) => void;
}

const AddressDetails = (props: Props) => {

  const { google } = window;

  const isMountedRef = useIsMountedRef();
  const isGoogleMapsLoaded = useGoogleMaps();
  const geocoder = useGeocoder();
  const defaultCenter = new Coordinates(59.91, 10.75); // Oslo


  const [defaultMapBounds, setDefaultMapBounds] = useState(null);
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [markerLocation, setMarkerLocation] = useState<Coordinates>(null);


  function formatLatLng(a: { latitude: number; longitude: number }) {
    if (a?.latitude && a?.longitude) {
      return `${a.latitude}, ${a.longitude}`;
    }
    return '';
  }
  function coordinatesToLatLng(coordinates: Coordinates) {
    if (coordinates.isValid()) {
      return { latitude: coordinates.lat, longitude: coordinates.lng };
    }
    return null;
  }

  const maxChars = 80;

  const getCharCountColor = (notatLength: number) => {
    if (notatLength) {
      const charsLeft = maxChars - notatLength;
      if (charsLeft <= 15) {
        return 'red';
      }
      if (charsLeft <= 30) {
        return 'orange';
      }
    }
    return 'green';
  };

  const findWithGeoCoder = () => {
    // geoCode on poststed
    geocoder.current.geocode(
      {
        address: props.adresse.poststed,
        // location: LatLng,
        // placeId: string,
        bounds: defaultMapBounds,
        // componentRestrictions: GeocoderComponentRestrictions,
        // region: string
      },
      geoCodeResponse => {
        const firstHit = geoCodeResponse && geoCodeResponse.length > 0 ? geoCodeResponse[0] : null;
        if (firstHit) {
          setMapCenter(
            new Coordinates(firstHit.geometry.location.lat(), firstHit.geometry.location.lng()),
          );
        }
      },
    );
  };

  useEffect(() => {
    if (markerLocation && markerLocation !== defaultCenter) {
      setMapCenter(markerLocation);
    } else if (!markerLocation && props.adresse && props.adresse.poststed) {
      // No valid coordinates, but we have a poststed
      if (isGoogleMapsLoaded && google && geocoder.current) {
        findWithGeoCoder();
      }
    }
  }, [props.adresse, isGoogleMapsLoaded, markerLocation, geocoder.current]);

  useEffect(() => {
    if (isMountedRef.current && google) {
      setDefaultMapBounds(
        new google.maps.LatLngBounds(new google.maps.LatLng(57, 2), new google.maps.LatLng(81, 42)),
      );
    } // Norge inkl. Svalbard
  }, [google]);

  useEffect(() => {
    let newCenter = defaultCenter;

    if (props.adresse?.latitude && props.adresse?.longitude) {
      newCenter = new Coordinates(props.adresse?.latitude, props.adresse?.longitude);
    }

    setMapCenter(newCenter);
    setMarkerLocation(newCenter);
  }, [props.adresse?.latitude, props.adresse?.longitude]);

  useEffect(() => {
    if (!props.adresse?.landkode && !props.isFormDisabled) {
      props.onValueChanged('landkode', 'NO');
    }
  }, [props.adresse])

  return (
    <div style={{ margin: 'auto' }}>
      <hr style={{ marginTop: 0 }} />
      <Row>
        <Col>
          {mapCenter && (
            <>
              <GoogleMaps
                center={mapCenter}
                existingLocation={markerLocation}
                canUpdateLocation={true}
                useSearchBox={true}
                onLatLngChanged={(coordinates: { lat: number, lng: number }) => props.onCoordinatesChanged(coordinates)}
                onLatLngRemoved={() => props.onCoordinatesRemoved(props.adresse.id)} />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>Hjelp</span>
                <IconPopoverMenu menuTitle="Bruk av kart" toggleIcon="info">
                  <div>
                    <ul>
                      <li>
                        Rød markør viser den lagrede lokasjonen (koordinater) for den gjeldende
                        adressen. Ved å klikke på den røde markøren får du muligheten til å klikke på
                        "flytt markør", markør går i "hoppemodus" og du kan flytte markøren dit du vil
                        for å endre lokasjonen (koordinatene) til den gjeldene adressen.
                      </li>
                      <li>
                        Longpress 2 sek plasserer ut markør i form av et strandflagg. Klikk på "bruk
                        koordinater" for å sette koordinatene til adressen du jobber med på denne
                        lokasjonen.
                      </li>
                      <li>
                        Dersom du klikker og holder på din egendefinerte markør fjerner markøren fra
                        kartet.
                      </li>
                    </ul>
                    <strong>
                      <span style={{ color: 'red' }}>OBS! </span>Du må trykke på "avslutt flytting"
                      og/eller "lagre" under adressen for å lagre lokasjonsendringen
                    </strong>
                  </div>
                </IconPopoverMenu>
              </div>
            </>
          )}
        </Col>
        <Col>
          <Form style={{ height: '100%', width: '100%', margin: '0 auto' }}>
            <Form.Group className={Style.addressFormGroup}>
              <Form.Control
                name="gateadresse"
                type="text"
                placeholder="Gatenavn"
                value={props.adresse?.gateadresse || ''}
                readOnly={props.isFormDisabled}
                isInvalid={props.errors?.gateadresse}
                onChange={e => props.onValueChanged(e.target.name, e.target.value.toUpperCase())}
              />
              <Form.Control.Feedback type="invalid">{props.errors?.gateadresse}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={Style.addressFormGroup}>
              <Row>
                <Col>
                  <Form.Control
                    name="postnr"
                    type="text"
                    placeholder="Postnummer"
                    value={props.adresse?.postnr || ''}
                    isInvalid={props.errors?.postnr}
                    onChange={e => props.onPostnrChanged(e.target.value.toUpperCase())}
                    readOnly={props.isFormDisabled}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors?.postnr}</Form.Control.Feedback>
                </Col>
                <Col>
                  <Form.Control
                    name="poststed"
                    type="text"
                    placeholder="Poststed"
                    value={props.adresse?.poststed || ''}
                    isInvalid={props.errors?.poststed}
                    onChange={e => props.onValueChanged(e.target.name, e.target.value.toUpperCase())}
                    readOnly={props.isFormDisabled}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors?.poststed}</Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
            <Form.Group className={Style.addressFormGroup}>
              <Row>
                <Col>
                  <CountryPicker
                    selected={props.adresse?.landkode || 'NO'}
                    onChange={value => props.onValueChanged('landkode', value)}
                    disabled={props.isFormDisabled}
                    isInvalid={props.errors?.landkode}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors?.landkode}</Form.Control.Feedback>
                </Col>

                <Col>
                  <Form.Control
                    name="koordinater"
                    type="text"
                    placeholder="Koordinater"
                    value={
                      props.coordinates
                        ? formatLatLng(coordinatesToLatLng(props.coordinates))
                        : ''
                    }
                    onChange={(e) => {
                      const [lat, lng] = e.target.value.split(", ").map(Number);
                      props.setCoordinates(new Coordinates(lat, lng));
                    }}
                    readOnly={props.isFormDisabled}
                    title="Flytt markøren på kartet for å endre koordinater."
                    isInvalid={props.errors?.koordinater}
                  />
                  <Form.Control.Feedback type="invalid">{props.errors?.koordinater}</Form.Control.Feedback>
                </Col>

              </Row>
              <Row>
                <Col>
                  <Form.Group style={{ paddingTop: '2em' }}>
                    <Form.Label>
                      <h6>Ekstra informasjon:</h6>
                    </Form.Label>
                    <Form.Control
                      name="ekstraInfo"
                      value={props.ekstraInfo || ''}
                      onChange={e => props.onEkstraInfoChanged(e.target.value.toUpperCase())}
                      maxLength={maxChars}
                      placeholder="Eks: 2 et."
                      isInvalid={props.errors?.ekstraInfo}
                    />
                    <p
                      style={{
                        textAlign: 'right',
                        color: getCharCountColor(props.ekstraInfo?.length),
                      }}
                    >
                      {props.ekstraInfo ? (
                        <span>{(maxChars - (props.ekstraInfo?.length))} </span>
                      ) : (
                        <span>{maxChars} </span>
                      )}
                      tegn tilgjengelig.
                    </p>
                  </Form.Group>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Col>
      </Row>

      <hr />
    </div>
  );
};

export default AddressDetails;
