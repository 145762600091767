import Style from './index.module.css';
import { Alert } from 'react-bootstrap';
import { Toast } from '../../utils/contexts/ToastProvider/types'
import { ReactNode } from 'react';

interface Props {
  toasts: Toast[];
  handleCloseToast: (index: number) => void;
}

const Toaster = (props: Props) => {
  return (
    <div className={Style.toastContainer}>
      {props.toasts &&
        props.toasts.map((t, index) => {
          return (
            <Alert
              key={`alert-${Math.random()}`}
              show={true}
              variant={t.type}
              onClose={() => props.handleCloseToast(index)}
              dismissible={t.dismissible}
            >
              <Alert.Heading>{t.title || ''}</Alert.Heading>
              {t.message}
            </Alert>
          );
        })}
    </div>
  )
};
export default Toaster;
