import { useState } from 'react';
import { Col, Tabs, Tab } from 'react-bootstrap';
import InfoTab from './InfoTab';
import EnheterTab from './EnheterTab';
import MedlemskjederTab from './MedlemskjederTab';
import MedlemsgrupperTab from './MedlemsgrupperTab';
import { Enhetgruppering } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import AdminTab from './AdminTab';

interface Props {
  gruppering: Enhetgruppering;
  onReset: () => void;
  reload: () => void;
}

const EnhetsgrupperingEdit = (props: Props) => {
  const [enhetsMedlemmerCount, setEnhetsMedlemmerCount] = useState<number | undefined>();
  const [medlemsKjederCount, setMedlemsKjederCount] = useState<number>(0);
  const [medlemsGrupperCount, setMedlemsGrupperCount] = useState<number>(0);


  return (
    <Col>
      <Tabs defaultActiveKey="info">
        <Tab eventKey="info" title="Info">
          <InfoTab gruppering={props.gruppering} reload={props.reload} />
        </Tab>

        <Tab eventKey="enheter" title={enhetsMedlemmerCount > 0 ? `Enhetsmedlemmer (${enhetsMedlemmerCount})` : 'Enhetsmedlemmer'}>
          <EnheterTab gruppering={props.gruppering} onTotalCountChanged={setEnhetsMedlemmerCount}></EnheterTab>
        </Tab>

        <Tab eventKey="medlemskjeder" title={`Kjedemedlemmer (${medlemsKjederCount})`}>
          <MedlemskjederTab gruppering={props.gruppering} reload={props.reload} setMedlemskjederCount={setMedlemsKjederCount}></MedlemskjederTab>
        </Tab>

        <Tab eventKey="medlemsgrupper" title={`Gruppermedlemmer (${medlemsGrupperCount})`}>
          <MedlemsgrupperTab gruppering={props.gruppering} reload={props.reload} setMedlemsCount={setMedlemsGrupperCount}></MedlemsgrupperTab>
        </Tab>

        <Tab eventKey="admin" title={`Admin`}>
          <AdminTab gruppering={props.gruppering} onEnhetsgruppeUpdated={props.reload} />
        </Tab>
      </Tabs>
    </Col>
  );
};

export default EnhetsgrupperingEdit;
