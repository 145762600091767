import { useState } from 'react';
import { Button, Row, Col, Form, Modal, Alert } from 'react-bootstrap';

import AddressTypeahead from 'components/AddressTypeahead';
import { IAdresse } from 'components/AddressTypeahead/types';
import GrossistPicker from 'components/GrossistPicker';

import { validateKontonummerMod11 } from '../../utils';

import Style from './index.module.css';
import DatePicker from 'components/DatePicker';
import CommonLoader from 'components/CommonLoader';
import { IconButton } from '@tradesolution/iceberg-ui-react';

interface Props {
  show: boolean;
  isLoadingBulkJob: boolean;
  bulkJobStarted: boolean;
  numberOfSelected: number;
  onClose: () => void;
  handleBulkJob: (type: number, value: string | number | IAdresse, extraInfo?: string, ignoreAddress?: boolean, overforKundenr?: boolean, dato?: Date) => void;
}

const BulkJobModal = (props: Props) => {
  const [selectedJob, setSelectedJob] = useState<number>(0);

  const [selectedAddresse, setSelectedAdresse] = useState<IAdresse | undefined>();
  const [selectedAddresseEkstraInfo, setSelectedAdresseEkstraInfo] = useState<string>("");
  const [ignoreAdresse, setIgnoreAdresse] = useState<boolean>(false);

  const [selectedEpost, setSelectedEpost] = useState<string>("");

  const [selectedKontoNr, setSelectedKontoNr] = useState<string>("");
  const [kontoNrInvalid, setKontoNrInvalid] = useState<boolean>(false);

  const [selectedGrossist, setSelectedGrossist] = useState<number[]>([]);
  const [overforKundenr, setOverforKundenr] = useState<boolean>(false);

  const [fraOgMedDato, setFraOgMedDato] = useState<Date | undefined>();
  const [kjedenummer, setKjedenummer] = useState<string>("");


  const handleKontoNrChanged = (kontonr: string) => {
    setSelectedKontoNr(kontonr);
    setKontoNrInvalid(!validateKontonummerMod11(kontonr));
  }

  const handleCloseModal = () => {
    setSelectedJob(0);
    setSelectedAdresse(undefined);
    setSelectedAdresseEkstraInfo("");
    setIgnoreAdresse(false);
    setSelectedEpost("");
    setSelectedKontoNr("");
    setKontoNrInvalid(false);
    setSelectedGrossist([]);
    setOverforKundenr(false);
    setKjedenummer("");
    setFraOgMedDato(undefined);
    props.onClose();
  }

  let content = <></>

  switch (selectedJob) {
    case 0:
      content =
        <>
          <Form.Group className={Style.formGroup}>
            <div className={Style.checkbox}>
              <Form.Check checked={ignoreAdresse} onChange={e => setIgnoreAdresse(e.target.checked)} />
              <Form.Label>Ignorer adresse</Form.Label>
            </div>
            <Form.Label>Ny fakturaadresse</Form.Label>
            <AddressTypeahead selected={selectedAddresse} onChange={(address: IAdresse) => { setSelectedAdresse(address) }} disabled={ignoreAdresse} />
            <Form.Label className={Style.secondLabel}>Ekstra informasjon</Form.Label>
            <Form.Control type='text' value={selectedAddresseEkstraInfo} onChange={e => setSelectedAdresseEkstraInfo(e.target.value.toUpperCase())} />
          </Form.Group>
          <Form.Group className={Style.formGroup}>
            <Form.Text className={Style.formText}>
              {props.numberOfSelected + ' enheter får ny fakturaadresse'}
            </Form.Text>
          </Form.Group>

        </>
      break;
    case 1:
      content =
        <>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Ny fakturaepost</Form.Label>
            <Form.Control type='text' value={selectedEpost} onChange={e => setSelectedEpost(e.target.value)} />
          </Form.Group>
          <Form.Text className={Style.formGroup}>
            {props.numberOfSelected + ' enheter får ny fakturaepost.'}
          </Form.Text>
        </>
      break;
    case 2:
      content =
        <>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Nytt kontonummer</Form.Label>
            <Form.Control type='text' value={selectedKontoNr} onChange={e => handleKontoNrChanged(e.target.value)} isInvalid={kontoNrInvalid} />
          </Form.Group>
          <Form.Text>
            {props.numberOfSelected + ' enheter får nytt kontonummer.'}
          </Form.Text>
        </>
      break;
    case 3:
      content =
        <>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Velg grossist</Form.Label>
            <GrossistPicker mode='radioSelect' onSelectionChanged={e => setSelectedGrossist(e.map(x => x.nr))} findPreselectedNodesByNR={() => selectedGrossist} />
            <div className={Style.checkbox}>
              <Form.Check checked={overforKundenr} onChange={e => setOverforKundenr(e.target.checked)} />
              <Form.Label>Overfør kundenummere?</Form.Label>
            </div>
          </Form.Group>
          <Form.Text>
            {props.numberOfSelected + ' valgte enheter. NB: Nedlagte enheter og enheter med enhetstype GROSSIST vil ikke få ny hovedgrossist. Enheter som allerede har valgt grossist som hovedgrossist vil ignoreres!'}
          </Form.Text>
        </>
      break;
    case 4:
      content =
        <>
          <Form.Group className={Style.formGroup}>
            <Form.Label>Fra og med dato</Form.Label>
            <Form.Group>
              <DatePicker
                selected={fraOgMedDato}
                onChange={value => setFraOgMedDato(value)}
                adjustTimeForTimezoneOffset={true}
              />
            </Form.Group>
            <Form.Label className={Style.secondLabel}>Nytt kjedenummer</Form.Label>
            <Form.Control type="text" value={kjedenummer} onChange={e => setKjedenummer(e.target.value)} />
            <div className={Style.checkbox}>
              <Form.Check checked={overforKundenr} onChange={e => setOverforKundenr(e.target.checked)} />
              <Form.Label>Overfør kundenummere?</Form.Label>
            </div>
          </Form.Group>
          <Form.Text>
            {props.numberOfSelected + ' enheter får ny kjede.'}
          </Form.Text>
        </>
      break;
  }

  return (
    <Modal show={props.show} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Bulk endringer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className={Style.formGroup}>
          <Form.Label>Velg jobb</Form.Label>
          <Form.Control as="select" value={selectedJob} onChange={e => setSelectedJob(Number(e.target.value))}>
            <option value={0}>Bytt fakturaadresse</option>
            <option value={1}>Bytt fakturaepost</option>
            <option value={2}>Bytt kontonummer</option>
            <option value={3}>Flytt til ny hovedgrossist</option>
            <option value={4}>Flytt til ny kjede</option>
          </Form.Control>
        </Form.Group>
        <Row>
          <Col>
            {content}
          </Col>
        </Row>
        {props.bulkJobStarted && <Alert variant='info' style={{ marginTop: "0.5rem" }}>
          Jobben er startet. Hvis den tar lenger enn 1 min vil du få tilsendt resultatet via epost. <a href="/bulkjobber">Oversikt over kjørte bulkjobber finner du her</a>
        </Alert>}
      </Modal.Body>
      <Modal.Footer>
        <IconButton icon='close' variant="outline-primary" className='col' onClick={props.onClose}>
          Avbryt
        </IconButton>
        <IconButton className='col' icon='disk' variant="primary" disabled={props.isLoadingBulkJob || props.bulkJobStarted || (selectedJob === 2 && kontoNrInvalid) || (selectedJob === 3 && selectedGrossist.length === 0)} onClick={() => {
          if (selectedJob === 0) {
            props.handleBulkJob(selectedJob, selectedAddresse, selectedAddresseEkstraInfo, ignoreAdresse);
          } else if (selectedJob === 1) {
            props.handleBulkJob(selectedJob, selectedEpost);
          } else if (selectedJob === 2) {
            props.handleBulkJob(selectedJob, selectedKontoNr);
          } else if (selectedJob === 3) {
            props.handleBulkJob(selectedJob, selectedGrossist[0], null, null, overforKundenr);
          } else if (selectedJob === 4) {
            props.handleBulkJob(selectedJob, kjedenummer, null, null, overforKundenr, fraOgMedDato);
          }

        }}>
          {props.isLoadingBulkJob && (
            <span style={{ paddingRight: '.3em' }}>
              <CommonLoader />
            </span>
          )}
          <span style={{ paddingRight: '0.3em' }}>Utfør</span>
        </IconButton>
      </Modal.Footer>
    </Modal>
  )
}

export default BulkJobModal;
