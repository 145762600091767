import { useEffect, useState } from 'react';
import { Col, Card, Form, Row } from 'react-bootstrap';
import { EnheterInGrupperingQuery } from 'services/KjederegisteretAdminApi/EnhetApi/Queries';
import EnhetTable from './EnhetTable';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { GrupperingMedlem } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import useDebounce from 'utils/hooks/useDebounce';
import { Enhetgruppering } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';

interface Props {
  gruppering: Enhetgruppering;
  onTotalCountChanged: (count: number) => void;
}

const EnheterTab: React.FC<Props> = props => {
  const [enheter, setEnheter] = useState<GrupperingMedlem[]>([]);
  const [queryText, setQueryText] = useState<string>("");
  const [query, setQuery] = useState(new EnheterInGrupperingQuery());
  const [isLoadingEnheter, setIsLoadingEnheter] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number | undefined>();

  const debouncedQueryText = useDebounce(queryText, 500);

  const loadEnheter = async () => {
    setIsLoadingEnheter(true);
    const result = await EnhetApi.getByGruppering(props.gruppering.enhetgrupperingID, query);
    setIsLoadingEnheter(false);
    props.onTotalCountChanged(result.totalCount);
    setTotalCount(result.totalCount);
    if (query.page > 1 && result.results.length > 0) {
      setEnheter(enheter.concat(result.results));
    } else {
      setEnheter(result.results);
    }
  };

  const handleQueryTextChanged = (value: string) => {
    setQueryText(value);
  };

  const handleSortByChanged = (value: string) => {
    setQuery(query.setSortBy(value));
  };

  const handleLoadMoreEnheter = () => {
    if (!isLoadingEnheter) {
      setQuery(query.nextPage());
    }
  };

  const handleIncludeUtmeldteChanged = (value: boolean) => {
    setQuery(query.setInkluderUtmeldte(value));
  };

  useEffect(() => {
    loadEnheter();
  }, [query]);

  useEffect(() => {
    setQuery(query.setQueryText(debouncedQueryText));
  }, [debouncedQueryText]);

  useEffect(() => {
    setEnheter([]);
    setQuery(new EnheterInGrupperingQuery());
  }, [props.gruppering]);

  return (
    <Card>
      <Card.Body>
        <h6>{props.gruppering.enhetsgrupperingnavn} har følgende enheter</h6>
        <Form style={{ marginBottom: '0.5rem' }}>
          <Row>
            <Col sm={8}>
              <Form.Control
                size="sm"
                placeholder="Søk"
                value={queryText}
                onChange={e => handleQueryTextChanged(e.target.value)}
              />
              <Form.Check
                style={{ marginTop: "0.2rem" }}
                type="checkbox"
                checked={query.includeHistoric}
                label="Inkluder tidligere medlemmer"
                onChange={e => handleIncludeUtmeldteChanged(e.target.checked)}
              />
            </Col>
            <Col>
              <Form.Control size="sm" placeholder="Sorter" as="select" value={query.sortBy || 'postnr'}
                            onChange={e => handleSortByChanged(e.target.value)}>
                <option value="">Postnr - laveste først</option>
                <option value="postnr_desc">Postnr - høyeste først</option>
                <option value="navn">Navn</option>
                <option value="lopenr">Løpenr - laveste først</option>
                <option value="lopenr_desc">Løpenr - høyeste først</option>
                <option value="innmeldt">Innmeldt dato - tidligste først</option>
                <option value="innmeldt_desc">Innmeldt dato - nyligste først</option>
                <option value="utmeldt">Utmeldt dato - tidligste først</option>
                <option value="utmeldt_desc">Utmeldt dato - nyligste først</option>
              </Form.Control>
            </Col>
          </Row>
        </Form>
        <EnhetTable
          hasMore={totalCount > query.page * query.pageSize}
          onLoadMoreEnheter={handleLoadMoreEnheter}
          isLoadingEnheter={isLoadingEnheter}
          enheter={enheter}
        />
      </Card.Body>
    </Card>
  );
};

export default EnheterTab;
