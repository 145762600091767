export interface KjedeDto {
  kjedeID: number;
  kjedenr: number;
  enhetID: number;
  lopenummer?: number;
  kjedenavn: string;
  kortnavn: string;
  oppstartsDato?: Date
  nedlagtDato?: Date;
}

export interface FlyttKjedeTilNyttInnkjopCommand {
  fraEnhetgruppeId: number;
  tilEnhetgruppeId: number;
  fraOgMedDato: Date;
}

export interface NewKjedeCommand {
  kjedenavn: string;
  kortnavn: string;
  enhetnavn: string;
  enhetId: number;
  overliggendeRegionalKjedeId: number;
  overliggendeSalgsOgMarkedsKjedeId: number;
  oppstartDato: Date;
}

export interface UpdateKjedeCommand
{
  id?: number;
  kjedenavn: string;
  kortnavn: string;
  enhetId: number;
  enhetnavn?: string;
  kjedenr?: number;
}

export interface GetKjedeByIdResponse {
  kjedeID: number;
  enhetID: number;
  kjedenavn: string;
  kjedenr: number;
  kortnavn: string;
  nameHierarchy: string[];
  hierarchy: {
    nr: number;
    name: string;
    enhetId: number;
    id: number;
  }[];
  overliggendRegionKjedeID?: number;
  overliggendSalgsOgMarkedsKjedeID?: number;
  enhet: {
    id: number;
    code?: string;
    name: string;
    number?: number;
    type?: string;
  };
  parentTree: {
    enhetId: number;
    id: number;
    name: string;
    kjedenr: number;
  }[];
}

export interface GetKjedeByIdRequest {
  id: number;
  kjedeType: string;
}

export interface KjedeNode {
  id: number;
  name: string;
  kjedenr: number;
  subtitle?: string;
  children?: KjedeNode[];
}

export class KjedeType {
  static Region = 'Regional inndeling';
  static Marked = 'Salg/ Markedsføring';
  static Topp = 'Toppnivå';
}