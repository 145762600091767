import { IcebergAlert } from "@tradesolution/iceberg-ui-react";

interface Props {
    text?: string;
}

const ErrorFeedback = (props: Props) => {
    if (!props.text) return null;
    return (
        <div className="mt-3">
            <IcebergAlert variant="danger">
                {props.text}
            </IcebergAlert>
        </div>
    )
};

export default ErrorFeedback;