import React, { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { EierskifteEnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { debounce } from 'lodash';

interface Props {
  onChange: (data: EierskifteEnhetViewModel) => void;
  isValid: boolean;
  disabled: boolean;
}

const EnheterTilEierskifteTypeAhead: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();
  const [searchResponse, setSearchResponse] = useState<EierskifteEnhetViewModel[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');

  const onQueryChanged = (q: string) => {
    if (isMountedRef.current && q) {
      setQuery(q);
    }
  };

  const search = async () => {
    setIsSearching(true);
    const sr = await EnhetApi.searchEnheterTilEierskifte(query);
    if (isMountedRef.current) {
      setIsSearching(false);
      setSearchResponse(sr);
    }
  };

  const onChange = (e: EierskifteEnhetViewModel[]) => {
    if (e.length === 0) {
      props.onChange(undefined);
    } else {
      props.onChange(e[0]);
    }
  };

  useEffect(() => {
    search();
  }, [query]);

  return (
    <AsyncTypeahead
      labelKey={option =>
        `${option.enhetnavn} (${option.kanVidereforeTil ? 'Kan brukes ' : 'Kan ikke brukes. '}${option.nedlagtDato ? 'Nedlagt' : ''
        })`
      }
      minLength={3}
      isLoading={isSearching}
      options={searchResponse || []}
      searchText="Søker"
      placeholder="Søk etter eksisterende enhet..."
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      maxResults={20}
      onChange={e => onChange(e)}
      onSearch={debounce(q => onQueryChanged(q), 300)}
      filterBy={() => true}
      useCache={false}
      isInvalid={query === '' ? null : !props.isValid}
      isValid={query === '' ? null : props.isValid}
      disabled={props.disabled}
    />
  );
};

export default EnheterTilEierskifteTypeAhead;
