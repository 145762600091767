import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col } from 'react-bootstrap';
import Style from './index.module.css';
import Grossist from './components/Grossist';
import Basis from './components/Basis';
import Gln from './components/Gln';
import Kjede from './components/Kjede';
import Gruppering from './components/Gruppering';
import EnhetHistory from '../components/EnhetHistory';
import { EnhetViewModel, KontonrOgFakturaAdresseSjekkResponse } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { EnhetStatestikk, Fakturalinje, GrossistKunde } from 'services/SalgsdatainfoApi/types';
import AdresserV2 from './components/AdresserV2';
import LeverandorKundenummer from './components/LeverandorKundenummer';
import Bevillinger from './components/Bevillinger';

interface LinkedForm {
  tab: string;
  field: string;
}
interface Props {
  enhet: EnhetViewModel;
  enhetStatestikk: EnhetStatestikk;
  grossistKunder: GrossistKunde[];
  fakturalinjer: Fakturalinje[];
  handleEnhetUpdated: () => void;
  onNavigateToLopenummer: (lopenummer: number) => void;
  linkedForm: LinkedForm;
  kontonrOgFakturaAdresseSjekkResponse?: KontonrOgFakturaAdresseSjekkResponse;
}

const EnhetEdit = (props: Props) => {
  const [activeKey, setActiveKey] = useState<string>('basis');

  const handleActiveKeyChanged = (key: string) => {
    if (key !== activeKey) {
      setActiveKey(key);
    }
  };

  const handleEnhetUpdated = () => {
    props.handleEnhetUpdated();
  };

  useEffect(() => {
    handleActiveKeyChanged(props.linkedForm.tab);
  }, [props.linkedForm]);

  return (
    <Tab.Container
      id={Style.tabContainer}
      onSelect={handleActiveKeyChanged}
      activeKey={activeKey}
      defaultActiveKey={'basis'}
    >
      <Row>
        <Col>
          <Nav className={Style.tabNav} variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="basis">Basis</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="grossist">Grossister</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="gln">GLN</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="kjede">Kjede</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="grupper">Grupper</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="adresser">Adresser</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="leverandorKundenummer">Leverandører</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bevillinger">Bevillinger</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="historikk">Historikk</Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content className={Style.tabContent}>
            <Tab.Pane eventKey="basis">
              <div className={Style.tabPane}>
                <Basis
                  linkedForm={props.linkedForm}
                  enhet={props.enhet}
                  onUpdated={handleEnhetUpdated}
                  kontonrOgFakturaAdresseSjekkResponse={props.kontonrOgFakturaAdresseSjekkResponse}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="grossist">
              <div className={Style.tabPane}>
                <Grossist
                  enhetId={props.enhet.id}
                  grossists={props.enhet.grossister}
                  onUpdated={handleEnhetUpdated}
                  enhetStatestikk={props.enhetStatestikk}
                  grossistKunder={props.grossistKunder}
                  fakturalinjer={props.fakturalinjer}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="gln">
              <div className={Style.tabPane}>
                <Gln
                  gln={props.enhet.firma.gln}
                  enhet={props.enhet}
                  onUpdated={handleEnhetUpdated}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="kjede">
              <div className={Style.tabPane}>
                <Kjede enhet={props.enhet} onUpdated={handleEnhetUpdated} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="grupper">
              <div className={Style.tabPane}>
                <Gruppering enhet={props.enhet} onUpdated={handleEnhetUpdated} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="adresser">
              <div className={Style.tabPane}>
                <AdresserV2
                  enhet={props.enhet}
                  onEnhetUpdated={handleEnhetUpdated}
                  kontonrOgFakturaAdresseSjekkResponse={props.kontonrOgFakturaAdresseSjekkResponse}
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="leverandorKundenummer">
              <div className={Style.tabPane}>
                <LeverandorKundenummer enhet={props.enhet} onUpdated={handleEnhetUpdated}></LeverandorKundenummer>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="bevillinger">
              <div className={Style.tabPane}>
                <Bevillinger enhet={props.enhet} onUpdated={handleEnhetUpdated} />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="historikk">
              <div className={Style.tabPane}>
                <EnhetHistory enhet={props.enhet} />
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default EnhetEdit;
