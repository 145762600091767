import { useCallback, useEffect, useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import MarkedsKjedePicker from 'components/MarkedsKjedePicker';
import DatePicker from 'components/DatePicker';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import Style from './index.module.css';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { AddMedlemKjedeCommand, Enhetgruppering } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import { PickedKjede, PickerMode } from 'components/KjedePicker';

interface Props {
  setIsEdit: (isEdit: boolean) => void;
  gruppering: Enhetgruppering;
  onDone: () => void;
}

const MedlemskapEdit = (props: Props) => {
  const [changeDate, setChangeDate] = useState(new Date());
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [selectedKjedeID, setSelectedKjedeID] = useState<number>();
  const toaster = useToaster();
  const isMountedRef = useIsMountedRef();

  const addMedlemKjede = async () => {
    const command: AddMedlemKjedeCommand = {
      kjedeID: selectedKjedeID,
      fraOgMedDato: changeDate,
    };

    try {
      await EnhetsgrupperingApi.addMedlemskjede(props.gruppering.enhetgrupperingID, command);
      toaster.success('Suksess!', 'Medlemskjede lagt til!');
      props.onDone();
      props.setIsEdit(false);
    } catch (err) {
      toaster.error('Feil! Kunne ikke legge til.', HttpErrorHelper.formatError(err));
    }
  };

  const handleKjedeSelected = useCallback((kjeder: PickedKjede[]) => {
    setSelectedKjedeID(kjeder && (kjeder.length > 0) ? kjeder[0].id : undefined)
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      if (!selectedKjedeID) {
        setIsSubmitDisabled(true);
      } else {
        setIsSubmitDisabled(false);
      }
    }
  }, [selectedKjedeID]);

  return (
    <div className={Style.container}>
      <Row>
        <Col md={6} lg={12} xl={6}>
          <Form.Label>Velg kjedemedlem</Form.Label>
          <MarkedsKjedePicker
            pickerMode={PickerMode.Single}
            onSelectionChanged={handleKjedeSelected}
          />
        </Col>
        <Col md={6} lg={12} xl={6}>
          <Form.Label>Innmeldingsdato</Form.Label>
          <DatePicker selected={changeDate} onChange={d => setChangeDate(d)} adjustTimeForTimezoneOffset={true} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button disabled={isSubmitDisabled} className={Style.submitBtn} onClick={() => addMedlemKjede()}>
            Legg til
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MedlemskapEdit;
