import axios from 'axios';
import { AddBransjeCommand, UpdateBransjeCommand, Bransje, BransjeTree} from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/bransjer';

const search = action =>
  axios
    .get(
      `${baseUrl}/search?query=${action.query
      }&bareBransjerSomKanKoblesTilEnhet=${action.bareBransjerSomKanKoblesTilEnhet || false}`,
    )
    .then(response => response.data);

async function getById(id: number): Promise<Bransje> {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
}

async function getByKode(kode: string): Promise<Bransje> {
  const response = await axios.get(`${baseUrl}/bykode/${kode}`);
  return response.data;
}

async function del(id: number) {
  await axios.delete(`${baseUrl}/${id}`);
}

async function updateBransje(command: UpdateBransjeCommand): Promise<Bransje> {
  const response = await axios.put(`${baseUrl}/${command.id}`, command);
  return response.data;
}

async function addBransje(command: AddBransjeCommand): Promise<Bransje> {
  const response = await axios.post(`${baseUrl}`, command);
  return response.data;
}

async function leggnedBransje(id: number): Promise<Bransje> {
  const response = await axios.post(`${baseUrl}/${id}/leggned`);
  return response.data;
}

async function getTree(): Promise<BransjeTree[]> {
  const response = await axios.get(`${baseUrl}/tree`);
  return response.data;
}

async function getAntallaktivemedlemmerforbransjeplussunderbransjer(id: number): Promise<number> {
  const response = await axios.get(`${baseUrl}/${id}/antallaktivemedlemmerforbransjeplussunderbransjer`);
  return response.data;
}

export default {
  getTree,
  search,
  getById,
  getByKode,
  del,
  updateBransje,
  addBransje,
  leggnedBransje,
  getAntallaktivemedlemmerforbransjeplussunderbransjer,
};
