import React from 'react';
import { Button } from 'react-bootstrap';

import IconPopOver from 'components/IconPopOver';
import EnhetTypeahead from 'components/EnhetTypeahead';

import { PickedEnhet } from 'components/EnhetTypeahead/types';

import openInNewTab from 'utils/functions/openInNewTab';
import useToaster from 'utils/hooks/useToaster';

import Style from './index.module.css';

interface Props {
  onChange: (enhet: PickedEnhet) => void;
  enhetID: number;
  lopenummer: string;
  bruktEnhet: boolean;
  erPublisert?: boolean;
  initialEnhetIsNotAktiv?: boolean;
}

const QAEnhetSearch: React.FC<Props> = props => {
  const toaster = useToaster();

  const handleEierskifteClicked = () => {
    toaster.info('Ikke implementert.', 'Funksjonalitet for knapp finnes ikke enda.');
  };

  return (
    <div className={Style.enhetSearchWrapper}>
      <div className={Style.possibleEnhetContainer}>
        <span style={{ fontSize: '.875rem' }}>
          <strong>{props.bruktEnhet ? 'Brukt enhet:' : 'Foreslått enhet:'} </strong>
        </span>

        {props.enhetID && props.lopenummer && (
          <>
            <Button className={Style.styleLopenummer} variant="link" size="sm" onClick={() => openInNewTab(`/enheter/${props.enhetID}`)}>
              {props.lopenummer}
            </Button>
            <span style={{ marginLeft: '0.25rem' }}>
              (
              <strong style={!props.erPublisert ? { color: 'red' } : undefined}>
                {props.erPublisert ? 'Publisert' : 'Ikke publisert'}
              </strong>
              )
            </span>
          </>
        )}

        {props.lopenummer && (
          <IconPopOver toggleIcon="ellipsis-horizontal" menuTitle="Enhetvalg snarvei">
            <Button variant="secondary" onClick={handleEierskifteClicked} size="sm">
              Eierskifte
            </Button>
          </IconPopOver>
        )}
      </div>

      <EnhetTypeahead size="sm" showFilters
        selectedLopenummer={props.lopenummer}
        initialEnhetIsNotAktiv={props.initialEnhetIsNotAktiv}
        onChange={props.onChange}
      />
    </div>
  );
};

export default QAEnhetSearch;
