import axios from 'axios';

import { OverliggendeKjede, AddOverliggendeKjedeCommand, LeggNedKjedeCommand } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/kjedeknytninger';

async function getOverliggende(id: number): Promise<OverliggendeKjede[]>{
  const response = await axios.get(`${baseUrl}/${id}/overliggende`);
  return response.data;
}

async function getUnderliggende(id: number){
 const response = await axios.get(`${baseUrl}/${id}/underliggende`);
 return response.data;
}

async function addOverliggende(id: number, command: AddOverliggendeKjedeCommand){
  const response = await axios.post(`${baseUrl}/${id}/overliggende`, command);
  return response.data; 
}

async function leggNedKjede(id: number, command: LeggNedKjedeCommand){
  const response = await axios.post(`${baseUrl}/${id}/avsluttmedlemskap`, command);
  return response.data;
}

const KjedeknytningerApi = {
  getOverliggende,
  getUnderliggende,
  addOverliggende,
  leggNedKjede
}

export default KjedeknytningerApi;
