import { IconButton } from '@tradesolution/iceberg-ui-react';
import CommonLoader from 'components/CommonLoader';
import { useState } from 'react';
import { ButtonProps } from 'react-bootstrap';

interface Props extends ButtonProps{  
  customtext?: string;
  style?: React.CSSProperties;
}

const ConfirmButton = (props: Props) => {
  const [timesPressed, setTimesPressed] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dialog = [props.customtext || 'Fjern', 'Er du sikker?', props.customtext || 'Fjerner'];

  const onPress = async (e: any) => {
    let count = timesPressed + 1;
    setTimesPressed(count);
    if (count === 2) {
      setIsLoading(true);
      setIsButtonDisabled(true);
      await props.onClick(e);
      setIsLoading(false);
    }
  };

  return (
    <IconButton
      icon='trash'
      {...props} 
      style={props.style}
      disabled={isLoading || props.disabled || isButtonDisabled}      
      onClick={e => onPress(e)}
      variant= {props.variant || "outline-danger"}>
      {dialog[timesPressed]}
      {isLoading && (
        <span className='ms-2'>
          <CommonLoader />
        </span>
      )}
    </IconButton>
  );
};

export default ConfirmButton;
