import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import Style from './index.module.css';

export interface SortOption {
  label: string;
  value: string;
  icon: 'check' | 'question';
  color: 'orange' | 'green';
}

interface Props {
  options: SortOption[];
  onChange: (toggled: string[]) => void;
}

const ToggleSortByTag = (props: Props) => {
  const [toggledValues, setToggledValues] = useState<string[]>([]);

  const isMountedRef = useIsMountedRef();

  useEffect(() => {
    if (isMountedRef.current) {
      props.onChange(toggledValues)
    }
  }, [toggledValues, isMountedRef]);

  return (
    <div className={Style.sortByTagContainer}>
      {props.options?.map((option, idx) => (
        <Button
          className={Style.customToggleBtn}
          key={idx}
          size="sm"
          variant={(toggledValues.find(x => x === option.value)) ? 'primary' : 'outline-primary'}
          name="custom-toggle-button"
          value={option.value}
          onClick={() => {
            const copy = [...toggledValues];
            if (copy.filter(x => x === option.value).length > 0) {
              const filtered = copy.filter(y => y !== option.value);
              setToggledValues([...filtered]);
            } else {
              setToggledValues([...toggledValues, option.value]);
            }
          }}
        >
          <IcebergIcon icon={option.icon} color="#fff" backgroundColor={option.color} size={"12px"} rounded bold />
          <span className={Style.labelTxt}>{option.label}</span>
        </Button>
      ))}
    </div>
  );
}

export default ToggleSortByTag;