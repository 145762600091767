import { useState, useEffect } from 'react';
import { InputGroup, Form, Button, OverlayTrigger } from 'react-bootstrap';

import BrregPopover from 'components/BrregPopover';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { BrregEnhetResponse } from 'services/KjederegisteretAdminApi/BrregApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';
import BrregTypeAhead from 'components/BrregTypeAhead';
export interface BrRegInfo {
  name: string
  orgNr: string
  isBedriftsNr?: boolean
}

interface Props {
  type: string;
  selectedJuridisk: BrRegInfo | undefined;
  clearTypeaheadInitiator: string | number;
  id: string;
  onSelectedChanged: (juridisk: BrRegInfo | undefined) => void;
  onUpdateForm: (type: string, name: string, orgNr: string) => void;
  error?: string;
}

const BrregInput = (props: Props) => {
  const isMountedRef = useIsMountedRef();
  const [isLoadingBrreg, setIsLoadingBrreg] = useState<boolean>(false);
  const [showNotFoundWarning, setShowNotFoundWarning] = useState<boolean>(false);
  const [brregInfo, setBrregInfo] = useState<BrregEnhetResponse | undefined>(undefined);

  const handleBrregSelectedChanged = (e: BrregEnhetResponse) => {
    if (e) {
      if (e.name && e.orgNr) {
        setBrregInfo(e);
        props.onUpdateForm(props.type, e.name, e.orgNr);
        const selected: BrRegInfo = {
          name: e.name.toUpperCase(),
          orgNr: e.orgNr,
          isBedriftsNr: !!e.overordnetEnhet // om orgnr har et overordnet orgnr så er dette et bedriftsnr
        };
        if (e.name !== props.selectedJuridisk?.name && e.orgNr !== props.selectedJuridisk?.orgNr) {
          props.onSelectedChanged(selected);
        }
      }
    } else {
      props.onSelectedChanged(undefined);
      setBrregInfo(undefined);
      props.onUpdateForm(props.type, null, null);
    }
  }

  useEffect(() => {
    if (isMountedRef.current && brregInfo && (brregInfo.finnesIkke || brregInfo.slettedato) && props.selectedJuridisk && !isLoadingBrreg) {
      setShowNotFoundWarning(true);
    } else {
      setShowNotFoundWarning(false);
    }
  }, [brregInfo, props.selectedJuridisk?.orgNr, isLoadingBrreg]);

  return (
    <>
      <Form.Label className={Style.juridiskFormLabel}>{props.type === 'bedriftsnr' ? 'Juridisk navn og bedriftsnummer' : 'Juridisk navn og organisasjonsnummer'}</Form.Label>
      <InputGroup id={props.id}>
        {!brregInfo ? (
          <InputGroup.Text style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
            <IcebergIcon icon="search" />
          </InputGroup.Text>
        ) : (
          <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={BrregPopover(brregInfo)}>
            {brregInfo.slettedato || brregInfo.finnesIkke ? (
              <Button variant="warning" id="button-addon1" style={{ paddingLeft: '.4rem', paddingRight: '.4rem' }}>
                <IcebergIcon cursor="pointer" icon="check" size="lg" bold />
              </Button>
            ) : (
              <Button variant="success" id="button-addon1" style={{ paddingLeft: '.4rem', paddingRight: '.4rem' }}>
                <IcebergIcon cursor="pointer" icon="check" size="lg" bold />
              </Button>
            )}
          </OverlayTrigger>
        )}
        <BrregTypeAhead
          selected={props.selectedJuridisk}
          clearTypeaheadInitiator={props.clearTypeaheadInitiator}
          onBrregEnhetSelected={e => handleBrregSelectedChanged(e)}
          setIsLoadingBrreg={setIsLoadingBrreg}
        />
      </InputGroup>
      {showNotFoundWarning && (<Form.Text className={Style.brregWarningFormText}>
        <span>Ingen funn på eksisterende juridisk navn og organisasjonsnummer kombinasjon. </span>
      </Form.Text>)}
      {props.error &&
        <Form.Text className={Style.brregErrorFormText}>
          {props.error}
        </Form.Text>}
    </>
  )
}

export default BrregInput;
