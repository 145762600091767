import { AppIconName, IcebergIcon, IconName, TjenestegruppeIconName, useLocalStorage } from "@tradesolution/iceberg-ui-react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";

export interface SubLink {
    label: string;
    link: string;
    icon?: IconName | TjenestegruppeIconName | AppIconName;
}

export interface NavLink {
    label: string;
    link: string;
    icon?: IconName | TjenestegruppeIconName | AppIconName;
    roles?: string[]; // which roles are allowed to see this link
    subLinks?: SubLink[];
}

export interface SidebarProps {
    navLinks: NavLink[];
}

const Sidebar = ({ navLinks }: SidebarProps) => {

    const location = useLocation();
    const [sidebarExpanded, setSidebarExpanded] = useLocalStorage<boolean>("sidebar-expanded", true);

    const sidebarWidth = sidebarExpanded ? "250px" : "55px";

    return (
        <div className="iceberg-navbar-left d-flex flex-column flex-shrink-0" style={{ width: sidebarWidth, position: "sticky", top: "58px" }}>

            <ul className="nav flex-column nav-pills mb-auto">
                <a className="nav-link" role="button" onClick={() => setSidebarExpanded(!sidebarExpanded)} style={{ whiteSpace: "nowrap" }}>
                    <span style={{ marginRight: "1rem" }}>{sidebarExpanded ? <IcebergIcon icon="chevron-left" /> : <IcebergIcon icon="chevron-right" />}</span> skjul
                </a>
                {navLinks.map(l => (<li key={l.link}>
                    <Nav.Link as={Link} to={l.link} title={l.label} className={l.link === location.pathname ? "active": ""} >
                        <span style={{ marginRight: "1rem" }}><IcebergIcon icon={l.icon as any} /></span> <span>{l.label}</span>
                    </Nav.Link>
                </li>))}
            </ul>
            <ul className="nav flex-column nav-pills">
                <li>
                    <a className="nav-link" href="http://tradesolution.no" style={{ marginBottom: "auto" }}>
                        <IcebergIcon icon="startsiden" color="#003b6e" /></a>
                </li>
            </ul>

        </div>
    );
}

export default Sidebar;