import axios from 'axios';
import { ImportData } from './types';

import { getImportApiUrl } from 'services';
import { getImportUrlWithAccessKey } from 'services/KjederegisteretAdminApi/AzureFunctions';

const baseUrl = getImportApiUrl();

async function get(day: string): Promise<ImportData[]> {
   const importApiUrl = await getImportUrlWithAccessKey(baseUrl, 'imports')
   const response = await axios.get(`${importApiUrl}&day=${day}`);
   return response.data;
}

const ImportApi = {
   get
}

export default ImportApi;