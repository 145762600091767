import { useState } from 'react';
import { Form, Row, Col, ListGroup, ListGroupItem, InputGroup } from 'react-bootstrap';
import SpinnerButton from 'components/SpinnerButton';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import useForm from 'utils/hooks/useForm';
import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import { Grossist, GrossistEnhet } from 'services/KjederegisteretAdminApi/GrossisterApi/types';

import useToaster from 'utils/hooks/useToaster';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import HttpErrorHelper from 'utils/HttpErrorHelper';

import GrossistEnhetSearch from './GrossistEnhetSearch';
import Style from './index.module.css';

interface Props {
  setCreatedGrossist: (grossist: Grossist) => void;
  setIsAddGrossist: (isAddGrossist: boolean) => void;
}

const AddGrossist: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();
  const toaster = useToaster();
  const [enhetFromSearch, setEnhetFromSearch] = useState<GrossistEnhet>();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const defaultValues: Grossist = {
    grossistEnhetId: 0,
    grossistnr: 0,
    kortnavn: '',
    gln: 0,
    navn: '',
    lopenummer: 0
  };

  const validate = (values: Grossist) => {
    enum ErrorMessage {
      NoGrossistEnhetId = 'Enhet mangler',
      NoKortnavn = 'Kortnavn mangler',
    }

    const errors: any = {};
    if (!values.grossistEnhetId) errors.grossistEnhetId = ErrorMessage.NoGrossistEnhetId;
    if (!values.kortnavn) errors.kortnavn = ErrorMessage.NoKortnavn;
    return errors;
  };

  const onSubmit = async (v: Grossist) => {
    try {
      if (isMountedRef.current) {
        setSubmitLoading(true);
        const res = await GrossisterApi.addGrossist({
          kortnavn: v.kortnavn.toUpperCase(),
          grossistEnhetId: v.grossistEnhetId,
          grossistgruppe: v.grossistgruppe,
        });

        props.setCreatedGrossist(res);
      }
      resetForm(defaultValues);
      toaster.success('Suksess!', 'Grossist er opprettet.');
      props.setIsAddGrossist(false);
    } catch (err) {
      toaster.error('Feil! Kunne ikke opprette.', HttpErrorHelper.formatError(err));
    }
    if (isMountedRef.current) {
      setSubmitLoading(false);
    }
  };

  const {
    touched,
    errors,
    handleChange,
    handleSubmit,
    resetForm,
    values,
  } = useForm(defaultValues, validate, onSubmit);

  const handleEnhetSelected = (enhet: GrossistEnhet) => {
    if (enhet) {
      if (isMountedRef.current) {
        setEnhetFromSearch(enhet);
      }
      handleChange('grossistEnhetId', enhet.enhetId);
    } else {
      handleChange('grossistEnhetId', '');
      if (isMountedRef.current) {
        setEnhetFromSearch(null);
      }
    }
  };

  return (
    <div className={Style.nyGrossistContainer}>
      <Row>
        <Col>
          <h5>Bruk eksisterende enhet som hovedgrossist</h5>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Form.Group as={Col} md="6" controlId="enhetTypeahead">
            <Form.Label className={Style.formLabel}>Velg enhet fra kjederegisteret *</Form.Label>
            <InputGroup>
              <InputGroup.Text style={{ paddingLeft: '.6rem', paddingRight: '.6rem' }}>
                <IcebergIcon icon="search" />
              </InputGroup.Text>

              <GrossistEnhetSearch
                required
                isValid={touched.grossistEnhetId && !errors.grossistEnhetId ? true : false}
                isInvalid={touched.grossistEnhetId && !!errors.grossistEnhetId ? true : false}
                onChange={e => handleEnhetSelected(e)}
              />
              {touched.grossistEnhetId && errors.grossistEnhetId && (
                <Form.Text className="invalid-feedback" style={{ display: 'block' }}>
                  {errors.grossistEnhetId}
                </Form.Text>
              )}
            </InputGroup>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="kortnavnGrossist">
            <Form.Label className={Style.formLabel}>Kortnavn *</Form.Label>
            <Form.Control
              type="text"
              placeholder="Kortnavn"
              aria-describedby="inputGroup"
              value={values.kortnavn}
              onChange={e => handleChange('kortnavn', e.target.value)}
              isInvalid={touched.kortnavn && errors.kortnavn ? true : false}
              isValid={touched.kortnavn && !errors.kortnavn ? true : false}
              required />
            <Form.Control.Feedback type="invalid">{errors.kortnavn}</Form.Control.Feedback>
          </Form.Group>
        </Row>

        {enhetFromSearch && (
          <Row className={Style.valgtEnhetFromSearchListGroupRow}>
            <Col>
              <h6>Valgt enhet</h6>
              <ListGroup>
                <ListGroupItem>
                  Enhetnavn: <strong>{enhetFromSearch.enhetnavn || ''}</strong>
                </ListGroupItem>
                <ListGroupItem>
                  Organisasjonsnummer: <strong>{enhetFromSearch.organisasjonsnr || ''}</strong>
                </ListGroupItem>
                <ListGroupItem>
                  Løpenummer: <strong>{enhetFromSearch.lopenummer || ''}</strong>
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        )}
        <Row className={Style.opprettBtnRow}>
          <SpinnerButton
            type="submit"
            loading={submitLoading}
            btntxt="Opprett" />
        </Row>
      </Form>
    </div>
  );
}

export default AddGrossist;
