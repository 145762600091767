import { useEffect, useState } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { Grossist } from "services/KjederegisteretAdminApi/ChangeRequestApi/types";
import SalgsdataInfoApi from 'services/SalgsdatainfoApi';
import { Fakturalinje } from 'services/SalgsdatainfoApi/types';

interface Props {
  show?: boolean;
  handleClose: () => void;
  grossist?: Grossist;
}

const FakturaLinjeModal = (props: Props) => {
    const [fakturalinjer, setFakturalinjer] = useState<Fakturalinje[] | undefined>();
    
    
    useEffect(() => {
        const loadFakturalinjer = async () => {            
            if(props.grossist?.kundenummerHosGrossist && props.grossist?.grossistGln){
                const result = await SalgsdataInfoApi.getFakturalinjerByGrossist(props.grossist.grossistGln, props.grossist.kundenummerHosGrossist);
                setFakturalinjer(result);
            }            
        };
        loadFakturalinjer().catch(console.error);     
    }, []);
  
  return (
    <>
      <Modal show={props.show} onHide={props.handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>100 siste fakturalinjer</Modal.Title>
        </Modal.Header>        
          <Modal.Body>
            {!fakturalinjer && (<span>Laster fakturalinjer...</span>)}
            {fakturalinjer?.length === 0 && (<span>Ingen linjer</span>)}            
            {fakturalinjer?.length > 0 && (
                  <div className="margin-bottom-1rem">
                    <Table striped responsive>
                      <thead>
                        <tr>
                          <th>Varenavn</th>
                          <th>Antall</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fakturalinjer.map(
                          (item, index) => <tr key={index}>
                            <td>{item.varenavn}</td>
                            <td>{item.antall}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
          </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Lukk
          </Button>          
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FakturaLinjeModal;
