
/*IMPORTS */

/*React & React module dependencies */

import { useState, useEffect } from 'react';
import { Row, Col, InputGroup, ListGroup, FormControl, Button, Table, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

/*Shared Components */
import PageWrapper from 'components/PageWrapper';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';

/* hooks */
import useIsMountedRef from '../../utils/hooks/useIsMountedRef';

/*Services */
import { Abonnement, AbonnementQuery } from '../../services/KjederegisteretAdminApi/AbonnementApi/types';
import AbonnementApi from '../../services/KjederegisteretAdminApi/AbonnementApi';

/*Styling */
import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

import EditModal from './components/EditModal';
import useToaster from 'utils/hooks/useToaster';
import CreateModal from './components/CreateModal';


/*IMPORTS END */

const AbonnementPage = () => {

  const toaster = useToaster();

  /*useState properties */
  const [query, setQuery] = useState<string>('');
  const [selectedAbonnement, setSelectedAbonnement] = useState<Abonnement>(null);
  const [displayCreateModal, setDisplayCreateModal] = useState<boolean>(false);
  const [abonnementList, setAbonnementList] = useState<Abonnement[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  /*FUNCTIONs */
  const loadAbonnementWithSpinner = async () => {
    setIsLoading(true);
    const result = await AbonnementApi.getAbonnement(new AbonnementQuery());
    setIsLoading(false);
    if (isMountedRef.current) {
      setAbonnementList(result);
    }
  }

  const loadAbonnementWithoutSpinner = async (id?: number) => {
    const result = await AbonnementApi.getAbonnement(new AbonnementQuery());
    if (isMountedRef.current) {
      setAbonnementList(result);
      if (id) {
        setSelectedAbonnement(result.find(x => x.id === id));
      }
    }
  };

  const handleOpprettClicked = () => {
    setDisplayCreateModal(true);
    setSelectedAbonnement(undefined);
  }

  const handleSelected = (abb: Abonnement) => {
    if (isMountedRef.current) {
      setSelectedAbonnement(abb);
    }
  };

  const handleAbonnementDeleted = async () => {
    setSelectedAbonnement(null);
    await loadAbonnementWithoutSpinner();
  };

  /*useEffect */
  // on initial pageload we fetch the list of abonnement
  useEffect(() => {
    loadAbonnementWithSpinner();
  }, []);

  /* Content */

  const handleCloseEditModal = () => {
    setSelectedAbonnement(undefined);
    loadAbonnementWithSpinner();
  }

  const handleCloseCreateModal = () => {
    setDisplayCreateModal(false);
    loadAbonnementWithSpinner();
  }

  const handleDeleteAbonnement = async (id: number) => {
    const result = await AbonnementApi.deleteAbonnement(id);
    if (result.success) {
      toaster.success("Suksess", "Abonnement slettet!");
    } else {
      toaster.error("En feil oppsto", result.error);
    }
    loadAbonnementWithSpinner();
  }

  return (
    <PageWrapper isFluid header="Abonnement">
      <Row>
        <Col md={5}>
          <div style={{ display: 'flex', justifyContent: 'flex-start', paddingTop: '1em' }}>
            <InputGroup className='mb-3'>
              <FormControl
                value={query}
                placeholder="Søk her.."
                disabled={isLoading}
                style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
                autoFocus
                onChange={e => setQuery(e.target.value)}
              />
              <InputGroup.Text style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
                {
                  query.length > 0 ?
                    <IcebergIcon icon='close' cursor='pointer'
                      onClick={() => setQuery('')} />
                    : <IcebergIcon icon='search' />
                }
              </InputGroup.Text>
            </InputGroup>
          </div>
        </Col>
        <Col>
          <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '1em' }}>
            <IconButton
              icon='plus'
              variant="primary"
              onClick={() => handleOpprettClicked()}>
              Nytt abonnement
            </IconButton>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {isLoading ? (
            <div className={Style.isLoadingContainer}>
              <CommonLoader />
              <h6>Laster abonnementer...</h6>
            </div>
          ) : (
            <Table hover>
              <thead>
                <tr>
                  <th>
                    Navn
                  </th>
                  <th style={{ textAlign: "center" }}>Antall mottakere</th>
                  <th>Filter</th>
                  <th className='fitContent'></th>
                  <th className='fitContent'></th>
                </tr>
              </thead>
              <tbody>
                {abonnementList
                  .filter(o => o.name.toLowerCase()
                    .includes(query.toLowerCase()))
                  .map((x: Abonnement) => (<tr key={x.id}>
                    <td>{x.name}</td>
                    <td style={{ textAlign: "center" }}>
                    <OverlayTrigger 
                        delay={{ hide: 450, show: 300 }}
                        placement="auto" 
                        overlay={(props) => ( 
                          <Tooltip {...props}> 
                            {x.mottakere.filter(x => !x.tilOgMedDato).map(m => 
                              <div>{m.name}</div>
                              )} 
                          </Tooltip> 
                        )} 
                      ><span>{x.mottakere.filter(x => !x.tilOgMedDato).length}</span> 
                    </OverlayTrigger>
                    </td>
                    <td>
                      <span>
                        {
                          x.bransjer?.length > 0 &&
                          <span className={Style.desc}>{x.bransjer.length} {x.bransjer.length > 1 ? 'Bransjer' : 'Bransje'}</span>
                        }
                        {
                          x.kjeder?.length > 0 &&
                          <span className={Style.desc}>{x.bransjer?.length > 0 && ','} {x.kjeder.length} {x.kjeder.length > 1 ? 'Kjeder' : 'Kjede'}</span>
                        }
                      </span>
                    </td>
                    <td className='fitContent'>
                      <IconButton icon='edit' variant='outline-primary' onClick={() => handleSelected(x)}>Endre</IconButton>
                    </td>
                    <td className='fitContent'>
                      <Dropdown className="noCaret">
                        <Dropdown.Toggle style={{ padding: "0.3rem" }} variant="none" id="dropdown-basic">
                          <IcebergIcon icon="ellipsis" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleDeleteAbonnement(x.id)}>Slett</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      {
        selectedAbonnement &&
        <EditModal
          abonnement={selectedAbonnement}
          onClose={handleCloseEditModal}
        />
      }
      {
        displayCreateModal &&
        <CreateModal
          onClose={handleCloseCreateModal}
        />
      }
    </PageWrapper>
  );
}

export default AbonnementPage;
