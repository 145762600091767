import React, { useEffect, useState, useCallback, ReactNode } from 'react';
import Toaster from 'components/Toaster';
import { Toast } from './types';

interface IToasterContext {
  info: (title: string, msg: string) => void;
  success: (title: string, msg: string) => void;
  error: (title: string, msg: ReactNode | { key: string; errors: string[] }[]) => void;
}

export const ToastContext = React.createContext<IToasterContext>({
  info: () => { },
  success: () => { },
  error: () => { },
});

interface Props {
  children?: React.ReactNode;
}

const ToastProvider = (props: Props) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const info = (title: string, msg: string) => {
    const infoToast: Toast = {
      type: 'info',
      title: title,
      message: msg,
      dismissible: true,
      timed: true,
    };
    setToasts((toasts) => [...toasts, infoToast]);
  };

  const success = (title: string, msg: string) => {
    const successToast: Toast = {
      type: 'success',
      title: title,
      message: msg,
      dismissible: true,
      timed: true,
    };
    setToasts((toasts) => [...toasts, successToast]);
  };

  const error = (title: string, msg: ReactNode) => {
    const dangerToast: Toast = {
      type: 'danger',
      title: title,
      message: msg,
      dismissible: true,
      timed: false,
    };
    setToasts((toasts) => [...toasts, dangerToast]);
  };

  const handleCloseToast = (index: number) => {
    if (index > -1) {
      toasts.splice(index, 1);
    }
    setToasts([...toasts]);
  };

  const findTimedToasts = useCallback(() => toasts.filter((o) => o.timed), [toasts]);
  const findOnlyDismissibleToasts = useCallback(
    () => toasts.filter((o) => o.dismissible && !o.timed),
    [toasts],
  );

  useEffect(() => {
    const timedToasts = findTimedToasts();
    const dismissibleToasts = findOnlyDismissibleToasts();
    const timer = setTimeout(() => {
      if (timedToasts.length) {
        timedToasts.shift();
        setToasts(timedToasts);
      }
      if (dismissibleToasts.length) {
        let merged = timedToasts.concat(dismissibleToasts);
        setToasts(merged);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [toasts, findTimedToasts, findOnlyDismissibleToasts]);

  return (
    <ToastContext.Provider value={{ info, error, success }}>
      <Toaster toasts={toasts} handleCloseToast={handleCloseToast} />
      {props.children}
    </ToastContext.Provider>
  );
};
export default ToastProvider;
