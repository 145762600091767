import { useEffect, useState } from 'react';
import { Button, Row, Col, Form, Tab, Tabs } from 'react-bootstrap';

import _ from 'lodash';

import KjedeEdit from './KjedeEdit';
import KjedeCreate from './KjedeCreate';

import SortableTree from 'components/SortableTree';
import { KjedeTreNode } from 'components/KjedePicker';
import PageWrapper from 'components/PageWrapper';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';

import KjedeApi from 'services/KjederegisteretAdminApi/KjedeApi';
import { KjedeDto, GetKjedeByIdResponse } from 'services/KjederegisteretAdminApi/KjedeApi/types';

import useToaster from 'utils/hooks/useToaster';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

const Kjeder = () => {
    const [includeDisbanded, setIncludeDisbanded] = useState<boolean>(false);
    const [regionalkjeder, setRegionalkjeder] = useState<KjedeTreNode[]>([]);
    const [markedskjeder, setMarkedskjeder] = useState<KjedeTreNode[]>([]);

    const [selectedKjede, setSelectedKjede] = useState<GetKjedeByIdResponse | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [tabKeyContext, setTabKeyContext] = useState<string>('Regional inndeling');
    const [filteredRegionalData, setFilteredRegionalData] = useState([]);
    const [filteredMarkedsData, setFilteredMarkedsData] = useState([]);
    const [showNew, setShowNew] = useState<boolean>(false);

    const toaster = useToaster();
    const isMountedRef = useIsMountedRef();

    const loadKjeder = () => {
        if (isMountedRef.current) {
            setIsLoading(true);
            Promise.all([
                KjedeApi.getRegionTre({ includeDisbanded: true }),
                KjedeApi.getMarkedsTre({
                    includeDisbanded: true,
                }),
            ])
                .then(results => {
                    setIsLoading(false);
                    setRegionalkjeder(results[0]);
                    setMarkedskjeder(results[1]);
                })
                .catch(err => toaster.error('Noe gikk galt.', `Kunne ikke laste kjeder: ${err}`));
        }
    };

    const filterActive = (all: any[]) => {
        return all.filter(function f(o) {
            if (o.isInactive) return false;
            if (o.children) {
                o.children = o.children.filter(f);
            }
            return true;
        });
    };

    const toggleIncludeDisbanded = () => {
        setIncludeDisbanded(!includeDisbanded);
    };

    const onSelectKjede = async (kjedId: number) => {
        const k = await KjedeApi.getKjedeById({
            id: kjedId,
            kjedeType: tabKeyContext,
        });
        setSelectedKjede(k);
        setShowNew(false);
    };

    const showNewClicked = () => {
        if (isMountedRef.current) {
            setSelectedKjede(undefined);
            setShowNew(!showNew);
        }
    };

    const handleKjedeCreated = async (createdKjede: KjedeDto) => {
        setShowNew(false);
        loadKjeder();
        const k = await KjedeApi.getKjedeById({
            id: createdKjede.kjedeID,
            kjedeType: tabKeyContext,
        });
        if (isMountedRef.current) {
            setSelectedKjede(k);
        }
    };

    const handleKjedeSlettet = async () => {
        loadKjeder();
        if (isMountedRef.current) {
            setSelectedKjede(undefined);
            setShowNew(false);
        }
    };

    const handleKjedeLagtNed = async () => {
        loadKjeder();
        if (isMountedRef.current) {
            setSelectedKjede(undefined);
            setShowNew(false);
        }
    };

    const handleMedlemskapUpdated = async (kjedeId: number) => {
        loadKjeder();
        setShowNew(false);
        if (kjedeId) {
            onSelectKjede(kjedeId);
        }
    };

    useEffect(() => {
        let tmpRegional = _.cloneDeep(regionalkjeder);
        let tmpMarked = _.cloneDeep(markedskjeder);

        setFilteredRegionalData(!includeDisbanded ? filterActive(tmpRegional) : regionalkjeder);
        setFilteredMarkedsData(!includeDisbanded ? filterActive(tmpMarked) : markedskjeder);
    }, [regionalkjeder, markedskjeder, includeDisbanded]);

    useEffect(() => {
        loadKjeder();
    }, []);

    return (
        <PageWrapper isFluid header="Administrasjon av Kjeder">
            <Row>
                <Col>
                    <Tabs id="controlled-tab-kjeder" activeKey={tabKeyContext} onSelect={(k: string) => setTabKeyContext(k)}>
                        <Tab className={Style.tabContainer} eventKey="Regional inndeling" title="Regionalkjeder">
                            {isLoading && filteredRegionalData ? (
                                <div className={Style.isLoadingContainer}>
                                    <CommonLoader />
                                    <h6>Laster inn kjeder...</h6>
                                </div>
                            ) : (
                                <SortableTree
                                    isSearchable
                                    data={filteredRegionalData}
                                    onNodeClick={(k: any) => onSelectKjede(k.id)}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check
                                            type="checkbox"
                                            label="Inkluder nedlagte"
                                            checked={includeDisbanded}
                                            onChange={toggleIncludeDisbanded}
                                        />
                                    </Form.Group>
                                </SortableTree>
                            )}
                        </Tab>
                        <Tab className={Style.tabContainer} eventKey="Salg/ Markedsføring" title="Markedskjeder">
                            {isLoading && filteredMarkedsData ? (
                                <div className={Style.isLoadingContainer}>
                                    <CommonLoader />
                                    <h6>Laster inn kjeder...</h6>
                                </div>
                            ) : (
                                <SortableTree
                                    isSearchable
                                    data={filteredMarkedsData}
                                    onNodeClick={(k: any) => onSelectKjede(k.id)}>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <Form.Check
                                            type="checkbox"
                                            label="Inkluder nedlagte"
                                            checked={includeDisbanded}
                                            onChange={toggleIncludeDisbanded}
                                        />
                                    </Form.Group>
                                </SortableTree>
                            )}
                        </Tab>
                    </Tabs>
                </Col>
                <Col>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '1em',
                    }}>
                        <IconButton icon='plus' variant="primary" onClick={() => showNewClicked()}>
                            <span style={{ marginLeft: '.3em' }}>{!showNew ? 'Ny kjede' : 'Avbryt'}</span>
                        </IconButton>
                    </div>
                    {!selectedKjede && showNew && <KjedeCreate onKjedeCreated={handleKjedeCreated} />}
                    {selectedKjede && (
                        <KjedeEdit
                            kjede={selectedKjede}
                            onKjedeDeleted={handleKjedeSlettet}
                            onKjedeLagtNed={handleKjedeLagtNed}
                            onMedlemskapUpdated={handleMedlemskapUpdated}
                        />
                    )}
                </Col>
            </Row>
        </PageWrapper>
    );
};

export default Kjeder;
