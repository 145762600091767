import { useState, useEffect, useRef } from "react";
import { Form } from "react-bootstrap";
import { AsyncTypeahead, Highlighter } from "react-bootstrap-typeahead";

import { debounce } from 'lodash';
import format from "date-fns/format";

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { PickedEnhet } from "./types";

import EnhetSearchApi from "services/KjederegisteretAdminApi/EnhetSearchApi";
import { SearchResult, EnhetFromSearch, EnhetSearchFilter } from "services/KjederegisteretAdminApi/EnhetSearchApi/types";

import useIsMountedRef from "utils/hooks/useIsMountedRef";
import useLocalStorage from "utils/hooks/useLocalStorage";
import usePrevious from "utils/hooks/usePrevious";

import Style from "./index.module.css";

interface Props {
  onChange: (enhet: PickedEnhet) => void;
  size?: "sm" | "lg";
  required?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  showFilters?: boolean;
  onlyActive?: boolean;
  selectedLopenummer?: string;
  enhettype?: string;
  initialEnhetIsNotAktiv?: boolean;
}

const EnhetTypeahead = (props: Props) => {
  const isMountedRef = useIsMountedRef();
  const typeaheadRef = useRef(null);
  const prevLopenr = usePrevious(props.selectedLopenummer);
  const formSize = props.size || null;

  let defaultFilter = new EnhetSearchFilter();

  const [isSearching, setIsSearching] = useState(false);
  const [searchResponse, setSearchResponse] = useState<SearchResult>();
  const [selectedEnhet, setSelectedEnhet] = useState<EnhetFromSearch[]>([]);
  const [filter, setFilter] = useState<EnhetSearchFilter>(defaultFilter);

  const [isOnlyActiveFilter, setIsOnlyActiveFilter] = useLocalStorage<boolean>('onlyActiveFilter', false);

  const clearTypeahead = () => {
    if (isMountedRef.current && typeaheadRef.current) {
      typeaheadRef.current.clear();
      setSelectedEnhet([]);
    }
  };

  const search = async () => {
    setIsSearching(true);
    const sr = await EnhetSearchApi.searchEnheter(filter);
    if (isMountedRef.current && sr) {
      setIsSearching(false);
      setSearchResponse(sr);
    }
  };

  const handleSelectedEnhetChange = (enheter: EnhetFromSearch[]) => {
    if (enheter.length === 0) {
      props.onChange({ id: null, name: '' });
      setSelectedEnhet([]);
    } else {
      props.onChange({ id: enheter[0].enhetID, name: enheter[0].enhetnavn });
      setSelectedEnhet(enheter);
    }
  };

  const handleQueryChanged = (q: string) => {
    if (isMountedRef.current && q) {
      setFilter(prev => ({ ...prev, criteria: q }));
    }
  };

  const handleSelectedLopenrChanged = (q: string) => {
    if (isMountedRef.current && q) {
      setFilter(prev => ({ ...prev, criteria: q }));
    }
  };

  const handleEnhettypeChanged = (type: string) => {
    if (isMountedRef.current && type) {
      setFilter(prev => ({ ...prev, enhetTypeFilter: [type] }));
    }
  };

  useEffect(() => {
    search();
  }, [filter]);

  useEffect(() => {
    if (searchResponse) {
      const filteredEnhet = searchResponse.items.filter(
        x => x.lopenr === Number(props.selectedLopenummer),
      );
      if (!isSearching && filteredEnhet.length === 1) {
        handleSelectedEnhetChange(filteredEnhet);
      }
    }
  }, [searchResponse, isSearching, props.selectedLopenummer]);

  useEffect(() => {
    if (props.selectedLopenummer) {
      if (prevLopenr !== props.selectedLopenummer) {
        handleSelectedLopenrChanged(props.selectedLopenummer.toString());
      }
    } else {
      clearTypeahead();
    }
  }, [prevLopenr, props.selectedLopenummer]);

  useEffect(() => {
    handleEnhettypeChanged(props.enhettype);
  }, [props.enhettype]);

  useEffect(() => {
    if (isMountedRef.current) {
      setFilter(prev => ({ ...prev, aktivFilter: isOnlyActiveFilter ? 'Active' : 'All', enhetTypeFilter: props.enhettype ? [props.enhettype] : [], }));
    }
  }, [isOnlyActiveFilter, props.enhettype]);

  useEffect(() => {
    if (isMountedRef.current && props.onlyActive && !isOnlyActiveFilter) {
      setIsOnlyActiveFilter(true);
      defaultFilter.aktivFilter = 'Active';
    }

    if (isMountedRef.current && !props.onlyActive && !props.showFilters && isOnlyActiveFilter) {
      setIsOnlyActiveFilter(false);
      defaultFilter.aktivFilter = 'All';
    } else {
      defaultFilter.aktivFilter = 'Active';
    }
  }, [isOnlyActiveFilter, props.onlyActive, props.showFilters]);

  const renderMenuItem = (option: EnhetFromSearch, props: any, index: number) => {
    const itemDesc = `${option.enhetnavn || ''} - Løpenr: ${option.lopenr || ''} - Orgnr: ${option.organisasjonsnr || ''}`;
    return (
      <div key={index} className={Style.optionsItemContainer}>
        <div className={Style.itemDesc} style={option.nedlagtDato && { color: 'red' }}>
          <Highlighter search={props.text}>{itemDesc}</Highlighter>
        </div>
        <div>
          <small className={Style.optionSubText} style={option.nedlagtDato && { color: 'red' }}>
            {option.nedlagtDato && (
              <IcebergIcon
                className={Style.icons}
                title={`${option.nedlagtDato < new Date() ? 'Nedlagt' : 'Legges ned'}: ${format(option.nedlagtDato, 'dd.MM.yyyy')}`}
                icon="alert" />
            )}

            {option.oppstartsdato && option.oppstartsdato > new Date() && (
              <IcebergIcon
                className={Style.icons}
                title={`Fremtidig oppstart: ${format(option.oppstartsdato, 'dd.MM.yyyy')}`}
                icon="clock" />
            )}
            <div>
              <span>{option.besoksadresse}</span>
              {option.besoksadresse && option.latestHovedBransjeDescription && (
                <span> - </span>
              )}
              <span style={{ paddingLeft: '.5em' }}>
                {option.latestHovedBransjeDescription}
              </span>
            </div>
          </small>
        </div>
      </div>
    );
  };

  return (
    <>
      <AsyncTypeahead
        clearButton ref={typeaheadRef}
        id="1337"
        labelKey={(option: EnhetFromSearch) => `${option.enhetnavn}`}
        renderMenuItemChildren={(option: EnhetFromSearch, props: any, index: number) => renderMenuItem(option, props, index)}
        minLength={3}
        size={formSize}
        isLoading={isSearching}
        options={searchResponse ? searchResponse.items : []}
        searchText="Søker"
        placeholder="Søk etter enhet..."
        promptText="Skriv for å søke"
        emptyLabel="Fant ingen treff"
        maxResults={20}
        onChange={(e: EnhetFromSearch[]) => handleSelectedEnhetChange(e)}
        onSearch={debounce((q: string) => handleQueryChanged(q), 300)}
        filterBy={() => true}
        useCache={false}
        inputProps={{ required: props.required }}
        isValid={props.isValid}
        isInvalid={props.isInvalid}
        selected={selectedEnhet} />
      {props.showFilters && (
        <div className={Style.filterContainer}>
          <Form.Check inline type="checkbox" className={Style.activeCheckbox}
            id={`default-checkbox`} label={`Kun aktive filter`} checked={isOnlyActiveFilter}
            onChange={() => setIsOnlyActiveFilter(!isOnlyActiveFilter)} />
          {props.initialEnhetIsNotAktiv && isOnlyActiveFilter && <div style={{ height: '25px', margin: '0px 5px' }}>
            <span style={{ fontSize: '.875rem', color: 'red' }}>
              <strong>Fjern avhukning på aktive filter for å se inaktiv enhet</strong>
            </span>
          </div>}
        </div>
      )}
    </>
  )
}

export default EnhetTypeahead;