import { format } from 'date-fns';

export default class DateHelper {
  // check if the object is a Date type
  static isDate = date => date instanceof Date;

  static formatDate = (date, dateFormat = 'dd.MM.yyyy') => {
    if (!date) return '';
    if (!DateHelper.isDate(date)) {
      return format(new Date(date), dateFormat);
    }
    return format(date, dateFormat);
  };

  // returns a new Date object with the specified days added
  static addDays = function (date: Date, days: number) {
    const copy = new Date(date.getTime());
    copy.setDate(date.getDate() + days);
    return copy;
  }

  // if date is a string try to parse into a date object
  static ensureDate = date => {
    if (!date) return null;
    if (!DateHelper.isDate(date)) {
      return new Date(date);
    }
    return date;
  };
}
