/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect, useCallback } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

/*Services */
import AbonnementApi from "services/KjederegisteretAdminApi/AbonnementApi";
import { AbonnementQuery } from "services/KjederegisteretAdminApi/AbonnementApi/types";

/*Hooks */
import useDebounce from "utils/hooks/useDebounce";

/*IMPORTS END */


/*Interface */
interface Props {
  onChange: (selected: Abo[]) => void;
  selected: Abo[];
}

export interface Abo {
  id: number;
  name: string;
}

const AbonnementTypeahead = (props: Props) => {

  /*useState properties */
  const [query, setQuery] = useState(new AbonnementQuery());
  const [abonnementList, setAbonnementList] = useState<Abo[]>([]);
  const [selected, setSelected] = useState(props.selected);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedQuery = useDebounce(query, 300);

  /*Functions */
  const loadAbonnement = useCallback(async () => {
    setIsLoading(true);
    const result = await AbonnementApi.getAbonnement(query);
    setAbonnementList(result.map(o => ({ id: o.id, name: o.name })));
    setIsLoading(false);
  }, []);

  const onChangeHandler = (e: Abo[]) => {
    setSelected(e);
    props.onChange(e);
  };

  /*useEffect */
  useEffect(() => {
    if (debouncedQuery) {
      loadAbonnement();
    }
  }, [debouncedQuery, loadAbonnement]);

  return (

    /*Content */
    <AsyncTypeahead
      id="abonnementTypeahead"
      labelKey="name"
      minLength={3}
      delay={100}
      isLoading={isLoading}
      options={abonnementList}
      searchText="Søker"
      placeholder="Søk etter abonnement"
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      useCache={false}
      maxResults={15}
      multiple
      onChange={e => onChangeHandler(e)}
      onSearch={q => setQuery({ ...query, q })}
      selected={selected} />
  )
}

export default AbonnementTypeahead;