import { useLoadScript } from '@react-google-maps/api';

export default function useGoogleMaps() {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBTdNWHFYnNRAcjOFJiODbjl4Ztiq3L3RY',
    libraries: ['places'],
  });

  return isLoaded;
}
