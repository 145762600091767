import { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { EnhetStatestikk } from 'services/SalgsdatainfoApi/types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { sumBy, chain } from 'lodash';


ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  enhetStatestikk: EnhetStatestikk;
}

const AndelBestillbarChart= (props: Props) => {
  const [grouped, setGrouped] = useState([]);
  const [slicedGroup, setSliceGroup] = useState(false);

  const isMountedRef = useIsMountedRef();

  const compareAndel = (a, b) => {
    const andelA = a.sumAntallBestillbarPercent;
    const andelB = b.sumAntallBestillbarPercent;
    let comparison = 0;
    if (andelA < andelB) {
      comparison = 1;
    } else if (andelA > andelB) {
      comparison = -1;
    }
    return comparison;
  };

  useEffect(() => {
    let source = props.enhetStatestikk.kundePrGrossistgruppePrMaaned;
    let grouped = chain(source)
      .groupBy('grossistnavn')
      .map(function(value, key) {
        return {
          grossistnavn: key,
          sumAntallBestillbarPercent: sumBy(value, 'antallBestilbarPercent'),
          linjer: value,
        };
      })
      .value()
      .sort(compareAndel);

    if (grouped.length > 4) {
      grouped = grouped.slice(0, 4);
      if (isMountedRef.current) {
        setSliceGroup(true);
      }
    }
    if (isMountedRef.current) {
      setGrouped(grouped);
    }
  }, []);

  //const percentage = findPercentage();

  const data = {
    datasets: [
      {
        data: grouped.map(
          x => Math.round((x.sumAntallBestillbarPercent + Number.EPSILON) * 100) / 100,
        ),
        backgroundColor: [
          '#a6cee3',
          '#1f78b4',
          '#b2df8a',
          '#33a02c',
          '#fb9a99',
          '#e31a1c',
          '#fdbf6f',
          '#ff7f00',
          '#cab2d6',
          '#6a3d9a',
          '#ffff99',
          '#b15928',
        ],
      },
    ],
    labels: grouped.map(
      x =>
        x.grossistnavn.substring(0, 5) +
        '..' +
        x.grossistnavn.substring(
          x.grossistnavn.length - (x.grossistnavn.length > 17 ? 10 : 4),
          x.grossistnavn.length,
        ),
    ),
  };

  return (
    <div>
      {slicedGroup && <h5>Andel bestillbare pakninger (%) - Top 4</h5>}
      {!slicedGroup && <h5>Andel bestillbare pakninger (%)</h5>}
      <Pie
        width={400}
        height={300}
        options={{ maintainAspectRatio: false, responsive: false }}
        data={data}
      />
    </div>
  );
};

export default AndelBestillbarChart;
