import React, { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import GrossisterApi from 'services/KjederegisteretAdminApi/GrossisterApi';
import { GrossistEnhet } from 'services/KjederegisteretAdminApi/GrossisterApi/types';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { debounce } from 'lodash';

interface Props {
  onChange: (e: GrossistEnhet) => void;
  isValid: boolean;
  isInvalid: boolean;
  required: boolean;
}

const GrossistEnhetSearch: React.FC<Props> = props => {
  const isMountedRef = useIsMountedRef();
  const [searchResponse, setSearchResponse] = useState<GrossistEnhet[]>([]);
  const [dataOptions, setDataOptions] = useState<GrossistEnhet[]>([]);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [filter, setFilter] = useState<{query: string}>({query: ''});

  const onQueryChanged = (q: string) => {
    if (isMountedRef.current && q) {
      setFilter({ ...filter, query: q });
    }
  };

  const search = async () => {
    setIsSearching(true);
    const sr = await GrossisterApi.searchEnheterWithGrossistType(filter);
    if (isMountedRef.current) {
      setIsSearching(false);
      setSearchResponse(sr);
    }
  };

  const onChange = (e: GrossistEnhet[]) => {
    if (e.length === 0) {
      props.onChange(undefined);
    } else {
      props.onChange(e[0]);
    }
  };

  useEffect(() => {
    if (filter && filter.query !== '') {
      search();
    }
  }, [filter]);

  useEffect(() => {
    if (searchResponse.length > 0) {
      setDataOptions(
        searchResponse.map(item => {
            return {
              enhetId: item.enhetId,
              enhetnavn: item.enhetnavn,
              lopenummer: item.lopenummer,
              organisasjonsnr: item.organisasjonsnr,
              disabled: item.erHovedgrossist ? true : false,
              erHovedgrossist: item.erHovedgrossist,
              enhetType: item.enhetType
            };
          },
        ),
      );
    }
  }, [searchResponse]);

  return (
    <AsyncTypeahead
      id={1337}
      labelKey={option => `${option.enhetnavn} ${option.disabled ? '| Er hovedgrossist' : ''}`}
      minLength={3}
      isLoading={isSearching}
      options={dataOptions || []}
      searchText="Søker"
      clearButton
      placeholder="Søk etter eksisterende enhet..."
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      maxResults={20}
      onChange={e => onChange(e)}
      onSearch={debounce((q: string) => onQueryChanged(q), 300)}
      filterBy={() => true}
      useCache={false}
      isInvalid={props.isInvalid}
      isValid={props.isValid}
    />
  );
};

export default GrossistEnhetSearch;
