import { Row, Col, ButtonGroup, Button } from "react-bootstrap";

import { format } from 'date-fns';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import QAEnhetSearch from "./components/QAEnhetSearch";

import { EndringsMelding } from "services/QAApi/types";
import { PickedEnhet } from "components/EnhetTypeahead/types";

import { formatBehandlesAvItem } from "../../../../utils";

import Style from "./index.module.css";
import { EnhetViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";
import CommonLoader from "components/CommonLoader";

interface Props {
    enhet: EnhetViewModel;
    erPublisert?: boolean;
    initialEnhetIsNotAktiv?: boolean;
    changeRequest: EndringsMelding;
    submitDisabled: boolean;
    submitLoading: boolean;
    folgesOppLoading: boolean;
    onFolgesOppClicked: () => void;
    onAvvisClicked: () => void;
    onIgnoreClicked: () => void;
    onSubmitClicked: () => void;
    onEnhetChange: (enhet: PickedEnhet) => void;
    onCloseWindowClicked: () => void;
}

const DetailStickyHeader = (props: Props) => {
    const formatDate = (date: Date) => {
        if (date) return format(date, 'dd.MM.yyyy');
        return '';
    };
    return (
        <div className={Style.headerContainer}>
            <Row className={Style.infoContainer}>
                <Col className={Style.headerInfoText} data-private>
                    <span>
                        <strong>Behandles av: </strong>
                        {
                            props.changeRequest.behandlesAv.length > 0
                                ? props.changeRequest.behandlesAv.map(formatBehandlesAvItem).join(', ')
                                : '...'
                        }
                    </span>
                    {
                        props.changeRequest.folgesOppAv &&
                        <div className={Style.folgesOppContainer}>
                            <span style={{ marginRight: "0.3rem" }}>
                                <strong>Følges opp av: </strong>
                                {props.changeRequest?.folgesOppAv?.changedBy || ''} ({format(props.changeRequest?.folgesOppAv?.statusChangedAt, 'dd.MM.yyyy')})
                            </span>
                            {
                                props.changeRequest.folgesOppAv.comment &&
                                <span>
                                    <strong>Kommentar: </strong>
                                    {props.changeRequest.folgesOppAv.comment}
                                </span>
                            }
                        </div>
                    }

                    {/* Active */}
                    {
                        props.enhet && props.enhet.grupper && props.enhet.grupper.filter(g => g.isActive).length > 0 && props.enhet.sisteGrupperingMedlemskap && props.enhet.sisteGrupperingMedlemskap.isActive &&
                        <span>
                            <strong>Enhetsgruppe(r): </strong>
                            {props.enhet.grupper.filter(o => o.isActive).map(g => g.toppnivaaEnhetsgrupperingnavn).join(', ')}
                        </span>
                    }

                    {/* Nedlagt */}
                    {
                        props.enhet && props.enhet.grupper && props.enhet.grupper.filter(g => g.isActive).length === 0 && props.enhet.sisteGrupperingMedlemskap && props.enhet.sisteGrupperingMedlemskap.isHistoric &&
                        <span>
                            <strong>Enhetsgruppe(r): </strong>
                            <span style={{ color: 'red' }}>
                                {props.enhet.grupper
                                    .filter(o => o.enhetgrupperingId === props.enhet.sisteGrupperingMedlemskap.enhetgrupperingId)
                                    .map(g => g.toppnivaaEnhetsgrupperingnavn).join(', ') + ` (ut ${formatDate(props.enhet.sisteGrupperingMedlemskap.tilOgMedDato)})`}
                            </span>
                        </span>
                    }
                    {/* fremtidig */}
                    {
                        props.enhet && props.enhet.grupper && props.enhet.grupper.filter(g => g.isActive).length === 0 && props.enhet.sisteGrupperingMedlemskap && props.enhet.sisteGrupperingMedlemskap.isFuture &&
                        <span>
                            <strong>Enhetsgruppe(r): </strong>
                            <span>
                                {props.enhet.grupper
                                    .filter(o => o.enhetgrupperingId === props.enhet.sisteGrupperingMedlemskap.enhetgrupperingId)
                                    .map(g => g.toppnivaaEnhetsgrupperingnavn).join(', ') + ` (inn ${formatDate(props.enhet.sisteGrupperingMedlemskap.fraOgMedDato)})`}
                            </span>
                        </span>
                    }

                    {
                        props.enhet && props.enhet.sisteMarkedskjedeMedlemskap && props.enhet.sisteMarkedskjedeMedlemskap.isActive &&
                        <Col>
                            <strong>Markedsskjede: </strong>
                            {
                                props.enhet.markedskjedeHistorikk.filter(o => o.kjedeId === props.enhet.sisteMarkedskjedeMedlemskap.kjedeId).flatMap((o, i) => (
                                    o.kjedeHierarki.map(h => h.kjedenavn)
                                )).join(' / ')
                            }
                        </Col>
                    }
                    {
                        props.enhet && props.enhet.sisteMarkedskjedeMedlemskap && props.enhet.sisteMarkedskjedeMedlemskap.isHistoric &&
                        <Col>
                            <strong>Markedsskjede: </strong>
                            <span style={{ color: 'red' }}>
                                {
                                    props.enhet.markedskjedeHistorikk.filter(o => o.kjedeId === props.enhet.sisteMarkedskjedeMedlemskap.kjedeId).flatMap((o, i) => (
                                        o.kjedeHierarki.map(h => `${h.kjedenavn}`)
                                    )).join(' / ') + ` (ut ${formatDate(props.enhet.sisteMarkedskjedeMedlemskap.tilOgMedDato)})`
                                }
                            </span>

                        </Col>
                    }

                    {
                        props.enhet && props.enhet.sisteMarkedskjedeMedlemskap && props.enhet.sisteMarkedskjedeMedlemskap.isFuture &&
                        <Col>
                            <strong>Markedsskjede: </strong>
                            <span>
                                {
                                    props.enhet.markedskjedeHistorikk.filter(o => o.kjedeId === props.enhet.sisteMarkedskjedeMedlemskap.kjedeId).flatMap((o, i) => (
                                        o.kjedeHierarki.map(h => `${h.kjedenavn}`)
                                    )).join(' / ') + ` (inn ${formatDate(props.enhet.sisteMarkedskjedeMedlemskap.fraOgMedDato)})`
                                }
                            </span>

                        </Col>
                    }

                </Col>

                <Col className={Style.closeIconCol}>
                    <IcebergIcon className={Style.windowCloseIcon} size='2x'
                        icon="close" color="#757575" cursor="pointer"
                        onClick={props.onCloseWindowClicked} bold />
                </Col>
            </Row>

            <Row className={Style.enhetSearchContainer}>
                <Col>
                    <QAEnhetSearch onChange={props.onEnhetChange}
                        enhetID={props.enhet ? props.enhet.id : undefined}
                        lopenummer={props.enhet ? props.enhet.lopenr : undefined}
                        bruktEnhet={props.changeRequest.changesUsedInEnhetWithEnhetId ? true : false}
                        erPublisert={props.erPublisert}
                        initialEnhetIsNotAktiv={props.initialEnhetIsNotAktiv} />
                </Col>
                <Col className={Style.btnGroupCol}>
                    <ButtonGroup size="sm">
                        <Button size='sm' variant="outline-primary" className="me-2"
                            disabled={!!(props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet')}
                            onClick={() => props.onFolgesOppClicked()}>
                            <span>{props.changeRequest.status === 'FolgesOpp' ? 'Stans oppfølging' : 'Følg opp'}</span>
                            {props.folgesOppLoading && <CommonLoader color="#003b6e" />}
                        </Button>
                        <Button size='sm' variant="outline-primary" className="me-2"
                            disabled={!!(props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet')}
                            onClick={props.onAvvisClicked}>
                            <span>Avvis</span>
                        </Button>
                        <Button size='sm' variant="outline-primary" className="me-2"
                            disabled={!!(props.changeRequest.status === 'Behandlet' || props.changeRequest.status === 'Avvist')}
                            onClick={props.onIgnoreClicked}>
                            <span>Ignorer</span>
                        </Button>
                        <Button size='sm' variant="primary"
                            disabled={props.submitDisabled}
                            onClick={props.onSubmitClicked}>
                            <span>Lagre</span>
                            {props.submitLoading && <CommonLoader />}
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    )
}

export default DetailStickyHeader;