import { useState, useEffect, useCallback } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import EnhetTypeahead from 'components/EnhetTypeahead';
import useForm from 'utils/hooks/useForm';
import KjedeApi from 'services/KjederegisteretAdminApi/KjedeApi';
import { KjedeDto, NewKjedeCommand } from 'services/KjederegisteretAdminApi/KjedeApi/types';
import RegionalkjedePicker from 'components/RegionalkjedePicker';
import MarkedsKjedePicker from 'components/MarkedsKjedePicker';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import DatePicker from 'components/DatePicker';
import Style from './index.module.css';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { PickedKjede, PickerMode } from 'components/KjedePicker';

interface Props {
  onKjedeCreated: (createdKjede: KjedeDto) => void;
}

const KjedeCreate = (props: Props) => {
  const toaster = useToaster();

  const initialData: NewKjedeCommand = {
    kjedenavn: '',
    kortnavn: '',
    enhetId: null,
    enhetnavn: '',
    overliggendeRegionalKjedeId: null,
    overliggendeSalgsOgMarkedsKjedeId: null,
    oppstartDato: new Date(),
  };

  const validate = (values: NewKjedeCommand) => {
    const errors: any = {};
    if (!values.kjedenavn) errors.kjedenavn = 'Kjedenavn mangler!';
    if (!values.kortnavn) errors.kortnavn = 'Kortnavn mangler!';
    if (!values.enhetId) errors.enhetId = 'Hovedkontor er ikke satt!';
    return errors;
  };

  const onSubmit = async (values: NewKjedeCommand) => {
    try {
      const createdKjede = await KjedeApi.addKjede(values);
      if (createdKjede) {
        props.onKjedeCreated(createdKjede);
        toaster.success('Suksess!', 'Kjede er opprettet.');
      }
    } catch (err) {
      toaster.error('Noe gikk galt.', HttpErrorHelper.formatError(err));
    }
  };

  const {
    touched,
    errors,
    values,
    handleChange,
    handleSubmit,
    submitDisabled,
    updateValues,
  } = useForm(initialData, validate, onSubmit);

  const [selectedParentRegionalKjede, setSelectedParentRegionalKjede] = useState<number | undefined>();
  const [selectedParentMarkedKjede, setSelectedParentMarkedKjede] = useState<number | undefined>();

  const isMountedRef = useIsMountedRef();

  const onChangeHovedkontor = (enhet: { id: number; name: string }) => {
    const copy = { ...values };
    copy.enhetnavn = enhet.name;
    copy.enhetId = enhet.id;
    updateValues(copy);
  };

  useEffect(() => {
    if (isMountedRef.current) {
      handleChange('overliggendeRegionalKjedeId', selectedParentRegionalKjede);
    }
  }, [selectedParentRegionalKjede]);

  useEffect(() => {
    if (isMountedRef.current) {
      handleChange('overliggendeSalgsOgMarkedsKjedeId', selectedParentMarkedKjede);
    }
  }, [selectedParentMarkedKjede]);

  const handleOverliggendeRegionKjedeChanged = useCallback((kjeder: PickedKjede[]) => {
    setSelectedParentRegionalKjede(kjeder && (kjeder.length > 0) ? kjeder[0].id : undefined);
  }, []);

  const handleOverliggendeMarkedsKjedeChanged = useCallback((kjeder: PickedKjede[]) => {
    setSelectedParentMarkedKjede(kjeder && (kjeder.length > 0) ? kjeder[0].id : undefined);
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group>
        <Form.Label>
          Hovedkontor<span style={{ color: 'red' }}>*</span> (påkrevd)
        </Form.Label>
        <EnhetTypeahead
          isInvalid={touched.enhetId && errors.enhetId}
          enhettype="HOVEDKONTOR"
          onlyActive
          onChange={e => onChangeHovedkontor(e)}
        />
        <Form.Control.Feedback type="invalid">
          {touched.enhetId && errors.enhetId}
        </Form.Control.Feedback>
        <Form.Text>
          {values.enhetId && values.enhetnavn && (
            <div>
              <span>Enhet: </span>
              <Button
                className={Style.linkToEnhetBtn}
                size="sm"
                variant="link"
                onClick={() => window.open(`enheter/${values.enhetId}`, '_blank')}
              >
                {values.enhetnavn}
              </Button>
            </div>
          )}
        </Form.Text>
      </Form.Group>
      <hr />
      <Form.Group>
        <Form.Label>
          Kjedenavn<span style={{ color: 'red' }}>*</span> (påkrevd)
        </Form.Label>
        <Form.Control
          name="kjedenavn"
          type="text"
          autoComplete="false"
          value={values.kjedenavn}
          onChange={e => handleChange('kjedenavn', e.target.value)}
          isInvalid={touched.kjedenavn && errors.kjedenavn}
          isValid={touched.kjedenavn && !errors.kjedenavn}
        />
        <Form.Control.Feedback type="invalid">{errors.kjedenavn}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>
          Kortnavn<span style={{ color: 'red' }}>*</span> (påkrevd)
        </Form.Label>
        <Form.Control
          name="kortnavn"
          type="text"
          value={values.kortnavn}
          onChange={e => handleChange('kortnavn', e.target.value)}
          isInvalid={touched.kortnavn && errors.kortnavn}
          isValid={touched.kortnavn && !errors.kortnavn}
        />
        <Form.Control.Feedback type="invalid">{errors.kortnavn}</Form.Control.Feedback>
      </Form.Group>
      <hr />
      <Form.Group>
        <Form.Label>Overliggende regionkjede:</Form.Label>
        <RegionalkjedePicker
          pickerMode={PickerMode.Single}
          onSelectionChanged={handleOverliggendeRegionKjedeChanged}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Overliggende markedskjede:</Form.Label>
        <MarkedsKjedePicker
          pickerMode={PickerMode.Single}
          onSelectionChanged={handleOverliggendeMarkedsKjedeChanged}
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Oppstartdato</Form.Label>
        <DatePicker
          selected={values.oppstartDato}
          onChange={d => handleChange('oppstartDato', d)}
          adjustTimeForTimezoneOffset={true}
        />
        <Form.Text>Hvis du ikke velger en dato, blir dagens dato automatisk valgt.</Form.Text>
      </Form.Group>
      <Form.Group style={{ marginTop: '1rem' }}>
        <Button type="submit" disabled={submitDisabled}>
          Opprett
        </Button>
      </Form.Group>
    </Form >
  );
};

export default KjedeCreate;

