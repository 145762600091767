import React, { useState, useEffect } from 'react';
import { Form, Button, Modal, InputGroup, Alert } from 'react-bootstrap';

import cx from 'classnames';
import addMinutes from 'date-fns/addMinutes';

import DatePicker from 'components/DatePicker';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import KjedeknytningerApi from 'services/KjederegisteretAdminApi/KjedeknytningerApi';
import { EnhetViewModel } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { OverliggendeKjede, LeggNedKjedeCommand } from 'services/KjederegisteretAdminApi/KjedeknytningerApi/types';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import useToaster from 'utils/hooks/useToaster';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

interface Props {
  kjedeID: number;
  isLoadingKjedeEnhet?: boolean;
  isToppkjede: boolean;
  overliggendeKjede: OverliggendeKjede;
  kjedeEnhet: EnhetViewModel;
  isMedlemmer?: boolean;
  isShowModal: boolean;
  handleCloseModal: () => void;
  onKjedeLagtNed: () => void;
  getKjedeEnhet: (id: number) => void;
}

const AvsluttMedlemskapModal: React.FC<Props> = props => {
  const [selectedUtmeldingsDato, setSelectedUtmeldingsDato] = useState<Date>(addMinutes(new Date(), -(new Date().getTimezoneOffset())));
  const [datePickerFormTxt, setDatePickerFormTxt] = useState<string>('Dagens dato automatisk valgt.')
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();
  const toaster = useToaster();

  const openKjedeEnhet = (id: number) => {
    const win = window.open(`/enheter/${id}`, '_blank');
    win.focus();
  };


  const handleSubmit = () => {

    // #24333: make sure date isn't changed by timezone adjustments
    const adjustedFraOgMedDato = addMinutes(props.overliggendeKjede.fraOgMedDato, -props.overliggendeKjede.fraOgMedDato.getTimezoneOffset());

    const command: LeggNedKjedeCommand = {
      kjedeID: props.kjedeID,
      kjedeIDOver: props.overliggendeKjede.parentKjede.kjedeID,
      kjedeTypeKode: props.overliggendeKjede.kjedetypeKode,
      fraOgMedDato: adjustedFraOgMedDato,
      utmeldingsDato: selectedUtmeldingsDato,
    }
    setIsLoadingSubmit(true);
    KjedeknytningerApi.leggNedKjede(props.kjedeID, command).then(() => {
      toaster.success('Suksess!', 'Kjede ble lagt ned.')
    }).catch(err => {
      toaster.error('Feil! Kunne ikke legge ned.', HttpErrorHelper.formatError(err));
    }).finally(() => {
      props.handleCloseModal();
      setIsLoadingSubmit(false);
      props.onKjedeLagtNed();
    })
  };

  const goToKjedeEnhet = (id: number) => {
    const win = window.open(`/enheter/${id}`, '_blank', 'noopener,noreferrer');
    if (win != null) {
      win.focus();
    }
  }

  useEffect(() => {
    if (isMountedRef.current && props.kjedeEnhet && props.kjedeEnhet.firma.nedlagtDato) {
      //setSelectedUtmeldingsDato(new Date(kjedeEnhet.firma.nedlagtDato));
      const nedlagt = new Date(props.kjedeEnhet.firma.nedlagtDato);
      setSelectedUtmeldingsDato(addMinutes(nedlagt, -(nedlagt).getTimezoneOffset()));
    }
  }, [props.kjedeEnhet]);

  useEffect(() => {
    if (isMountedRef.current) {
      if (props.kjedeEnhet && props.kjedeEnhet.firma.nedlagtDato) {
        const nedlagt = new Date(props.kjedeEnhet.firma.nedlagtDato);
        setSelectedUtmeldingsDato(addMinutes(nedlagt, -(nedlagt).getTimezoneOffset()));
      } else if (new Date(props.overliggendeKjede?.fraOgMedDato) <= new Date() || props.overliggendeKjede?.fraOgMedDato) {
        let date = new Date(props.overliggendeKjede?.fraOgMedDato);
        let next = new Date(date.getTime() + 1000 * 60 * 60 * 24)
        setSelectedUtmeldingsDato(next);
        setDatePickerFormTxt('Dagen etter innmeldt dato er valgt, da man ikke kan bruke samme dato for innmeldt og utmeldt. ')
      } else {
        setDatePickerFormTxt('Dagens dato automatisk valgt.');
      }
    }
  }, [props.overliggendeKjede, props.kjedeEnhet]);

  return (
    <Modal show={props.isShowModal} onHide={props.handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>Meld ut {props.isToppkjede && '(Toppnivå)'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.kjedeEnhet && !props.kjedeEnhet.firma?.nedlagtDato && props.overliggendeKjede && (
          <div>
            <h6>{props.overliggendeKjede.kjede.kjedenavn} blir meldt ut av {props.isToppkjede ? 'Toppnivå' : props.overliggendeKjede.parentKjede.kjedenavn}</h6>
            {props.isToppkjede && (
              <Alert className={Style.hovedKontorNotNedlagtMsg} variant="warning">
                <span>Hovedkontor er ikke nedlagt:</span>
                <Button
                  size="sm"
                  variant="link"
                  onClick={() => goToKjedeEnhet(props.kjedeEnhet.id)}
                >
                  {props.kjedeEnhet.enhetnavn}
                </Button>
              </Alert>
            )}
          </div>
        )}
        <Form>
          <InputGroup>
            <DatePicker
              disabled={props.isMedlemmer}
              selected={selectedUtmeldingsDato}
              onChange={(date: Date) => setSelectedUtmeldingsDato(date)}
              adjustTimeForTimezoneOffset={true}
            />
            {props.kjedeEnhet && props.kjedeEnhet.firma?.nedlagtDato ? (
              <Form.Text>Automatisk satt til nedlagtdato fra enhet:
                <a href="#" onClick={() => openKjedeEnhet(props.kjedeEnhet.id)}>
                  <strong> {props.kjedeEnhet.enhetnavn} ({props.kjedeEnhet.lopenr})</strong></a> </Form.Text>
            ) : (
              <Form.Text>{datePickerFormTxt}</Form.Text>
            )}
          </InputGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer className={cx(Style.modalFooter, {
        [Style.toppKjedeModalFotter]: props.isToppkjede,
        [Style.notToppKjedeModalFotter]: !props.isToppkjede,
      })}>
        {(props.isToppkjede) && (
          <Button onClick={() => props.getKjedeEnhet(props.kjedeEnhet.id)}>
            <span>{props.isLoadingKjedeEnhet ? 'Laster inn' : 'Frisk opp'}</span>
            {props.isLoadingKjedeEnhet ? (
              <div className={Style.refreshKjedeEnhetIcon}>
                <CommonLoader />
              </div>
            ) : (
              <IcebergIcon className={Style.refreshKjedeEnhetIcon} icon="synchronize" />
            )}
          </Button>
        )}
        <div className={Style.handleKjedeModalBtnContainer}>
          <Button className={Style.closeModalBtn} variant="secondary" onClick={props.handleCloseModal}>
            Lukk
          </Button>
          <Button variant="danger" onClick={handleSubmit} disabled={isLoadingSubmit}>
            {isLoadingSubmit && (
              <span style={{ paddingRight: '.4em' }}>
                <CommonLoader  />
              </span>
            )}
            <span>{isLoadingSubmit ? '...melder ut' : 'Ja, meld ut'}</span>
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AvsluttMedlemskapModal;
