import axios from "axios";
import { getKjederegAdminApiUrl } from "../../";
import { EnhetgruppeKjedeMedlemskapDto } from "./types";

const baseUrl = getKjederegAdminApiUrl() + '/EnhetsgruppeMedlemsskaps';

const EnhetsgruppeMedlemsskapsApi = {
    getByKjedeId: async (kjedeId: number): Promise<EnhetgruppeKjedeMedlemskapDto[]> => {
        const response = await axios.get(`${baseUrl}/byKjedeId/${kjedeId}`)
        return response.data;
    }
};

export default EnhetsgruppeMedlemsskapsApi;