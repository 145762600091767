import { useEffect, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {
  IcebergNavbarBrandLogo,
  IcebergProductMenu,
  IcebergAvatar,
  IcebergCustomerPicker,
  IcebergNotifications,
  IcebergIcon,
} from '@tradesolution/iceberg-ui-react';
import KundeportalenApi from 'services/KundeportalenApi';
import { MenuItem } from 'services/KundeportalenApi/types';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { useMsal } from '@azure/msal-react';
import HealthStatus from 'components/HealthStatus';
import LogRocket from 'logrocket';



interface Props {
}


const MainMenu = (props: Props) => {

  const { instance, accounts } = useMsal();

  const username = accounts[0] && accounts[0].name;
  const getFirstName = (fullname: string | undefined) => {
    if (!fullname) return '';
    return fullname.split(' ')[0];
  };
  /*useState handles */
  const [services, setServices] = useState<MenuItem[]>([]);

  const toaster = useToaster();

  const currentSelectedServiceName = 'KR Admin';


  /*Functions */
  const loadServices = (isTileDisplay: boolean) => {
    KundeportalenApi.fetchMenu(isTileDisplay)
      .then((res) => {
        setServices(res);
      })
      .catch((error) => {
        toaster.error('', HttpErrorHelper.formatError(error));
      });
  };

  // for logrocket
  useEffect(() => {
    LogRocket.identify(username);
  }, [username]);

  /*useEffect hooks */
  /* useEffect(() => {
    if (!user && props.userContext && props.userContext.user) {
      setUser(props.userContext.user);
    }
  }, [props.userContext, user]); */

  useEffect(() => {
    loadServices(false);
  }, []);



  // if (Array.isArray(user?.profile?.Role)) {
  //   canReadAll = user?.profile?.Role?.includes('TradesolutionAnsatt' || 'UberAdmin');
  // } else {
  //   canReadAll =
  //     user?.profile?.Role === 'TradesolutionAnsatt' || user?.profile?.Role === 'UberAdmin';
  // }

  const handleLogout = () => {
    instance.logoutRedirect().catch(e => {
      console.error(e);
    });
  }

  /*Content */
  return (
    <Navbar fixed="top" collapseOnSelect expand="lg" bg='info-dark' style={{ padding: "0.2rem 0" }}>
      <Container fluid>

        <Navbar.Brand href="/">
          <IcebergIcon icon='kradmin' size="lg" />
          <span className='ms-3'>Kjederegisteret</span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="navbarScroll" />

        <Navbar.Collapse role='navigation'>

          <Nav className="me-auto">
            <NavDropdown title={<IcebergIcon icon='meatballs' />}>
              <IcebergProductMenu />
            </NavDropdown>
          </Nav>

          <Nav>
            <span style={{ margin: "0.7rem" }}>
              <HealthStatus />
            </span>
            <NavDropdown
              id="dropdownUserMenu"
              align="end"
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginLeft: '0.5em' }}>{getFirstName(username)}</span>
                </div>
              }
            >
              <NavDropdown.Item onClick={handleLogout}>
                <span>
                  <strong>Logg av </strong>
                </span>
              </NavDropdown.Item>
            </NavDropdown>
            <IcebergCustomerPicker />
            <IcebergNotifications />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainMenu;
