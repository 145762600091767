import { useEffect, useRef, useState } from 'react';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import BrregApi from 'services/KjederegisteretAdminApi/BrregApi';
import { debounce } from 'lodash';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { BrregEnhetResponse } from 'services/KjederegisteretAdminApi/BrregApi/types';

interface Props {
  onBrregEnhetSelected: (item: BrregEnhetResponse) => void;
  isValid?: boolean;
  isInvalid?: boolean;
  required?: boolean;
  clearTypeaheadInitiator?: string | number;
  selected?: { orgNr: string, name: string };
  size?: "small" | "large";
  setIsLoadingBrreg?: (isLoading: boolean) => void;
}

const BrregTypeAhead = (props: Props) => {
  const [searchResult, setSearchResult] = useState<BrregEnhetResponse[]>();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useIsMountedRef();
  const typeaheadRef = useRef(null);
  const formSize = props.size || null;

  const searchBrregByOrgnr = async (orgnr: string) => {
    setIsLoading(true);
    const result = await BrregApi.getByOrgNr(orgnr);
    if (result && result.length > 0) {
      const filtered = result.find((x: BrregEnhetResponse) => x.orgNr === props.selected.orgNr);
      if (filtered) {
        props.onBrregEnhetSelected(filtered);
      }
      setSearchResult(result);
      setIsLoading(false);
    }
  }

  const isNumeric = (value: string) => {
    return /^\d+$/.test(value);
  }

  const lookupQueryInBrreg = debounce(async (q: string) => {
    setIsLoading(true);
    if (q) {
      let cleaned = q;
      cleaned = cleaned.replaceAll(' ', '');
      cleaned = cleaned.replaceAll('.', '');
      if (!isNumeric(cleaned)) {
        const result = await BrregApi.getByName(q);
        if (result.length > 0) {
          setSearchResult(result);
          setIsLoading(false);
          const filtered = result.find((x: any) => `${x.name.toUpperCase()} ${x.orgNr}` === q);
          if (filtered) {
            props.onBrregEnhetSelected(filtered);
          }
          return result;
        }
      } else {
        const result = await BrregApi.getByOrgNr(cleaned);
        if (result && result.length > 0) {
          setSearchResult(result);
          setIsLoading(false);
          return result;
        }
      }
    }
    setSearchResult([]);
    setIsLoading(false);
  }, 300);

  const renderMenuItem = (option: any, props: any, index: number) => {
    const itemDesc = `${option.name} ${option.orgNr}`;
    return (
      <div key={index}>
        <Highlighter search={props.text}>{itemDesc}</Highlighter>
      </div>
    );
  };

  const clearTypeahead = () => {
    if (isMountedRef.current && typeaheadRef.current) {
      typeaheadRef.current.clear();
      typeaheadRef.current.setState({ text: '' });
    }
  }

  useEffect(() => {
    clearTypeahead();
  }, [props.clearTypeaheadInitiator]);

  useEffect(() => {
    if (isMountedRef.current && props.selected?.orgNr) {
      searchBrregByOrgnr(props.selected.orgNr);
    }
  }, [props.selected]);

  useEffect(() => {
    if (props.setIsLoadingBrreg) {
      props.setIsLoadingBrreg(isLoading);
    }
  }, [isLoading]);

  return (
    <AsyncTypeahead
      inputProps={{ required: props.required || undefined }}
      id="brregTypeAhead"
      filterBy={() => true}
      labelKey={option => `${option.name} ${option.orgNr}`}
      renderMenuItemChildren={(option: any, props: any, index: number) =>
        renderMenuItem(option, props, index)
      }
      size={formSize}
      useCache={false}
      minLength={3}
      clearButton
      isLoading={isLoading}
      options={searchResult}
      searchText="Søker"
      placeholder="Søk i brønnøysundregistrene..."
      promptText="Skriv for å søke"
      emptyLabel="Fant ingen treff"
      maxResults={10}
      onChange={(e: BrregEnhetResponse[]) => props.onBrregEnhetSelected(e[0])}
      onSearch={q => lookupQueryInBrreg(q)}
      isInvalid={(!isLoading && props.isInvalid) || undefined}
      isValid={(!isLoading && props.isValid) || undefined}
      ref={typeaheadRef}
      selected={props.selected ? [props.selected] : []}
    />
  );
};

export default BrregTypeAhead;
