import axios from 'axios';
import { GlnModel, AvailableGlnModel } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/gln';

const lookupGln = async (gln: string): Promise<GlnModel> => {
  const response = await axios.get(`${baseUrl}/${gln}`);
  if (response.data.nedlagtDato) {
    response.data.nedlagtDato = new Date(response.data.nedlagtDato);
  }
  return response.data;
};

const glnSearch = async (query: string): Promise<GlnModel[]> =>
  axios.get(`${baseUrl}/GlnSearch?lokasjonsnavn=${query}`).then(response => response.data);

const findAvailableGln = async (gln: string): Promise<AvailableGlnModel> =>
  axios.get(`${baseUrl}/FindAvailableGln?gln=${gln}`).then(response => response.data);

const eierGln = async (gln: string): Promise<GlnModel> => {
  const response = await axios.get(`${baseUrl}/${gln}/EierGln`);
  if (response.data.nedlagtDato) {
    response.data.nedlagtDato = new Date(response.data.nedlagtDato);
  }
  return response.data;
};


const GlnApi = {
  lookupGln, 
  glnSearch,
  findAvailableGln,
  eierGln
}

export default GlnApi;