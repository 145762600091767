import ReactDatePicker from 'react-datepicker/dist';
import 'react-datepicker/dist/react-datepicker.css';
import addMinutes from 'date-fns/addMinutes';
import Style from './index.module.css';
import cx from 'classnames';

interface Props {
  selected?: Date;
  placeholderText?: string;
  adjustTimeForTimezoneOffset?: boolean;
  onChange: (date: Date) => void;
  disabled?: boolean;
  isClearable?: boolean;
  minDate?: Date;
  required?: boolean;
  id?: string;
  isInvalid?: boolean;
  isSmall?: boolean;
}

const DatePicker = (props: Props) => {

  const handleDateSelected = (date: Date) => {
    if (date) {
      if (props.adjustTimeForTimezoneOffset) {
        const adjustedDate = addMinutes(date, -date.getTimezoneOffset());
        props.onChange(adjustedDate);
      } else {
        props.onChange(date);
      }
    } else {
      props.onChange(undefined); // onClear
    }
  };

  const onFocus = (event: any) => {
    if (event.target.autocomplete) {
      event.target.setAttribute('autocomplete', 'off');
    }
  };

  return (
    <ReactDatePicker
      id={props.id}
      locale="nb"
      className="form-control"
      selected={props.selected}
      onChange={(date: Date) => handleDateSelected(date)}
      dateFormat="dd.MM.yyyy"
      placeholderText={props.placeholderText}
      disabled={props.disabled}
      autoComplete="new-password"
      onFocus={onFocus}
      isClearable={props.isClearable}
      minDate={props.minDate}
      required={props.required}
      wrapperClassName={cx(Style.datePicker, {
        [Style.invalidDatePicker]: props.isInvalid,
        [Style.small]: props.isSmall,
      })}
    />
  );
};

export default DatePicker;
