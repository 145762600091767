import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Badge, Button, Table } from 'react-bootstrap';
import { KjedeMedlem } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import CommonLoader from 'components/CommonLoader';

interface Props {
  enheter: KjedeMedlem[];
  skip: number;
  hasMore: boolean;
  onLoadMore: () => void;
  isLoading: boolean;
}

const EnhetTable: React.FC<Props> = props => {
  let currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  return (
    <div style={{ height: '90vh' }}>
      {props.enheter && (
        <div>
          <Table>
            <thead>
              <tr>
                <th>Enhet</th>
                <th>Periode</th>
              </tr>
            </thead>
            <tbody>
              {props.enheter.map(k => (
                <tr key={k.enhetID}>
                  <td>
                    {k.nedlagtDato && k.nedlagtDato.getTime() <= currentDate.getTime() &&
                      <div>
                        <Badge bg="danger">NEDLAGT</Badge>
                      </div>}
                    <Link to={`/enheter/${k.enhetID}`}>{k.lopenr + ', ' + k.enhetnavn}</Link>
                    <br />
                    {k.besoksadresse &&
                      `${k.besoksadresse.gateadresse} ${k.besoksadresse.postnr} ${k.besoksadresse.poststed
                      }`}
                    <br />
                    Kundenr: {k.kundenrIKjede}
                  </td>
                  <td>
                    {format(k.fraOgMedDato, 'dd.MM.yyyy')} -{' '}
                    {!!k.tilOgMedDato ? format(k.tilOgMedDato, 'dd.MM.yyyy') : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {props.isLoading && (
            <div key="loader" style={{ textAlign: 'center', padding: '2rem' }}>
              <CommonLoader/>
            </div>
          )}
          {props.hasMore && !props.isLoading && (
            <div style={{ textAlign: 'center', padding: '1rem' }}>
              <Button variant="link" onClick={props.onLoadMore}>
                Hent flere...
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EnhetTable;
