import axios from 'axios';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl();

const getQaApiAccessKey = async (targetUrl: string): Promise<string> => {
  const accesskeysUrl = `${baseUrl}/AzureFunctionCodes/QaCode`;
  const response = await axios.get(`${accesskeysUrl}?targetUrl=${targetUrl}`);
  return response.data;
};

const getImportApiAccessKey = async (targetUrl: string): Promise<string> => {
  const accesskeysUrl = `${baseUrl}/AzureFunctionCodes/ImportCode`;
  const response = await axios.get(`${accesskeysUrl}?targetUrl=${targetUrl}`);
  return response.data;
};

const getQaAccessKey = async (targetUrl: string): Promise<string> => {
  let accessKey = sessionStorage.getItem(targetUrl);
  if (accessKey) {
    return accessKey;
  }

  accessKey = await getQaApiAccessKey(targetUrl);
  sessionStorage.setItem(targetUrl, accessKey);
  return accessKey;
};

const getImportAccessKey = async (targetUrl: string): Promise<string> => {
  let accessKey = sessionStorage.getItem(targetUrl);
  if (accessKey) {
    return accessKey;
  }

  accessKey = await getImportApiAccessKey(targetUrl);
  sessionStorage.setItem(targetUrl, accessKey);
  return accessKey;
};

export const getQaUrlWithAccessKey = async (targetUrl: string, path: string): Promise<string> => {
  const accessKey = await getQaAccessKey(targetUrl);
  return `${targetUrl}/${path}?code=${accessKey}`;
};

export const getImportUrlWithAccessKey = async (targetUrl: string, path: string): Promise<string> => {
  const accessKey = await getImportAccessKey(targetUrl);
  return `${targetUrl}/${path}?code=${accessKey}`;
};