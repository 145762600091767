import axios from 'axios';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl().replace("/api", "") + '/_health';

const HealthApi = {
    get: async (): Promise<any> => {
        const response = await axios.get(baseUrl);
        return response.data;
    }
}

export default HealthApi;