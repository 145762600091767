/*IMPORTS */

/*React & React module dependencies */
import React from 'react'

/*Styling */
import Style from './index.module.css';

/*IMPORTS END */

/*Component interface */
interface Props {
  children?: React.ReactNode
}

const PageHeader= (props:Props) => {

  /*Content */
  return (
    <h3 className={Style.Header}>{props.children}</h3>
  )
}

export default PageHeader;