
import React, { ReactNode, useEffect, useState } from 'react';
import SortableTree, { toggleExpandedForAll } from '@nosferatu500/react-sortable-tree';
import '@nosferatu500/react-sortable-tree/style.css'; // This only needs to be imported once in your app
import { InputGroup, FormControl, Col } from 'react-bootstrap';

import { transformTitle, setParent } from './NodeMapper';
import { breadthFirstSearch } from './searchTree';

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from "./index.module.css";

interface Props {
  data: any[];
  children?: ReactNode;
  isSearchable?: boolean;
  expandAll?: boolean;
  onNodeClick: (picked: any) => void;
}

/* eslint-disable react/prefer-stateless-function */
const StyledSortableTree = (props: Props) => {
  const [treeData, setTreeData] = useState(null);
  const [originalData, setOriginalData] = useState(props.data);
  //const [lookupInProgress, setLookupInProgress] = useState<boolean>(false);
  const [filterText, setFilterText] = useState<string>('');

  const isMountedRef = useIsMountedRef();

  const onLookupTreeNode = () => {
    const clone = JSON.parse(JSON.stringify(originalData));
    if (filterText === '' || !filterText) {
      const transformedData = transformTitle(clone, props.onNodeClick);
      setTreeData(transformedData);
      return;
    }

    let results = [];
    const foundNode = node => {
      const filtered = results.filter(
        e => e.id !== node.id || e.children.filter(f => f.id !== node.id),
      );
      if (node.length !== props.data.length) filtered.push(node);

      results = filtered;
    };
    setParent(clone);
    breadthFirstSearch({ children: clone }, filterText, foundNode);

    const transformed = transformTitle(clone.filter(e => e.expanded), props.onNodeClick, filterText);
    setTreeData(transformed);
  };

  const onChange = changedData => {
    const transformed = transformTitle(changedData, props.onNodeClick);
    setTreeData(transformed);
  };

  useEffect(() => {
    let transformedData = transformTitle(JSON.parse(JSON.stringify(props.data)), props.onNodeClick);
    if (props.expandAll) {
      transformedData = toggleExpandedForAll({ treeData: transformedData, expanded: true });
    }
    setTreeData(transformedData);
    setOriginalData(props.data);
    //setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.expandAll]);

  useEffect(() => {
    if (isMountedRef.current) {
      onLookupTreeNode();
    }
  }, [filterText]);

  useEffect(() => {
    if (isMountedRef.current && originalData && filterText !== '') {
      onLookupTreeNode();
    }
  }, [originalData]);

  return (
    <div className={Style.Wrapper}>
      {props.isSearchable && (
        <div className={Style.SearchWrapper}>
          <Col>
            <InputGroup>
              <FormControl
                value={filterText}
                placeholder="Søk her.."
                onChange={e => setFilterText(e.target.value)}
              />
              <InputGroup.Text
                style={{ cursor: 'pointer' }}
                id="basic-addon2"
                onClick={() => setFilterText('')}
              >
                {filterText.length > 0 ?
                  <IcebergIcon icon="close" bold /> :
                  <IcebergIcon icon="search" bold />}
              </InputGroup.Text>
            </InputGroup>
          </Col>
          {props.children && <Col>{props.children}</Col>}
        </div>
      )}
      {treeData && (
        <SortableTree
          rowHeight={40}
          canDrag={() => false}
          treeData={treeData}
          onlyExpandSearchedNodes
          onChange={(e: any) => onChange(e)}
          generateNodeProps={({ node, path }) => ({
            title: (
              <a href={node.url}>
                {node.title}
              </a>
            ),
          })}
        />
      )}
    </div>
  );
};

export default StyledSortableTree;
