export interface SearchResult {
    count: number;
    facets: any[];
    items: EnhetFromSearch[];
}

export interface EnhetFromSearch {
    enhetnavn: string;
    besoksadresse: string;
    gateadresse: string;
    postnummer: string;
    poststed: string;
    juridiskNavn: string;
    kontonr: string;
    gln: string;
    epost: string;
    notat: string;
    latestHovedBransjeDescription: string;
    latestHovedGrossistDescription: string;
    latestKjedenavn: string;
    organisasjonsnr: string;
    enhetTypeKoder: string[];
    telefon?: string;
    enhetID: number;
    lopenr: number;
    forrigeLopenr?: number;
    nesteLopenr?: number;
    produl?: number;
    salgsArealKvadratmeter: number;
    statistikknr: number;
    erOffentlig: boolean;
    erPublisert: boolean;
    erSesongdrift: boolean;
    harGln: boolean
    nedlagtDato?: Date;
    oppstartsdato?: Date;
    postFraOgMedDato?: Date;
    komplettTidspunkt?: Date;
    opprettet: Date;
    besoksadresser?: EnhetFromSearchAdresse[];
    postadresser?: EnhetFromSearchAdresse[];
    fakturaadresser?: EnhetFromSearchAdresse[]
    adresseList: {
        adresse: string;
        adressetypeKode: string;
    }[];
    direkteMarkedsKjedeMedlemskap: DirekteKjedeMedlemskap[];
    direkteRegionalKjedeMedlemskap: DirekteKjedeMedlemskap[];
    regionalKjeder: any[];
    markedsKjeder: any[];
    bransjer: any[];
    grossister: any[];
    enhetsgrupperinger: any[];
    enhetTyper: any[];
    glnNedlagtDato?: Date;
}

interface EnhetFromSearchAdresse {
    gateadresse: string;
    postnummer: string;
    ekstraInfo: string;
    poststed: string;
    latitude?: number;
    longitude?: number;
    landkode: string;
    kommunenummer: string;
    kommunenavn: string;
    fylkenummer: string;
    fylkenavn: string;
    land: string;
    erHovedAdresse?: boolean;
    formattedForOneLine?: string;
    utm33NEast?: string;
    utm33NNorth?: string;
}

export interface DirekteKjedeMedlemskap {
    kjedeId: number;
    kjedenummer: string;
    kjedenavn: string;
    kundenummerHosKjede: string;
    fraOgMedDato: string;
    tilOgMedDato: string;
}

export class EnhetSearchFilter {
    criteria: string = '';
    searchFields: string = 'OtherFields,Besoksadresse,FakturaAdresse,Postadresse';
    aktivFilter: string = 'All';
    utenHovedbransjeFilter: string = 'All';
    glnFilter: string = 'All';
    komplettTidspunktFilter: string = 'All';
    ikkePublisertFilter: string = 'All';
    bransjeFilter: number[] = [];
    fylker: string[] = [];
    kommuner: string[] = [];
    postnr: string[] = [];
    regionKjedeFilter: number[] = [];
    salgsOgMarkedsforingKjedeFilter: number[] = [];
    ekskluderMarkedskjeder: boolean = false;
    grossistFilter: number[] = [];
    enhetsgrupperingFilter: number[] = [];
    sistEndretAv: string[] = [];
    enhetTypeFilter: string[] = [];
    skip: number = 0;
    take: number = 50;
    calculateFacets: boolean = false;
    opprettetFilter = '';
    customOpprettetFilter = '';
    sortBy: string | undefined;
    sortByDescending: boolean = false;
    mottakerTsKundeId: string | undefined;
}