import React, { useEffect, useState } from 'react';

import GoogleMaps from 'components/GoogleMaps';
import { Coordinates } from 'models/Coordinates';

import useGoogleMaps from 'utils/hooks/useGoogleMaps';
import useGeocoder from 'utils/hooks/useGeocoder';

interface Props {
  coordinates: Coordinates;
  poststed: string;
}

const QAAddressMap: React.FC<Props> = props => {
  const isGoogleMapsLoaded = useGoogleMaps();
  const geocoder: any = useGeocoder();
  const { google } = window;
  const defaultCenter = new Coordinates(59.91, 10.75); // Oslo
  const [center, setCenter] = useState(defaultCenter);

  useEffect(() => {
    // use coordinates
    if (props.coordinates) {
      setCenter(props.coordinates);
    }
    // find center of poststed
    else if (props.poststed) {
      if (isGoogleMapsLoaded && google && geocoder.current) {
        const defaultMapBounds = new google.maps.LatLngBounds(
          new google.maps.LatLng(57, 2),
          new google.maps.LatLng(81, 42),
        );
        geocoder.current.geocode(
          {
            address: props.poststed,
            bounds: defaultMapBounds,
          },
          geoCodeResponse => {
            const firstHit = geoCodeResponse.length > 0 ? geoCodeResponse[0] : null;
            if (firstHit) {
              setCenter(
                new Coordinates(firstHit.geometry.location.lat(), firstHit.geometry.location.lng()),
              );
            }
          },
        );
      }
    }
    // falback to default center - oslo
    else {
      setCenter(defaultCenter);
    }
  }, [props.coordinates, geocoder, props.poststed, isGoogleMapsLoaded, google]);

  return (
    <GoogleMaps
      center={center}
      existingLocation={props.coordinates}
      existingLocationTitle="Koordinater fra eksisterende adresse/enhet" />
  );
}


export default QAAddressMap;
