import React, { useState, useEffect } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'components/DatePicker';
import DateHelper from 'utils/DateHelper';
import { addMinutes } from 'date-fns';
import ConfirmButton from 'components/ConfirmButton';

export interface KundeNummerPeriodeFormData {
  kundenummer: string;
  eksisterendeFraOgMedDato: Date;
  fraOgMedDato: Date;
  tilOgMedDato?: Date;
}

interface Props {
  kundenummer: string;
  isEditMode: boolean;
  tilOgMedDato: Date;
  fraOgMedDato: Date;
  handleUpdateKjedeInfo: (data: KundeNummerPeriodeFormData) => void;
  handleFjernEnhetFromKjede?: () => Promise<void>;
  canRemoveKjede: boolean;
}

const KundeNummerPeriodeForm: React.FC<Props> = props => {
  const eksisterendeFraOgMedDato = addMinutes(props.fraOgMedDato, -props.fraOgMedDato.getTimezoneOffset());

  const [values, setValues] = useState<KundeNummerPeriodeFormData>({
    kundenummer: props.kundenummer || '',
    eksisterendeFraOgMedDato: eksisterendeFraOgMedDato || undefined,
    fraOgMedDato: props.fraOgMedDato,
    tilOgMedDato: props.tilOgMedDato,
  });

  useEffect(() => {
    setValues({
      kundenummer: props.kundenummer,
      eksisterendeFraOgMedDato,
      tilOgMedDato: props.tilOgMedDato,
      fraOgMedDato: props.fraOgMedDato,
    });
  }, [props.kundenummer, props.tilOgMedDato, props.fraOgMedDato]);

  const onSaveClick = () => {
    props.handleUpdateKjedeInfo(values);
  };

  const onFjernConfirmed = async () => {
    props.handleFjernEnhetFromKjede();
  };

  const handleFraOgMedDatoChange = date => {
    setValues(prevValues => ({
      ...prevValues,
      fraOgMedDato: date,
    }));
  };

  const handleTilOgMedDatoChange = date => {
    setValues(prevValues => ({
      ...prevValues,
      tilOgMedDato: date,
    }));
  };

  const handleKundenummerChange = (value: string) => {
    setValues(prevValues => ({
      ...prevValues,
      kundenummer: value,
    }));
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Label>Inn og utdato</Form.Label>
          {!props.isEditMode ? (
            <Form.Control
              disabled
              plaintext
              value={`${DateHelper.formatDate(props.fraOgMedDato)} - ${props.tilOgMedDato ? DateHelper.formatDate(props.tilOgMedDato) : ''
                }`}
            />
          ) : (
            <Row>
              <Form.Group as={Col}>
                <DatePicker
                  placeholderText="Inndato"
                  selected={values.fraOgMedDato}
                  onChange={date => handleFraOgMedDatoChange(date)}
                  adjustTimeForTimezoneOffset={true}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <DatePicker
                  placeholderText="Utdato"
                  selected={values.tilOgMedDato}
                  onChange={date => handleTilOgMedDatoChange(date)}
                  adjustTimeForTimezoneOffset={true}
                />
              </Form.Group>
            </Row>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>Avdelingsnummer</Form.Label>
          <Form.Control
            disabled={!props.isEditMode}
            plaintext={!props.isEditMode}
            value={values.kundenummer}
            onChange={e => handleKundenummerChange(e.target.value)}
          />
        </Form.Group>
        {props.isEditMode && (
          <Form.Group>
            <ConfirmButton
              onClick={onFjernConfirmed}
              disabled={!props.canRemoveKjede || !onFjernConfirmed}
            />
            <Button style={{ margin: '.5em' }} className="float-right" onClick={onSaveClick}>
              Lagre
            </Button>
          </Form.Group>
        )}
      </Form>
    </div>
  );
};

export default KundeNummerPeriodeForm;
