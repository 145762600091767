/*IMPORTS */

/*React & React module dependencies */
import React from 'react';
import { Modal, Row, Col, Table } from 'react-bootstrap';

/*Service */
import { JobbDetails } from 'services/KjederegisteretAdminApi/EnheterBulkApi/types';

/*IMPORTS END */


/*Interface */
interface Props {
  show: boolean;
  jobbNavn: string;
  jobDetails: JobbDetails;
  onClose: () => void;
}


const JobbDetailsModal: React.FC<Props> = props => {

  //Declare variables for 
  let header = <></>;
  let columnHeader: string = '';

  if (props.jobDetails) {
    switch (props.jobbNavn) {
      case 'ByttAdresse':
        header = (<p>Følgende enheter har fått endret {props.jobDetails?.adresseType} til{' '}
          {props.jobDetails.newValue}</p>);
        columnHeader = 'Forrige adresse';
        break;
      case 'ByttAdresseEkstraInfo':
        header = (<p>Følgende enheter har fått endret {props.jobDetails?.adresseType} ekstrainfo til{' '}
          '{props.jobDetails.newValue}'</p>);
        columnHeader = 'Forrige adresse ekstrainfo';
        break;
      case 'ByttFakturaEpost':
        header = (<p>Følgende enheter har fått endret FakturaEpost til {props.jobDetails.newValue}</p>);
        columnHeader = 'Forrige fakturaepost';
        break;
      case 'ByttKontonummer':
        header = (<p>Følgende enheter har fått endret Kontonummer til {props.jobDetails.newValue}</p>);
        columnHeader = 'Forrige kontonummer';
        break;
      case 'FlyttKjedeTilNyttInnkjøp':
        header = (<p>Følgende enheter har fått nytt innkjøpssamarbeid {props.jobDetails.newValue}</p>);
        columnHeader = 'Forrige innkjøpssamarbeid';
        break;
      case 'FlyttEnheterTilNyHovedgrossist':
        header = (<p>Følgende enheter har fått ny hovedgrossist {props.jobDetails.newValue}</p>);
        columnHeader = 'Forrige innkjøpssamarbeid';
        break;
      case 'FjernBedriftsNummer':
        header = (<p>Følgende enheter har mistet bedriftsnummer</p>);
        columnHeader = 'Fjernet bedriftsnummer';
        break;
      case 'SettKanMottaEFaktura':
        header = (<p>Følgende enheter har fått følgende verdi for om enhet kan motta efaktura: {props.jobDetails.newValue}</p>);
        columnHeader = 'Tidligere status for e-faktura';
        break;
      case 'FlyttEnheterTilKjede':
        header = (<p>Følgende enheter har blitt flyttet til følgende kjede: {props.jobDetails.newValue}</p>);
        columnHeader = "Forrige kjede"

    }
  }


  /*Content */
  return (
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Bulk jobb detaljer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            {props.jobDetails && (
              <Row>
                <Col>
                  {header}
                  <Table>
                    <thead>
                      <tr>
                        <th>Løpenr</th>
                        <th>{columnHeader}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(props.jobDetails?.updatedEnheter || []).map((i) => {
                        return (
                          <tr key={i.lopenr}>
                            <td>{i.lopenr}</td>
                            <td>{i.oldValue}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default JobbDetailsModal;
