import React, { useState, useEffect } from "react";
import { Button, Collapse, Row, Form, InputGroup, OverlayTrigger, Col, Tooltip } from "react-bootstrap";

import cx from "classnames";

import CompareIcon from "../CompareIcon";

import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import AddressTypeahead from "components/AddressTypeahead";

import { IAdresse } from 'components/AddressTypeahead/types';

import AdresseApi from "services/KjederegisteretAdminApi/AdresseApi";
import { AddressSearchResult } from "services/KjederegisteretAdminApi/AdresseApi/types";

import useIsMountedRef from "utils/hooks/useIsMountedRef";
import { formatAddressAsOneLiner } from "utils/AddressHelper";
import { qaCompareSimpleValue } from "../../utils";

import Style from "./index.module.css";

interface Props {
  changeRequestAddress: IAdresse;
  improvedAddress: IAdresse;
  onImprovedAddressChanged: (address: IAdresse) => void;
  isCustomAddress: boolean;
  changeRequestId: string;
}

const QAAddressForm = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const [lookupCrResults, setLookupCrResults] = useState<AddressSearchResult[]>([]);
  const [isChangeRequestAddressValid, setIsChangeRequestAddressValid] = useState<boolean>(false);
  const [validatedChangeRequestAddress, setValidatedChangeRequestAddress] = useState<AddressSearchResult>();
  const [isExpandRow, setIsExpandRow] = useState<boolean>(true);

  const transferAddress = () => {
    if (props.changeRequestAddress && isChangeRequestAddressValid) {
      props.onImprovedAddressChanged(props.changeRequestAddress);
    }
  };

  const lookupChangeRequestAddress = async (q: string) => {
    try {
      const foundAddresses = await AdresseApi.search(q);
      if (isMountedRef.current) {
        setLookupCrResults(foundAddresses);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (props.changeRequestAddress && isMountedRef.current) {
      lookupChangeRequestAddress(formatAddressAsOneLiner(props.changeRequestAddress));
    }
  }, [props.changeRequestAddress?.gateadresse]);

  const checkAddressExists = (changeRequestAddress: IAdresse, lookupResults: AddressSearchResult[]): boolean => {
    const formattedAddress = formatAddressAsOneLiner(changeRequestAddress).toUpperCase();
    return lookupResults.some(result =>
      result.formattedForOneLine.toUpperCase() === formattedAddress
    );
  };

  useEffect(() => {
    // Check if the address from the change request exists in the lookup results.
    const addressExists = checkAddressExists(props.changeRequestAddress, lookupCrResults);

    if (isMountedRef.current) {
      // Set the validation state of the change request address.
      setIsChangeRequestAddressValid(addressExists);

      // If the address exists, find its index in the lookup results to set the validated address.
      if (addressExists) {
        const formattedChangeRequestAddress = formatAddressAsOneLiner(props.changeRequestAddress).toUpperCase();
        const index = lookupCrResults.findIndex(result =>
          result.formattedForOneLine.toUpperCase() === formattedChangeRequestAddress
        );

        // If a valid index is found, use it to set the validated change request address.
        if (index !== -1) {
          setValidatedChangeRequestAddress(lookupCrResults[index]);
        }
      }
    }
  }, [lookupCrResults, props.changeRequestAddress, isMountedRef]);

  return (
    <div className={Style.addressFormContainer}>
      <div className={cx(Style.changeRequestRowHeaderContainer, { [Style.isNotExpandedRowContainer]: !isExpandRow })}
        onClick={() => setIsExpandRow(!isExpandRow)}>
        <h6 className={Style.changeRequestRowHeader}>
          <span className={Style.changeRequestRowHeaderText}>Fakturaadresse</span>
        </h6>
        <Button variant="link" size="sm">
          <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
        </Button>
      </div>
      <Collapse in={isExpandRow}>
        <div>
          <Row className={Style.addressFormRow}>
            <div className={Style.formBesoksadresseContainer}>
              <Form.Group className={Style.formGroup}>
                <InputGroup size="sm">
                  <Form.Control
                    readOnly
                    isValid={isChangeRequestAddressValid}
                    isInvalid={props.changeRequestAddress && props.changeRequestAddress.formattedForOneLine && !isChangeRequestAddressValid}
                    value={formatAddressAsOneLiner(props.changeRequestAddress)}
                  />
                  <InputGroup.Text>
                    <CopyToClipboardIcon val={formatAddressAsOneLiner(props.changeRequestAddress)} />
                  </InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    Adresse ble ikke funnet (kjederegisteret)
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="valid">
                    Adresse funnet og verifisert (kjederegisteret)
                  </Form.Control.Feedback>
                </InputGroup>

              </Form.Group>
            </div>
            <div className={Style.compareIconContainer}>
              {props.changeRequestAddress && isChangeRequestAddressValid ? (
                <div>
                  <CompareIcon
                    compareStatus={
                      qaCompareSimpleValue(
                        props.changeRequestAddress.formattedForOneLine,
                        props.improvedAddress?.formattedForOneLine
                      ) // compare with controlled query input value if no selected
                    }
                    onClick={transferAddress}
                  />
                </div>
              ) : (
                <OverlayTrigger
                  key="tooltip-top"
                  placement="top"
                  overlay={<Tooltip id="tooltip">Adresse er ikke gyldig og kan derfor ikke overføres.</Tooltip>}>
                  <div>
                    <IcebergIcon icon="arrow-right" backgroundColor="grey" color="#fff" rounded bold />
                  </div>
                </OverlayTrigger>
              )}
            </div>
            <div className={Style.formBesoksadresseContainer}>
              <Form.Group>
                <InputGroup size="sm">
                  {!props.improvedAddress ? (
                    <InputGroup.Text style={{ lineHeight: 0, paddingLeft: '.25rem', paddingRight: '.25rem' }}>
                      <IcebergIcon icon="search" />
                    </InputGroup.Text>
                  ) : (
                    <InputGroup.Text style={{ padding: '.15rem .15rem' }}>
                      <IcebergIcon icon="check" />
                    </InputGroup.Text>
                  )}
                  <AddressTypeahead
                    selected={props.improvedAddress}
                    clearTypeaheadInitiator={props.changeRequestId}
                    allowNew={true}
                    onChange={adresse => { props.onImprovedAddressChanged(adresse); }}
                    size="sm"
                  />
                </InputGroup>
                {props.isCustomAddress && props.improvedAddress && (
                  <Form.Text className="text-muted">Egendefinert og gyldig adresse.</Form.Text>
                )}
                {!props.isCustomAddress && props.improvedAddress && (
                  <Form.Text className="text-muted">Adresse er funnet i kjederegisteret.</Form.Text>
                )}

              </Form.Group>
            </div>
          </Row>
        </div>
      </Collapse>
    </div>
  )
}

export default QAAddressForm;