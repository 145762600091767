import { useState } from "react";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import Style from './index.module.css';
import CommonLoader from "components/CommonLoader";

interface Props {
    handleReaktiver: (reaktiverOgsåKjedeOgEnhetsgruppemedlemsskap: boolean) => void;
    show?: boolean;
    handleClose: () => void;
    reaktiverIsLoading: boolean;
}



const ReaktiverModal = (props: Props) => {
    const [checked, setChecked] = useState(false);


    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Reaktiver enhet</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <FormGroup className="mb-3">
                        <div className="card card-body bg-light">
                            <Form.Check
                                type="checkbox"
                                defaultChecked={checked}
                                onChange={() => setChecked(!checked)}
                                label="Reaktiver medlemskap i kjede / enhetsgruppering"
                            />
                            <Form.Text>
                                Kun medlemskap med til og med dato 14 dager etter enhetens nedlagtdato vil bli automatisk reaktivert. Medlemsskap i nedlagte grupper eller kjeder vil heller ikke reaktiveres.
                            </Form.Text>
                        </div>
                    </FormGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>Avbryt</Button>
                    <Button
                        className={Style.spinnerBtn}
                        variant="primary"
                        onClick={() => props.handleReaktiver(checked)}
                    >
                        <span>Utfør</span>
                        {props.reaktiverIsLoading && (
                            <span className='ms-2'>
                                <CommonLoader />
                            </span>
                        )}
                    </Button>

                </Modal.Footer>
            </Modal>

        </>
    );
};


export default ReaktiverModal;