import React, { useEffect, useState, useRef } from 'react';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import { EnhetsgruppeTreeItem } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import DropdownTreeSelect, { Mode, TreeNode } from 'react-dropdown-tree-select';
import 'react-dropdown-tree-select/dist/styles.css';
import isEqual from 'lodash/isEqual';
import { IMappedGrupperingNode } from "./types";
import CommonLoader from 'components/CommonLoader';

interface Props {
  treeSelectMode?: Mode;
  customTexts?: { placeholder: string, noMatches: string };
  disabled?: boolean;
  disableTopNodes?: boolean;
  preSelectedNodes?: number[];
  findPreselectedNodesByNumber?: (pickerType: string) => number[];
  findPreselectedNodesByID?: (pickerType: string) => number[];
  onSelectionChanged: (grupperinger: IMappedGrupperingNode[]) => void;
  closeOnSelect?: boolean;
  enhetgrupperingType?: string;
  onlyTopLevel?: boolean;
}

const GrupperingPicker = (props: Props) => {
  const [data, setData] = useState<IMappedGrupperingNode[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  const pickerRef = useRef<DropdownTreeSelect>(null);

  const defTexts = {
    placeholder: 'Velg...',
    noMatches: 'Ingen treff',
  };

  let preSelectedNodeNumbers: number[] = [];
  let preSelectedNodeIDS: number[] = [];

  const mapPreSelectedTypeNode = (node: IMappedGrupperingNode,/*  preSelectedArr: number[], */ parent?: IMappedGrupperingNode) => {
    let isChecked = false;
    let isParentPreSelected = false;

    if (preSelectedNodeIDS && preSelectedNodeIDS.length > 0) {
      isChecked = preSelectedNodeIDS.includes(node.id);
      isParentPreSelected = parent && preSelectedNodeIDS.includes(parent.id);
    }

    if (preSelectedNodeNumbers && preSelectedNodeNumbers.length > 0) {
      isChecked = preSelectedNodeNumbers.includes(node.nr);
      isParentPreSelected = parent && preSelectedNodeNumbers.includes(parent.nr);
    }


    const item: IMappedGrupperingNode = {
      label: node.label,
      id: node.id,
      idOver: node.idOver,
      name: node.name,
      nr: node.nr,
      kjedenr: node.kjedenr,
      disabled: props.disableTopNodes && node.children && node.children.length > 0 ? true : false,
      children: [],
      checked: isChecked || isParentPreSelected,
      enhetgrupperingType: node.enhetgrupperingType
    }
    if (node.children) {
      node.children.forEach((nodeChild: IMappedGrupperingNode) => {
        item.children.push(mapPreSelectedTypeNode(nodeChild,/*  preSelectedArr,  */item));
      });
    }
    return item;
  }

  const mapGrupperingNode = (node: TreeNode) => {
    let isChecked = false;
    if (preSelectedNodeIDS && preSelectedNodeIDS.length > 0) {
      isChecked = preSelectedNodeIDS.includes(node.id);
    }

    if (preSelectedNodeNumbers && preSelectedNodeNumbers.length > 0) {
      isChecked = preSelectedNodeNumbers.includes(node.nr);
    }

    const item: IMappedGrupperingNode = {
      label: node.label,
      id: node.id,
      idOver: node.idOver,
      name: node.name,
      nr: node.nr,
      kjedenr: node.kjedenr,
      disabled: props.disableTopNodes && node?._children?.length > 0 ? true : false,
      checked: isChecked,
      enhetgrupperingType: node.enhetgrupperingType
    };

    if (node.children) {
      node.children.forEach((childNode: EnhetsgruppeTreeItem) => {
        item.children.push(mapGrupperingTreeItem(childNode, item));
      });
    }

    return item;
  };

  const mapGrupperingTreeItem = (node: EnhetsgruppeTreeItem, parent?: IMappedGrupperingNode) => {
    let isChecked = false;
    let isParentPreSelected = false;
    if (preSelectedNodeIDS && preSelectedNodeIDS.length > 0) {
      isChecked = preSelectedNodeIDS.includes(node.id);
      isParentPreSelected = parent && preSelectedNodeIDS.includes(parent.id);
    }

    if (preSelectedNodeNumbers && preSelectedNodeNumbers.length > 0) {
      isChecked = preSelectedNodeNumbers.includes(node.enhetgrupperingnr);
      isParentPreSelected = parent && preSelectedNodeNumbers.includes(parent.nr);
    }
    const item: IMappedGrupperingNode = {
      label: node.name + " - " + node.enhetgrupperingtypekode,
      id: node.id,
      idOver: node.enhetgrupperingIDOver,
      name: node.name,
      nr: node.enhetgrupperingnr,
      kjedenr: node.kjedenr,
      enhetgrupperingType: node.enhetgrupperingtypekode,
      disabled: props.disableTopNodes && node.children && node.children.length > 0 ? true : false,
      children: [],
      checked: isChecked || isParentPreSelected,
    };

    if (node.children) {
      node.children.forEach((childNode: EnhetsgruppeTreeItem) => {
        item.children.push(mapGrupperingTreeItem(childNode, item));
      });
    }
    return item;
  };

  const loadGrupperinger = async () => {
    setIsLoading(true);
    const res = await EnhetsgrupperingApi.getEnhetsgrupperingerTree(false, props.enhetgrupperingType, props.onlyTopLevel);
    if (res.length > 0 && isMountedRef.current) {
      const resData: IMappedGrupperingNode[] = res.map(x => mapGrupperingTreeItem(x));
      setData(resData);

    }
    setIsLoading(false);
  };

  const handleChange = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    if (Array.isArray(selectedNodes)) {
      props.onSelectionChanged(selectedNodes.map(x => mapGrupperingNode(x)));
      if (isMountedRef.current && props.closeOnSelect && pickerRef.current.state.showDropdown) {
        pickerRef.current.searchInput.click();
      }
    }
  };

  useEffect(() => {
    if (isMountedRef.current) {
      if (props.findPreselectedNodesByID && data && data.length > 0) {
        const res: number[] = props.findPreselectedNodesByID('gruppering');
        if (res && res.length > 0) {
          preSelectedNodeIDS = res;
        }
      }
    }
  }, [props.findPreselectedNodesByID, data]);

  useEffect(() => {
    if (isMountedRef.current) {
      if (props.findPreselectedNodesByNumber && data && data.length > 0) {
        const res: number[] = props.findPreselectedNodesByNumber('gruppering');
        if (res && res.length > 0) {
          preSelectedNodeNumbers = res;
        }
      }
    }
  }, [props.findPreselectedNodesByNumber, data]);

  useEffect(() => {
    if (data && data.length > 0) {
      if ((preSelectedNodeNumbers && preSelectedNodeNumbers.length > 0) || (preSelectedNodeIDS && preSelectedNodeIDS.length > 0)) {
        const newData: IMappedGrupperingNode[] = data.map(dataNode => mapPreSelectedTypeNode(dataNode));
        if (newData && !isEqual(data, newData)) {
          setData(newData);
        }
      }
    }
  }, [preSelectedNodeNumbers, preSelectedNodeIDS, data]);

  useEffect(() => {
    if (!data) {
      loadGrupperinger();
    }
  }, []);

  return (
    <>
      <DropdownTreeSelect
        className="reactDropdownMultiPicker"
        mode={props.treeSelectMode}
        texts={props.customTexts || defTexts}
        data={data || []}
        keepTreeOnSearch
        keepChildrenOnSearch
        showPartiallySelected
        keepOpenOnSelect
        onChange={(currentNode: TreeNode, selectedNodes: TreeNode[]) => handleChange(currentNode, selectedNodes)}
        disabled={props.disabled || false}
        ref={pickerRef}
      />
      {isLoading && (
        <div>
          <CommonLoader  />
          <span> Henter data... </span>
        </div>
      )}
    </>
  );
};

export default React.memo(GrupperingPicker, (props, nextProps) => {
  if (JSON.stringify(props) === JSON.stringify(nextProps)) {
    return true;
  }
});

