import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import KjedeInfo from './KjedeInfo';
import KjedeMedlemskap from './Kjedemedlemskap';
import Medlemmer from './Medlemmer';
import { EnheterInKjedeQuery } from 'services/KjederegisteretAdminApi/EnhetApi/Queries';
import { KjedeMedlem } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { GetKjedeByIdResponse } from 'services/KjederegisteretAdminApi/KjedeApi/types';
import _ from 'lodash';
import Style from './index.module.css';
import InnkjopsMedlemsskap from './InnkjopsMedlemsskap';
import Admin from './Admin';

interface Props {
  kjede: GetKjedeByIdResponse;
  onKjedeDeleted: () => void;
  onKjedeLagtNed: () => void;
  onMedlemskapUpdated: (kjedeId: number) => void;
}

const KjedeEdit: React.FC<Props> = props => {
  const [medlemmer, setMedlemmer] = useState<KjedeMedlem[]>([]);
  const [kanSlettes, setKanSlettes] = useState<boolean>(false);
  const [kanLeggesNed, setKanLeggesNed] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number | undefined>();
  const [query, setQuery] = useState(new EnheterInKjedeQuery());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const enableAdminTab = false;

  const loadMedlemmer = async () => {
    setIsLoading(true);
    const result = await EnhetApi.getByKjede(props.kjede.kjedeID, query);
    setIsLoading(false);
    setTotalCount(result.totalCount);
    if (query.page > 1 && result.results.length > 0) {
      setMedlemmer(medlemmer.concat(result.results));
    } else {
      setMedlemmer(result.results);
    }

    setKanLeggesNed(result.results.length === 0);
    setKanSlettes(result.results.length === 0);
  };

  useEffect(() => {
    setMedlemmer([]);
    setQuery(new EnheterInKjedeQuery());
  }, [props.kjede]);

  useEffect(() => {
    loadMedlemmer();
  }, [query]);

  const handleQueryChanged = (q: EnheterInKjedeQuery) => {
    setQuery(q);
  };

  return (
    <Row>
      <Col>
        <Tabs defaultActiveKey="kjede">
          <Tab eventKey="kjede" title="Kjedeinfo" className={Style.Tab}>
            {props.kjede && (
              <KjedeInfo
                kjede={props.kjede}
                onKjedeDeleted={props.onKjedeDeleted}
                kanSlettes={kanSlettes}
                kanLeggedNed={kanLeggesNed}
              />
            )}
          </Tab>
          {props.kjede && (
            <Tab eventKey="medlemav" title="Kjedemedlemskap" className={Style.Tab}>
              <KjedeMedlemskap
                kjede={props.kjede}
                onMedlemskapUpdated={props.onMedlemskapUpdated}
                onKjedeLagtNed={props.onKjedeLagtNed}
                isMedlemmer={medlemmer.length > 0}
              />
            </Tab>
          )}
          {props.kjede && (
            <Tab eventKey="enheter" title={`Enheter (${totalCount || ''})`} className={Style.Tab}>
              <Medlemmer
                kjedeNavn={props.kjede.kjedenavn}
                query={query}
                isLoading={isLoading}
                totalCount={totalCount}
                medlemmer={medlemmer}
                onQueryChanged={handleQueryChanged}
              />
            </Tab>
          )}
          <Tab eventKey="innkjop" title="Innkjøpssamarbeid" className={Style.Tab}>
            <InnkjopsMedlemsskap kjede={props.kjede} />
          </Tab>
          {enableAdminTab && <Tab eventKey="admin" title="Admin" className={Style.Tab}>
            <Admin kjede={props.kjede} />
          </Tab>}
        </Tabs>
      </Col>
    </Row>
  );
};

export default KjedeEdit;
