/*IMPORTS */

/*React & React module dependencies */
import React, { useState, useEffect } from 'react';
import { Col, Row, InputGroup, FormControl, Button, Accordion, Badge, Table } from 'react-bootstrap';

/*Shared components */
import PageWrapper from 'components/PageWrapper';

/*Components */
import MottakerEdit from './components/MottakerEdit';
import DistribusjonsHistorikkTabell from './components/DistribusjonsHistorikkTabell';

/*Services */
import MottakerApi from 'services/KjederegisteretAdminApi/MottakerApi';
import { Mottaker } from 'services/KjederegisteretAdminApi/MottakerApi/types';
import DistribusjonsApi from 'services/KjederegisteretAdminApi/DistribusjonsApi';
import { Distribusjon } from 'services/KjederegisteretAdminApi/DistribusjonsApi/types';

/*Hooks */
import useDebounce from 'utils/hooks/useDebounce';
import useToaster from 'utils/hooks/useToaster';

/*Styling */
import Style from './index.module.css';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import CommonLoader from 'components/CommonLoader';
import CreateModal from './components/CreateModal';

/*IMPORTS END */

const Mottakere = () => {
  const toaster = useToaster();

  /*useState handles */
  const [searchText, setSearchText] = useState<string>('');
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [mottakere, setMottakere] = useState<Mottaker[]>([]);
  const [selectedMottaker, setSelectedMottaker] = useState<Mottaker | undefined>();
  const [distribusjoner, setDistribusjoner] = useState<Distribusjon[]>([]);
  const [showHistorikk, setShowHistorikk] = useState(false);

  const isStoppet = (mottaker: Mottaker) =>
    mottaker.abonnementList.find((o) => o.name === 'Stoppet');

  /*Functions */
  const loadMottaker = async (id: number) => {
    const m = await MottakerApi.getMottaker(id);
    const d = await DistribusjonsApi.getByMottaker(id);
    setSelectedMottaker(m);
    //   setSelectedKey(m.id.toString() || undefined)
    setDistribusjoner(d);
  };

  const loadMottakere = async () => {
    setIsLoading(true);
    const m = await MottakerApi.getMottakere();
    setMottakere(m);
    setIsLoading(false);
  };

  const deleteMottaker = async (id: number) => {
    try {
      const response = await MottakerApi.deleteMottaker(id);
      if (response.status === 200) {
        toaster.success("Mottaker er slettet!", "");
        loadMottakere();
      } else {
        toaster.error("Sletting av mottaker feilet", "");
      }
    } catch (err) {
      if (err.response && err.response.status === 400) {
        const errorMsg = err.response.data.errors.DomainValidations[0];
        toaster.error(`Sletting av mottaker feilet - ${err.response.status}: ${errorMsg}`, "");
      } else {
        toaster.error("Sletting av mottaker feilet", err.response ? `${err.response.status} - ${err.response.statusText}` : "");
      }
    }
  };

  /*useEffect */
  useEffect(() => {
    loadMottakere();
  }, []);


  const handleCloseCreateModal = (): void => {
    setShowCreateModal(false);
    loadMottakere();
  }

  /*Content */
  return (
    <>
      <PageWrapper isFluid header="Mottakere">
        <Row className={Style.headerRow}>
          <Col md={5}>
            <InputGroup className='mb-3'>
              <FormControl
                value={searchText}
                placeholder="Søk her.."
                style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
                onChange={(e) => setSearchText(e.target.value)} />
              <InputGroup.Text style={{ background: 'white', borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
                {
                  searchText.length > 0 ?
                    <IcebergIcon icon='close' cursor='pointer'
                      onClick={() => setSearchText('')} />
                    : <IcebergIcon icon='search' />
                }
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col className='text-end'>
            <IconButton
              icon="plus"
              variant="primary"
              onClick={() => setShowCreateModal((prev) => !prev)}>
              Ny mottaker
            </IconButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey={undefined}>
              {mottakere?.length > 0 ?
                mottakere.filter(x => searchText?.length > 0 ? x.name.toLowerCase().includes(searchText.toLowerCase()) : x).map((m: Mottaker) => (
                  <Accordion.Item key={m.id} onClick={() => loadMottaker(m.id)} eventKey={m.id.toString()}>
                    <Accordion.Header>
                      <span style={{ marginRight: '1rem' }}>{m.name}</span>
                      {isStoppet(m) && <Badge bg="danger">Stoppet</Badge>}
                    </Accordion.Header>

                    <Accordion.Body>
                      {isLoading && <CommonLoader />}
                      {
                        selectedMottaker?.id === m.id &&
                        <>
                          <Row>
                            <Col>
                              <h4>Info</h4>
                              <MottakerEdit mottaker={selectedMottaker} onUpdated={() => loadMottakere()} />
                            </Col>
                            <Col>
                              <Accordion defaultActiveKey='0'>
                                <Accordion.Item eventKey='0'>
                                  <Accordion.Header>Enhetsutvalg detaljer</Accordion.Header>
                                  <Accordion.Body>
                                    <p>Mottaker kan se summen av alle abonnement</p>
                                    <Table>
                                      <thead>
                                        <th>Name</th>
                                        <th>Bransjefilter</th>
                                        <th>Kjedefilter</th>
                                      </thead>
                                      <tbody>
                                        {m.abonnementList.map(abb => (
                                          <tr key={abb.id}>
                                            <td>{abb.name}</td>
                                            <td className={Style.noWrap}>{abb.bransjer.map(b => <>{b.name} ({b.number}) <br /></>)}</td>

                                            <td className={Style.noWrap}>
                                              {abb.kjeder.length === 0
                                                ? "Alle"
                                                : abb.kjeder.map(k => (
                                                  <>
                                                    {k.isEkskluderende ? 'Unntatt' : 'Bare'}: {k.name} ({k.number})
                                                    <br />
                                                  </>
                                                ))}
                                            </td>

                                          </tr>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                  <Accordion.Header>Distribusjoner</Accordion.Header>

                                  <Accordion.Body>
                                    <div className={Style.selectedMottakerContainer}>
                                      <Button size="sm" style={{ float: 'right' }}
                                        variant={showHistorikk ? 'primary' : 'outline-primary'}
                                        onClick={() => setShowHistorikk(prev => !prev)}>
                                        {
                                          showHistorikk ? 'Lukk distribusjonshistorikk' : 'Se distribusjonshistorikk'
                                        }
                                      </Button>
                                    </div>
                                    {
                                      distribusjoner.map(d =>
                                        showHistorikk ?
                                          <DistribusjonsHistorikkTabell id={d.id} key={d.id} /> :
                                          <div key={d.id} className={Style.mottakerInfoContainer}>
                                            <dl className="row">
                                              <dt className="col-sm-3">Navn</dt>
                                              <dd className="col-sm-9">{d.navn}</dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Metode</dt>
                                              <dd className="col-sm-9">
                                                {d.metode} (${d.epostUrl})
                                              </dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Format</dt>
                                              <dd className="col-sm-9">{d.format}</dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Aktiv</dt>
                                              <dd className="col-sm-9">{d.aktiv ? 'Ja' : 'Nei'}</dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Komplett</dt>
                                              <dd className="col-sm-9">{d.komplett ? 'Ja' : 'Nei'}</dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Ikke HKodeNr</dt>
                                              <dd className="col-sm-9">
                                                {d.ikkeHKodenr ? 'Ja' : 'Nei'}
                                              </dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Endringsdager</dt>
                                              <dd className="col-sm-9">{d.endringsdager}</dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Abonnement</dt>
                                              <dd className="col-sm-9">{d.abonnementNavn}</dd>
                                            </dl>
                                            <dl className="row">
                                              <dt className="col-sm-3">Frekvens</dt>
                                              <dd className="col-sm-9">{d.frekvensText}</dd>
                                            </dl>
                                          </div>
                                      )
                                    }
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Col>
                          </Row>
                          {
                            selectedMottaker &&
                            <Row>
                              <Col>
                                <hr />
                                {selectedMottaker && <Button
                                  onClick={() => deleteMottaker(selectedMottaker.id)}
                                  variant="danger"
                                  className="btn-block">
                                  <IcebergIcon icon="trash" /> Slett mottaker
                                </Button>}
                              </Col>
                            </Row>
                          }
                        </>
                      }
                    </Accordion.Body>
                  </Accordion.Item>
                )
                ) :
                <div className={Style.spinnerContainer}>
                  <CommonLoader />
                  <h5>Henter grossister...</h5>
                </div>
              }
            </Accordion>
          </Col>
        </Row>
      </PageWrapper>
      {showCreateModal && <CreateModal onClose={handleCloseCreateModal} />}
    </>
  );
};

export default Mottakere;
