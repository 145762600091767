import React from 'react';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import useCopyToClipboard from 'utils/hooks/useCopyToClipboard';

interface Props {
  val: string;
  showDesc?: boolean;
}

const CopyToClipboardIcon: React.FC<Props> = props => {
  const { handleCopy, isCopied } = useCopyToClipboard(3000);

  return (
    <div style={{ cursor: 'pointer' }} onClick={() => handleCopy(props.val)}>
      {props.showDesc && (
        <span className='text-muted'>{isCopied ? 'kopiert' : 'kopier'} </span>
      )}
      <IcebergIcon
        size="16px"
        icon={isCopied ? 'check' : 'copy-to-clipboard'}
      />
    </div>
  );
};

export default CopyToClipboardIcon;
