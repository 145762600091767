import { useEffect, useState } from 'react';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'

import EnhetOverview from '../EnhetOverview';
import EnhetEdit from '../EnhetEdit';

import { EnhetViewModel, KontonrOgFakturaAdresseSjekkResponse } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { EnhetStatestikk, Fakturalinje, GrossistKunde } from 'services/SalgsdatainfoApi/types';

import HttpErrorHelper from 'utils/HttpErrorHelper';
import useCopyToClipboard from 'utils/hooks/useCopyToClipboard';
import useToaster from 'utils/hooks/useToaster';

import Style from './index.module.css';
import { IconButton } from '@tradesolution/iceberg-ui-react';
import { EnhetSearchFilter } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
import querystring from 'query-string';
import openInNewTab from 'utils/functions/openInNewTab';

interface Props {
  enhet: EnhetViewModel;
  enhetStatestikk: EnhetStatestikk;
  grossistKunder: GrossistKunde[];
  fakuralinjer: Fakturalinje[];
  isPossibleDuplicateByEnhetsnavnAndBesok: boolean;
  isPossibleDuplicateByByOrgnrAndBesok: boolean;
  handleEnhetUpdated: () => void;
  onNavigateToLopenummer: (lopenummer: number) => void;
  onMarkerSomKomplett: () => Promise<void>;
  onUndoMarkerSomKomplett: () => Promise<void>;
  kontonrOgFakturaAdresseSjekkResponse?: KontonrOgFakturaAdresseSjekkResponse;
}

interface LinkedForm {
  tab: string;
  field: string;
}

const EnhetView = (props: Props) => {
  const [isKomplettChecked, setIsKomplettChecked] = useState<boolean>(false);
  const [linkedForm, setLinkedForm] = useState<LinkedForm>({ tab: 'basis', field: undefined });
  const copiableEnhetDesc = `Enhetnavn: ${props.enhet.enhetnavn},Løpenummer: ${props.enhet.lopenr},GLN: ${props.enhet.firma.gln},Organisasjonsnummer: ${props.enhet.firma.organisasjonsnr},Juridisk navn: ${props.enhet.firma.juridisknavn},`.split(',').join('\r\n') + `Besøksadresse: ${props.enhet?.besoksadresse?.formattedForOneLine}`;
  const { handleCopy, isCopied } = useCopyToClipboard(3000);
  const toaster = useToaster();

  const onKomplettSwitchClicked = () => {
    setIsKomplettChecked(!isKomplettChecked);

    if (props.enhet.komplettTidspunkt == null) {
      props
        .onMarkerSomKomplett()
        .then(() => {
          setShowKomplettMsg(true);
        })
        .catch(err => {
          toaster.error(
            'Feil! kunne ikke markere som komplett',
            HttpErrorHelper.formatError(err),
          );
        });
    } else {
      props
        .onUndoMarkerSomKomplett()
        .then(() => {
          setShowKomplettMsg(true);
        })
        .catch(err => {
          toaster.error(
            'Feil! kunne ikke markere som komplett',
            HttpErrorHelper.formatError(err),
          );
        });
    }
  };
  const [showKomplettMsg, setShowKomplettMsg] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowKomplettMsg(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [showKomplettMsg]);

  useEffect(() => {
    if (props.enhet.komplettTidspunkt) {
      setIsKomplettChecked(true);
    } else {
      setIsKomplettChecked(false);
    }
  }, [props.enhet.komplettTidspunkt]);

  const handleGoToEnhetSearch = (criteria: string) => {
    let filter = new EnhetSearchFilter();
    filter.criteria = criteria;
    let searchstring = querystring.stringify(filter, {
      arrayFormat: 'bracket',
      skipNull: true,
    });
    openInNewTab(`/enheter?${searchstring}`);
  }

  if (!props.enhet) return <div />;
  return (
    <Card className={Style.basisInfoCard}>
      <Card.Header className={Style.cardHeader}>
        <div className={Style.headerInfoContainer}>
          <h6 className={Style.enehtNameHeader}>
            <span>{props.enhet.enhetnavn}</span>
          </h6>
          <h6 className={Style.headerBadge}>
            {props.enhet.firma.nedlagtDato && new Date(props.enhet.firma.nedlagtDato) < new Date() && (
              <Badge bg="danger">
                NEDLAGT
              </Badge>
            )}
            {props.enhet.firma.enhettype && (
              <Badge bg="info" text='dark'>
                {props.enhet.firma.enhettype}
              </Badge>
            )}
            {(props.isPossibleDuplicateByByOrgnrAndBesok || props.isPossibleDuplicateByByOrgnrAndBesok) && !props.enhet?.erPublisert && (
              props.isPossibleDuplicateByByOrgnrAndBesok ?
                <a href="#" className='badge bg-warning' onClick={() => handleGoToEnhetSearch(`${props.enhet.firma.organisasjonsnr} ${props.enhet.besoksadresse?.formattedForOneLine}`)}>Mulig duplikat</a> :
                <a href="#" className='badge bg-warning' onClick={() => handleGoToEnhetSearch(`${props.enhet.enhetnavn} ${props.enhet.besoksadresse?.formattedForOneLine}`)}>Mulig duplikat</a>
            )}
          </h6>
        </div>

        <div className={Style.headerActionsContainer}>
          <IconButton
            size='sm'
            icon={isCopied ? 'check' : 'copy-to-clipboard'}
            variant="outline-primary"
            onClick={() => handleCopy(copiableEnhetDesc.trim())}
          >
            Kopier til utklippstavle
          </IconButton>

          <div>
            <BootstrapSwitchButton
              checked={isKomplettChecked}
              onlabel='Komplett'
              offlabel='Ikke komplett'
              onstyle='success'
              offstyle='info'
              size='sm'
              style='komplettBtn'
              onChange={onKomplettSwitchClicked}
            />
          </div>

        </div>
      </Card.Header>

      <Card.Body className={Style.basisInfoCardBody}>
        {props.enhet && (
          <Row>
            <Col xl={4} className={Style.overviewCol}>
              <div className={Style.overviewContainer}>
                <EnhetOverview setLinkedForm={setLinkedForm} enhet={props.enhet} kontonrOgFakturaAdresseSjekkResponse={props.kontonrOgFakturaAdresseSjekkResponse} />
              </div>
            </Col>

            <Col xl={8} className={Style.tabFormsCol}>
              <EnhetEdit
                enhet={props.enhet}
                enhetStatestikk={props.enhetStatestikk}
                grossistKunder={props.grossistKunder}
                fakturalinjer={props.fakuralinjer}
                handleEnhetUpdated={props.handleEnhetUpdated}
                onNavigateToLopenummer={props.onNavigateToLopenummer}
                linkedForm={linkedForm}
                kontonrOgFakturaAdresseSjekkResponse={props.kontonrOgFakturaAdresseSjekkResponse}
              />
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default EnhetView;
