import React from 'react';
import { Alert, Modal, Button } from 'react-bootstrap';
import openInNewTab from 'utils/functions/openInNewTab';
import { EnhetSearchFilter, EnhetFromSearch } from 'services/KjederegisteretAdminApi/EnhetSearchApi/types';
import querystring from 'query-string';

interface Props {
  komplettTidspunkt: Date;
  show: boolean;
  duplicateByOrgnrAndBesok: EnhetFromSearch[];
  //duplicateByName: EnhetFromSearch[];
  duplicateByEnhetsnavnAndBesok: EnhetFromSearch[];
  isMissingBesoksadresse: boolean;
  isMissingEnhetstype: boolean;
  isMissingBransje: boolean;
  isMissingOppstartdato: boolean;
  criteria: {
    enhetId: number;
    enhetnavn: string;
    juridisknavn: string;
    organisasjonsnummer: string;
    besoksadresse: string;
  };
  onPubliser: () => void;
  onCloseModal: () => void;
}

const PubliserModal: React.FC<Props> = props => {

  const handleGoToEnhetSearch = (criteria: string) => {
    let filter = new EnhetSearchFilter();
    filter.criteria = criteria;
    let searchstring = querystring.stringify(filter, {
      arrayFormat: 'bracket',
      skipNull: true,
    });
    openInNewTab(`/enheter?${searchstring}`);
  }

  const canPublish = () => {
    return false;
  }

  return (
    <Modal show={props.show} onHide={props.onCloseModal}>
      <Modal.Dialog style={{ margin: '0' }}>
        <Modal.Header closeButton>
          <Modal.Title>Advarsler</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!props.komplettTidspunkt &&
            <Alert variant="warning">Enheten er markert som <strong>IKKE KOMPLETT</strong>. Vil du publisere likevel?</Alert>
          }
          {props.duplicateByOrgnrAndBesok && props.duplicateByOrgnrAndBesok.length > 0 &&
            <Alert variant="warning">
              Det finnes <strong>{props.duplicateByOrgnrAndBesok.length}</strong> enhet(er) med samme organisasjonsnummer og besoksadresse kombinasjon.
              <Alert.Link onClick={() => handleGoToEnhetSearch(`${props.criteria.organisasjonsnummer} ${props.criteria.besoksadresse}`)}> <strong>Er dette duplikat?</strong></Alert.Link>
            </Alert>
          }

          {props.duplicateByEnhetsnavnAndBesok && props.duplicateByEnhetsnavnAndBesok.length > 0 &&
            <Alert variant='warning'> Det finnes <strong>{props.duplicateByEnhetsnavnAndBesok.length}</strong> enhet(er) med samme enhetnavn og besoksadresse kombinasjon.
              <Alert.Link onClick={() => handleGoToEnhetSearch(`${props.criteria.enhetnavn} ${props.criteria.besoksadresse}`)}> <strong>Er dette duplikat?</strong></Alert.Link>
            </Alert>
          }

          {props.isMissingBesoksadresse && (
            <Alert variant="danger">Enhet mangler besoksadresse og kan ikke publiseres.</Alert>
          )}

          {props.isMissingBransje && (
            <Alert variant="danger">Enhet mangler bransje og kan ikke publiseres.</Alert>
          )}

          {props.isMissingOppstartdato && (
            <Alert variant="danger">Enhet mangler oppstartdato og kan ikke publiseres.</Alert>
          )}

          {props.isMissingEnhetstype && (
            <Alert variant="danger">Enhet mangler enhetstype og kan ikke publiseres.</Alert>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.onCloseModal}>
            Lukk
          </Button>
          <Button disabled={canPublish()} variant="primary" onClick={props.onPubliser}>
            Publiser
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </Modal>
  );
}


export default PubliserModal;