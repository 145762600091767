import React, { useState, useEffect } from "react";
import { Form, Button, Collapse, Row, Col, InputGroup } from "react-bootstrap";

import cx from "classnames";

import BransjePicker from "components/BransjePicker";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { EndringsMelding } from "services/QAApi/types";

import { lookUpBransjeNavnfraTine } from "./utils";

import Style from "./index.module.css";
import { Grossist } from "services/KjederegisteretAdminApi/ChangeRequestApi/types";
import FakturaLinjeModal from "./FakturalinjeModal";

interface Props {
  changerequest: EndringsMelding;
  onBransjeChanged: (id: number) => void;
  selectedBransjeId: number;
  notat: string;
  onNotatChanged: (value: string) => void;
  grossist?: Grossist;
}

const TilleggsInfoForm: React.FC<Props> = props => {
  const [isExpandBransje, setIsExpandBransje] = useState<boolean>(true);
  const [isExpandNotat, setIsExpandNotat] = useState<boolean>(false);
  const [isExpandDiverse, setIsExpandDiverse] = useState<boolean>(false);
  const [showFakturalinjeModal, setShowFakturalinjeModel] = useState<boolean>(false);  

  const toggleFakturalinjeModal = () => {    
    setShowFakturalinjeModel(!showFakturalinjeModal);
  };

  const findBransjeForslag = (leverandorName: string, bransjenr: string) => {
    if (!bransjenr) {
      return "";
    }
    if (leverandorName === "Tine" || leverandorName === "TINE SA") {
      return lookUpBransjeNavnfraTine(bransjenr) + ` (${bransjenr})`;
    }
    return bransjenr;
  }

  useEffect(() => {
    if (props.notat !== '') {
      setIsExpandNotat(true);
    }
  }, [props.notat]);

  useEffect(() => {
    if (props.changerequest.description !== '') {
      setIsExpandDiverse(true);
    }
  }, [props.changerequest.description]);

  return (
    <div className={Style.container}>
      <Form.Group className={Style.formGroup} controlId="bransjepicker">
        <div className={cx(Style.notatContainer, { [Style.collapsedNotatContainer]: !isExpandBransje })}
          onClick={() => setIsExpandBransje(!isExpandBransje)}>
          <h6 className={Style.notatHeader}>Bransjeinformasjon</h6>
          <Button variant="link" size="sm">
            <IcebergIcon icon={isExpandBransje ? 'chevron-up' : 'chevron-down'} bold />
          </Button>
        </div>
        <Collapse in={isExpandBransje}>
          <div>
            <Row>
              <Col>
                <div>
                  <Form.Group className={Style.formGroup}>
                    <Form.Label>Bransjeforslag fra {props.changerequest.changes.leverandorKundenummerHos}</Form.Label>
                    <InputGroup size="sm">
                      <Form.Control value={findBransjeForslag(props.changerequest.changes.leverandorKundenummerHos, props.changerequest.changes.bransjeForslag)} readOnly />
                    </InputGroup>
                  </Form.Group>
                </div>
              </Col>
              <Col>
                <div>
                  <Form.Label>Velg bransje</Form.Label>
                  <Form.Group className={Style.formGroup}>
                    <BransjePicker
                      onSelectionChanged={(pickedBransje) => props.onBransjeChanged(pickedBransje[0].id)}
                      selected={[props.selectedBransjeId]}
                      mode="radioSelect"
                      isSmall={true} />
                  </Form.Group>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {props.grossist?.kundenummerHosGrossist && !props.selectedBransjeId && (<Button variant="link" size="sm" onClick={() => toggleFakturalinjeModal()}>Vis siste 100 fakturalinjer</Button>)}
                  </div>                  
                </div>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Form.Group>
      <Form.Group className={Style.formGroup} controlId="notat">
        <div className={cx(Style.notatContainer, { [Style.collapsedNotatContainer]: !isExpandNotat })}
          onClick={() => setIsExpandNotat(!isExpandNotat)}>
          <h6 className={Style.notatHeader}>Notat</h6>
          <Button variant="link" size="sm">
            <IcebergIcon icon={isExpandNotat ? 'chevron-up' : 'chevron-down'} bold />
          </Button>
        </div>

        <Collapse in={isExpandNotat}>
          <div>
            <Row>
              <Col>
                <InputGroup size="sm">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={props.notat || ''}
                    onChange={e => props.onNotatChanged(e.target.value)} />
                </InputGroup>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Form.Group>

      <Form.Group className={Style.formGroup} controlId="description">
        <div className={cx(Style.notatContainer, { [Style.collapsedNotatContainer]: !isExpandDiverse })}
          onClick={() => setIsExpandDiverse(!isExpandDiverse)}>
          <h6 className={Style.notatHeader}>Diverse</h6>
          <Button variant="link" size="sm">
            <IcebergIcon icon={isExpandDiverse ? 'chevron-up' : 'chevron-down'} bold />
          </Button>
        </div>
        <Collapse in={isExpandDiverse}>
          <div>
            <Row>
              <Col>
                <InputGroup size="sm">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={props.changerequest.description || ''}
                    readOnly />
                </InputGroup>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Form.Group>
      {props.grossist && !props.selectedBransjeId && (
        <FakturaLinjeModal
        show={showFakturalinjeModal}       
        grossist={props.grossist}
        handleClose={() => toggleFakturalinjeModal()}
        />
      )}      
    </div>    
  )
}

export default TilleggsInfoForm;