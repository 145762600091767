import React, { useState } from 'react';
import { ListGroup, Alert, Button, FormCheck } from 'react-bootstrap';
import { GrupperingCommand } from '..';
import openInNewTab from 'utils/functions/openInNewTab';
import Style from './index.module.css';

interface Props {
  selected: GrupperingCommand;
  selectedIDs: number[];
  onSetSelectedGrupperinger: (id: number, label: string, idOver: number, nr: number, typekode: string) => void;
}

const GrupperingSuggestion: React.FC<Props> = props => {
  const [isShowAll, setIsShowAll] = useState<boolean>(false);

  return (
    <>
      {props.selected && (
        <div className={Style.container}>
          {props.selected.suggestions?.length > 0 && props.selected.suggestions?.map(y => (
            <div key={y.enhetgruppering.enhetgrupperingID}>
              {y.matchingUndergruppering && y.enhetgruppering ? (
                <ListGroup>
                  <ListGroup.Item className={Style.knytningSuggestionListItem}>
                    <div>
                      <span className={Style.enhetgrupperingnavn}>
                        {y.enhetgruppering.enhetsgrupperingnavn + ' '}
                      </span>
                      <span className={Style.dashSeperator}><strong>/</strong></span>
                      <Button
                        variant="link" size="sm"
                        className={Style.suggestionNameEnhetLink}
                        onClick={() => openInNewTab(`/enheter/${y.enhetgruppering.enhetID}`)}>
                        {y.matchingUndergruppering.enhetgrupperingnr && ' ' + y.matchingUndergruppering.enhetgrupperingnr + ' '}
                        {y.matchingUndergruppering.enhetsgrupperingnavn + ' '}
                        ({y.matchingUndergruppering.enhetgrupperingtypekode})
                      </Button>
                    </div>
                    <FormCheck
                      type="checkbox"
                      className={Style.checkbox}
                      checked={props.selectedIDs.filter(x => x === y.matchingUndergruppering.enhetgrupperingID).length > 0}
                      onChange={() =>
                        props.onSetSelectedGrupperinger(
                          y.matchingUndergruppering.enhetgrupperingID,
                          `${y.matchingUndergruppering.enhetsgrupperingnavn} ${y.matchingUndergruppering.enhetgrupperingtypekode}`,
                          y.matchingUndergruppering.enhetgrupperingIDOver, 
                          y.matchingUndergruppering.enhetgrupperingnr,
                          y.matchingUndergruppering.enhetgrupperingtypekode
                        )
                      }
                    />
                  </ListGroup.Item>
                </ListGroup>
              ) : (
                <Alert variant="info">
                  <span>Ingen match på navn. Se liste under for andre alternativer.</span>
                </Alert>
              )}

              {y.undergrupperinger?.length > 0 && (
                <Button variant="link" size="sm" onClick={() => setIsShowAll(!isShowAll)}>{!isShowAll ? 'Vis alle for' : 'Lukk alle for'} {y.enhetgruppering.enhetsgrupperingnavn}({y.undergrupperinger.length})</Button>
              )}

              {isShowAll && y.undergrupperinger && (
                <ListGroup className={Style.undergrupperingListGroup}>
                  {/* filtrer ut den grupperingen som matcher på navn med den som er valgt i picker før map */}
                  {y.undergrupperinger.filter(z => z.enhetsgrupperingnavn !== y.matchingUndergruppering.enhetsgrupperingnavn).map(g => (
                    <ListGroup.Item key={g.enhetgrupperingID} className={Style.knytningSuggestionListItem}>
                      <div>
                        <Button
                          variant="link" size="sm"
                          className={Style.suggestionNameEnhetLink}
                          onClick={() => openInNewTab(`/enheter/${g.enhetID}`)}
                        >
                          {' ' + g.enhetgrupperingnr + ' '}
                          {g.enhetsgrupperingnavn + ' '}
                          ({g.enhetgrupperingtypekode})
                        </Button>
                      </div>
                      <FormCheck
                        type="checkbox"
                        className={Style.checkbox}
                        checked={props.selectedIDs.filter(x => x === g.enhetgrupperingID).length > 0}
                        onChange={() =>
                          props.onSetSelectedGrupperinger(
                            g.enhetgrupperingID,
                            `${g.enhetsgrupperingnavn} ${g.enhetgrupperingtypekode}`,
                            g.enhetgrupperingIDOver, 
                            g.enhetgrupperingnr,
                            g.enhetgrupperingtypekode
                          )
                        }
                      />
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </div>

          ))}
        </div>
      )}
    </>
  )
}

export default GrupperingSuggestion;
