import { Bar } from 'react-chartjs-2';
import { PeriodDetail } from 'services/QAApi/types';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
  data: PeriodDetail[];
}

const BarChart = (props: Props) => {
  const data = {
    labels: props.data.map((o) => o.kilde),
    datasets: [
      {
        label: 'Avvist',
        data: props.data.map((o) => o.antallAvist),
        backgroundColor: '#F58F18',
        borderWidth: 1,
      },
      {
        label: 'Behandlet',
        data: props.data.map((o) => o.antallBehandlet),
        backgroundColor: '#32872C',
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom' as const,
        display: true,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <div>
      <Bar data={data} height={400} width={600} options={options} />
    </div>
  );
};

export default BarChart;
