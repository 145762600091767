import React, { useState, useEffect } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import Api from 'services/KjederegisteretAdminApi/EnhetApi';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';
import GrupperingMedlemskap from './components/GrupperingMedlemskap';
import AddToGrupperingForm from './components/AddToGrupperingForm';
import Style from './index.module.css';
import { ReaktiverEnhetgruppeMedlemskapCommand, RemoveEnhetgruppeFromEnhetCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { EnhetgrupperingnavnHierarki } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import { EnhetViewModel, Enhetsgruppering } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import CommonLoader from 'components/CommonLoader';
import { format } from 'date-fns'

interface Props {
  enhet: EnhetViewModel;
  onUpdated: () => void;
}

const Gruppering = (props: Props) => {
  const toaster = useToaster();
  const [grupperinger, setGrupperinger] = useState<Enhetsgruppering[]>([]);
  const [grupperingNavnHierarki, setGrupperingNavnHierarki] = useState<EnhetgrupperingnavnHierarki[]>([]);
  const [isFetchingGrupperinger, setIsFetchingGrupperinger] = useState<boolean>(false);
  const [isShowAddGruppering, setIsShowAddGruppering] = useState<boolean>(false);

  const isMountedRef = useIsMountedRef();

  const loadGrupperinger = async () => {
    setIsFetchingGrupperinger(true);
    const eg = await Api.getGrupperingerForEnhet(props.enhet.id);
    const grNavn = await EnhetsgrupperingApi.getEnhetsgrupperingNavnHierarki(props.enhet.id);

    if (isMountedRef.current) {
      setGrupperinger(eg);
      setGrupperingNavnHierarki(grNavn);
    }
    setIsFetchingGrupperinger(false);
  };

  useEffect(() => {
    setGrupperinger([]);
    loadGrupperinger();
  }, [props.enhet]);

  const handleUpdateEnhet = () => {
    loadGrupperinger();
  };

  function filterNavnHierarki(id: number) {
    const resultater = grupperingNavnHierarki.filter(g => g.struktur === id);
    return resultater;
  }

  const fjernEnhetsgruppe = async (enhetgrupperingId: number) => {
    const command: RemoveEnhetgruppeFromEnhetCommand = {
      enhetId: props.enhet.id,
      enhetgrupperingId: enhetgrupperingId,
    };
    try {
      await Api.removeEnhetsgruppeFromEnhet(command);
      handleUpdateEnhet();
      props.onUpdated();
      toaster.success('Suksess!', 'Enhetgruppe fjernet fra enhet');
    } catch (err) {
      toaster.error(
        'Feil! Kunne ikke fjerne enhetgruppe.',
        HttpErrorHelper.formatError(err),
      );
    }
  };

  const checkForDuplicateGroupings = (group: string, date: Date) => {
    return grupperinger.some( 
      g => g.enhetgrupperingtypekode === group && 
        format(g.fraOgMedDato, 'dd.MM.yyyy') === format(date, 'dd.MM.yyyy') );
  }
  const onReaktiver = async (enhetgrupperingId: number) => {
    const command: ReaktiverEnhetgruppeMedlemskapCommand = {
      enhetId: props.enhet.id,
      enhetgrupperingId: enhetgrupperingId,
    };
    try {
      await Api.reaktiverEnhetsgruppeMedlemskap(command);
      handleUpdateEnhet();
      props.onUpdated();
      toaster.success('Suksess!', 'Enhetsgruppe reaktivert');
    } catch (err) {
      toaster.error(
        'Feil! Kunne ikke reaktivere.',
        HttpErrorHelper.formatError(err),
      );
    }
  };

  return (
    <div>
      <Row className={Style.addToGrupperingRow}>
        <Col>
          <Row>
            <Col>
              <Button className={Style.showAddgrupperingBtn} size="sm" variant="primary"
                onClick={() => setIsShowAddGruppering(!isShowAddGruppering)}>
                <IcebergIcon icon={!isShowAddGruppering ? 'plus' : 'close'} bold />
                <span style={{ marginLeft: '.5em' }}>
                  {!isShowAddGruppering ? 'Knytt til ny enhetsgruppering' : 'Lukk'}
                </span>
              </Button>
            </Col>
          </Row>
          {isShowAddGruppering && (
            <Row>
              <Col>
                <AddToGrupperingForm
                  isFetchingGrupperinger={isFetchingGrupperinger}
                  setIsShowForm={setIsShowAddGruppering}
                  onUpdateEnhet={handleUpdateEnhet}
                  onUpdated={props.onUpdated}
                  enhetId={props.enhet.id}
                  checkForDuplicateGroupings={checkForDuplicateGroupings}
                  skalBetraktesSomKjedeMedlemskapDefaultChecked={props.enhet.regionalkjedeHistorikk.length === 0 && props.enhet.markedskjedeHistorikk.length === 0 && grupperinger.length === 0}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Row>
        <Col>
          {isFetchingGrupperinger ? (
            <div className={Style.spinnerContainer}>
              <CommonLoader />
              <h5>Henter enhetsgrupperinger...</h5>
            </div>
          ) : (
            <div className={Style.medlemskapContainer}>
              {grupperinger.map(g => (
                <GrupperingMedlemskap
                  key={g.enhetEnhetgrupperingID}
                  gruppering={g}
                  enhetId={props.enhet.id}
                  triggerReloadGrupperinger={loadGrupperinger}
                  navnHierarki={filterNavnHierarki(g.enhetgrupperingId)}
                  handleFjernEnhetFromEnhetsgruppe={() => fjernEnhetsgruppe(g.enhetgrupperingId)}
                  handleReaktiverEnhetsgruppering={() => onReaktiver(g.enhetgrupperingId)}
                />
              ))}
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Gruppering;
