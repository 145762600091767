import { useCallback, useEffect, useState } from 'react';
import { Form, Alert, Button, Col, Row } from 'react-bootstrap';
import MarkedsKjedePicker from 'components/MarkedsKjedePicker';
import RegionalkjedePicker from 'components/RegionalkjedePicker';
import DatePicker from 'components/DatePicker';
import useToaster from 'utils/hooks/useToaster';
import KjedeApi from 'services/KjederegisteretAdminApi/KjedeApi';
import KjedeknytningerApi from 'services/KjederegisteretAdminApi/KjedeknytningerApi';
import { OverliggendeKjede, AddOverliggendeKjedeCommand } from 'services/KjederegisteretAdminApi/KjedeknytningerApi/types';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import { format } from 'date-fns';
import addMinutes from 'date-fns/addMinutes';
import Style from './index.module.css';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';
import { PickedKjede, PickerMode } from 'components/KjedePicker';
import { KjedeType } from 'services/KjederegisteretAdminApi/KjedeApi/types';

interface Props {
  overliggendeList: OverliggendeKjede[];
  kjede: {
    kjedeID: number;
    overliggendRegionKjedeID?: number;
    overliggendSalgsOgMarkedsKjedeID?: number;
    kjedenavn: string;
  };
  kjedeType: string;
  loadHistorikk: (kjedeId: number) => void;
  onParentUpdated: (name: string) => void;
  onMedlemskapUpdated: (kjedeId: number) => void;
  setIsEdit: (isEdit: boolean) => void;
}

const MedlemskapEdit = (props: Props) => {
  const [memberCount, setMemberCount] = useState<number>(0);
  const [changeDate, setChangeDate] = useState(new Date());
  const [isLatestHistoryUtmeldtDate, setIsLatestHistoryUtmeldtDate] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  const [overliggendeRegionID, setOverliggendeRegionID] = useState<number>(
    props.kjede.overliggendRegionKjedeID,
  );
  const [overliggendeMarkedID, setOverliggendeMarkedID] = useState<number>(
    props.kjede.overliggendSalgsOgMarkedsKjedeID,
  );
  const toaster = useToaster();
  const isMountedRef = useIsMountedRef();

  const handleChangeParent = async (kjeder: PickedKjede[], type: KjedeType) => {
    if (type === KjedeType.Region) {
      if (kjeder.length > 0) {
        setOverliggendeRegionID(kjeder[0].id);
        props.onParentUpdated(kjeder[0].name);
      } else {
        setOverliggendeRegionID(null);
        props.onParentUpdated('');
      }
    }
    if (type === KjedeType.Marked) {
      if (kjeder.length > 0) {
        setOverliggendeMarkedID(kjeder[0].id);
        props.onParentUpdated(kjeder[0].name);
      } else {
        setOverliggendeMarkedID(null);
        props.onParentUpdated('');
      }
    }
    checkMemberCount();
  };

  const checkMemberCount = async () => {
    try {
      const count = await KjedeApi.getAntallaktivemedlemmerforkjedeplussunderkjeder(
        props.kjede.kjedeID,
        props.kjedeType,
      );
      if (count) {
        setMemberCount(count);
      }
    } catch (error) {
      console.log(
        'Kunne ikke hente antall medlemmer endringen i kjedemedlemskap påvirker: ',
        error,
      );
    }
  };

  const updateMembership = async (kjedeType: string) => {
    let kjedeIdOver;

    if (kjedeType === KjedeType.Region) {
      kjedeIdOver = overliggendeRegionID;
    }

    if (kjedeType === KjedeType.Marked) {
      kjedeIdOver = overliggendeMarkedID;
    }

    if (kjedeType === KjedeType.Topp) {
      kjedeIdOver = null;
    }

    const command: AddOverliggendeKjedeCommand = {
      kjedeID: props.kjede.kjedeID,
      OverliggendeKjedeId: kjedeIdOver,
      kjedeTypeKode: kjedeType,
      fraOgMedDato: changeDate,
    };

    try {
      await KjedeknytningerApi.addOverliggende(props.kjede.kjedeID, command);
      toaster.success('Suksess!', 'Kjedeknytning er oppdatert!');
      props.setIsEdit(false);
      props.loadHistorikk(props.kjede.kjedeID);
      props.onMedlemskapUpdated(props.kjede.kjedeID);
    } catch (err) {
      toaster.error(
        'Feil! Kunne ikke oppdatere kjedeknytning.',
        HttpErrorHelper.formatError(err),
      );
    }
  };

  const displayInfoAlert = (count: number) => {
    return (
      <Alert variant="info" className={Style.memberCountInfo}>
        {count} aktive enheter vil få nytt kjedehierarki som følge av denne endringen.
      </Alert>
    );
  };

  useEffect(() => {
    if (props.overliggendeList.length > 0 && !props.overliggendeList.some(x => x.isActive && !x.tilOgMedDato)) {
      const latest = props.overliggendeList.reduce((a, b) =>
        new Date(a.tilOgMedDato) > new Date(b.tilOgMedDato) ? a : b,
      );
      if (latest && isMountedRef.current) {
        const date = new Date(latest.tilOgMedDato);
        const next = new Date(date.getTime() + 1000 * 60 * 60 * 24); // next day
        setChangeDate(addMinutes(next, -new Date().getTimezoneOffset()));
        setIsLatestHistoryUtmeldtDate(true);
      } else {
        setChangeDate(new Date());
        setIsLatestHistoryUtmeldtDate(false);
      }
    }
  }, [props.overliggendeList]);

  const handleParentKjedeChanged = useCallback((kjeder: PickedKjede[]) => {
    handleChangeParent(kjeder, props.kjedeType);
  }, []);

  useEffect(() => {
    if (isMountedRef.current) {
      checkMemberCount();
      setOverliggendeRegionID(props.kjede.overliggendRegionKjedeID);
      setOverliggendeMarkedID(props.kjede.overliggendSalgsOgMarkedsKjedeID);
    }
  }, [props.kjede, props.kjedeType]);

  useEffect(() => {
    if (props.kjedeType === KjedeType.Region && !overliggendeRegionID) {
      setIsSubmitDisabled(true);
    } else if (props.kjedeType === KjedeType.Marked && !overliggendeMarkedID) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [overliggendeMarkedID, overliggendeRegionID]);

  return (
    <div className={Style.container}>
      <Row>
        <Col md={6} lg={12} xl={6}>
          <Form.Label>Ny overliggende kjede</Form.Label>
          {props.kjedeType === KjedeType.Region && (
            <RegionalkjedePicker
              pickerMode={PickerMode.Single}
              selected={[overliggendeRegionID]}
              onSelectionChanged={handleParentKjedeChanged}
            />
          )}
          {props.kjedeType === KjedeType.Marked && (
            <MarkedsKjedePicker
              pickerMode={PickerMode.Single}
              selected={[overliggendeMarkedID]}
              onSelectionChanged={handleParentKjedeChanged}
            />
          )}
        </Col>
        <Col md={6} lg={12} xl={6}>
          <Form.Label>Innmeldingsdato</Form.Label>
          <DatePicker selected={changeDate} onChange={d => setChangeDate(d)} adjustTimeForTimezoneOffset={true} />
          {isLatestHistoryUtmeldtDate && (
            <Form.Text>
              Automatisk satt til dagen etter forrige aktive medlemskap sin utmeldingsdato for å
              unngå hull i historikk.
            </Form.Text>
          )}
          {!isLatestHistoryUtmeldtDate &&
            format(changeDate, 'dd.MM.yyyy') === format(new Date(), 'dd.MM.yyyy') && (
              <Form.Text>Automatisk satt til dagens dato.</Form.Text>
            )}
        </Col>
      </Row>
      {memberCount > 0 && displayInfoAlert(memberCount)}
      <Row>
        <Col>
          <Button
            disabled={isSubmitDisabled}
            className={Style.submitBtn}
            onClick={() => updateMembership(props.kjedeType)}
          >
            Opprett
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default MedlemskapEdit;
