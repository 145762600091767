/*IMPORTS */

/*React & React module dependencies */
import { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/*Third-party modules */
import { format } from 'date-fns';

/*Third-party modules */
import DatePicker from 'components/DatePicker';
import PageWrapper from 'components/PageWrapper';

/*hooks */
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

/*Services */
import { ImportData } from 'services/ImportApi/types';
import ImportApi from 'services/ImportApi';
import { Form } from 'react-bootstrap';
import CommonLoader from 'components/CommonLoader';

/*IMPORTS END */

const Importer = () => {
  const isMountedRef = useIsMountedRef();

  /*useState properties */
  const [imports, setImports] = useState<ImportData[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  const loadImports = async (date: Date) => {
    setIsLoading(true);
    const day = format(date, 'yyyyMMdd');
    const data = await ImportApi.get(day);
    if (isMountedRef.current) {
      setImports(data.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()));
    }
    setIsLoading(false);
  };

  const formatTimestamps = (from: Date, to: Date) => {
    const timestampFormat = 'HH.mm.ss';
    return `kl ${format(from, timestampFormat)} - kl ${format(to, timestampFormat)}`;
  };

  const formatDuration = (from, to) => `${(to - from) / 1000} sek`;

  /*useEffect */
  useEffect(() => {
    loadImports(selectedDate);
  }, [selectedDate]);

  /*Content */
  return (
    <PageWrapper isFluid header="Dagens importkjøringer">
      <Row>
        <Col>
          <Form>
            <Form.Group>
              <DatePicker
                selected={selectedDate}
                onChange={date => setSelectedDate(date)}
                adjustTimeForTimezoneOffset={true}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col>
          {isLoading && <CommonLoader />}
          {!isLoading && imports.length === 0 && <div style={{ textAlign: "center", padding: "2rem" }}>Finner ingen importer for valgt dato</div>}
          {!isLoading && imports.length > 0 &&
            <Table hover>
              <thead>
                <tr>
                  <th>Kilde</th>
                  <th style={{ textAlign: "center" }}>Antall endringsmeldinger</th>
                  <th>Start - Slutt</th>
                  <th>Varighet</th>
                </tr>
              </thead>
              <tbody>
                {imports.map(i => (
                  <tr key={i.rowKey}>
                    <td>{i.source}</td>
                    <td style={{ textAlign: "center" }}>{i.sentTilQaCount}</td>
                    <td>{formatTimestamps(new Date(i.start), new Date(i.end))}</td>
                    <td>{formatDuration(new Date(i.start), new Date(i.end))}</td>
                  </tr>
                ))}
              </tbody>
            </Table>}
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default Importer;
