// for statistical model of QA/Home page
export interface BehandletStatistikk {
  periods: Period[];
}

export interface PeriodDetail {
  kilde: string;
  antallBehandlet: number;
  antallAvist: number;
}

export interface Period {
  periodName: string;
  group: string;
  antallBehandlet: number;
  antallAvist: number;
  details: PeriodDetail[];
}

export interface Statistikk {
  antallAvviste: number;
  antallAktive: number;
  antallFolgesOpp: number;
  antallBehandlet: number;
  perKilde: {
    kilde: string;
    antallAktive: number;
    antallFolgesOpp: number;
  }[];
}

export interface SearchFilter {
  status: string;
  tags: string[];
  query: string;
  source: string;
  orderBy: string;
  take: number;
  leverandorKundenummerHosTsKundeId?: string;
  grossistKundenummerHosGln: string[];
}

export interface Filter {
  id: string;
  eierGln: string;
  organisasjonsnummer: string;
  comment: string;
  changedBy: string;
  lastChanged?: Date;
}

export interface EndringsMelding {
  behandlesAv: BehandlesAv[];
  folgesOppAv: {
    changedBy: string;
    comment?: string;
    status: string;
    statusChangedAt: Date;
  };
  id: string;
  lastChangedBy: string;
  changesMightBeRelatedToEnhetId?: number;
  changesMightBeRelatedToLopenummer?: string;
  changesUsedInEnhetWithLopenummer?: string;
  changesUsedInEnhetWithEnhetId?: string;
  description: string;
  enhetId?: string;
  lopenummer?: string;
  lastVasketAt: Date;
  recievedAt: Date;
  sentBy: string;
  sentBySystem: string;
  contactEmail: string;
  title: string;
  raw: any;
  status: string;
  statusLog: {
    changedBy: string;
    comment?: string;
    status: string;
    statusChangedAt: Date;
  }[];
  changes: {
    besoksadresse: QaAddress;
    fakturaAdresse?: QaAddress;
    postadresse?: QaAddress;
    bransjeForslag: string;
    enhetnavn: string;
    gln: string;
    eierGLN: string;
    eierGLNNavn: string;
    grossistKundenummer?: string;
    grossistKundenummerHos?: string;
    grossistKundenummerHosGln?: string;
    juridisknavn: string;
    kommentar: string;
    leverandorKundenummer: string;
    leverandorKundenummerHos: string;
    leverandorKundenummerHosTsKundeId: string;
    lopenr?: string;
    nedlagtDato?: Date;
    organisasjonsnr: string;
    telefon: string;
    validations: {
      fieldName: string;
      fieldValue: string;
      validationResults: {
        isValueValid: boolean;
        occuredAt: Date;
        resultat: string;
        source: string;
        validationMessage: string;
      }[];
    }[];
  };
}

export interface BehandlesAv {
  navn: string;
  time: Date;
}

interface QaAddress {
  eastingUtmZone33N?: number;
  gateadresse: string;
  latitude?: number;
  longitude?: number;
  northingUtmZone33N?: number;
  postnr: string;
  poststed: string;
  formattedForOneLine: string;
  adressetype: 'Besøksadresse' | 'FakturaAdresse' | 'Postadresse' | 'Leveringsadresse';
  validations: {
    fieldName: string;
    fieldValue: string;
    validationResults: {
      isValueValid: boolean;
      occuredAt: Date;
      resultat: string;
      source: string;
      validationMessage: string;
    }[];
  }[];
}

export interface BehandletAction {
  id: string;
  lopenummer?: string;
  changedBy: string;
  enhetId?: number;
}

export interface BehandlesAction {
  id: string;
  approvedByusername: string;
}

export interface UpsertFilterAction {
  id: string;
  eierGln: string;
  organisasjonsnummer: string;
  comment: string;
  changedBy: string;
}

export class ChangeRequestStatus {
  static Ubehandlet = 'ubehandlet';
  static FolgesOpp = 'folgesOpp';
  static Behandlet = 'behandlet';
  static Avvist = 'avvist';
}