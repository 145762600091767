export const mapBransjeTre = (bransjeTre: any[], selected: any[], canSelectNedlagt?: boolean) => {
  if (!bransjeTre) return [];
  return bransjeTre.map(o => mapNode(o, selected, canSelectNedlagt));
};

const mapNode = (node: any, selected: any[], canSelectNedlagt?: boolean) => {
  const item = {
    label: node.tilOgMedDato ? node.name + "  (nedlagt)" : node.name,
    id: node.id,
    children: [],
    checked: false,
    expanded: false,
    disabled: node.tilOgMedDato && !canSelectNedlagt ? true : false
  };

  if (selected.indexOf(node.id) > -1) {
    item.checked = true;
  }

  if (node.children) {
    node.children.forEach((element: any) => {
      item.children.push(mapNode(element, selected));
    });
  }
  return item;
};