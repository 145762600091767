import { useState, useEffect } from "react";
import { Collapse, Button, Row, Form, InputGroup, OverlayTrigger, Tooltip, Alert } from "react-bootstrap";

import cx from "classnames";

import CompareIcon from "../../../CompareIcon";

import CopyToClipboardIcon from "components/CopyToClipboardIcon";
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { qaCompareSimpleValue } from "../../../../utils";

import Style from "../../index.module.css";
import useLocalStorage from "utils/hooks/useLocalStorage";
import { EnhetViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";


interface Props {
    glnFromCr?: string;
    gln?: string;
    eierGLNNavnFromCr?: string;
    eierGLNNavn?: string;
    sentBySystem: string;
    onChange: (updatedGln: string) => void;
    enhet: EnhetViewModel;
    sentBy: string;
}

const GlnRow = (props: Props) => {
    const [isExpandRow, setIsExpandRow] = useState<boolean>(false);
    const [isUserPreferneceExpandRow, setIsUserPreferneceExpandRow] = useLocalStorage<boolean>('userPreferenceGlnRowExpanded', false);

    const [compareStatus, setCompareStatus] = useState<string>(
        qaCompareSimpleValue(props.glnFromCr, props.gln),
    );
    const [eierGlnNavnCompareStatus, setEierGlnNavnCompareStatus] = useState<string>();

    useEffect(() => {
        if (compareStatus === 'ulik' || compareStatus === 'ny') {
            setIsExpandRow(true);
        }

        if (eierGlnNavnCompareStatus === 'ulik') {
            setIsExpandRow(true);
        }

        if (isUserPreferneceExpandRow) {
            setIsExpandRow(true);
        }

        if (props.enhet?.firma?.glnNedlagtDato) {
            setIsExpandRow(true);
        }

    }, [compareStatus, eierGlnNavnCompareStatus]);

    useEffect(() => {
        setCompareStatus(qaCompareSimpleValue(props.glnFromCr, props.gln));
    }, [props.gln, props.glnFromCr]);

    useEffect(() => {
        setEierGlnNavnCompareStatus(qaCompareSimpleValue(props.eierGLNNavn, props.eierGLNNavnFromCr));
    }, [props.eierGLNNavn, props.eierGLNNavnFromCr]);

    const handleExpandRowClick = () => {
        setIsExpandRow(!isExpandRow);
        setIsUserPreferneceExpandRow(!isExpandRow);
    };

    return (
        <>
            <div
                className={cx(Style.changeRequestRowHeaderContainer, {
                    [Style.isNotExpandedRowContainer]: !isExpandRow,
                })}
                onClick={handleExpandRowClick}
            >
                <h6 className={Style.changeRequestRowHeader}>
                    {!isExpandRow && <CompareIcon compareStatus={compareStatus} size="sm" />}
                    <span className={Style.changeRequestRowHeaderText}>
                        GLN{' '}
                    </span>
                </h6>
                <Button variant="link" size="sm">
                    <IcebergIcon icon={isExpandRow ? 'chevron-up' : 'chevron-down'} bold />
                </Button>
                {
                    isExpandRow && props.enhet?.firma?.glnNedlagtDato &&
                    <span style={{ display: 'inline-block', marginLeft: 'auto' }}>
                        <Form.Text className="text-muted">Gln er nedlagt</Form.Text>
                    </span>
                }
            </div>
            <Collapse in={isExpandRow}>
                <div>
                    <Row className={Style.formGroupRow}>
                        <div className={Style.formGroupContainer}>
                            <Form.Group className={Style.formGroup}>
                                <InputGroup size="sm">
                                    <Form.Control readOnly value={props.glnFromCr || ''} />
                                    <InputGroup.Text>
                                        <CopyToClipboardIcon val={props.glnFromCr} />
                                    </InputGroup.Text>
                                </InputGroup>
                                {props.eierGLNNavnFromCr && (
                                    <Form.Text className='text-muted'>
                                        Eier: <strong> {props.eierGLNNavnFromCr}</strong>
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </div>
                        {props.sentBySystem === 'GlnApi' || props.sentBy === 'GlnSnitch' || props.sentBy === 'GlnApi' || props.sentBy === 'Salgsdata' ? (
                            <div className={Style.compareIconContainer}>
                                <CompareIcon
                                    compareStatus={compareStatus}
                                    onClick={() => props.onChange(props.glnFromCr)} />
                            </div>
                        ) : (
                            <div className={Style.compareIconContainer}>
                                <OverlayTrigger
                                    key="tooltip-top"
                                    placement="top"
                                    overlay={<Tooltip id="tooltip">Innkommende GLN kommer ikke fra GlnApi.</Tooltip>}>
                                    <div className={Style.changeRequestCompareIcon}>
                                        <IcebergIcon icon="close" backgroundColor="grey" color="#fff" bold rounded />
                                    </div>
                                </OverlayTrigger>
                            </div>
                        )}

                        <div className={Style.formGroupContainer}>
                            <Form.Group className={Style.formGroup}>
                                <InputGroup size="sm">
                                    {
                                        props.enhet?.firma?.glnNedlagtDato ?
                                            <Form.Control className="text-danger fw-bold" value={props.gln || ''} readOnly /> :
                                            <Form.Control value={props.gln || ''} readOnly />
                                    }

                                </InputGroup>
                                {props.eierGLNNavn && (
                                    <Form.Text className="text-muted">
                                        Eier:
                                        {eierGlnNavnCompareStatus === 'ulik' && <strong style={{ color: 'red' }}> {props.eierGLNNavn}</strong>}
                                        {eierGlnNavnCompareStatus !== 'ulik' && <strong> {props.eierGLNNavn}</strong>}
                                    </Form.Text>
                                )}
                                {/* {props.enhet?.firma?.glnNedlagtDato && 
                                    <Form.Text className="text-danger fw-bold">, Gln er nedlagt.</Form.Text>
                                } */}
                            </Form.Group>
                        </div>
                    </Row>
                </div>
            </Collapse>
        </>
    )
}

export default GlnRow;