import { useState } from 'react';

// Type variable T to the identity function.
// This T allows us to capture the type the user provides (e.g. number),
// so that we can use that information later.
export default function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store value
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // get from local storage
      const item = window.localStorage.getItem(key);

      if (item === 'undefined') {
        return undefined;
      }
      // if stored return parsed value, if not return intitalvalue..
      return !!item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log('Feil ved henting fra localStorage: ', error);
      //return initialvalue if error also
      return initialValue;
    }
  });

  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      //Save local changes
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue] as const;
}
