import { useState, useEffect, useCallback } from "react";
import { Form, Row, Col, Tabs, Tab } from "react-bootstrap";
import SortingIcon from "components/SortingIcon";

import ToggleSortByTag from "./components/ToggleSortByTag";
import { sortOptions } from "./components/ToggleSortByTag/utils";
import SignalRConnectionStatusIcon from "./components/SignalRConnectionStatusIcon";
import ChangeRequestsList from "./components/ChangeRequestsList";

import { SearchFilter, Statistikk, EndringsMelding } from "services/QAApi/types";

import Style from "./index.module.css";
import MottakerTypeahead from "components/MottakerTypeahead";
import GrossistgruppePicker from "components/GrossistgruppePicker";
import CommonLoader from "components/CommonLoader";
import PopoverQACardList from "./PopoverQACardList";

interface Props {
    filter: SearchFilter;
    onFilterChanged: (value: string, type: string) => void;
    onFilterTagsChanged: (values: string[]) => void;
    onFilterGlnChanged: (value: string[]) => void;
    onFilterTabChanged: (value: string) => void;
    signalRConnectionStatus: string;
    loadingChangeRequest: boolean;
    statistikk: Statistikk;
    changeRequests: EndringsMelding[];
    folgesOppRequests: EndringsMelding[];
    processedChangeRequests: EndringsMelding[];
    rejectedChangeRequests: EndringsMelding[];
    selectedChangeRequest: EndringsMelding;
    onChangeRequestSelected: (changeRequest: EndringsMelding, nextChangeRequest?: EndringsMelding) => void;
}

const QACardList = (props: Props) => {

    const [antallAktive, setAntallAktive] = useState<number>(props.statistikk.antallAktive | 0);
    const [antallFolgesOpp, setAntallFolgesOpp] = useState<number>(props.statistikk.antallFolgesOpp | 0);
    const [kilde, setKilde] = useState<string | null>(props.filter.source);

    const handleCardClicked = (changeRequest: EndringsMelding, nextChangeRequest?: EndringsMelding) => {
        // next is for being able to go to next changeRequest after submitting
        if (changeRequest && nextChangeRequest) {
            props.onChangeRequestSelected(changeRequest, nextChangeRequest);
        } else if (changeRequest && !nextChangeRequest) {
            props.onChangeRequestSelected(changeRequest);
        }
    };

    useEffect(() => {
        if (!kilde) {
            setAntallAktive(props.statistikk.antallAktive);
            setAntallFolgesOpp(props.statistikk.antallFolgesOpp);
        }
        else {
            const item = props.statistikk.perKilde.find(x => x.kilde === kilde);
            setAntallAktive(item?.antallAktive ?? 0);
            setAntallFolgesOpp(item?.antallFolgesOpp ?? 0);
        }
    }, [kilde, props.statistikk]);

    const loadingContent =
        <div className={Style.loadingListPaceholder}>
            <h4 className={Style.loadingListHeader}>Laster endringsmeldinger..</h4>
            <CommonLoader />
        </div>

    const handleGrossistgruppeSelectionChanged = useCallback((grossistGln: string[]) => {
        console.log(grossistGln);
        props.onFilterGlnChanged(grossistGln);
    }, []);

    return (
        <>
            <Form className="mb-3">
                <Row>
                    <Col>
                        <Form.Group controlId="filterFormGroup">
                            <Form.Control
                                type="filter"
                                value={props.filter.query}
                                placeholder="Filtrer endringsmeldinger..."
                                onChange={e => props.onFilterChanged(e.target.value, 'query')} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Form.Select as="select" value={kilde} onChange={e => {
                            setKilde(e.target.value);
                            props.onFilterChanged(e.target.value, 'source');
                        }}>
                            <option value="">--Filtrer på kilde--</option>
                            <option key="GlnApi" value="GlnApi">GlnApi</option>
                            <option key="Tine" value="Tine">Tine</option>
                            <option key="DiplomIs" value="DiplomIs">DiplomIs</option>
                            <option key="BladCentralen" value="BladCentralen">BladCentralen</option>
                            <option key="Langaard" value="Langaard">Langaard</option>
                            <option key="Salgsdata" value="Salgsdata">Salgsdata</option>
                            <option key="Snitch" value="Snitch">Snitch</option>
                        </Form.Select>
                    </Col>
                    <Col>
                        <Form.Group controlId="takeFilter" className="mb-3">
                            <Form.Select value={props.filter.take} onChange={e => props.onFilterChanged(e.target.value, 'take')}>
                                <option>25</option>
                                <option>50</option>
                                <option>100</option>
                                <option>200</option>
                                <option>500</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <MottakerTypeahead
                            onHandleChange={mottaker => props.onFilterChanged(mottaker ? mottaker.tsKundeId : null, 'leverandorKundenummerHosTsKundeId')}
                            placeholderText="Filtrer på mottaker"
                        />
                    </Col>
                    <Col>
                        <GrossistgruppePicker
                            onSelectionChanged={handleGrossistgruppeSelectionChanged}
                            findSelectedGrossists={() => props.filter.grossistKundenummerHosGln}
                        />
                    </Col>
                </Row>
            </Form>
            <ToggleSortByTag options={sortOptions} onChange={props.onFilterTagsChanged} />

            <div className={Style.statusIcon}>
                <div onClick={() => props.onFilterChanged('', 'orderBy')} style={{ cursor: 'pointer' }}>
                    <SortingIcon sortDirection={props.filter.orderBy === 'ascending' ? 'asc' : (props.filter.orderBy === 'decending' ? 'desc' : '')} />
                </div>
                <SignalRConnectionStatusIcon status={props.signalRConnectionStatus} />
                <PopoverQACardList />
            </div>
            <div>
                <Tabs defaultActiveKey="ubehandlet" onSelect={e => props.onFilterTabChanged(e)}>
                    <Tab eventKey="ubehandlet" title={antallAktive > 0 ? `Aktive (${antallAktive})` : 'Aktive'}>
                        {
                            props.loadingChangeRequest ?
                                loadingContent :
                                <ChangeRequestsList changeRequests={props.changeRequests}
                                    selectedChangeRequest={props.selectedChangeRequest}
                                    onCardClicked={handleCardClicked} />
                        }
                    </Tab>
                    <Tab eventKey="folgesOpp" title={antallFolgesOpp > 0 ? `Følges opp (${antallFolgesOpp})` : 'Følges opp'}>
                        {
                            props.loadingChangeRequest ?
                                loadingContent :
                                <ChangeRequestsList changeRequests={props.folgesOppRequests}
                                    selectedChangeRequest={props.selectedChangeRequest}
                                    onCardClicked={handleCardClicked} />
                        }
                    </Tab>
                    <Tab eventKey="avvist" title="Avvist">
                        {
                            props.loadingChangeRequest ?
                                loadingContent :
                                <ChangeRequestsList changeRequests={props.rejectedChangeRequests}
                                    selectedChangeRequest={props.selectedChangeRequest}
                                    onCardClicked={handleCardClicked} />
                        }
                    </Tab>
                    <Tab eventKey="behandlet" title="Behandlet">
                        {
                            props.loadingChangeRequest ?
                                loadingContent :
                                <ChangeRequestsList changeRequests={props.processedChangeRequests}
                                    selectedChangeRequest={props.selectedChangeRequest}
                                    onCardClicked={handleCardClicked} />
                        }
                    </Tab>
                </Tabs>
            </div>
        </>
    );
}

export default QACardList;