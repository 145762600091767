import axios from 'axios';
import { KjedeTreNode } from 'components/KjedePicker';

import { KjedeDto, NewKjedeCommand, UpdateKjedeCommand, GetKjedeByIdRequest, GetKjedeByIdResponse, FlyttKjedeTilNyttInnkjopCommand } from './types';

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/kjeder';

const getKjedeById = async (request: GetKjedeByIdRequest): Promise<GetKjedeByIdResponse> => {
  const httpResponse = await axios.get(
    `${baseUrl}/${request.id}?type=${encodeURI(request.kjedeType)}`,
  );
  const kjedeResponse: GetKjedeByIdResponse = httpResponse.data;

  // we need this for kjedebreadcrumb
  kjedeResponse.hierarchy = kjedeResponse.parentTree.map(o => ({ nr: o.kjedenr, name: o.name, enhetId: o.enhetId, id: o.id }));
  kjedeResponse.hierarchy.push({ nr: kjedeResponse.kjedenr, name: kjedeResponse.kjedenavn, enhetId: kjedeResponse.enhetID, id: kjedeResponse.kjedeID });
  return kjedeResponse;
};

const getRegionTre = async (query?: {
  includeDisbanded: boolean;
}): Promise<KjedeTreNode[]> => {
  let url = `${baseUrl}/regional/tree`;
  if (query && query.includeDisbanded) {
    url += `?includeDisbanded=${query.includeDisbanded}`;
  }
  const response = await axios.get(url);
  return response.data;
};

const getMarkedsTre = async (query?: {
  includeDisbanded: boolean;
}): Promise<KjedeTreNode[]> => {
  let url = `${baseUrl}/markedsforing/tree`;
  if (query && query.includeDisbanded) {
    url += `?includeDisbanded=${query.includeDisbanded}`;
  }
  const response = await axios.get(url);
  return response.data;
};

const getAntallaktivemedlemmerforkjedeplussunderkjeder = async (
  kjedeId: number,
  kjedeType: string,
): Promise<number> =>
  axios
    .get(
      `${baseUrl}/${kjedeId}/antallaktivemedlemmerforkjedeplussunderkjeder?kjedeType=${encodeURI(
        kjedeType,
      )}`,
    )
    .then(response => response.data);

const updateKjede = async (id: number, command: UpdateKjedeCommand) =>
  axios.put(`${baseUrl}/${id}`, command).then(response => response.data);

const addKjede = async (command: NewKjedeCommand): Promise<KjedeDto> =>
  axios.post(baseUrl, command).then(response => response.data);

const deleteKjede = async (id: number) =>
  axios.delete(`${baseUrl}/${id}`).then(response => response.data);

const flyttKjedeTilNyttInnkjop = async (kjedeId: number, command: FlyttKjedeTilNyttInnkjopCommand) => {
  return axios.post(`${baseUrl}/${kjedeId}/flytt-til-nytt-innkjop`, command);
};

export default {
  getRegionTre,
  getMarkedsTre,
  getAntallaktivemedlemmerforkjedeplussunderkjeder,
  getKjedeById,
  updateKjede,
  addKjede,
  deleteKjede,
  flyttKjedeTilNyttInnkjop,
};
