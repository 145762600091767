export const msalConfig = {
    auth: {
        clientId: "17e17e71-ed34-403f-9dfc-500b29e1dd4c", // ApplicationId som en finner under App Registrations i Azure AD (trades.no)
        authority: "https://login.microsoftonline.com/trades.no", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: "/",
        postLogoutRedirectUri: "http://tradesolution.no"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["https://trades.no/TradesolutionApi/user_impersonation"]
};