
export class AbonnementQuery {
  skip = 0;
  take = 10000;
  q = '';
}

/* for create api calls */
export interface CreateAbonnementCommand {
  name: string;
  bransjer: AbonnementContent[];
  kjeder: AbonnementContent[];
  mottakere?: {
    fraOgMedDato: string,
    tilOgMedDato: string,
    enhetId: number,
    tsKundeId: string,
    id: number,
    name: string,
    code: string,
    number: string,
    type: string
  }[];
}

export interface CreateAbonnementResponse {
  id: number,
  code: string,
  number: string,
  type: null,
  name: string;
  bransjer: AbonnementContent[];
  kjeder: AbonnementContent[];
  mottakere: [];
}

/* for update api calls */
export interface UpdateAbonnementCommand {
  name: string;
  mottakere?: AbonnementMottaker[];
}

export interface UpdateAbonnementBransjerCommand {
  bransjer: AbonnementContent[];
}

export interface UpdateAbonnementKjederCommand {
  kjeder: AbonnementContent[];
}

export interface UpdateAbonnementResponse {
  mottakere: [],
  bransjer: {
    isEkskluderende: boolean,
    id: number,
    name: string,
    code: string,
    number: string,
    type: string
  }[],
  kjeder: [],
  id: number,
  name: string,
  code: string,
  number: string,
  type: string
}

export interface Abonnement {
  id: number;
  name: string;
  bransjer: AbonnementContent[];
  kjeder: AbonnementContent[];
  mottakere: AbonnementMottaker[];
}

export interface AbonnementContent {
  id: number;
  name?: string;
  number?: string;
  code?: string;
  isEkskluderende: boolean;
}

export interface AbonnementMottaker {
  id: number;
  name: string;
  fraOgMedDato: Date;
  tilOgMedDato?: Date;
  enhetId?: number;
  tsKundeId?: string;
}

export interface UpdateAbonnementCommand2 {
  id: number
  name: string
  bransjeIds: number[]
  kjedeIds: number[]
  isKjederEkskluderende: boolean
}

export interface CreateAbonnementCommand2 {
  name: string
  bransjeIds: number[]
  kjedeIds: number[]
  isKjederEkskluderende: boolean
}