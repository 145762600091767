import { AddEnhetGrossistKoblingCommand } from "./types";

import { getKjederegAdminApiUrl } from "../../";
import axios from "axios";

const baseUrl = getKjederegAdminApiUrl() + '/enhetgrossist';

const EnhetGrossistApi = {
    addKobling: async (command: AddEnhetGrossistKoblingCommand) => {
        const response = await axios.post(`${baseUrl}`, command);
        return response.data;
    },
    removeKobling: async (enhetGrossistId: number) => {
        const response = await axios.delete(`${baseUrl}/${enhetGrossistId}`);
        return response.data;
    },
    updateHovedgrossist: async (enhetGrossistId: number) => {
        const response = await axios.put(`${baseUrl}/${enhetGrossistId}/setashovedgrossist`);
        return response.data;
    }
};

export default EnhetGrossistApi;