import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import EnhetTable from './EnhetTable';
import _ from 'lodash';
import { EnheterInKjedeQuery } from 'services/KjederegisteretAdminApi/EnhetApi/Queries';
import { KjedeMedlem } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import useDebounce from 'utils/hooks/useDebounce';

interface Props {
  kjedeNavn: string;
  query: EnheterInKjedeQuery;
  medlemmer: KjedeMedlem[];
  isLoading: boolean;
  totalCount: number;
  onQueryChanged: (query: EnheterInKjedeQuery) => void;
}

const Medlemmer: React.FC<Props> = props => {
  const [q, setQ] = useState<string>("");

  const debouncedQ = useDebounce(q, 500);

  const handleSortByChanged = (value: string) => {
    props.onQueryChanged(props.query.setSortBy(value));
  };

  const handleLoadMore = () => {
    if (!props.isLoading) {
      props.onQueryChanged(props.query.nextPage());
    }
  };

  const handleIncludeUtmeldteChanged = (value: boolean) => {
    props.onQueryChanged(props.query.setInkluderUtmeldte(value));
  };

  useEffect(() => {
    props.onQueryChanged(props.query.setQueryText(debouncedQ));
  }, [debouncedQ]);

  return (
    <>
      <h6>{props.kjedeNavn} har følgende medlemmer</h6>
      <Form style={{ marginBottom: '0.5rem' }}>
        <Row>
          <Col sm={8}>
            <Form.Control
              size="sm"
              placeholder="Søk"
              value={q}
              onChange={e => setQ(e.target.value)}
            />
            <Form.Check
              style={{ marginTop: "0.2rem" }}
              type="checkbox"
              checked={props.query.includeHistoric}
              label="Inkluder tidligere medlemmer"
              onChange={e => handleIncludeUtmeldteChanged(e.target.checked)}
            />
          </Col>
          <Col>
            <Form.Control
              size="sm"
              placeholder="Sorter"
              as="select"
              value={props.query.sortBy || ''}
              onChange={e => handleSortByChanged(e.target.value)}
            >
              <option value="">Postnr - laveste først</option>
              <option value="postnr_desc">Postnr - høyeste først</option>
              <option value="navn">Navn</option>
              <option value="lopenr">Løpenr - laveste først</option>
              <option value="lopenr_desc">Løpenr - høyeste først</option>
              <option value="innmeldt">Innmeldt dato - tidligste først</option>
              <option value="innmeldt_desc">Innmeldt dato - nyligste først</option>
              <option value="utmeldt">Utmeldt dato - tidligste først</option>
              <option value="utmeldt_desc">Utmeldt dato - nyligste først</option>
            </Form.Control>
          </Col>
        </Row>
      </Form>
      <EnhetTable
        enheter={props.medlemmer}
        skip={0}
        onLoadMore={handleLoadMore}
        isLoading={props.isLoading}
        hasMore={props.totalCount > props.query.page * props.query.pageSize}
      />
    </>
  );
};

export default Medlemmer;
