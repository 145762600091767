import axios from 'axios';
import { getKjederegAdminApiUrl } from "../../";
import { TsKunde} from './types';

const baseUrl = getKjederegAdminApiUrl() + '/TsKunder';


async function getTsKundeById(id: string): Promise<TsKunde> {
    const response = await axios.get(`${baseUrl}/${id}`);
    return response.data;
}

async function searchTsKundeByName(query: string): Promise<TsKunde[]> {
    const response = await axios.get(`${baseUrl}/Search?query=${query}`);
    return response.data;
}

const TsKunderApi = {
    getTsKundeById,
    searchTsKundeByName
}

export default TsKunderApi;