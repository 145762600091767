import React, { useState, useEffect } from 'react';
import { Card, Form, FormGroup, Button, Col, Row, Badge, ButtonToolbar, ButtonGroup } from 'react-bootstrap';


import EnhetsgrupperingBreadcrumb from './GrupperingBreadcrumb';

import DatePicker from 'components/DatePicker';
import ConfirmButton from 'components/ConfirmButton';
import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import EnhetApi from 'services/KjederegisteretAdminApi/EnhetApi';
import { Enhetsgruppering } from 'services/KjederegisteretAdminApi/EnhetApi/types';
import { EnhetgrupperingnavnHierarki } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import { UpdateGrupperingCommand } from 'services/KjederegisteretAdminApi/EnhetApi/Commands';

import useForm from 'utils/hooks/useForm';
import useToaster from 'utils/hooks/useToaster';
import HttpErrorHelper from 'utils/HttpErrorHelper';
import DateHelper from 'utils/DateHelper';

import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

interface Props {
  gruppering: Enhetsgruppering;
  enhetId: number;
  navnHierarki: EnhetgrupperingnavnHierarki[];
  triggerReloadGrupperinger: () => void;
  handleFjernEnhetFromEnhetsgruppe?: () => Promise<void>;
  handleReaktiverEnhetsgruppering?: () => Promise<void>;
}
const EnhetsgrupperingMedlemskap: React.FC<Props> = props => {
  const toaster = useToaster();


  const [isEditMedlemskap, setIsEditMedlemskap] = useState<boolean>(false);
  const [grupperingHarKjedenr, setGrupperingHarKjedenr] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const initialData: UpdateGrupperingCommand = {
    enhetEnhetgrupperingId: props.gruppering.enhetEnhetgrupperingID,
    enhetId: props.enhetId,
    grupperingNr: props.gruppering.enhetgrupperingnr,
    kundenrIGruppering: props.gruppering.kundenr,
    fraOgMedDato: new Date(props.gruppering.fraOgMedDato),
    tilOgMedDato: props.gruppering.tilOgMedDato ? new Date(props.gruppering.tilOgMedDato) : null,
    skalBetraktesSomKjedemedlemskap: props.gruppering.skalBetraktesSomKjedemedlemskap,
    kjedenr: props.gruppering.kjedenr,
    isFuture: new Date() < new Date(props.gruppering.fraOgMedDato),
    isActive:
      new Date() >= new Date(props.gruppering.fraOgMedDato) &&
      (!props.gruppering.tilOgMedDato || new Date() < new Date(props.gruppering.tilOgMedDato)),
  };

  const openEditGruppering = () => {
    setIsEditMedlemskap(!isEditMedlemskap);
    if (touched) {
      resetForm(initialData);
    }
  };

  const validate = (m: UpdateGrupperingCommand) => {
    const errors: any = {};
    if (m.tilOgMedDato && m.tilOgMedDato < m.fraOgMedDato) {
      errors.tilOgMedDato = `Kan ikke sette TilOgMedDato - ${m.tilOgMedDato
        } - til samme dato som FraOgMedDato ${m.fraOgMedDato} eller en dato før FraOgMedDato!`;
    }

    if (!m.fraOgMedDato) {
      errors.fraOgMedDato = 'FraOgMedDato må ha verdi!';
    }
    return errors;
  };

  const onSubmit = async (command: UpdateGrupperingCommand) => {
    // const fomd = format(new Date(v.fraOgMedDato), 'yyyy-MM-dd');
    // const tomd = v.tilOgMedDato != null ? format(new Date(v.tilOgMedDato), 'yyyy-MM-dd') : null;
    setIsLoadingSubmit(true);
    try {
      await EnhetApi.updateGrupperingMedlemskap(props.enhetId, command);
      toaster.success('Suksess', 'Endringer er lagret!');
      setIsLoadingSubmit(false);
      openEditGruppering();
      props.triggerReloadGrupperinger();
    } catch (err) {
      toaster.error('Feil.', HttpErrorHelper.formatError(err));
      setIsLoadingSubmit(false);
    }
  };

  const calculateMinDate = (date: Date, days: number) => {
    return new Date(date.getTime() + days * 86400000);
  };

  const onFjernConfirmed = async () => {
    props.handleFjernEnhetFromEnhetsgruppe();
  };

  const [isReaktivering, setIsReaktivering] = useState(false);
  const onReaktiver = async () => {
    setIsReaktivering(true);
    await props.handleReaktiverEnhetsgruppering();
    setIsReaktivering(false);
  };

  const {
    touched,
    errors,
    handleChange,
    handleSubmit,
    submitDisabled,
    values,
    resetForm,
  } = useForm(initialData, validate, onSubmit);

  useEffect(() => {
    if (props.gruppering.kjedenr) {
      setGrupperingHarKjedenr(true);
    } else {
      setGrupperingHarKjedenr(false);
    }
  }, [props.gruppering]);

  return (
    <Card className={Style.card}>
      <Card.Title className={Style.cardCurrentTitle}>
        <div className={Style.titleContainer}>
          {initialData.isActive && <h4 className={Style.activeTag}>Aktiv</h4>}
          {initialData.isFuture && <h4 className={Style.infoTag}>Fremtidig</h4>}
          <Badge pill bg="info" text="dark">
            {props.gruppering.enhetgrupperingtypenavn}
          </Badge>
        </div>
        <Button size="sm" variant="outline-primary" onClick={openEditGruppering}>
          <IcebergIcon size="sm" icon={isEditMedlemskap ? 'close' : 'edit'} bold={isEditMedlemskap} />
          <span className={Style.editMedlemskapBtn}>{isEditMedlemskap ? 'Lukk' : 'Endre'}</span>
        </Button>
      </Card.Title>
      <Card.Body className={Style.cardBody}>
        <EnhetsgrupperingBreadcrumb navnHierarki={props.navnHierarki} />
        <Form className={Style.editGrupperingForm} onSubmit={handleSubmit}>
          <Row>
            <Form.Group as={Col} controlId="formFraOgMedDato" className="mb-3">
              <Form.Label>
                <strong>Fra og med dato</strong>
              </Form.Label>
              {isEditMedlemskap ? (
                <DatePicker
                  selected={values.fraOgMedDato}
                  onChange={date => handleChange('fraOgMedDato', date)}
                  adjustTimeForTimezoneOffset={true}
                />
              ) : (
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={DateHelper.formatDate(values.fraOgMedDato) || ''}
                />
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="formTilOgMedDato" className="mb-3">
              <Form.Label>
                <strong>Til og med dato</strong>
              </Form.Label>
              {isEditMedlemskap ? (
                <DatePicker
                  selected={values.tilOgMedDato}
                  onChange={date => handleChange('tilOgMedDato', date)}
                  minDate={calculateMinDate(values.fraOgMedDato, 1)}
                  adjustTimeForTimezoneOffset={true}
                />
              ) : (
                <Form.Control
                  plaintext
                  readOnly
                  defaultValue={DateHelper.formatDate(values.tilOgMedDato) || ''}
                />
              )}
              <Form.Control.Feedback type="invalid">{errors.tilOgMedDato || ''}</Form.Control.Feedback>
            </Form.Group>
          </Row>
          <Row>
            <FormGroup as={Col} controlId={`formKjedeNr-${Math.random()}`} className="mb-3">
              <Form.Label>
                <strong>Kjedenummer</strong>
              </Form.Label>
              <Form.Control plaintext readOnly defaultValue={props.gruppering.kjedenr || ''} />
            </FormGroup>
            <FormGroup as={Col} controlId={`formKundenr-${Math.random()}`} className="mb-3">
              <Form.Label>
                <strong>Medlemsnummer</strong>
              </Form.Label>
              {isEditMedlemskap ? (
                <Form.Control
                  type="text"
                  name="kundenrIGruppering"
                  value={values.kundenrIGruppering || ''}
                  onChange={v => handleChange('kundenrIGruppering', v.target.value)}
                />
              ) : (
                <Form.Control plaintext readOnly value={values.kundenrIGruppering || ''} />
              )}
            </FormGroup>
          </Row>
          {
            props.gruppering.enhetgrupperingtypekode === 'IS' &&
            <Row>
              <FormGroup as={Col} controlId={`formSkalBetraktesSomKjedeMedlemskap-${Math.random()}`}>
                <Form.Check
                  inline
                  type="checkbox"
                  label="Skal betraktes som kjede medlemskap"
                  checked={values.skalBetraktesSomKjedemedlemskap}
                  disabled={!grupperingHarKjedenr || !isEditMedlemskap}
                  onChange={v => handleChange('skalBetraktesSomKjedemedlemskap', v.target.checked)} />
                {!grupperingHarKjedenr && (
                  <Form.Text className="text-muted">
                    Enhetsgrupperingen har ikke kjedenr og derfor kan den ikke betraktes som
                    kjedemedlemskap!
                  </Form.Text>
                )}
              </FormGroup>
            </Row>
          }
          {isEditMedlemskap && (
            <Row>
              <Col>
                <ConfirmButton
                  onClick={onFjernConfirmed}
                  disabled={!(initialData.isActive || initialData.isFuture) || !onFjernConfirmed}
                />
              </Col>
              <Col>
                <ButtonToolbar className="float-right">
                  {values.tilOgMedDato && (
                    <ButtonGroup className="mr-2">
                      <Button size="sm" variant="primary" onClick={() => onReaktiver()}>
                        Reaktiver
                        {isReaktivering && <CommonLoader />}
                      </Button>
                    </ButtonGroup>
                  )}
                  <ButtonGroup className={Style.submitBtnCol}>
                    <Button
                      className={Style.submitBtn}
                      variant="primary"
                      type="submit"
                      disabled={submitDisabled}
                    >
                      {isLoadingSubmit && (
                        <span className='mr-2'>
                          <CommonLoader />
                        </span>
                      )}
                      <span>Lagre</span>
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Col>
            </Row>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
};

export default EnhetsgrupperingMedlemskap;
