import axios from 'axios';
import { Distribusjon, DistribusjonsHistorikk, DistribusjonsHistorikkHelper } from './types'

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/distribusjoner';

async function getByMottaker(id: number): Promise<Distribusjon[]>{
  const response = await axios.get(`${baseUrl}/bymottakerid/${id}`);
  return response.data;
}

async function getDistribusjonsHistorikk(filter: string, fraDato: Date): Promise<DistribusjonsHistorikk[]> {
  const response = await axios.get(`${baseUrl}/historikk?fraOgMedDato=${fraDato || ''}&q=${filter || ''}`)
  return response.data.map(o => DistribusjonsHistorikkHelper.parseFromJson(o));
}

async function getDistribusjonsHistorikkById(id: number): Promise<DistribusjonsHistorikk[]>{
  const response = await axios.get(`${baseUrl}/${id}/historikk`);
  return response.data.map(o => DistribusjonsHistorikkHelper.parseFromJson(o));
}

const DistribusjonsApi = {
  getByMottaker,
  getDistribusjonsHistorikk,
  getDistribusjonsHistorikkById
}

export default DistribusjonsApi;
