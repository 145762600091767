import React from "react";
import { Card, Badge, Row, Col } from "react-bootstrap";

import ToolButtons from "./components/ToolButtons";

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

import { EndringsMelding } from "services/QAApi/types";

import { formatBehandlesAvItem } from "../../../../../../utils";

import Style from "./index.module.css";

interface Props {
    changeRequest: EndringsMelding;
    recievedAt: string;
    selected: boolean;
}

const ChangeRequestCard = (props: Props) => {
    const isExistingEnhet = props.changeRequest.changesMightBeRelatedToEnhetId;
    const isNedlagtDatoForEnhet = props.changeRequest.changes.nedlagtDato;

    const getDisplayNameForStatus = (status: string) => status === 'FolgesOpp' ? 'Følges opp' : status;

    const getBasisValidations = (fieldname: string) => {
        if (props.changeRequest.changes && props.changeRequest.changes.validations) {
            return props.changeRequest.changes.validations.filter(o => o.fieldName === fieldname);
        }
        return [];
    }

    const getBesoksAddresseValidations = () => {
        if (props.changeRequest.changes.besoksadresse && props.changeRequest.changes.besoksadresse.validations) {
            return props.changeRequest.changes.besoksadresse.validations.filter(o => o.fieldName === 'Besoksadresse');
        }
        return [];
    }

    const glnValidations = getBasisValidations('GLN');
    const glnValidationResult = glnValidations && glnValidations.length > 0 ? glnValidations[0] : undefined;
    const glnIsNotValid = glnValidationResult ? !glnValidationResult.validationResults[glnValidationResult.validationResults.length - 1].isValueValid : false;

    const orgnrValidations = getBasisValidations('Organisasjonsnr');
    const orgnrValidationResult = orgnrValidations && orgnrValidations.length > 0 ? orgnrValidations[0] : undefined;
    const orgnrIsNotValid = orgnrValidationResult ? !orgnrValidationResult.validationResults[orgnrValidationResult.validationResults.length - 1].isValueValid : false;

    const besoksadresseValidations = getBesoksAddresseValidations();
    const besoksadresseValidationResult = besoksadresseValidations && besoksadresseValidations.length > 0 ? besoksadresseValidations[0] : undefined;
    const besoksadresseisNotValid = besoksadresseValidationResult ? !besoksadresseValidationResult.validationResults[besoksadresseValidationResult.validationResults.length - 1].isValueValid : false;

    return (
        <Card className={props.selected ? Style.qaCardSelected : Style.qaCard}>
            <Card.Header>
                <Card.Title style={{ color: "#000", fontWeight: 700 }}>
                    {props.changeRequest.title}                    
                    {(props.changeRequest.status === 'Avvist' || props.changeRequest.status === 'Behandlet' || props.changeRequest.status === 'FolgesOpp') && (
                            <span className="float-end">
                                <Badge className={Style.behandletBadge} bg="success">
                                    {(props.changeRequest.status === 'FolgesOpp') ? 'Følges opp' : props.changeRequest.status}
                                </Badge>
                            </span>                            
                    )}
                </Card.Title>

                <div className={Style.optionsContainer}>
                    <div className={Style.cardStatusContainer}>  
                        {
                            props.changeRequest.behandlesAv.length > 0 &&
                            <IcebergIcon
                                className={Style.eyeIcon}
                                icon="eye-open"
                                color="grey"
                                size="sm"
                                title={props.changeRequest.behandlesAv.map(o => formatBehandlesAvItem(o)).join('\r\n')} />
                        }
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Row>
                    {props.selected && <div>
                        <ToolButtons changeRequest={props.changeRequest} />
                    </div>}
                    <Col>
                        {!isExistingEnhet && (
                            <div className={Style.cardBodyStatusMessageContainer}>
                                <IcebergIcon icon="question" color="#fff" backgroundColor="orange" size="sm" rounded bold />
                                <p className={Style.cardBodyStatusMessage}>Kan være ny enhet</p>
                            </div>
                        )}

                        {isExistingEnhet && (
                            <div className={Style.cardBodyStatusMessageContainer}>
                                <IcebergIcon icon="check" color="#fff" backgroundColor="green" size="sm" rounded bold />
                                <p className={Style.cardBodyStatusMessage}> Mulig enhet funnet</p>
                            </div>
                        )}

                        {isNedlagtDatoForEnhet && (
                            <div className={Style.cardBodyStatusMessageContainer}>
                                <IcebergIcon icon="question" color="#fff" backgroundColor="orange" size="sm" rounded bold />
                                <p className={Style.cardBodyStatusMessage}>Mulig nedleggelse</p>
                            </div>
                        )}
                    </Col>

                    <Col>
                        {glnIsNotValid && (
                            <div className={Style.cardBodyValidationMessageContainer}>
                                <IcebergIcon icon="close" color="#fff" backgroundColor="red" size="sm" rounded bold />
                                <p className={Style.cardBodyValidationMessage}>GLN</p>
                            </div>
                        )}

                        {orgnrIsNotValid && (
                            <div className={Style.cardBodyValidationMessageContainer}>
                                <IcebergIcon icon="close" color="#fff" backgroundColor="red" size="sm" rounded bold />
                                <p className={Style.cardBodyValidationMessage}>Organisasjonsnummer</p>
                            </div>
                        )}

                        {besoksadresseisNotValid && (
                            <div className={Style.cardBodyValidationMessageContainer}>
                                <IcebergIcon icon="close" color="#fff" backgroundColor="red" size="sm" rounded bold />
                                <p className={Style.cardBodyValidationMessage}>Besøksadresse</p>
                            </div>
                        )}
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className={Style.cardFooter}>
                <div className={Style.cardFooterTextContainer}>
                    <p className={Style.cardFooterText}>
                        {getDisplayNameForStatus(props.changeRequest.status)} : {props.changeRequest.lastChangedBy}
                    </p>
                    <p className={Style.cardFooterText}>Kilde:<span> </span>  
                    {props.changeRequest.contactEmail && (
                        props.changeRequest.contactEmail + ' (' + props.changeRequest.sentBy + ')'
                    )}
                    {!props.changeRequest.contactEmail && (
                        props.changeRequest.sentBy
                    )}
                    {!props.changeRequest.contactEmail && props.changeRequest.sentBy !== props.changeRequest.sentBySystem && (
                        ' (' + props.changeRequest.sentBySystem + ')'
                    )}                    
                    </p>
                    <p className={Style.cardFooterText}>{props.recievedAt} ago</p>
                </div>
            </Card.Footer>
        </Card>
    )
};

export default ChangeRequestCard;