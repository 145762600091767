import EnhetSearchApi from '../EnhetSearchApi';
import { EnhetFromSearch, EnhetSearchFilter } from '../EnhetSearchApi/types';

export interface IEnhetInfo {
  enhetId?: number;
  enhetnavn: string;
  juridisknavn: string;
  organisasjonsnummer: string;
  statistikkNr?: number;
  besoksadresse?: string;
}

interface IResult {
  byOrgnr: EnhetFromSearch[];
  byEnhetnavn: EnhetFromSearch[];
  byOrgnrBesokCombo: EnhetFromSearch[];
  byEnhetsnavnBesokCombo: EnhetFromSearch[];
}

export async function findEnhetDuplicates(enhet: IEnhetInfo) {
  let foundByEnhetnavn: EnhetFromSearch[] = [];
  let foundByOrgNr: EnhetFromSearch[] = [];

  if (enhet.enhetnavn) {
    foundByEnhetnavn = await findByEnhetnavn(enhet.enhetnavn).then(res => {
      if (enhet.enhetId) {
        return res.filter(x => x.enhetID !== enhet.enhetId);
      } else {
        return res;
      }
    });
  }

  if (enhet.organisasjonsnummer) {
    foundByOrgNr = await findByOrgnr(enhet.organisasjonsnummer).then(res => {
      if (enhet.enhetId) {
        return res.filter(x => x.enhetID !== enhet.enhetId);
      } else {
        return res;
      }
    });
  }


  let foundByOrgnrBesokCombo: EnhetFromSearch[] = [];
  if (enhet.besoksadresse && enhet.statistikkNr) {
    foundByOrgnrBesokCombo = await findByOrgnrAndBesoksadresseCombo(
      enhet.organisasjonsnummer,
      enhet.besoksadresse,
      enhet.statistikkNr
    ).then(res => {
      if (enhet.enhetId) {
        return res.filter(x => x.enhetID !== enhet.enhetId);
      } else {
        return res;
      }
    });
  }

  let foundByEnhetsnavnBesokCombo: EnhetFromSearch[] = [];
  if (enhet.besoksadresse && enhet.statistikkNr) {
    foundByEnhetsnavnBesokCombo = await findByEnhetsnavnAndBesoksadresseCombo(
      enhet.enhetnavn,
      enhet.besoksadresse,
      enhet.statistikkNr
    ).then(res => {
      if (enhet.enhetId) {
        return res.filter(x => x.enhetID !== enhet.enhetId);
      } else {
        return res;
      }
    });
  }

  let found: IResult = {
    byOrgnr: foundByOrgNr,
    byEnhetnavn: foundByEnhetnavn,
    byOrgnrBesokCombo: foundByOrgnrBesokCombo,
    byEnhetsnavnBesokCombo: foundByEnhetsnavnBesokCombo,
  };

  return found;
}

async function lookupEnheter(filter: EnhetSearchFilter) {
  return await EnhetSearchApi.searchEnheter(filter);
}

function findByEnhetnavn(name: string) {
  const filter = new EnhetSearchFilter();
  filter.criteria = name;
  return lookupEnheter(filter).then(e => e.items.filter(x => x.enhetnavn === name));
}

function findByOrgnr(orgnr: string) {
  const filter = new EnhetSearchFilter();
  filter.criteria = orgnr;
  return lookupEnheter(filter).then(e => e.items.filter(x => x.organisasjonsnr === orgnr));
}

async function findByEnhetsnavnAndBesoksadresseCombo(name: string, adresse: string, statistikkNr: number) {
  const filter = new EnhetSearchFilter();
  filter.criteria = `${name} ${adresse}`;
  const e = await lookupEnheter(filter);
  return e.items.filter(x => (statistikkNr && x.statistikknr !== statistikkNr) && x.enhetnavn === name && x.besoksadresse === adresse);
}
async function findByOrgnrAndBesoksadresseCombo(orgnr: string, adresse: string, statistikkNr: number) {
  const filter = new EnhetSearchFilter();
  filter.criteria = `${orgnr} ${adresse}`;
  const e = await lookupEnheter(filter);
  return e.items.filter(x => (statistikkNr && x.statistikknr !== statistikkNr) && x.organisasjonsnr === orgnr && x.besoksadresse === adresse);
}