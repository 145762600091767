import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AlkoholBevillingerApi from "services/KjederegisteretAdminApi/AlkoholBevillingerApi";
import { AlkoholBevilling } from "services/KjederegisteretAdminApi/AlkoholBevillingerApi/types";
import { EnhetViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";

interface Props {
    enhet: EnhetViewModel;
    onUpdated: () => void;
}

const Bevillinger = (props: Props) => {

    const kundenummer = props.enhet.leverandorKundenummer.find(o => o.mottakerNavn == "HELSEDIREKTORATET");

    const [bevilling, setBevilling] = useState<AlkoholBevilling | undefined>();

    const loadBevilling = async (kundenr: string) => {
        const result = await AlkoholBevillingerApi.getByBevillingNr(kundenr);
        setBevilling(result);
    }

    useEffect(() => {
        if (kundenummer?.kundenummer) {
            loadBevilling(kundenummer.kundenummer);
        }
    }, [kundenummer?.kundenummer]);

    return (
        <div>
            {!kundenummer && <div>
                Ingen bevilling registrert på enhet
            </div>}
            {kundenummer && <div>
                Alkohol bevillingsnr hos Helsedirektoratet: <strong>{kundenummer.kundenummer}</strong>
                {bevilling &&
                    <Table className="mt-3">
                        <thead>
                            <th>TillatelsesType</th>
                            <th>Varegrupper</th>
                            <th>Periode</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{bevilling.tillatelsestype}</td>
                                <td>{bevilling.varegrupper}</td>
                                <td>{format(bevilling.fra, "dd.MM.yyyy")} - {bevilling.til ? format(bevilling.til, "dd.MM.yyyy") : ""}</td>
                            </tr>
                        </tbody>
                    </Table>
                }
            </div>}

        </div>
    )
}

export default Bevillinger;