import React from 'react';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { HierarchyKjede } from 'services/KjederegisteretAdminApi/EnhetApi/types';

import openInNewTab from 'utils/functions/openInNewTab';
import Style from './index.module.css';

interface Props {
  kjedeHierarchy: HierarchyKjede[];
  substyle?: boolean;
}

const KjedeBreadcrumb: React.FC<Props> = props=> {
  
  return (
    <Card.Subtitle className="mb-2 text-muted" style={{ paddingBottom: '0em' }}>
      <Row className="align-items-center">
        <Col>
          <ul className={props.substyle ? Style.breadcrumbsub : Style.breadcrumb}>
            {props.kjedeHierarchy &&
              props.kjedeHierarchy.map((item, i) => (
                <li key={`${Math.random()}`}>
                  {props.kjedeHierarchy.length === i + 1 ? (
                    <Button
                      onClick={() => openInNewTab(`/enheter/${item.kjedeEnhetId}`)}
                      variant="link">{`${item.kjedenr} ${item.kjedenavn}`}
                    </Button>
                  ) : (
                    <span>{`${item.kjedenr} ${item.kjedenavn}`}</span>
                  )}

                </li>
              ))}
          </ul>
        </Col>
      </Row>
    </Card.Subtitle>
  );
}
export default KjedeBreadcrumb;
