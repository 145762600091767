import { Button, Modal} from "react-bootstrap";
import Style from './index.module.css';
import { EnhetViewModel } from "services/KjederegisteretAdminApi/EnhetApi/types";
import { format } from "date-fns";
import CommonLoader from "components/CommonLoader";


interface Props {
    onFjernVidereforing: () => void;
    show?: boolean;
    handleClose: () => void;
    fjernVidereforingIsLoading: boolean;
    enhet: EnhetViewModel;
}

const FjernvidereforingModal = (props: Props) => {
    const fjernVidereforing = () => {
        props.onFjernVidereforing();
    };

    return (
        <>
            <Modal show={props.show} onHide={props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Fjern videreføring av enheten</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>
                        Enheten ble videreført til løpenummer {props.enhet?.firma.nesteLopenr} den {props.enhet?.firma.nedlagtDato ? format(new Date(props.enhet?.firma.nedlagtDato), "dd.MM.yyyy") : ""}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleClose}>Avbryt</Button>

                    <Button
                        className={Style.spinnerBtn}
                        variant="primary"
                        onClick={fjernVidereforing}
                    >
                        <span>Fjern videreforing</span>
                        {props.fjernVidereforingIsLoading && (
                            <span className='ms-2'>
                                <CommonLoader />
                            </span>
                        )}
                    </Button>
                </Modal.Footer>

            </Modal>
        </>

    );
}


export default FjernvidereforingModal;
