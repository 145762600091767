import React from "react";
import { Row, Col } from "react-bootstrap";

import AlleBarChart from "./components/AlleBarChart";

import QAStatistics from "components/QAStatistics";

import { Statistikk } from "services/QAApi/types";

import Style from "./index.module.css";

interface Props {
    data: Statistikk;
}

const QaStats: React.FC<Props> = props => {
    return (
      <Row>
        <Col>
            <Row>
                <Col>
                    <Row className={Style.statRow}>
                        <Col className={Style.statCol}>
                            <Row>
                                <Col>
                                    <div>
                                        <h3>Venter på behandling</h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className={Style.statusStats}>
                                    <div className={Style.statContainer}>
                                        <h2 className={Style.statHeader}>{props.data.antallAktive || 0}</h2>
                                        <h2 className={Style.statNameHeader}>Aktive</h2>
                                    </div>
                                    <div className={Style.statContainer}>
                                        <h2 className={Style.statHeader}>{props.data.antallFolgesOpp || 0}</h2>
                                        <h2 className={Style.statNameHeader}>Følges opp</h2>
                                    </div>
                                </Col>
                            </Row>
                            <AlleBarChart data={props.data}/>
                        </Col>
                        <QAStatistics />
                    </Row>
                </Col>
            </Row>
        </Col>
      </Row>
    )
}

export default QaStats;