import axios from 'axios';

import { getKjederegAdminApiUrl } from "../../";

export interface PostnummerRegisterEntry {
  postnummer: string;
  poststed: string;
  kommunenummer: string;
  kommunenavn: string;
  fylkenr: string;
  fylkenavn: string;
}

const baseUrl = getKjederegAdminApiUrl() + '/postnummerregister';

const getByPostkode = async (postkode: string): Promise<PostnummerRegisterEntry> => axios.get(`${baseUrl}/${postkode}`).then(response => response.data);

export default {
  getByPostkode
}