import { useState, useRef, Fragment, useEffect } from 'react';
import { ListGroup, Form, Button, Alert } from 'react-bootstrap';
import { Enhetgruppering, GrupperingForMedlemsKjede } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import openInNewTab from 'utils/functions/openInNewTab';
import Style from './index.module.css';

export interface Suggestion { gruppeNavnOver: string, gruppenavn: string, id: number, kjedenr: string, gruppenr: number };

interface Props {

    // called whenever the list of selected suggestions changes
    onSuggestionsUpdated: (selected: Suggestion[]) => void;

    // so that we can map against enhetsgrupper
    kjedeName: string;

    // enhetsgrupping kjeden er medlem av. Undergrupper displays as suggestions.
    enhetsgruppering: GrupperingForMedlemsKjede;
    selectedSuggestions: Suggestion[];
}

// this component should show a list of suggestions for enhetsgrupper the enhet could be a member of based on membership in a kjede
const KjedeGrupperingSuggestions = (props: Props) => {

    const [showAllUnderliggendeGrupper, setShowAllUnderliggendeGrupper] = useState<boolean>(false);

    // do we have a undergruppering with the same name as the kjede?
    const matchingUndergruppering = props.enhetsgruppering.matchingUndergruppering;

    // converts enhetsgruppering to suggestion
    const mapToSuggestion = (gruppering: Enhetgruppering): Suggestion => {
        const suggestion: Suggestion = {
            gruppeNavnOver: gruppering.enhetsgrupperingnavn,
            gruppenavn: gruppering.enhetsgrupperingnavn,
            id: gruppering.enhetgrupperingID,
            kjedenr: gruppering.kjedenr,
            gruppenr: gruppering.enhetgrupperingnr
        };

        return suggestion;
    };

    // is this enhetgrupping in the suggestions list?
    const isEnhetsgrupperingSelected = (gruppering: Enhetgruppering) => {
        return selectedSuggestions.some(x => x.id === gruppering.enhetgrupperingID);
    };
    
    const [selectedSuggestions, setSelectedSuggestions] = useState<Suggestion[]>(props.selectedSuggestions);

    const handleEnhetsgrupperingSelected = (gruppering: Enhetgruppering) => {
        const suggestion = mapToSuggestion(gruppering);
        // if not exists, add to list, else remove from list
        if (selectedSuggestions.some(x => x.id === suggestion.id)) {
            const filtered = selectedSuggestions.filter(x => x.id !== suggestion.id);
            setSelectedSuggestions(filtered);
            props.onSuggestionsUpdated(filtered);
        } else {
            const newList = [...selectedSuggestions, suggestion];
            setSelectedSuggestions(newList);
            props.onSuggestionsUpdated(newList);
        }
        
    };

    const ref = useRef(null);

    return (
        <div>
            {/* form for matching undergruppe */}
            {matchingUndergruppering ? (
                <ListGroup className={Style.knytningSuggestionListGroup}>
                    <ListGroup.Item
                        key={matchingUndergruppering.enhetgrupperingID}
                        className={Style.knytningSuggestionListItem}
                    >
                        <div>
                            <span className={Style.enhetgrupperingnavn}>
                                {props.enhetsgruppering.enhetgruppering.enhetsgrupperingnavn + ' '}
                            </span>
                            <span className={Style.dashSeperator}><strong> / </strong></span>
                            <div>
                                {props.enhetsgruppering.parents?.length > 0 && props.enhetsgruppering.parents.slice(0, -1).reverse().map(parent => (
                                    <div key={parent.enhetgrupperingID}>
                                        <span
                                            className={Style.enhetgrupperingnavn}
                                        >
                                            {parent.enhetsgrupperingnavn + ' '}
                                        </span>
                                        <span className={Style.dashSeperator}><strong> / </strong></span>
                                    </div>
                                ))}
                            </div>
                            <Button
                                variant="link" size="sm"
                                className={Style.suggestionNameEnhetLink}
                                onClick={() => openInNewTab(`/enheter/${props.enhetsgruppering.matchingUndergruppering.enhetID}`)}
                            >
                                {/* {props.data.matchingUndergruppering.enhetgrupperingnr + ' '} */}
                                {props.enhetsgruppering.matchingUndergruppering.enhetsgrupperingnavn + ' '}
                                {props.enhetsgruppering.matchingUndergruppering.enhetgrupperingtypekode}
                            </Button>
                        </div>
                        <Form.Check
                            type="checkbox"
                            className={Style.checkbox}
                            checked={isEnhetsgrupperingSelected(props.enhetsgruppering.matchingUndergruppering)}
                            onChange={() => handleEnhetsgrupperingSelected(props.enhetsgruppering.matchingUndergruppering)}
                        />
                    </ListGroup.Item>
                </ListGroup>
            ) : (
                <Alert variant="info">
                    <span>Ingen enhetsgrupperinger matcher på {props.kjedeName}. </span>
                </Alert>
            )}

            {/* the other undergrupperinger */}
            <Fragment>
                {props.enhetsgruppering.undergrupperinger?.length > 0 && (
                    <Button className={Style.showAllBtn} variant="link" size="sm" onClick={() => setShowAllUnderliggendeGrupper(!showAllUnderliggendeGrupper)}>
                        {showAllUnderliggendeGrupper ?
                            `Lukk alternativer for ${props.enhetsgruppering.enhetgruppering.enhetsgrupperingnavn}` :
                            `Vis alle alternativer for ${props.enhetsgruppering.enhetgruppering.enhetsgrupperingnavn}`}
                        ({props.enhetsgruppering?.undergrupperinger?.filter(x => x.enhetsgrupperingnavn !== props.kjedeName).length})
                    </Button>
                )}
                {showAllUnderliggendeGrupper && (
                    <ListGroup ref={ref} className={Style.knytningSuggestionListGroup}>
                        {props.enhetsgruppering.undergrupperinger?.filter(x => x.enhetsgrupperingnavn !== props.kjedeName)
                            .map((y, i: number) => (
                                <ListGroup.Item
                                    key={i + Math.random() + `${y.enhetgrupperingID}`} className={Style.knytningSuggestionListItem}
                                >
                                    <div>
                                        <span className={Style.enhetgrupperingnavn}>
                                            {props.enhetsgruppering.enhetgruppering.enhetsgrupperingnavn + ' '}
                                        </span>
                                        <span className={Style.dashSeperator}><strong>/</strong></span>
                                        <Button
                                            variant="link" size="sm"
                                            className={Style.suggestionNameEnhetLink}
                                            onClick={() => openInNewTab(`/enheter/${y.enhetID}`)}
                                        >
                                            {/* {y.enhetgrupperingnr + ' '} */}
                                            {y.enhetsgrupperingnavn + ' '}
                                            ({y.enhetgrupperingtypekode})
                                        </Button>
                                    </div>
                                    <Form.Check
                                        key={i}
                                        checked={isEnhetsgrupperingSelected(y)}
                                        onChange={() => handleEnhetsgrupperingSelected(y)}
                                        type="checkbox"
                                    />
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                )}
            </Fragment>

        </div>
    );
}
export default KjedeGrupperingSuggestions;
