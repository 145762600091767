
import { useState, useEffect, useCallback, useRef, ReactNode } from 'react';
import { InputGroup, Button, Overlay, Tooltip, ButtonGroup, Form } from 'react-bootstrap';

import AddressDetails from './AddressDetails'

import AddressTypeahead from 'components/AddressTypeahead';
import { IAdresse } from 'components/AddressTypeahead/types';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';

import PostnummerRegisterApi from 'services/KjederegisteretAdminApi/PostnummerRegisterApi';
import { KontonrOgFakturaAdresseSjekkResponse } from 'services/KjederegisteretAdminApi/EnhetApi/types';

import { formatAddressAsOneLiner } from 'utils/AddressHelper';
import useIsMountedRef from 'utils/hooks/useIsMountedRef';

import Style from './index.module.css';
import PopoverMenuAdressInput from './PopoverMenuAdressInput';
import { Coordinates } from 'models/Coordinates';

interface Props {
  title: string;
  adresse: IAdresse;
  onAdresseChanged: (adresse: IAdresse) => void;
  onAdresseCopied: (adresse: IAdresse, ekstraInfo: string) => void;
  ekstraInfo: string;
  onEkstraInfoChanged: (info: string) => void;
  copyAddresses?: { adresse: IAdresse, title: string, ekstraInfo: string }[];
  kontonrOgFakturaAdresseSjekkResponse?: KontonrOgFakturaAdresseSjekkResponse;
  requireCoordinates?: boolean;
  onDelete: () => void;
  onSave: () => void;
  onUpdateCoordinates: (coor: { lat: number; lng: number }) => void;
  onDeleteCoordinates: (id: number) => void;
  coordinates: Coordinates;
  setCoordinates: (coordinates: Coordinates) => void;
}

const AdresseInput = (props: Props) => {
  const isMountedRef = useIsMountedRef();

  const adresseInputRef = useRef(null);

  const [touched, setTouched] = useState<boolean>(false);
  const [errors, setErrors] = useState({} as any);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isResetDisabled, setIsResetDisabled] = useState<boolean>(true);
  const [showReadonlyWarning, setShowReadonlyWarning] = useState<boolean>(false);

  const handlePostnrChanged = async (postnr: string) => {
    const addressCopy = { ...props.adresse };
    if (postnr && postnr.length === 4) {
      const info = await PostnummerRegisterApi.getByPostkode(postnr);
      if (info) {
        addressCopy["postnr"] = postnr;
        addressCopy["poststed"] = info.poststed;
      } else {
        addressCopy["postnr"] = postnr;
        addressCopy["poststed"] = null
      }
    } else {
      addressCopy["postnr"] = postnr;
    }
    addressCopy.formattedForOneLine = formatAddressAsOneLiner(addressCopy);
    props.onAdresseChanged(addressCopy);
  };

  // update specific address object value, ex: besoksadresse.postnr
  const handleAddressValuesChanged = (targetName: string, value: string) => {
    if (targetName) {
      const addressCopy = { ...props.adresse };
      addressCopy[targetName] = value;
      addressCopy.formattedForOneLine = formatAddressAsOneLiner(addressCopy);
      props.onAdresseChanged(addressCopy);
      setTouched(true);
    }
  }

  const validate = useCallback(() => {
    let adresseErrors: any = {};

    if (props.adresse) {
      const isPostnummerOnlyNumbers = /^\d+$/.test(props.adresse?.postnr);
      const hasPoststedNumbers = /\d/.test(props.adresse?.poststed);
      if (!props.adresse.gateadresse) adresseErrors.gateadresse = 'Gatenavn mangler!';
      if (!isPostnummerOnlyNumbers) adresseErrors.postnr = 'Bare tall i postnummer!';
      if (!props.adresse.postnr) adresseErrors.postnr = 'Postnummer mangler!';
      if (props.adresse.postnr?.length > 4) adresseErrors.postnr = 'Kun 4 tall i postnummer!';
      if (!props.adresse.poststed) adresseErrors.poststed = 'Poststed mangler!';
      if (hasPoststedNumbers) adresseErrors.poststed = 'Poststed skal ikke inneholde tall';
      if (!props.adresse.landkode) adresseErrors.landkode = 'Land mangler!';
      if (props.requireCoordinates && props.requireCoordinates === true) {
        if (!props.adresse.latitude || !props.adresse.longitude) {
          adresseErrors.koordinater = 'Koordinater mangler!';
        }
      }
    }
    setErrors(adresseErrors);
  }, [props.adresse]);

  useEffect(() => {
    if (isMountedRef.current) {
      validate();
    }
  }, [isMountedRef, props.adresse, validate]);

  const hasErrors = errors && (Object.keys(errors).find(key => errors[key])?.length > 0);

  const disableAddressInput = props.kontonrOgFakturaAdresseSjekkResponse?.harSammeOrgNr && !props.kontonrOgFakturaAdresseSjekkResponse.fakturaAdresseErForskjellig;
  const hasAddressChangedRef = useRef(false);
  const saveDisabled = !hasErrors && !touched;

  useEffect(() => {
    if (hasAddressChangedRef.current) {
      props.onSave();
      hasAddressChangedRef.current = false;
    }
  }, [props.adresse, props.onSave]);

  const handleAddressChanged = (newAddress: IAdresse) => {
    setTouched(true);
    props.onAdresseChanged(newAddress);
  }

  const handleSaveClicked = () => {
    props.onSave();
    setTouched(false);
  }

  const formatErrors = (errors: object): ReactNode => {
    return Object.entries(errors).map(o => <li key={o[0]}>{o[1]}</li>)
  }

  const handleEkstraInfoChanged = (info: string): void => {
    setTouched(true);
    props.onEkstraInfoChanged(info);
  }

  return (
    <div className={Style.addressContainer}>
      <h6>{props.title}</h6>
      {props.ekstraInfo && (
        <div>
          <span>Ekstra info: </span>
          <span className="text-muted">{props.ekstraInfo}</span>
        </div>
      )}
      <InputGroup className={`${Style.inputGroup}`} ref={adresseInputRef} onFocus={() => setShowReadonlyWarning(prev => disableAddressInput ? !prev : false)} onBlur={() => setShowReadonlyWarning(false)}>
        {!props.adresse ? (
          <InputGroup.Text style={{ paddingLeft: '.6rem', paddingRight: '.6rem' }}>
            <IcebergIcon icon="search" />
          </InputGroup.Text>
        ) : (
          <InputGroup.Text style={{ paddingLeft: '.45rem', paddingRight: '.45rem' }}>
            <IcebergIcon icon="check" size="lg" bold />
          </InputGroup.Text>
        )}
        <AddressTypeahead
          allowNew
          selected={props.adresse}
          onChange={handleAddressChanged}
          isInvalid={props.adresse?.formattedForOneLine.length > 0 && hasErrors}
          isValid={touched && props.adresse?.formattedForOneLine.length > 0 && !hasErrors}
          disabled={disableAddressInput}
          handleDelete={props.onDelete}
        />
        <IconButton icon={showDetails ? 'eye-closed' : 'eye-open'} size='sm' variant='outline-primary' onClick={() => { setShowDetails(prev => !prev); setIsResetDisabled(true); setShowReadonlyWarning(false) }}>
          Detaljer
        </IconButton>

        <IconButton disabled={saveDisabled} icon='disk' size='sm' variant='primary' onClick={handleSaveClicked}>
          Lagre
        </IconButton>


      </InputGroup>

      {/* <PopoverMenuAdressInput onDelete={props.onDelete} /> */}

      <Overlay rootClose target={adresseInputRef.current} show={showReadonlyWarning} placement="top">
        {(props) => (
          <Tooltip id="overlay-warning" className={Style.adresseTooltip} {...props}>
            Enhet har samme orgnr som kjedens hovedkontor og skal da dele fakturaadresse. Dette kan ikke endres.
          </Tooltip>
        )}
      </Overlay>

      {hasErrors && <Form.Text style={{ color: 'red' }}>
        <ul>
          {formatErrors(errors)}
        </ul>
      </Form.Text>}

      {!disableAddressInput && <div className={Style.optionsContainer}>
        <div className={Style.addressOptions}>
          {
            props.copyAddresses && props.copyAddresses.length > 0 &&
            props.copyAddresses.map(copyAddress =>
              <Button
                key={copyAddress.title}
                size='sm'
                variant="link"
                disabled={!copyAddress.adresse?.id || showDetails || copyAddress.adresse.formattedForOneLine === props.adresse?.formattedForOneLine}
                onClick={() => {
                  props.onAdresseCopied(copyAddress.adresse, copyAddress.ekstraInfo);
                  hasAddressChangedRef.current = true;
                }}>
                {copyAddress.title}
              </Button>
            )
          }
          {showDetails && (
            <Button
              size='sm'
              variant="link"
              onClick={() => {
                setIsResetDisabled(prev => !prev);
              }}
            >
              {isResetDisabled ? 'Rediger adresse' : 'Avbryt'}
            </Button>
          )}
        </div>

      </div>}
      {showDetails && (
        <div>
          <AddressDetails
            errors={errors}
            isFormDisabled={isResetDisabled}
            adresse={props.adresse}
            ekstraInfo={props.ekstraInfo}
            onEkstraInfoChanged={handleEkstraInfoChanged}
            coordinates={props.coordinates}
            setCoordinates={props.setCoordinates}
            onValueChanged={handleAddressValuesChanged}
            onCoordinatesChanged={props.onUpdateCoordinates}
            onCoordinatesRemoved={props.onDeleteCoordinates}
            onPostnrChanged={handlePostnrChanged}
          />
        </div>
      )}
    </div>
  )
}

export default AdresseInput;
