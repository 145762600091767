import { Grossist } from "services/KjederegisteretAdminApi/GrossisterApi/types";
import { groupBy } from "lodash";

export interface IMappedGrossistGruppeNode {
    label: string;
    value: string;
    id?: number;
    nr?: number;
    gln?: string;
    children?: IMappedGrossistGruppeNode[];
    checked?: boolean;
}

export const mapGrossistGruppeNode = (grossister: Grossist[], selectedGrossists: string[]): IMappedGrossistGruppeNode[] => {

    // group grossister by grossistgruppe
    const groupedByGruppe = groupBy(grossister, 'grossistgruppe');

    let nodes: IMappedGrossistGruppeNode[] = [];

    for (const [key, values] of Object.entries(groupedByGruppe)) {
        const c = (values as Grossist[]).map(g => {
            return {
                label: g.navn,
                value: g.gln?.toString(),
                id: g.grossistEnhetId,
                nr: g.grossistnr,
                gln: g.gln?.toString(),
                checked: selectedGrossists.includes(key) || selectedGrossists.includes(g.gln?.toString())
            };
        });
        nodes.push({
            label: key,
            value: key,
            children: c,
            checked: selectedGrossists.includes(key)
        });
    }

    // legg ugrupperte grossister på toppnivå
    const ungrouped = nodes.find(o => o.label === 'null');

    // fjern null gruppen
    nodes = nodes.filter(o => o.label !== 'null');

    // legg barna til null gruppen på toppnivå
    if (ungrouped && ungrouped.children) {
        ungrouped.children.forEach(c => {
            nodes.push(c);
        });
    }

    return nodes;
};


export const mapGrossistGruppeNode_old = (grossister: Grossist[], selectedGrossists: string[]): IMappedGrossistGruppeNode[] => {
    const individualGrossister = [];
    const groupedGrossister = [];
    grossister.forEach(grossist => {
        if (grossist.grossistgruppe !== null) {
            let groupIndex = groupedGrossister.findIndex(g => g.label === grossist.grossistgruppe);
            if (groupIndex === -1) {
                const group = {
                    label: grossist.grossistgruppe,
                    children: [{
                        label: grossist.navn,
                        id: grossist.grossistEnhetId,
                        nr: grossist.grossistnr,
                        gln: grossist.gln,
                        checked: selectedGrossists.includes(grossist.gln?.toString())
                    }],
                }
                groupedGrossister.push(group);
            } else {
                groupedGrossister[groupIndex].children.push({
                    label: grossist.navn,
                    id: grossist.grossistEnhetId,
                    nr: grossist.grossistnr,
                    gln: grossist.gln,
                    checked: selectedGrossists.includes(grossist.gln?.toString())
                });
            }
        } else {
            individualGrossister.push({
                label: grossist.navn,
                id: grossist.grossistEnhetId,
                nr: grossist.grossistnr,
                gln: grossist.gln,
                checked: selectedGrossists.includes(grossist.gln?.toString())
            });
        }
    }, {});

    const mappedGrossister = Object.entries(groupedGrossister).map(([groupId, items]) => {
        return {
            label: items.label,
            id: groupId,
            name: items.label,
            nr: undefined,
            gln: undefined,
            children: items.children,
        };
    });

    // kombinere individual grossister and groups
    return [...mappedGrossister, ...individualGrossister];
};


