import { useRef, useEffect, useState } from 'react';
import useGoogleMaps from './useGoogleMaps';

export default function useGeocoder() {
  const serviceRef = useRef<any>();
  const isLoaded = useGoogleMaps();

  useEffect(() => {
    if (isLoaded) {
      if (!serviceRef.current) {
        serviceRef.current = new window.google.maps.Geocoder();
      }
    }
  }, [isLoaded]);

  return serviceRef;
}
