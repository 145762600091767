import { useEffect, useState } from 'react';
import EnhetsgrupperingApi from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi';
import SortableTree from 'components/SortableTree';
import { Button, Row, Col, Form } from 'react-bootstrap';
import GrupperingOverview from './GrupperingOverview';
import CreateEnhetgruppering from './CreateEnhetgruppering';
import { Enhetgruppering, EnhetsgruppeTreeItem } from 'services/KjederegisteretAdminApi/EnhetsgrupperingApi/types';
import PageWrapper from 'components/PageWrapper';
import { IcebergIcon, IconButton } from '@tradesolution/iceberg-ui-react';
import Style from './index.module.css';
import CommonLoader from 'components/CommonLoader';

const Enhetsgruppering = () => {
  const [grupperinger, setGrupperinger] = useState<EnhetsgruppeTreeItem[]>([]);
  const [selectedGruppering, setSelectedGruppering] = useState<Enhetgruppering | undefined>();
  const [showNew, setShowNew] = useState<boolean>(false);
  const [isFetchingEnhetsgruppering, setIsFetchingEnhetsgruppering] = useState<boolean>(false);
  const [includeDisbanded, setIncludeDisbanded] = useState<boolean>(false);

  const loadGrupperinger = async (includeDisbandedGrupper: boolean) => {
    const gr = await EnhetsgrupperingApi.getEnhetsgrupperingerTree(includeDisbandedGrupper);
    setGrupperinger(gr);
  };

  useEffect(() => {
    loadGrupperinger(includeDisbanded);
  }, []);

  const onSelectGruppering = async selectedGrp => {
    setIsFetchingEnhetsgruppering(true);
    setShowNew(false);
    setSelectedGruppering(undefined);
    const gr = await EnhetsgrupperingApi.getEnhetgruppering(selectedGrp.id);
    setIsFetchingEnhetsgruppering(false);
    setSelectedGruppering(gr);
  };

  const onReset = () => {
    setSelectedGruppering(undefined);
    setShowNew(false);
  };

  const onReload = () => {
    setGrupperinger([]);
    onReset();
    loadGrupperinger(includeDisbanded);
  };

  const onCreateNewClicked = () => {
    onReset();

    setShowNew(!showNew);
  };

  const toggleIncludeDisbanded = () => {
    setIncludeDisbanded(!includeDisbanded);
    loadGrupperinger(!includeDisbanded);
  };

  return (
    <PageWrapper isFluid header="Administrasjon av enhetsgruppering">
      <Row>
        <Col>
          <IconButton
            icon="plus"
            className={Style.closeResetBtn}
            variant="primary"
            onClick={() => onCreateNewClicked()}
          >
            {!showNew ? <span>Opprett enhetsgruppering</span> : <span>Lukk</span>}
          </IconButton>
        </Col>
      </Row>
      <Row>
        <Col md={5} style={{ height: '90vh' }}>
          {grupperinger && grupperinger.length < 1 ? (
            <div className={Style.spinnerContainer}>
              <CommonLoader />
              <h5>Henter enhetsgrupperinger...</h5>
            </div>
          ) : (
            <SortableTree
              data={grupperinger}
              onNodeClick={k => onSelectGruppering(k)}
              expandAll={false}
              isSearchable>
              <Form.Group controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Inkluder nedlagte"
                  checked={includeDisbanded}
                  onChange={toggleIncludeDisbanded}
                />
              </Form.Group>
            </SortableTree>
          )}
        </Col>

        <Col md={7} style={{ height: '90vh' }}>
          <Row className={Style.opprettBtnRow}>
            <Col>
              <Row className={Style.contentRow}>
                {!selectedGruppering && showNew && (
                  <Col>
                    <Row>
                      <Col>
                        <h4>Ny enhetsgruppering</h4>
                      </Col>
                    </Row>
                    <CreateEnhetgruppering reload={onReload} />
                  </Col>
                )}

                {isFetchingEnhetsgruppering && (
                  <Col>
                    <Row>
                      <Col className={Style.spinnerContainerEgDetaljer}>
                        <CommonLoader />
                        <h5>Henter enhetsgruppering...</h5>
                      </Col>
                    </Row>
                  </Col>
                )}

                {selectedGruppering && (
                  <Col>
                    <Row className={Style.closeResetBtnRow}>
                      <Col>
                        <Button
                          className={Style.closeResetBtn}
                          variant="outline-dark"
                          onClick={onReset}
                        >
                          <IcebergIcon icon="close" bold />
                          <span style={{ paddingLeft: '.5em' }}>Lukk</span>
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <GrupperingOverview
                        onReset={onReset}
                        gruppering={selectedGruppering}
                        reload={onReload}
                      />
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageWrapper>
  );
}

export default Enhetsgruppering;
