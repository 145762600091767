import axios from "axios";

import { KommuneTree } from "./types";

import { getKjederegAdminApiUrl } from "../../";

const baseUrl = getKjederegAdminApiUrl() + '/kommuner';

async function getTree(): Promise<KommuneTree[]> {
  const response = await axios.get(`${baseUrl}/tree`);
  return response.data;
}

const KommuneApi = {
  getTree
}

export default KommuneApi;