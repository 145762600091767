import React from "react";

import { IcebergIcon } from '@tradesolution/iceberg-ui-react';

interface Props {
  sortDirection: 'asc' | 'desc' | '';
}

const SortingIcon: React.FC<Props> = props => {
  return (
    <IcebergIcon
      icon={props.sortDirection === 'asc' ? 'arrow-down' : (props.sortDirection === 'desc' ? 'arrow-up' : 'arrow-up-down')} />
  )
}

export default SortingIcon;